import React from "react";
export function Categories({ fill = "#666666", strokeWidth = 0 }) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="17" height="17" rx="1.5" stroke="#666666" />
      <rect x="3.5" y="3.5" width="11" height="7" rx="0.5" stroke="#666666" />
      <mask id="path-3-inside-1_27415_34426" fill="white">
        <rect x="3" y="12" width="3.1" height="3.5" rx="0.5" />
      </mask>
      <rect
        x="3"
        y="12"
        width="3.1"
        height="3.5"
        rx="0.5"
        fill="#F9F9F9"
        stroke="#666666"
        stroke-width="2"
        mask="url(#path-3-inside-1_27415_34426)"
      />
      <mask id="path-4-inside-2_27415_34426" fill="white">
        <rect x="7" y="12" width="4.1" height="3.5" rx="0.5" />
      </mask>
      <rect
        x="7"
        y="12"
        width="4.1"
        height="3.5"
        rx="0.5"
        fill="#F9F9F9"
        stroke="#666666"
        stroke-width="2"
        mask="url(#path-4-inside-2_27415_34426)"
      />
      <mask id="path-5-inside-3_27415_34426" fill="white">
        <rect x="12" y="12" width="3.1" height="3.5" rx="0.5" />
      </mask>
      <rect
        x="12"
        y="12"
        width="3.1"
        height="3.5"
        rx="0.5"
        fill="#F9F9F9"
        stroke="#666666"
        stroke-width="2"
        mask="url(#path-5-inside-3_27415_34426)"
      />
    </svg>
  );
}

export function SelCategories({ fill = "#666666", strokeWidth = 0 }) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="17" height="17" rx="1.5" stroke="#1641DB" />
      <rect x="3.5" y="3.5" width="11" height="7" rx="0.5" stroke="#1641DB" />
      <mask id="path-3-inside-1_29158_4595" fill="white">
        <rect x="3" y="12" width="3.1" height="3.5" rx="0.5" />
      </mask>
      <rect
        x="3"
        y="12"
        width="3.1"
        height="3.5"
        rx="0.5"
        fill="#F9F9F9"
        stroke="#1641DB"
        stroke-width="2"
        mask="url(#path-3-inside-1_29158_4595)"
      />
      <mask id="path-4-inside-2_29158_4595" fill="white">
        <rect x="7" y="12" width="4.1" height="3.5" rx="0.5" />
      </mask>
      <rect
        x="7"
        y="12"
        width="4.1"
        height="3.5"
        rx="0.5"
        fill="#F9F9F9"
        stroke="#1641DB"
        stroke-width="2"
        mask="url(#path-4-inside-2_29158_4595)"
      />
      <mask id="path-5-inside-3_29158_4595" fill="white">
        <rect x="12" y="12" width="3.1" height="3.5" rx="0.5" />
      </mask>
      <rect
        x="12"
        y="12"
        width="3.1"
        height="3.5"
        rx="0.5"
        fill="#F9F9F9"
        stroke="#1641DB"
        stroke-width="2"
        mask="url(#path-5-inside-3_29158_4595)"
      />
    </svg>
  );
}
