import { Paper, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import FilledEditButton from "../../../components/buttons/FilledEditButton";
import CombinedButtons from "../../../components/buttons/CombinedButtons";
import useStyles from "../components/ConfigStyles";
import {
  fetchApps,
  getPluginConfig,
  postPluginConfig,
} from "../../../redux/apps/appActions";
import { useDispatch, useSelector } from "react-redux";
import { copyToClipboard } from "../../../utils/utils";
import {
  RemoveRedEyeOutlined,
  VisibilityOffOutlined,
} from "@material-ui/icons";
import Controls from "../../../components/controls/Controls";
import Toast from "../../../components/Layout/Toast";
import { useHistory } from "react-router-dom";
import { centerContainer, layoutCenterContainer } from "../../../styles/common";
import HeaderBreadcrumbs from "../../../components/common/HeaderBreadcrumbs";
import axios from "axios";
import OutlinedLocationButton from "../../../components/buttons/OutlinedLocationButton";
import WebhookDetails from "./components/WebhookDetails";
import LocationFormModal from "../../../components/common/LocationFormModal";
import { fetchPickupLocation } from "../../../utils/locationUtils";

const list = [
  {
    label: "App Store",
    link: "/apps",
  },
  { label: "Xpressbees", link: "/apps/xpressbees" },
  {
    label: "Configure",
  },
];

const XpressbeesConfig = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const storeData = useSelector((state) => state.store);
  const appState = useSelector((state) => state.apps);
  const apps = appState.apps;

  const initialState = {
    email: {
      value: "",
      touched: false,
      error: false,
    },
    password: { value: "", touched: false, error: false },
  };
  const [formState, setFormState] = useState(initialState);
  const [isEditing, setIsEditing] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [location, setLocation] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (apps.length === 0) {
      dispatch(fetchApps());
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (storeData.fetched) {
      dispatch(getPluginConfig("xpressbees"));
      fetchPickupLocation(storeData, "xpressbees").then((loc) => {
        if (loc != null) {
          setLocation(loc);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeData]);

  useEffect(() => {
    if (Object.keys(appState.xpressbees).length > 0) {
      let form = {};
      Object.keys(appState.xpressbees).forEach((key) => {
        form[key] = {
          value: appState.xpressbees[key],
          touched: false,
          error: "",
        };
      });
      setFormState(form);
    }

    // eslint-disable-next-line
  }, [appState.xpressbees]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let error = "";

    if (name === "email") {
      if (value.length === 0 || !/^\S+@\S+\.\S+$/.test(value)) {
        error = "Please ensure to enter a valid email";
      }
    } else if (name === "password") {
      if (value.length === 0) {
        error = "Please enter a password";
      }
    }

    setFormState((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        value: value,
        touched: true,
        error: error,
      },
    }));
  };

  const getMessage = (name) => {
    const messages = {
      email: "Please enter a valid email.",
      password: "please enter a valid password.",
    };
    return messages[name] || "";
  };

  const onBlur = (event) => {
    const { name } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        error: !prevState[name].value
          ? getMessage(name)
          : prevState[name].error,
      },
    }));
  };

  const onFocus = (event) => {
    const { name } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        touched: true,
        error: "",
      },
    }));
  };

  const handleCopyToClipboard = (text) => () => {
    const result = copyToClipboard(text);
    if (!result) {
      setShowError(true);
    } else {
      setShowSuccess(true);
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
    let clonedForm = { ...initialState };
    clonedForm.email.value = formState.email.value;
    setFormState(clonedForm);
  };

  const validation = () => {
    let errorFlag = false;
    Object.keys(formState).forEach((key) => {
      let cloneData = formState[key];
      if (!cloneData.value) {
        errorFlag = true;
        cloneData.error = getMessage(key);
      }
      setFormState({ ...formState, [key]: cloneData });
    });
    return errorFlag;
  };

  const navigateToUrl = () => {
    if (isEditing && Object.keys(appState.xpressbees).length > 0) {
      setIsEditing(false);
      let form = {};
      Object.keys(appState.xpressbees).forEach((key) => {
        form[key] = {
          value: appState.xpressbees[key],
          touched: false,
          error: "",
        };
      });
      setFormState(form);
    } else {
      history.goBack();
    }
  };

  const handleSave = async () => {
    let isError = validation();
    if (isError) return;

    dispatch(
      postPluginConfig("xpressbees", {
        email: formState.email.value,
        token: formState.password.value,
      })
    ).then((cobj) => {
      if (cobj.error) {
        setErrorMessage(cobj.message);
        setShowError(true);
      }
    });
  };

  const onLocationSave = async (data) => {
    let storeSlug = storeData.store.slug;
    let accessToken = localStorage.getItem("access_token");
    let resp = {};
    if (location && location.id) {
      resp = await axios.put(
        `${process.env.REACT_APP_API}/api/v1/store/${storeSlug}/pickup-locations/${location.id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );
    } else {
      resp = await axios.post(
        `${process.env.REACT_APP_API}/api/v1/store/${storeSlug}/pickup-locations`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );
    }
    setLocation(resp.data.payload);
  };

  return (
    <div className={layoutCenterContainer}>
      <div className={centerContainer}>
        <div className={classes.breadcrumbsContainer}>
          <div className={classes.backDiv}>
            <HeaderBreadcrumbs list={list} />
          </div>
        </div>
        <Paper elevation={0} className={classes.paper}>
          <div>
            <div className={classes.headingButtonSection}>
              <Typography className={classes.formHead}>
                Xpressbees Configuration Settings
              </Typography>

              {formState["token"] ? (
                <FilledEditButton onClick={handleEdit} />
              ) : (
                <OutlinedLocationButton onClick={() => setOpenModal(true)} />
              )}
            </div>

            <Controls.Input
              name="email"
              label="Email"
              value={formState.email.value}
              onChange={handleInputChange}
              error={
                formState.email && formState.email.error.length > 0
                  ? formState.email.error
                  : ""
              }
              onFocus={onFocus}
              onBlur={onBlur}
              labelPlacement="top"
              disabled={formState["token"] ? true : false}
            />
            <Controls.Input
              name="password"
              label="Password"
              value={
                formState.token
                  ? formState.token.value
                  : formState.password
                  ? formState.password.value
                  : ""
              }
              onChange={handleInputChange}
              error={
                formState.password && formState.password.error.length > 0
                  ? formState.password.error
                  : ""
              }
              onFocus={onFocus}
              onBlur={onBlur}
              labelPlacement="top"
              disabled={formState["token"] ? true : false}
              endAdornment={
                formState["token"] ? (
                  <></>
                ) : !showPass ? (
                  <RemoveRedEyeOutlined
                    style={{ cursor: "pointer", color: "#2238A8" }}
                    onClick={() => {
                      setShowPass(true);
                    }}
                  />
                ) : (
                  <VisibilityOffOutlined
                    style={{ cursor: "pointer", color: "#2238A8" }}
                    onClick={() => {
                      setShowPass(false);
                    }}
                  />
                )
              }
              type={showPass ? "text" : "password"}
            />
            {formState["token"] && (
              <WebhookDetails
                formData={formState}
                handleCopyToClipboard={handleCopyToClipboard}
              />
            )}
          </div>

          {!formState["token"] && (
            <div className={classes.button}>
              <CombinedButtons
                outlinedBtnText="Back"
                outlinedBtnAction={navigateToUrl}
                solidBtnAction={handleSave}
              />
            </div>
          )}
        </Paper>
      </div>
      <LocationFormModal
        open={openModal}
        setOpen={setOpenModal}
        title={"Pickup Location"}
        record={location}
        onSave={onLocationSave}
      />
      <Toast
        message={errorMessage ? errorMessage : "Could not copy to clipboard"}
        open={showError}
        close={() => setShowError(false)}
        severity="error"
        autoClose
      />
      <Toast
        message={"Copied to clipboard"}
        open={showSuccess}
        close={() => setShowSuccess(false)}
        autoClose
      />
    </div>
  );
};

export default XpressbeesConfig;
