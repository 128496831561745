import {
  Box,
  Typography,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import useStyles from "../../../styles";
import { DeleteIcon } from "../../../../../../icons";
import Controls from "../../../../../../components/controls/Controls";
import Toast from "../../../../../../components/Layout/Toast";
import { interval, options } from "../../../planUtils";
import { useSelector } from "react-redux";

const DeliveryFrequency = ({
  formData,
  setFormData,
  onChange,
  onBlur,
  errors,
}) => {
  const classes = useStyles();
  const [fields, setFields] = useState([]);
  const [message, setMessage] = useState("");
  const [discount, setDiscount] = useState("AMOUNT_OFF");
  const store = useSelector((state) => state.store.store);
  const countrySymbol = store.currency_symbol;
  useEffect(() => {
    if (formData.frequencies) {
      setFields(formData.frequencies);
      setDiscount(formData.frequencies[0].discount_type);
    }
  }, [formData.frequencies]);

  const handleFieldChange = (index, e) => {
    const { name, value } = e.target;
    if (name === "discount_type") {
      const updatedFields = fields.map((field) => ({
        ...field,
        discount_type: value,
      }));
      setDiscount(value);
      setFields(updatedFields);
      setFormData((prev) => ({ ...prev, frequencies: updatedFields }));
    } else if (name === "interval") {
      let selectedValue = value;
      const selectedInterval = interval.find(
        (item) => item.id === selectedValue
      );
      const repeatEvery = selectedInterval ? selectedInterval.frequecy : "1";

      const updatedFields = fields.map((field, i) =>
        i === index
          ? { ...field, [name]: selectedValue, repeat_every: repeatEvery }
          : field
      );

      setFields(updatedFields);
      setFormData((prev) => ({ ...prev, frequencies: updatedFields }));
    } else {
      const parsedValue =
        name === "repeat_every" || name === "discount_value"
          ? parseInt(value, 10) || 0
          : value;
      onChange(e, index, "frequencies");

      const updatedFields = fields.map((field, i) =>
        i === index ? { ...field, [name]: parsedValue } : field
      );

      setFields(updatedFields);
      setFormData((prev) => ({ ...prev, frequencies: updatedFields }));
      onBlur(name, parsedValue);
    }
  };

  const handleAddOption = () => {
    const newField = {
      interval: "",
      repeat_every: null,
      discount_type: discount,
      discount_value: null,
    };
    setFields((prev) => [...prev, newField]);
    setFormData((prev) => ({
      ...prev,
      frequencies: [...prev.frequencies, newField],
    }));
  };

  const handleRemoveOption = (index) => {
    const targetField = fields[index];
    if (targetField.deletable === false) {
      setMessage("Cannot delete Interval, Contract exists!");
      return;
    }
    const updatedFields = fields.filter((_, i) => i !== index);
    setFields(updatedFields);
    setFormData((prev) => ({ ...prev, frequencies: updatedFields }));
  };

  // Get all selected intervals to filter out
  const selectedIntervals = fields.map((field) => field.interval);
  return (
    <div>
      <Typography className={classes.heading}> Discount Type </Typography>

      <div>
        <div style={{ display: "flex", marginTop: "4px" }}>
          <RadioGroup
            name="discount_type"
            value={discount || ""}
            onChange={(e) => handleFieldChange(null, e)}
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              marginLeft: "13px",
              gap: "15px",
            }}
          >
            {options.map((option) => (
              <FormControlLabel
                key={option.id}
                control={
                  <Radio
                    value={option.id}
                    style={{ width: "20px", height: "20px" }}
                  />
                }
                label={
                  <Typography
                    style={{
                      marginLeft: "5px",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {option.title}
                  </Typography>
                }
              />
            ))}
          </RadioGroup>
        </div>
      </div>

      {fields.map((field, index) => (
        <Box
          key={index}
          style={{
            justifyContent: "space-between",
            display: "flex",
            marginTop: "8px",
          }}
        >
          <div style={{ width: "45%" }}>
            <Controls.Select
              name="interval"
              label="Interval"
              labelFontWeight={400}
              placeholder="Select"
              customHeight="0px"
              labelPlacement="top"
              options={interval
                .filter(
                  (option) =>
                    !selectedIntervals.includes(option.id) ||
                    option.id === field.interval
                )
                .map((option) => ({
                  id: option.id,
                  title: option.title,
                }))}
              value={field.interval || "none"}
              onChange={(e) => handleFieldChange(index, e)}
              error={errors[`frequency_${index}_interval`]}
              onBlur={(e) =>
                onBlur(`frequency_${index}_interval`, e.target.value, index)
              }
            />
          </div>

          <div style={{ width: "45%" }}>
            <Controls.Input
              type="text"
              name="discount_value"
              label={
                field.discount_type === "FLAT_RATE"
                  ? "Flat Rate"
                  : field.discount_type === "AMOUNT_OFF"
                  ? "Amount Off"
                  : field.discount_type === "PERCENTAGE"
                  ? "Percentage"
                  : "Discount"
              }
              labelFontWeight={400}
              labelPlacement="top"
              startAdornment={
                field.discount_type === "FLAT_RATE" ||
                field.discount_type === "AMOUNT_OFF" ? (
                  <span className={classes.rupee}>{countrySymbol}</span>
                ) : null
              }
              endAdornment={
                field.discount_type === "PERCENTAGE" ? (
                  <span className={classes.rupee}>%</span>
                ) : null
              }
              value={field.discount_value}
              onChange={(e) => handleFieldChange(index, e)}
              error={!!errors[`frequency_${index}_discount_value`]}
              helperText={errors[`frequency_${index}_discount_value`]}
              onBlur={(e) =>
                onBlur(
                  `frequency_${index}_discount_value`,
                  e.target.value,
                  index
                )
              }
            />
          </div>
          <Box style={{ position: "relative", top: "6px", margin: "auto 0" }}>
            <DeleteIcon
              color="red"
              onClick={
                fields.length > 1 ? () => handleRemoveOption(index) : null
              }
              style={{
                cursor: fields.length > 1 ? "pointer" : "not-allowed",
                opacity: fields.length > 1 ? 1 : 0.6,
              }}
            />
          </Box>
        </Box>
      ))}
      {fields.length < 6 && (
        <Box>
          <Button
            className={classes.addOptionButton}
            onClick={handleAddOption}
            disabled={fields.some(
              (field) => !field.interval || !field.discount_value
            )}
          >
            + Add Option
          </Button>
        </Box>
      )}
      <Toast
        open={message}
        message={message}
        severity="error"
        close={() => setMessage(null)}
      />
    </div>
  );
};

export default DeliveryFrequency;
