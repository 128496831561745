import { TextField, Typography } from "@material-ui/core";
import React, { forwardRef } from "react";
import {
  inputTag,
  rowFlexAlignCenter,
  rowFlexAlignStart,
} from "../../styles/common";

const Input = forwardRef(
  (
    {
      id,
      label = "",
      labelPlacement = "start",
      labelFontWeight = 400,
      width,
      labelWidth,
      containerCss = {},
      textClass,
      inputCss = {},
      tag,
      hideHelperText = false,
      helperSubText = "",
      extraSpace = false,
      labelStyle = {},
      inputCtnCss = {},
      labelCtnStyle = {},
      ...props
    },
    ref
  ) => {
    const endAdornment = props.endAdornment;
    const startAdornment = props.startAdornment;
    const maxLength = props.maxLength;
    const error = props.error;
    const onEnter = props.onEnter;
    const onKeyDown = props.onKeyDown;
    const onKeyPress = props.onKeyPress;
    const preventDecimal = props.preventDecimal;
    delete props.endAdornment;
    delete props.startAdornment;
    delete props.maxLength;
    delete props.error;
    delete props.onEnter;
    delete props.onKeyDown;
    delete props.onKeyPress;
    delete props.preventDecimal;

    return (
      <div
        className={labelPlacement === "start" ? rowFlexAlignCenter : ""}
        style={{ ...containerCss }}
      >
        {label && (
          <div className={`${rowFlexAlignStart}`} style={{ ...labelCtnStyle }}>
            <Typography
              style={{
                fontSize: 14,
                fontWeight: labelFontWeight,
                minWidth:
                  labelPlacement === "start"
                    ? labelWidth
                      ? labelWidth
                      : 110
                    : 0,
                maxWidth: labelPlacement === "start" ? 110 : "auto",
                marginRight: labelPlacement === "start" ? 8 : 0,
                marginTop: labelPlacement === "start" ? -18 : 0,
                marginBottom: labelPlacement === "top" ? 8 : 0,
                ...labelStyle,
              }}
            >
              {label}
            </Typography>
            {tag && <div className={inputTag}>{tag}</div>}
          </div>
        )}
        <div
          style={
            labelPlacement === "start"
              ? {
                  width: width ? width : "100%",
                  ...(!width && { flexGrow: 1 }),
                  ...inputCtnCss,
                }
              : { width: width ? width : "100%", ...inputCtnCss }
          }
        >
          <TextField
            id={id}
            color="secondary"
            variant={props?.variant ?? "outlined"}
            InputProps={{
              ...(endAdornment && { endAdornment }),
              ...(startAdornment && { startAdornment }),
              ...props.InputProps,
              style: { border: "1px solid #E6E6E6", borderRadius: 4 },
            }}
            style={{ ...inputCss }}
            className={textClass}
            autoFocus={props.autoFocus ? true : false}
            error={!!error}
            {...props}
            fullWidth
            ref={ref}
            onChange={(e) => {
              if (maxLength && e.target.value.length > maxLength) {
                return;
              }
              props.onChange && props.onChange(e);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onEnter && onEnter(e);
              }
              onKeyDown && onKeyDown(e);
            }}
            onKeyPress={(e) => {
              if (preventDecimal)
                if (!(e.charCode >= 48 && e.charCode <= 57)) {
                  e.preventDefault();
                }
              onKeyPress && onKeyPress(e);
            }}
          />
          {helperSubText && (
            <Typography
              style={{ fontSize: 12, margin: "0px 2px", color: "#666666" }}
            >
              {helperSubText}
            </Typography>
          )}
          {!hideHelperText && (
            <Typography
              style={{
                color: error ? "#F44336" : "#FFFFFF",
                fontSize: 12,
                margin: "0px 2px",
              }}
            >
              {error ? error : <span>h</span>}
            </Typography>
          )}
          {extraSpace ? <br></br> : ""}
        </div>
      </div>
    );
  }
);

export default Input;
