import axios from "axios";
import { useLocation } from "react-router-dom";
import { getDefaultStore } from "../../../../utils/storeUtil";
export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const initialFormState = {
  type: { value: "FLAT", hasError: false, error: "", helpText: "" },
  code: {
    value: "",
    hasError: false,
    error: "",
    helpText:
      "Coupon code that users will enter during checkout (16 characters)",
    touched: false,
  },
  validity: {
    value: "",
    hasError: false,
    error: "",
    helpText: "No expiry date if you keep it empty",
    touched: false,
  },
  usage_per_customer: {
    value: "",
    hasError: false,
    error: "",
    helpText: "No. of times same customer can use this coupon",
    touched: false,
  },
  max_discount: {
    value: "",
    hasError: false,
    error: "",
    helpText: "Type the discount amount you want to provide",
    touched: false,
  },
  min_sale_price: {
    value: "",
    hasError: false,
    error: "",
    helpText: "Type the minimum order amount to be eligible for the discount",
    touched: false,
  },
  percentage: {
    value: "",
    hasError: false,
    error: "",
    helpText: "Type the discount in percent that you want to provide",
    touched: false,
  },
  auto_apply: {
    value: false,
    hasError: false,
    error: "",
    helpText: "Type the discount in percent that you want to provide",
    touched: false,
  },
  visible: {
    value: false,
    hasError: false,
    error: "",
    helpText: "",
    touched: false,
  },
  new_customer: {
    value: false,
    hasError: false,
    error: "",
    helpText: "",
    touched: false,
  },
  description: {
    value: "",
    hasError: false,
    error: "",
    helpText: "Describe the coupon briefly, it will be visible to Customers",
    touched: false,
  },
  banner_template_id: {
    value: "",
    hasError: false,
    error: "",
    helpText: "",
    touched: false,
  },
  cat_slugs: {
    value: [],
    hasError: false,
    error: "",
    helpText: "",
    touched: false,
  },
  super_prod_skus: {
    value: {},
    hasError: false,
    error: "",
    helpText: "",
    touched: false,
  },
  customers: {
    value: {},
    hasError: false,
    error: "",
    helpText: "",
    touched: false,
  },
};

const normalizeProductData = (productArr, store) => {
  const countrySymbol = store.currency_symbol;
  return productArr.map((product) => {
    return {
      title: product.display_name,
      subTitle: `${countrySymbol} ${product.sale_price}`,
      id: product.super_prod_sku,
      image: product.thumbnail,
    };
  });
};

const normalizeCustomerData = (customerArr) => {
  return customerArr.map((cust) => {
    return {
      title: cust.name,
      subTitle: cust.mobile,
      id: cust.id,
      image: null,
    };
  });
};

const normalizeProductDataForHydration = (arr, store) => {
  const countrySymbol = store.symbol;
  let productsMap = {};
  arr.forEach((product) => {
    productsMap[product.super_prod_sku] = {
      title: product.name,
      subTitle: `${countrySymbol} ${product.sale_price}`,
      id: product.super_prod_sku,
      image: product.thumbnail,
    };
  });
  return productsMap;
};

const normalizeCustomerDataForHydration = (arr) => {
  let customerMap = {};
  arr.forEach((customer) => {
    customerMap[customer.id] = {
      title: customer.name,
      subTitle: customer.mobile,
      id: customer.id,
      image: null,
    };
  });
  return customerMap;
};

const normalizeCategoryDataForHydration = (arr) => {
  return arr.map((_) => _.slug);
};

export const handleProductDataFetch = async (
  keyword,
  size = 50,
  page = 0,
  relatedProductSku
) => {
  try {
    let accessToken = localStorage.getItem("access_token");
    const store = getDefaultStore();
    let response = await axios.get(
      `${process.env.REACT_APP_API}/api/v1/org/${store.org_id}/super-products${
        process.env.REACT_APP_LOCAL_HOST === "api.shoopy.in" ? "/search" : ""
      }?q=${keyword}&page=${page}&size=${size}&sort=name,asc${relatedProductSku}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const list = normalizeProductData(response.data.payload.content, store);
    return { list, response };
  } catch (err) {
    console.log({ err });
    return [];
  }
};

// Function to handle fetching super products with keyword search

export const handleCustomerDataFetch = async (keyword) => {
  try {
    let accessToken = localStorage.getItem("access_token");
    const store = getDefaultStore();
    let response = await axios.get(
      `${process.env.REACT_APP_API}/api/v1/org/store/${
        store.store_id
      }/customer?query=${keyword}&page=${0}&size=${50}&sort=name,asc`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const list = normalizeCustomerData(response.data.payload.content);
    return { list };
  } catch (err) {
    console.log({ err });
    return [];
  }
};

export const hydrateProductData = async (idString) => {
  try {
    let queryString = idString
      .split(",")
      .map((_) => `super-product-sku=${_}`)
      .join("&");
    let accessToken = localStorage.getItem("access_token");
    let store = getDefaultStore();
    let response = await axios.get(
      `${process.env.REACT_APP_API}/api/v1/org/${store.org_id}/super-products/list?${queryString}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return normalizeProductDataForHydration(response.data.payload, store);
  } catch (err) {
    console.log({ err });
    return [];
  }
};

export const hydrateCustomerData = async (idString) => {
  try {
    let queryString = idString
      .split(",")
      .map((_) => `id=${_}`)
      .join("&");
    let accessToken = localStorage.getItem("access_token");
    const store = getDefaultStore();
    let response = await axios.get(
      `${process.env.REACT_APP_API}/api/v2/org/store/${store.store_id}/customers/list?${queryString}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return normalizeCustomerDataForHydration(response.data.payload);
  } catch (err) {
    console.log({ err });
    return [];
  }
};

export const hydrateCategoryData = async (idString) => {
  try {
    let queryString = idString
      .split(",")
      .map((_) => `cat-slug=${_}`)
      .join("&");
    let accessToken = localStorage.getItem("access_token");
    const store = getDefaultStore();
    let response = await axios.get(
      `${process.env.REACT_APP_API}/api/v2/org/${store.org_id}/categories/list?${queryString}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return normalizeCategoryDataForHydration(response.data.payload);
  } catch (err) {
    console.log({ err });
    return [];
  }
};

export const fetchCouponById = async (id) => {
  try {
    const store = getDefaultStore();
    const accessToken = localStorage.getItem("access_token");
    const response = await fetch(
      `${process.env.REACT_APP_API}/api/v1/store/${store.store_id}/coupons/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (!response.ok) {
      throw response;
    } else {
      let data = await response.json();
      return data.payload;
    }
  } catch (err) {
    console.log({ err });
    return {};
  }
};

export const CouponItemsLinkingTypes = [
  {
    id: "entire-order",
    title: "Entire Order",
  },
  {
    id: "selected-categories",
    title: "Specific Categories",
    plan: "BASIC",
  },
  {
    id: "selected-products",
    title: "Specific Products",
    plan: "BASIC",
  },
];
