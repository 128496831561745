import React from "react";

export default function ResetIcon(props) {
  return (
    <svg
      width="17"
      height="19"
      viewBox="0 0 17 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.85059 18.95C5.83392 18.7 4.16309 17.8208 2.83809 16.3125C1.51309 14.8042 0.850586 13.0333 0.850586 11C0.850586 9.9 1.06725 8.84583 1.50059 7.8375C1.93392 6.82917 2.55059 5.95 3.35059 5.2L4.77559 6.625C4.14225 7.19167 3.66309 7.85 3.33809 8.6C3.01309 9.35 2.85059 10.15 2.85059 11C2.85059 12.4667 3.31725 13.7625 4.25059 14.8875C5.18392 16.0125 6.38392 16.7 7.85059 16.95V18.95ZM9.85059 18.95V16.95C11.3006 16.6833 12.4964 15.9917 13.4381 14.875C14.3798 13.7583 14.8506 12.4667 14.8506 11C14.8506 9.33333 14.2673 7.91667 13.1006 6.75C11.9339 5.58333 10.5173 5 8.85059 5H8.77559L9.87559 6.1L8.47559 7.5L4.97559 4L8.47559 0.5L9.87559 1.9L8.77559 3H8.85059C11.0839 3 12.9756 3.775 14.5256 5.325C16.0756 6.875 16.8506 8.76667 16.8506 11C16.8506 13.0167 16.1881 14.7792 14.8631 16.2875C13.5381 17.7958 11.8673 18.6833 9.85059 18.95Z"
        fill="currentColor"
      />
    </svg>
  );
}
