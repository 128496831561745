import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-number-input/style.css";
import { InputLabel, Typography } from "@material-ui/core";
import { rowFlexAlignStart } from "../../styles/common";
import { validatePhoneNumber } from "../../utils/phoneUtils";

const ReusablePhoneInput = ({
  value,
  onChange,
  country = "in",
  placeholder = "Phone Number",
  specialLabel = "",
  error,
  onValidation,
  labelCtnStyle = {},
  labelStyle = {},
  labelFontWeight = 400,
  height = 36,
  labelPlacement = "top",
  label = "Phone Number",
  helperText = "",
  onlyCountries,
}) => {
  const [isValid, setIsValid] = useState(true);
  const [showError, setShowError] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(country);
  const handlePhoneChange = (value, country) => {
    if (onChange) {
      onChange(value);
    }
    if (country.countryCode !== selectedCountry) {
      const phoneNumberObj = validatePhoneNumber(
        value,
        selectedCountry.toUpperCase()
      );
      setIsValid(phoneNumberObj);
      if (!phoneNumberObj) {
        setShowError(true);
      }
    }
    if (country) {
      setSelectedCountry(country.countryCode);
    }
  };

  const handleBlur = () => {
    const formattedValue = value.startsWith("+") ? value : `+${value}`;
    const phoneNumberObj = validatePhoneNumber(formattedValue, selectedCountry);
    console.log(phoneNumberObj);
    setIsValid(phoneNumberObj);
    if (!phoneNumberObj) {
      setShowError(true);
    }
    if (onValidation) {
      onValidation(phoneNumberObj);
    }
  };

  const handleFocus = () => {
    setShowError(false);
  };

  return (
    <div>
      {label && (
        <div className={`${rowFlexAlignStart}`} style={{ ...labelCtnStyle }}>
          <Typography
            style={{
              fontSize: 14,
              fontWeight: labelFontWeight,
              maxWidth: labelPlacement === "start" ? 110 : "auto",
              marginRight: labelPlacement === "start" ? 8 : 0,
              marginTop: labelPlacement === "start" ? -18 : 0,
              marginBottom: labelPlacement === "top" ? 8 : 0,
              ...labelStyle,
            }}
          >
            {label}
          </Typography>
        </div>
      )}

      <PhoneInput
        onlyCountries={onlyCountries?.length ? onlyCountries : undefined}
        international
        name="phone"
        specialLabel={specialLabel}
        country={selectedCountry || country}
        placeholder={placeholder}
        value={value?.startsWith("+") ? value : `+${selectedCountry}${value}`}
        onChange={handlePhoneChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        isValid={!error}
        inputStyle={{
          height: height,
          fontSize: "14px",
        }}
        dropdownStyle={{
          borderRadius: "4px",
        }}
      />

      <div style={{ minHeight: "18px", marginTop: "4px" }}>
        {!isValid && showError ? (
          <p style={{ color: "red", fontSize: "12px" }}>
            {error || "Invalid phone number"}
          </p>
        ) : (
          helperText && (
            <p style={{ color: "gray", fontSize: "12px" }}>{helperText}</p>
          )
        )}
      </div>
    </div>
  );
};

export default ReusablePhoneInput;
