import React from "react";
import {
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  Link,
} from "@material-ui/core";
import { getStoreIdFromUrl } from "../../../utils/storeUtil";

const CODRiskComponent = ({
  title,
  description,
  checked,
  onChange,
  riskLevels,
  paymentSettings,
}) => {
  const storeId = getStoreIdFromUrl();
  return (
    <Box>
      <Box borderRadius={4}>
        <Typography style={{ fontSize: 14, fontWeight: 600 }}>
          {title}
        </Typography>
        <Typography style={{ fontSize: 12, color: "#666666" }}>
          {description}
        </Typography>
        <Box mt={1} style={{ display: "flex", flexDirection: "column" }}>
          <FormControlLabel
            style={{ marginBottom: 0 }}
            control={<Checkbox />}
            label={
              <Typography style={{ fontWeight: 400, fontSize: 14 }}>
                COD
              </Typography>
            }
            disabled={!paymentSettings.isCODEnabled || !checked}
            onChange={() => onChange("COD")}
            checked={riskLevels.COD}
          />
          <FormControlLabel
            control={<Checkbox />}
            label={
              <Typography style={{ fontWeight: 400, fontSize: 14 }}>
                Partial Payment
              </Typography>
            }
            checked={riskLevels.PART_PAID}
            disabled={!checked || !paymentSettings.isPartialPaymentAllowed}
            style={{ marginBottom: 0 }}
            onChange={() => onChange("PART_PAID")}
          />
          <FormControlLabel
            style={{ marginBottom: 0 }}
            control={<Checkbox />}
            label={
              <Typography style={{ fontWeight: 400, fontSize: 14 }}>
                Prepaid
              </Typography>
            }
            checked={riskLevels.PREPAID}
            disabled={!paymentSettings.isPrepaidEnabled || !checked}
            onChange={() => onChange("PREPAID")}
          />
        </Box>
        {!paymentSettings.isPartialPaymentAllowed && (
          <Typography
            style={{ marginTop: "5px", fontSize: 12, fontStyle: "italic" }}
          >
            To enable partial payment and prepaid features, activate them in{" "}
            <Link
              style={{
                fontWeight: 600,
                textDecoration: "none",
                color: "#0044CC",
                fontStyle: "normal",
              }}
              href={`${storeId}/settings/payments`}
            >
              payment settings
            </Link>
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default CODRiskComponent;
