export const colorProfile = {
  "engage-surge": {
    text_font_color: "#1A1A1A",
    background_color: "#F6F1FF",
    button_text_color: "#73388F",
    button_background_color: "#F6F1FF",
    button_border_color: "#73388F",
    close_icon_color: "#73388F",
  },
  "action-ignite": {
    text_font_color: "#1A1A1A",
    background_color: "#FFEEDE",
    button_text_color: "#FF9736",
    button_background_color: "#FFEEDE",
    button_border_color: "#FF9736",
    close_icon_color: "#FF9736",
  },
  "engage-elevate": {
    text_font_color: "#196F82",
    background_color: "#F1FDFF",
    button_text_color: "#0B9FBF",
    button_background_color: "#F1FDFF",
    button_border_color: "#0B9FBF",
    close_icon_color: "#0B9FBF",
  },
  "engage-pulse": {
    text_font_color: "#FFFFFF",
    background_color: "#140763",
    button_text_color: "#FFFFFF",
    button_background_color: "#140763",
    button_border_color: "#FFFFFF",
    close_icon_color: "#FFFFFF",
  },
};
