import React, { Fragment, useState } from "react";
import {
  Button,
  Collapse,
  FormControl,
  Paper,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { ColorPicker } from "material-ui-color";
import Input from "../../../../../../components/controls/Input";
import Switch from "../../../../../../components/controls/Switch";
import Select from "../../../../../../components/controls/Select";
import Devider from "../../Devider";
import { InputGridContainer, InputGridItem } from "../../InputGridContainer";
import { colorPickerColors } from "../../../../../../utils/colorPickerColors";
import { applyColor, colorProfileOptions } from "../helpers/colorProfileUtils";
import PenIcon from "../../../assets/PenIcon";
import { colorProfile } from "./colorProfile";

const useStyle = makeStyles(() => ({
  colorPicker: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #E6E6E6",
    padding: 4,
    borderRadius: 4,
    height: 36,
  },
}));

const ButtonStyle1 = ({
  formState,
  handleFormChnage,
  handleFormMultipleValueChange,
}) => {
  const classes = useStyle();
  const [collapsed, setCollapsed] = useState(false);
  return (
    <Fragment>
      <div style={{ padding: "10px" }}>
        <Paper
          elevation={0}
          style={{
            padding: "20px",
            boxShadow: "0px 1px 3px 0px #0000001A",
            borderRadius: 8,
          }}
        >
          <div
            style={{
              fontSize: "14px",
              fontWeight: 700,
              marginBottom: 20,
            }}
          >
            Hello Bar Display
          </div>
          <Input
            label={formState.description.label}
            labelFontWeight={400}
            labelPlacement="top"
            value={formState.description.value}
            onChange={(e) =>
              handleFormChnage("description", {
                value: e.target.value,
              })
            }
            onFocus={(e) => {
              handleFormChnage("description", {
                error: "",
              });
            }}
            onBlur={(e) => {
              formState.description.required &&
                handleFormChnage("description", {
                  error: e.target.value ? "" : "This Field is Required!",
                });
            }}
            error={formState.description.error}
            extraSpace={formState.description.error && true}
          />
          <Input
            label={formState.button_text.label}
            labelFontWeight={400}
            labelPlacement="top"
            value={formState.button_text.value}
            onChange={(e) =>
              handleFormChnage("button_text", {
                value: e.target.value,
              })
            }
            onFocus={(e) => {
              handleFormChnage("button_text", {
                error: "",
              });
            }}
            onBlur={(e) => {
              formState.button_text.required &&
                handleFormChnage("button_text", {
                  error: e.target.value ? "" : "This Field is Required!",
                });
            }}
            error={formState.button_text.error}
            extraSpace={formState.button_text.error && true}
          />
          <Input
            label={formState.button_url.label}
            labelFontWeight={400}
            labelPlacement="top"
            value={formState.button_url.value}
            onChange={(e) =>
              handleFormChnage("button_url", {
                value: e.target.value,
              })
            }
            onFocus={(e) => {
              handleFormChnage("button_url", {
                error: "",
              });
            }}
            onBlur={(e) => {
              formState.button_url.required &&
                handleFormChnage("button_url", {
                  error: e.target.value ? "" : "This Field is Required!",
                });
            }}
            error={formState.button_url.error}
            extraSpace={formState.button_url.error && true}
          />
        </Paper>
      </div>
      <div style={{ padding: "10px" }}>
        <Paper
          elevation={0}
          style={{
            padding: "20px",
            boxShadow: "0px 1px 3px 0px #0000001A",
            borderRadius: 8,
          }}
        >
          <div
            style={{
              fontSize: "14px",
              fontWeight: 700,
              marginBottom: 20,
            }}
          >
            Style
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <InputGridItem style={{ padding: 0, paddingRight: 10 }}>
              <FormControl fullWidth color="secondary">
                <Select
                  name="type"
                  variant="outlined"
                  label="Theme Color"
                  labelStyles={{ fontWeight: 400 }}
                  labelPlacement="top"
                  onChange={(e) => {
                    applyColor(
                      colorProfile[e.target.value],
                      handleFormMultipleValueChange
                    );
                  }}
                  color="secondary"
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                  options={colorProfileOptions}
                />
              </FormControl>
            </InputGridItem>
            <Button
              size="small"
              color="secondary"
              startIcon={<PenIcon />}
              style={{
                textTransform: "none",
                fontSize: "14px",
                fontWeight: 600,
                padding: "6px 8px",
                lineHeight: "12px",
              }}
              onClick={() => setCollapsed(!collapsed)}
            >
              Customize
            </Button>
          </div>
          <Collapse in={collapsed} timeout="auto">
            <InputGridContainer style={{ marginBottom: 20 }}>
              <InputGridItem>
                <Typography
                  style={{
                    fontSize: 14,
                    marginBottom: 8,
                  }}
                >
                  {formState.text_font_color.label}
                </Typography>
                <div className={classes.colorPicker}>
                  <ColorPicker
                    hideTextfield
                    value={formState.text_font_color.value}
                    onChange={(value) => {
                      handleFormChnage("text_font_color", {
                        color: value,
                        value: value.css.backgroundColor,
                      });
                    }}
                    palette={colorPickerColors}
                  />
                  <Typography style={{ fontSize: 14, marginLeft: 10 }}>
                    {formState.text_font_color.value}
                  </Typography>
                </div>
              </InputGridItem>
              <InputGridItem>
                <Typography
                  style={{
                    fontSize: 14,
                    marginBottom: 8,
                  }}
                >
                  {formState.background_color.label}
                </Typography>
                <div className={classes.colorPicker}>
                  <ColorPicker
                    hideTextfield
                    value={formState.background_color.value}
                    onChange={(value) => {
                      handleFormChnage("background_color", {
                        color: value,
                        value: value.css.backgroundColor,
                      });
                    }}
                    palette={colorPickerColors}
                  />
                  <Typography style={{ fontSize: 14, marginLeft: 10 }}>
                    {formState.background_color.value}
                  </Typography>
                </div>
              </InputGridItem>
              <InputGridItem>
                <Typography
                  style={{
                    fontSize: 14,
                    marginBottom: 8,
                  }}
                >
                  {formState.button_text_color.label}
                </Typography>
                <div className={classes.colorPicker}>
                  <ColorPicker
                    hideTextfield
                    value={formState.button_text_color.value}
                    onChange={(value) => {
                      handleFormChnage("button_text_color", {
                        color: value,
                        value: value.css.backgroundColor,
                      });
                    }}
                    palette={colorPickerColors}
                  />
                  <Typography style={{ fontSize: 14, marginLeft: 10 }}>
                    {formState.button_text_color.value}
                  </Typography>
                </div>
              </InputGridItem>
              {formState.allow_to_close.value && (
                <InputGridItem>
                  <Typography
                    style={{
                      fontSize: 14,
                      marginBottom: 8,
                    }}
                  >
                    {formState.close_icon_color.label}
                  </Typography>
                  <div className={classes.colorPicker}>
                    <ColorPicker
                      hideTextfield
                      value={formState.close_icon_color.value}
                      onChange={(value) => {
                        handleFormChnage("close_icon_color", {
                          color: value,
                          value: value.css.backgroundColor,
                        });
                      }}
                      palette={colorPickerColors}
                    />
                    <Typography style={{ fontSize: 14, marginLeft: 10 }}>
                      {formState.close_icon_color.value}
                    </Typography>
                  </div>
                </InputGridItem>
              )}
            </InputGridContainer>
          </Collapse>
        </Paper>
      </div>
      <div style={{ padding: "10px" }}>
        <Paper
          elevation={0}
          style={{
            padding: "20px",
            boxShadow: "0px 1px 3px 0px #0000001A",
            borderRadius: 8,
          }}
        >
          <div
            style={{
              fontSize: "14px",
              fontWeight: 700,
              marginBottom: 20,
            }}
          >
            Hello Bar behaviour
          </div>
          <InputGridContainer>
            <InputGridItem>
              <FormControl fullWidth color="secondary">
                <Select
                  name="type"
                  variant="outlined"
                  value={formState.where_to_display.value}
                  label="Show Hello Bar"
                  labelStyles={{ fontWeight: 400 }}
                  labelPlacement="top"
                  onChange={(e) => {
                    handleFormChnage("where_to_display", {
                      value: e.target.value,
                    });
                  }}
                  color="secondary"
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                  renderValue={(selected) => {
                    let value = formState.where_to_display.options.filter(
                      (o) => o.id === selected
                    )[0].title;
                    return (
                      <Typography
                        style={{
                          fontSize: 14,
                        }}
                      >
                        {value}
                      </Typography>
                    );
                  }}
                  helperText={
                    formState.where_to_display.options.filter(
                      (o) => o.id === formState.where_to_display.value
                    )[0].helperText
                  }
                  options={formState.where_to_display.options}
                  height={36}
                />
              </FormControl>
            </InputGridItem>
          </InputGridContainer>
          <InputGridContainer>
            <InputGridItem>
              <FormControl fullWidth color="secondary">
                <Select
                  name="type"
                  variant="outlined"
                  value={formState.initial_delay.value}
                  label="Show Hello Bar"
                  labelStyles={{ fontWeight: 400 }}
                  labelPlacement="top"
                  onChange={(e) => {
                    handleFormChnage("initial_delay", {
                      value: e.target.value,
                    });
                  }}
                  color="secondary"
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                  renderValue={(selected) => {
                    let value = formState.initial_delay.options.filter(
                      (o) => o.id === selected
                    )[0].title;
                    return (
                      <Typography
                        style={{
                          fontSize: 14,
                        }}
                      >
                        {value}
                      </Typography>
                    );
                  }}
                  helperText={
                    formState.initial_delay.options.filter(
                      (o) => o.id === formState.initial_delay.value
                    )[0].helperText
                  }
                  options={formState.initial_delay.options}
                  height={36}
                />
              </FormControl>
            </InputGridItem>
          </InputGridContainer>
          <Devider style={{ marginBottom: 20 }} />
          <div style={{ marginBottom: 20 }}>
            <Switch
              name="allow_to_close"
              label="Allow users to close it"
              labelFontWeight={400}
              helperText="It will allow  users to close Hello bar using cross button"
              color="secondary"
              value={formState.allow_to_close.value}
              onChange={() => {
                handleFormChnage("allow_to_close", {
                  value: !formState.allow_to_close.value,
                });
              }}
              width="100%"
            />
          </div>
          <div>
            <Switch
              name="make_it_sticky"
              label="Make it sticky"
              labelFontWeight={400}
              helperText="It will allow to users to make Hello bar sticky on top"
              color="secondary"
              value={formState.make_it_sticky.value}
              onChange={() => {
                handleFormChnage("make_it_sticky", {
                  value: !formState.make_it_sticky.value,
                });
              }}
              width="100%"
            />
          </div>
        </Paper>
      </div>
    </Fragment>
  );
};

export default ButtonStyle1;
