import React from "react";

const DropdownIcon = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_28000_677"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="16"
      >
        <rect width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_28000_677)">
        <path
          d="M1.26079 5.36394L2.15695 4.46777L7.42995 9.74077L12.703 4.46777L13.5991 5.36394L7.42995 11.5331L1.26079 5.36394Z"
          fill="#1641DB"
        />
      </g>
    </svg>
  );
};

export default DropdownIcon;
