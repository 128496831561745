import { createColor } from "material-ui-color";
import { svgToDataUri } from "../helpers/svgToDataUri";
import { generateJsForTimer } from "../helpers/generateJsForTimer";

export const config = {
  where_to_display: {
    value: "home-page",
    touched: false,
    error: "",
    required: true,
    options: [
      {
        title: "Home Page",
        id: "home-page",
        helperText: "Hello bar will be displayed in Homepage only",
      },
      {
        title: "All Pages",
        id: "all-pages",
        helperText: "Hello bar will be displayed in All pages",
      },
    ],
    willPersist: true,
  },
  show_timer_till: {
    label: "Show Timer Till",
    value: new Date(new Date().getTime() + 2 * 60 * 60 * 1000).toISOString(),
    touched: false,
    error: "",
    helperText: "Countdown will start till the above selected date and time",
    willPersist: true,
  },
  initial_delay: {
    value: "0",
    touched: false,
    error: "",
    required: true,
    options: [
      {
        title: "Immediately",
        id: "0",
        helperText: "Display Immediately Without Delay",
      },
      {
        title: "2 Secs",
        id: "2000",
        helperText: "Hello Bar will display after 2 seconds",
      },
      {
        title: "5 Secs",
        id: "5000",
        helperText: "Hello Bar will display after 5 seconds",
      },
      {
        title: "10 Secs",
        id: "10000",
        helperText: "Hello Bar will display after 10 seconds",
      },
      {
        title: "15 Secs",
        id: "15000",
        helperText: "Hello Bar will display after 15 seconds",
      },
    ],
    willPersist: true,
  },
  allow_to_close: {
    value: false,
    touched: false,
    error: "",
    willPersist: true,
  },
  make_it_sticky: {
    value: false,
    touched: false,
    error: "",
    willPersist: true,
  },
  description: {
    label: "Description",
    value: "30% off on all clothes. Use code - FLAT30",
    touched: false,
    error: "",
    required: true,
    helperText: "",
    willPersist: true,
  },
  url: {
    label: "Url (Optional)",
    value: "",
    touched: false,
    error: "",
    helperText: "Adding Url will make your card act as a button",
    willPersist: true,
  },
  text_font_color: {
    label: "Text Font Color",
    value: createColor("#196F82").css.backgroundColor,
    name: "text_font_color",
    touched: false,
    error: "",
    color: createColor("#196F82"),
  },
  timer_font_color: {
    label: "Timer Font Color",
    value: createColor("#FFFFFF").css.backgroundColor,
    name: "timer_font_color",
    touched: false,
    error: "",
    color: createColor("#FFFFFF"),
  },
  timer_background_color: {
    label: "Timer Background Color",
    value: createColor("#0B9FBF").css.backgroundColor,
    name: "timer_background_color",
    touched: false,
    error: "",
    color: createColor("#0B9FBF"),
  },
  background_color: {
    label: "Background Color",
    value: createColor("#F1FDFF").css.backgroundColor,
    name: "background_color",
    touched: false,
    error: "",
    color: createColor("#F1FDFF"),
  },
  texture_color: {
    label: "Texture Color",
    value: createColor("#84D9EB").css.backgroundColor,
    name: "texture_color",
    touched: false,
    error: "",
    color: createColor("#84D9EB"),
  },
  close_icon_color: {
    label: "Close Icon Color",
    value: createColor("#0B9FBF").css.backgroundColor,
    name: "close_icon_color",
    touched: false,
    error: "",
    color: createColor("#0B9FBF"),
  },
};

export const generateHtml = () => {
  return `<div id="notice-timer"></div>`;
};

const bgTexture = (
  fill = "#84D9EB"
) => `<svg width="249" height="91" viewBox="0 0 249 91" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M235.605 52.2092C237.043 52.2092 238.209 51.0431 238.209 49.6046C238.209 48.1661 237.043 47 235.605 47C234.166 47 233 48.1661 233 49.6046C233 51.0431 234.166 52.2092 235.605 52.2092ZM235.605 51.558C236.683 51.558 237.558 50.6834 237.558 49.6046C237.558 48.5257 236.683 47.6511 235.605 47.6511C234.526 47.6511 233.651 48.5257 233.651 49.6046C233.651 50.6834 234.526 51.558 235.605 51.558ZM246.483 49.6046L248.42 47.6675L247.96 47.2071L246.023 49.1442L244.086 47.2071L243.625 47.6675L245.563 49.6046L243.625 51.5417L244.086 52.0021L246.023 50.065L247.96 52.0021L248.42 51.5417L246.483 49.6046ZM246.023 62.6275C247.461 62.6275 248.628 61.4614 248.628 60.0229C248.628 58.5845 247.461 57.4184 246.023 57.4184C244.584 57.4184 243.418 58.5845 243.418 60.0229C243.418 61.4614 244.584 62.6275 246.023 62.6275ZM246.023 61.9764C247.102 61.9764 247.976 61.1018 247.976 60.0229C247.976 58.9441 247.102 58.0695 246.023 58.0695C244.944 58.0695 244.07 58.9441 244.07 60.0229C244.07 61.1018 244.944 61.9764 246.023 61.9764ZM236.065 60.0229L238.002 58.0859L237.542 57.6254L235.605 59.5625L233.668 57.6254L233.207 58.0859L235.144 60.0229L233.207 61.96L233.668 62.4205L235.605 60.4834L237.542 62.4205L238.002 61.96L236.065 60.0229Z" fill="${fill}"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M121.605 80.2092C123.043 80.2092 124.209 79.0431 124.209 77.6046C124.209 76.1661 123.043 75 121.605 75C120.166 75 119 76.1661 119 77.6046C119 79.0431 120.166 80.2092 121.605 80.2092ZM121.605 79.558C122.683 79.558 123.558 78.6834 123.558 77.6046C123.558 76.5257 122.683 75.6511 121.605 75.6511C120.526 75.6511 119.651 76.5257 119.651 77.6046C119.651 78.6834 120.526 79.558 121.605 79.558ZM132.483 77.6046L134.42 75.6675L133.96 75.2071L132.023 77.1442L130.086 75.2071L129.625 75.6675L131.563 77.6046L129.625 79.5417L130.086 80.0021L132.023 78.065L133.96 80.0021L134.42 79.5417L132.483 77.6046ZM132.023 90.6275C133.461 90.6275 134.628 89.4614 134.628 88.0229C134.628 86.5845 133.461 85.4184 132.023 85.4184C130.584 85.4184 129.418 86.5845 129.418 88.0229C129.418 89.4614 130.584 90.6275 132.023 90.6275ZM132.023 89.9764C133.102 89.9764 133.976 89.1018 133.976 88.0229C133.976 86.9441 133.102 86.0695 132.023 86.0695C130.944 86.0695 130.07 86.9441 130.07 88.0229C130.07 89.1018 130.944 89.9764 132.023 89.9764ZM122.065 88.0229L124.002 86.0859L123.542 85.6254L121.605 87.5625L119.668 85.6254L119.207 86.0859L121.144 88.0229L119.207 89.96L119.668 90.4205L121.605 88.4834L123.542 90.4205L124.002 89.96L122.065 88.0229Z" fill="${fill}"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M183.605 77.2092C185.043 77.2092 186.209 76.0431 186.209 74.6046C186.209 73.1661 185.043 72 183.605 72C182.166 72 181 73.1661 181 74.6046C181 76.0431 182.166 77.2092 183.605 77.2092ZM183.605 76.558C184.683 76.558 185.558 75.6834 185.558 74.6046C185.558 73.5257 184.683 72.6511 183.605 72.6511C182.526 72.6511 181.651 73.5257 181.651 74.6046C181.651 75.6834 182.526 76.558 183.605 76.558ZM194.483 74.6046L196.42 72.6675L195.96 72.2071L194.023 74.1442L192.086 72.2071L191.625 72.6675L193.563 74.6046L191.625 76.5417L192.086 77.0021L194.023 75.065L195.96 77.0021L196.42 76.5417L194.483 74.6046ZM194.023 87.6275C195.461 87.6275 196.628 86.4614 196.628 85.0229C196.628 83.5845 195.461 82.4184 194.023 82.4184C192.584 82.4184 191.418 83.5845 191.418 85.0229C191.418 86.4614 192.584 87.6275 194.023 87.6275ZM194.023 86.9764C195.102 86.9764 195.976 86.1018 195.976 85.0229C195.976 83.9441 195.102 83.0695 194.023 83.0695C192.944 83.0695 192.07 83.9441 192.07 85.0229C192.07 86.1018 192.944 86.9764 194.023 86.9764ZM184.065 85.0229L186.002 83.0859L185.542 82.6254L183.605 84.5625L181.668 82.6254L181.207 83.0859L183.144 85.0229L181.207 86.96L181.668 87.4205L183.605 85.4834L185.542 87.4205L186.002 86.96L184.065 85.0229Z" fill="${fill}"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M144.605 10.2092C146.043 10.2092 147.209 9.04307 147.209 7.60459C147.209 6.16611 146.043 5 144.605 5C143.166 5 142 6.16611 142 7.60459C142 9.04307 143.166 10.2092 144.605 10.2092ZM144.605 9.55803C145.683 9.55803 146.558 8.68345 146.558 7.60459C146.558 6.52573 145.683 5.65115 144.605 5.65115C143.526 5.65115 142.651 6.52573 142.651 7.60459C142.651 8.68345 143.526 9.55803 144.605 9.55803ZM155.483 7.60459L157.42 5.66751L156.96 5.20708L155.023 7.14416L153.086 5.20708L152.625 5.66751L154.563 7.60459L152.625 9.54167L153.086 10.0021L155.023 8.06502L156.96 10.0021L157.42 9.54167L155.483 7.60459ZM155.023 20.6275C156.461 20.6275 157.628 19.4614 157.628 18.0229C157.628 16.5845 156.461 15.4184 155.023 15.4184C153.584 15.4184 152.418 16.5845 152.418 18.0229C152.418 19.4614 153.584 20.6275 155.023 20.6275ZM155.023 19.9764C156.102 19.9764 156.976 19.1018 156.976 18.0229C156.976 16.9441 156.102 16.0695 155.023 16.0695C153.944 16.0695 153.07 16.9441 153.07 18.0229C153.07 19.1018 153.944 19.9764 155.023 19.9764ZM145.065 18.0229L147.002 16.0859L146.542 15.6254L144.605 17.5625L142.668 15.6254L142.207 16.0859L144.144 18.0229L142.207 19.96L142.668 20.4205L144.605 18.4834L146.542 20.4205L147.002 19.96L145.065 18.0229Z" fill="${fill}"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M2.60459 77.2092C4.04307 77.2092 5.20918 76.0431 5.20918 74.6046C5.20918 73.1661 4.04307 72 2.60459 72C1.16611 72 0 73.1661 0 74.6046C0 76.0431 1.16611 77.2092 2.60459 77.2092ZM2.60459 76.558C3.68345 76.558 4.55803 75.6834 4.55803 74.6046C4.55803 73.5257 3.68345 72.6511 2.60459 72.6511C1.52573 72.6511 0.651147 73.5257 0.651147 74.6046C0.651147 75.6834 1.52573 76.558 2.60459 76.558ZM13.4834 74.6046L15.4205 72.6675L14.96 72.2071L13.0229 74.1442L11.0859 72.2071L10.6254 72.6675L12.5625 74.6046L10.6254 76.5417L11.0859 77.0021L13.0229 75.065L14.96 77.0021L15.4205 76.5417L13.4834 74.6046ZM13.0229 87.6275C14.4614 87.6275 15.6275 86.4614 15.6275 85.0229C15.6275 83.5845 14.4614 82.4184 13.0229 82.4184C11.5845 82.4184 10.4184 83.5845 10.4184 85.0229C10.4184 86.4614 11.5845 87.6275 13.0229 87.6275ZM13.0229 86.9764C14.1018 86.9764 14.9764 86.1018 14.9764 85.0229C14.9764 83.9441 14.1018 83.0695 13.0229 83.0695C11.9441 83.0695 11.0695 83.9441 11.0695 85.0229C11.0695 86.1018 11.9441 86.9764 13.0229 86.9764ZM3.06502 85.0229L5.0021 83.0859L4.54167 82.6254L2.60459 84.5625L0.667508 82.6254L0.207078 83.0859L2.14416 85.0229L0.207078 86.96L0.667508 87.4205L2.60459 85.4834L4.54167 87.4205L5.0021 86.96L3.06502 85.0229Z" fill="${fill}"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M68.6046 44.2092C70.0431 44.2092 71.2092 43.0431 71.2092 41.6046C71.2092 40.1661 70.0431 39 68.6046 39C67.1661 39 66 40.1661 66 41.6046C66 43.0431 67.1661 44.2092 68.6046 44.2092ZM68.6046 43.558C69.6834 43.558 70.558 42.6834 70.558 41.6046C70.558 40.5257 69.6834 39.6511 68.6046 39.6511C67.5257 39.6511 66.6511 40.5257 66.6511 41.6046C66.6511 42.6834 67.5257 43.558 68.6046 43.558ZM79.4834 41.6046L81.4205 39.6675L80.96 39.2071L79.0229 41.1442L77.0859 39.2071L76.6254 39.6675L78.5625 41.6046L76.6254 43.5417L77.0859 44.0021L79.0229 42.065L80.96 44.0021L81.4205 43.5417L79.4834 41.6046ZM79.0229 54.6275C80.4614 54.6275 81.6275 53.4614 81.6275 52.0229C81.6275 50.5845 80.4614 49.4184 79.0229 49.4184C77.5845 49.4184 76.4184 50.5845 76.4184 52.0229C76.4184 53.4614 77.5845 54.6275 79.0229 54.6275ZM79.0229 53.9764C80.1018 53.9764 80.9764 53.1018 80.9764 52.0229C80.9764 50.9441 80.1018 50.0695 79.0229 50.0695C77.9441 50.0695 77.0695 50.9441 77.0695 52.0229C77.0695 53.1018 77.9441 53.9764 79.0229 53.9764ZM69.065 52.0229L71.0021 50.0859L70.5417 49.6254L68.6046 51.5625L66.6675 49.6254L66.2071 50.0859L68.1442 52.0229L66.2071 53.96L66.6675 54.4205L68.6046 52.4834L70.5417 54.4205L71.0021 53.96L69.065 52.0229Z" fill="${fill}"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M34.6046 5.20918C36.0431 5.20918 37.2092 4.04307 37.2092 2.60459C37.2092 1.16611 36.0431 0 34.6046 0C33.1661 0 32 1.16611 32 2.60459C32 4.04307 33.1661 5.20918 34.6046 5.20918ZM34.6046 4.55803C35.6834 4.55803 36.558 3.68345 36.558 2.60459C36.558 1.52573 35.6834 0.651147 34.6046 0.651147C33.5257 0.651147 32.6511 1.52573 32.6511 2.60459C32.6511 3.68345 33.5257 4.55803 34.6046 4.55803ZM45.4834 2.60459L47.4205 0.667508L46.96 0.207078L45.0229 2.14416L43.0859 0.207078L42.6254 0.667508L44.5625 2.60459L42.6254 4.54167L43.0859 5.0021L45.0229 3.06502L46.96 5.0021L47.4205 4.54167L45.4834 2.60459ZM45.0229 15.6275C46.4614 15.6275 47.6275 14.4614 47.6275 13.0229C47.6275 11.5845 46.4614 10.4184 45.0229 10.4184C43.5845 10.4184 42.4184 11.5845 42.4184 13.0229C42.4184 14.4614 43.5845 15.6275 45.0229 15.6275ZM45.0229 14.9764C46.1018 14.9764 46.9764 14.1018 46.9764 13.0229C46.9764 11.9441 46.1018 11.0695 45.0229 11.0695C43.9441 11.0695 43.0695 11.9441 43.0695 13.0229C43.0695 14.1018 43.9441 14.9764 45.0229 14.9764ZM35.065 13.0229L37.0021 11.0859L36.5417 10.6254L34.6046 12.5625L32.6675 10.6254L32.2071 11.0859L34.1442 13.0229L32.2071 14.96L32.6675 15.4205L34.6046 13.4834L36.5417 15.4205L37.0021 14.96L35.065 13.0229Z" fill="#84D9EB"/>
</svg>`;

const generateCountDownTimerHtml = (compareTime) => {
  const difference = new Date(compareTime) - new Date();
  if (difference <= 0) {
    return null;
  }
  const _days = Math.floor(difference / (1000 * 60 * 60 * 24));
  const _hours = Math.floor(
    (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const _minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
  const _seconds = Math.floor((difference % (1000 * 60)) / 1000);
  const time = {
    days: _days >= 0 && _days <= 9 ? "0" + _days : _days,
    hours: _hours >= 0 && _hours <= 9 ? "0" + _hours : _hours,
    minutes: _minutes >= 0 && _minutes <= 9 ? "0" + _minutes : _minutes,
    seconds: _seconds >= 0 && _seconds <= 9 ? "0" + _seconds : _seconds,
  };

  return `<div>
<strong>${time.days}</strong>
<p>Days</p>
</div>
<span class="shaprator">:</span>
<div>
<strong>${time.hours}</strong>
<p>Hrs</p>
</div>
<span class="shaprator">:</span>
<div>
<strong>${time.minutes}</strong>
<p>Mins</p>
</div>
<span class="shaprator">:</span>
<div>
<strong>${time.seconds}</strong>
<p>Secs</p>
</div>`;
};

export const generateHtmlForJs = (config) => {
  const timerHtml = generateCountDownTimerHtml(config.show_timer_till.value);

  const html = `<style>
  .timer-ctn.style4 {
    padding: 16px;
    position: relative;
    width: 100%;
    background-color: ${config.background_color.value};
    overflow: hidden;
  }
  .timer-ctn.style4::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(${svgToDataUri(bgTexture(config.texture_color.value))});
  }
  .timer-ctn.style4 .bg-texture path{
    fill: ${config.texture_color.value};
  }
  .timer-ctn.style4 .timer-close-wrapper{
    display: flex;
  }
  .timer-ctn.style4 .timer-wrapper{
    position: relative;
    margin: -6px;
    flex: 1;
  }
  .timer-ctn.style4 .title {
    display: flex;
    justify-content: center;
    padding: 6px;
  }
  .timer-ctn.style4 .title span {
    display: block;
    text-align: center;
    color: inherit;
    color: ${config.text_font_color.value};
    font-size: 14px;
    font-weight: 600;
  }
  .timer-ctn.style4 .timer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 32px;
    width: fit-content;
    background-color: ${config.timer_background_color.value};
    margin: 8px auto;
    border-radius: 200px;
  }
  .timer-ctn.style4 .timer > * {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .timer-ctn.style4 .timer > *:not(:last-child) {
    margin-right: 12px;
  }
  .timer-ctn.style4 .timer p,
  .timer-ctn.style4 .timer strong,
  .timer-ctn.style4 .timer span
  {
    color: ${config.timer_font_color.value};
  }
  .timer-ctn.style4 .timer strong {
    font-size: 20px;
    font-weight: 600;
  }
  .timer-ctn.style4 .timer p {
    font-size: 12px;
    margin: 0;
  }
  .notice-ancher:hover .timer-ctn.style4 {
    text-decoration: none;
  }
  .notice-ancher:hover .timer-ctn.style4 p,
  .notice-ancher:hover .timer-ctn.style4 strong,
  .notice-ancher:hover .timer-ctn.style4 span
  {
    text-decoration: underline;
    text-decoration-color: inherit;
  }
  .timer-ctn.style4 .timer .shaprator {
    font-weight: 600;
  }
  .timer-ctn.style4 .close {
    width: 22px;
    height: 22px;
    padding: 6px;
    border-radius: 4px;
    cursor: pointer;
    display: block;
    margin: -8px -8px 0 0;
    position: relative;
  }
  .timer-ctn.style4 .close svg {
    width: 100%;
    height: 100%;
    display: block;
  }
</style>
<div class="timer-ctn style4">
  <div class="timer-close-wrapper">
    <div class="timer-wrapper">
      <div class="title">
        ${
          config.url.value
            ? `<a class="notice-ancher" href="${config.url.value}">
              <span>${config.description.value}</span>
               </a>`
            : `<span>${config.description.value}</span>`
        }
      </div>
      <div class="timer" id="timer">${timerHtml}</div>
    </div>
    ${
      config.allow_to_close.value
        ? `<div class="close" onclick="window.helloBar.closeHelloBar()">
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.4 14L0 12.6L5.6 7L0 1.4L1.4 0L7 5.6L12.6 0L14 1.4L8.4 7L14 12.6L12.6 14L7 8.4L1.4 14Z"
          fill="${config.close_icon_color.value}"
        />
      </svg>
    </div>`
        : ""
    }
  </div>
</div>`;

  return timerHtml ? html : "";
};

export const generateJs = (config) =>
  generateJsForTimer({
    config,
    generateCountDownTimerHtml,
    generateHtmlForJs,
  });
