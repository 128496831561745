import axios from "axios";
import { API, getDefaultStore } from "../../../utils/storeUtil";

export const getContractById = async (contractId) => {
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  try {
    let response = await axios.get(
      `${API()}/api/v1/business/store/${
        store.store_id
      }/subscriptions/contracts/${contractId}`,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data.payload;
  } catch (err) {
    console.log("Failure while fetching analytics data", err);
  }
};

export const getUpcomingActivities = async (contractId, page, size) => {
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  try {
    let response = await axios.get(
      `${API()}/api/v1/business/store/${
        store.store_id
      }/contract/${contractId}/activities?&page=${page}&size=${size}`,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data.payload;
  } catch (err) {
    console.log("Failure while fetching analytics data", err);
  }
};

export const skipOrResume = async (payload, contractId) => {
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  try {
    let response = await axios.patch(
      `${API()}/api/v1/business/store/${
        store.store_id
      }/contract/${contractId}/activities/status`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (err) {
    console.log("Failure while fetching analytics data", err);
  }
};
export const formatSelectedDays = (selectedDays) => {
  const allDays = [
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
    "SUNDAY",
  ];
  const selectedArray = selectedDays.split(",");
  if (allDays.every((day) => selectedArray.includes(day))) {
    return "Every day";
  }
  return selectedArray
    .map((day) => day.charAt(0) + day.slice(1, 3).toLowerCase())
    .join(", ");
};
