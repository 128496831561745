import { style } from "typestyle";

export const formWrapperLeft = style({
  backgroundColor: "#fff",
  borderRadius: "8px",
  padding: "32px",
  marginTop: "20px",
  width: "55%",
  maxWidth: "650px",
});

export const formWrapperRight = style({
  backgroundColor: "#fff",
  borderRadius: "8px",
  padding: "32px",
  marginTop: "20px",
  width: "45%",
  maxWidth: "450px",
  marginLeft: "20px",
  alignSelf: "flex-start",
});

export const formLabel = style({
  fontSize: "14px",
  lineHeight: "20px",
  color: "#1A1A1A",
  marginBottom: "8px",
});

export const textFieldLabel = style({
  alignItems: "flex-start",
  justifyContent: "flex-end",
  margin: "0 0 8px 0",
  width: "100%",
});
export const labelWidth = style({
  width: "auto",
});
export const controlInput = style({
  width: "100% !important",
  margin: "12px 0px 0px 0px !important",
});
export const colorPicker = style({
  display: "flex",
  alignItems: "center",
  border: "1px solid #E6E6E6",
  padding: 4,
  borderRadius: 4,
  height: "38px",
});
export const sizeNote = style({
  marginTop: "8px",
  fontSize: "12px",
  fontWeight: 400,
  color: "#666666",
});
