import React, { useState, useEffect } from "react";
import useStyles from "./styles";
import {
  CircularProgress,
  Container,
  Link,
  Typography,
} from "@material-ui/core";
import ClockIcon from "../../../icons/ClockIcon";
import SolidButton from "../../../components/buttons/SolidButton";
import { circular, nopicctn, progressbar } from "../../../styles/common";
import PlanChargeSummary from "./PlanChargeSummary";
import { createRenewalOrder, renewApi } from "../utils";
import Moment from "react-moment";
import { useHistory } from "react-router-dom";
import { NoPicIcon } from "../../../icons";
import { useDispatch } from "react-redux";
import { getPlanRenewalInfo } from "../../../redux/store/storeActions";
import { getStoreUrl } from "../../../utils/storeUtil";

const statusMap = {
  EXPIRED: {
    backgroundColor: "#FFEDED",
    color: "#FF4444",
    title: "Renew Plan",
    description:
      "Your account is up for renewal. Please make the payment to continue the service.",
    duedate: "Due Date",
    allowpayment: false,
  },
  PENDING: {
    backgroundColor: "#E1E7FE",
    color: "#1A1A1A",
    title: "Renew Plan",
    description:
      "Your account is up for renewal. Please make the payment to continue the service.",
    duedate: "Due Date",
    allowpayment: true,
  },
  NOTDUE: {
    backgroundColor: "#E1E7FE",
    color: "#1A1A1A",
    title: "Plan Information",
    description:
      "Your plan is not due for renewal currently. Thank you for being a Shoopy customer.",
    duedate: "Renewal Date",
    allowpayment: false,
  },
};

const RenewSubscription = ({ storeId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [planInfo, setPlanInfo] = useState(null);
  const [store, setStore] = useState(null);
  const [storeUrl, setStoreUrl] = useState("");
  const [paymentLoading, setPaymentLoading] = useState(false); // New state for payment loading
  const history = useHistory();

  useEffect(() => {
    dispatch(getPlanRenewalInfo(storeId))
      .then((info) => {
        let storeDtls = info.store;
        let address = "";
        if (storeDtls.street) {
          address += `${storeDtls.street}, `;
        }
        if (storeDtls.street2) {
          address += storeDtls.street2;
        }
        if (storeDtls.city) {
          address += `${storeDtls.city}, `;
        }
        if (storeDtls.state) {
          address += `${storeDtls.state} - `;
        }
        if (storeDtls.pincode) {
          address += storeDtls.pincode;
        }
        if (storeDtls.business_name) {
          address += storeDtls.business_name;
        }
        storeDtls.address = address;
        setStore(storeDtls);
        setPlanInfo(info);
        setLoading(false);
        setStoreUrl(getStoreUrl(storeDtls));
      })
      .catch((err) => {});
  }, [storeId]);

  const onProceedForPayment = async () => {
    setPaymentLoading(true);
    let subOrder = await createRenewalOrder(storeId);
    razorPayWallet(subOrder)
      .then(() => {
        setPaymentLoading(false);
      })
      .catch((err) => {
        setPaymentLoading(false);
      });
  };

  const razorPayWallet = async (subReq) => {
    const options = {
      key: `${process.env.REACT_APP_RAZORPAY_API_KEY}`,
      name: "Shoopy",
      description: subReq.description,
      image: "https://logo.clearbit.com/shoopy.in",
      order_id: subReq.gw_order_id,
      amount: subReq.amount,
      notes: {
        order_id: subReq.order_id,
        store_id: storeId,
        renew: "true",
      },
      prefill: { contact: subReq.mobile, email: subReq.email },
      handler: async (response) => {
        setPaymentLoading(false); // Ensure spinner is hidden when Razorpay opens
        setLoading(true);
        let renewResp = await renewApi(
          storeId,
          response.razorpay_payment_id,
          "ONLINE",
          "razorpay",
          response.razorpay_order_id,
          response.razorpay_signature,
          subReq.order_id
        );
        if (!renewResp.error) {
          history.replace(
            `/${storeId}/billing/plans/confirmation/${subReq.order_id}`
          );
        }
        setLoading(false);
      },
      modal: {
        ondismiss: () => {},
        escape: true,
      },
      theme: {
        color: "#1641DB",
      },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  if (loading) {
    return (
      <CircularProgress
        color="secondary"
        className={progressbar}
        classes={{ colorSecondary: circular }}
      />
    );
  }

  return (
    <Container className={classes.renew}>
      {paymentLoading && (
        <div className={classes.paymentLoading}>
          <CircularProgress color="secondary" />
        </div>
      )}
      <div className={classes.renewinfo}>
        <div style={{ maxWidth: "388px" }}>
          <Typography variant="h1" className={classes.pageheading}>
            {statusMap[planInfo.status].title}
          </Typography>
          <Typography className={classes.text} style={{ marginTop: 8 }}>
            {statusMap[planInfo.status].description}
          </Typography>
          <div
            className={classes.duedate}
            style={{
              backgroundColor: statusMap[planInfo.status].backgroundColor,
              color: statusMap[planInfo.status].color,
            }}
          >
            <ClockIcon fill={statusMap[planInfo.status].color} />
            <span style={{ marginLeft: 8 }}>
              {statusMap[planInfo.status].duedate} :{" "}
            </span>
            &nbsp;
            <Moment format="DD MMM, YYYY">{planInfo.renew_date}</Moment>
          </div>
        </div>
        <hr style={{ marginTop: 24, marginBottom: 24 }}></hr>
        <div style={{ display: "flex", maxWidth: 388 }}>
          {store.logo ? (
            <div>
              <img
                src={store.logo}
                alt={store.name}
                className={classes.media}
              />
            </div>
          ) : (
            <div className={nopicctn}>
              <NoPicIcon />
            </div>
          )}
          <div style={{ marginLeft: 8 }}>
            <Typography
              variant="h2"
              className={classes.primary}
              style={{ fontWeight: "normal" }}
            >
              {store.name}
            </Typography>
            <div style={{ marginTop: 8, marginBottom: 24 }}>
              <Link
                style={{
                  textDecoration: "none",
                }}
                color="secondary"
                target="_blank"
                href={storeUrl}
              >
                {storeUrl}
              </Link>
            </div>
          </div>
        </div>
        <hr style={{ marginTop: 0, marginBottom: 0 }}></hr>
      </div>
      <PlanChargeSummary
        planInfo={planInfo}
        store={store}
        allowpayment={statusMap[planInfo.status].allowpayment}
      />
      {statusMap[planInfo.status].allowpayment && (
        <div className={classes.paymentbutton}>
          <SolidButton onClick={onProceedForPayment}>
            Proceed to Payment
          </SolidButton>
        </div>
      )}
    </Container>
  );
};

export default RenewSubscription;
