import React from "react";

export default function GokwikBuyIcon() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.985 15.4857L12.0888 11.9364L9.17926 9.64593H12.7493L13.9048 6.03502L15.0398 9.64593H18.6303L15.7102 11.9364L16.7935 15.4857L13.9048 13.2881L10.985 15.4857ZM6.55883 27V17.5388C5.67834 16.6034 5.03175 15.5648 4.61905 14.4231C4.20635 13.2812 4 12.1084 4 10.9048C4 8.11224 4.95261 5.76326 6.85783 3.85783C8.76326 1.95261 11.1122 1 13.9048 1C16.6973 1 19.0463 1.95261 20.9517 3.85783C22.8569 5.76326 23.8095 8.11224 23.8095 10.9048C23.8095 12.1084 23.6032 13.2812 23.1905 14.4231C22.7778 15.5648 22.1312 16.6034 21.2507 17.5388V27L13.9048 24.5445L6.55883 27ZM13.9048 18.7459C16.0921 18.7459 17.9458 17.9859 19.466 16.466C20.9859 14.9458 21.7459 13.0921 21.7459 10.9048C21.7459 8.71746 20.9859 6.86372 19.466 5.34355C17.9458 3.82358 16.0921 3.06359 13.9048 3.06359C11.7175 3.06359 9.86372 3.82358 8.34355 5.34355C6.82358 6.86372 6.0636 8.71746 6.0636 10.9048C6.0636 13.0921 6.82358 14.9458 8.34355 16.466C9.86372 17.9859 11.7175 18.7459 13.9048 18.7459Z"
        fill="#1641DB"
      />
    </svg>
  );
}
