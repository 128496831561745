import React, { useState } from "react";
import { Box, Button, IconButton, Typography } from "@material-ui/core";
import DrawerCloseIcon from "../../../icons/DrawerCloseIcon";
import {
  rowFlexJustifyBetween,
  rowFlexCenterAll,
  rowFlexAlignStart,
  rowFlexAlignCenter,
} from "../../../styles/common";
import styles from "../styles";
import { CardContent } from "semantic-ui-react";
import CancelOutlinedIcon from "../../../icons/CancelOutlinedIcon";
import { handleProductDataFetch } from "../../coupons/Components/CouponForm/formUtils";
import SearchableSelect from "../../../components/common/SearchableSelect";
import { NoPicIcon } from "../../../icons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import axios from "axios";
import { getDefaultStore } from "../../../utils/storeUtil";
import Toast from "../../../components/Layout/Toast";
import { DragIndicatorOutlined } from "@material-ui/icons";
import { useSelector } from "react-redux";

export const addRelatedProducts = async (productSku, relatedProducts) => {
  const relatedProductsPayload = Object.entries(relatedProducts).map(
    ([key, product]) => {
      return {
        ...(product.id > 0 && { id: product.id }),
        ssku: key,
      };
    }
  );
  const orgId = getDefaultStore();
  try {
    let accessToken = localStorage.getItem("access_token");
    let response = await axios.post(
      `${process.env.REACT_APP_API}/api/v2/org/${orgId.org_id}/super-products/${productSku}/related-products`,
      relatedProductsPayload,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
  }
};
export const fetchRelatedProducts = async (superProductSku) => {
  const orgId = getDefaultStore();
  try {
    let accessToken = localStorage.getItem("access_token");
    let response = await axios.get(
      `${process.env.REACT_APP_API}/api/v2/org/${orgId.org_id}/super-products/${superProductSku}/related-products`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (err) {
    console.log({ err });
    return [];
  }
};

const RelatedProducts = ({ onClose, product, lists, setLists }) => {
  const classes = styles();
  const [toast, setToast] = useState({
    open: false,
    severity: "success",
    message: "",
  });
  const store = useSelector((state) => state.store.store);
  const countrySymbol = store.symbol;
  const [showProductSelectionPopUp, setShowProductSelectionPopUp] =
    useState(false);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const orgId = getDefaultStore();
  const handleDelete = async (relatedProductSku) => {
    try {
      let accessToken = localStorage.getItem("access_token");
      const response = await axios.delete(
        `${process.env.REACT_APP_API}/api/v2/org/${orgId.org_id}/super-products/${product.super_product_sku}/related-products/${relatedProductSku.ssku}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.status === 200) {
        setToast({
          open: true,
          severity: "success",
          message: "Product deleted successfully",
        });
        const data = await fetchRelatedProducts(product.super_product_sku);
        setLists(data);
        setRelatedProducts(data);
      }
    } catch (err) {
      setToast({
        open: true,
        severity: "error",
        message: err || "An error occured deleting products",
      });
    }
  };
  const positionUpdate = async (productSku, relatedProducts) => {
    const addProducts = relatedProducts.reduce((acc, product) => {
      const sskuKey = `SSK-${product.ssku.slice(4)}`;
      acc[sskuKey] = product.position;
      return acc;
    }, {});

    const orgId = getDefaultStore();
    try {
      let accessToken = localStorage.getItem("access_token");
      await axios.patch(
        `${process.env.REACT_APP_API}/api/v2/org/${orgId.org_id}/super-products/${productSku}/related-products/position`,
        addProducts,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setToast({
        open: true,
        severity: "success",
        message: "Product position updated successfully",
      });
    } catch (err) {
      setToast({
        open: true,
        severity: "error",
        message: err || "An error occured updating products",
      });
    }
  };

  const onDragEnd = (result) => {
    const { destination, source } = result;
    if (!destination) {
      return;
    }
    const firstAutoGeneratedIndex = lists.findIndex(
      (item) => item.auto_generated === true
    );
    if (
      firstAutoGeneratedIndex !== -1 &&
      destination.index >= firstAutoGeneratedIndex
    ) {
      setToast({
        open: true,
        severity: "error",
        message: "Auto-generated related super products cannot be rearranged",
      });
      return;
    }
    if (destination.index !== source.index) {
      const reorderedLists = Array.from(lists);
      const [removed] = reorderedLists.splice(source.index, 1);
      reorderedLists.splice(destination.index, 0, removed);
      const updatedLists = reorderedLists.map((item, index) => ({
        ...item,
        position: index,
      }));
      setLists(updatedLists);
      positionUpdate(product.super_product_sku, updatedLists);
    }
  };
  const onSave = async () => {
    const data = await fetchRelatedProducts(product.super_product_sku);
    setLists(data);
  };
  const transformedData = lists.reduce((acc, item) => {
    const id = item.ssku || item.id;
    if (id && !item.auto_generated) {
      acc[id] = {
        name: item.name,
        sale_price: item.sale_price,
        id: item.id,
        image_url: item.image_url || null,
        auto_generated: item.auto_generated || false,
        ssku: item.ssku,
        position: item.position,
      };
    }
    return acc;
  }, {});

  const isOnlyauto_generated = relatedProducts.every(
    (product) => product.auto_generated
  );

  return (
    <div>
      <div className={classes.headerBorder} style={{ paddingBottom: 16 }}>
        <div className={rowFlexJustifyBetween}>
          <Typography style={{ fontWeight: 700, fontSize: 20 }}>
            Related Products For {""}
            {product.name}
          </Typography>

          <IconButton
            aria-label="Close"
            aria-haspopup="true"
            color="inherit"
            onClick={onClose}
          >
            <DrawerCloseIcon />
          </IconButton>
        </div>
      </div>
      <div className={rowFlexJustifyBetween}>
        <Typography className={classes.productCount}>
          {Object.keys(transformedData).length}/10 Products Added
        </Typography>
        <Button
          style={{
            color: "#1641DB",
            fontSize: 14,
            marginTop: 8,
          }}
          onMouseOver={(e) => (e.target.style.backgroundColor = "transparent")}
          onMouseOut={(e) => (e.target.style.backgroundColor = "transparent")}
          onClick={() => setShowProductSelectionPopUp(true)}
        >
          + Add Related Products
        </Button>
      </div>
      <div
        className={lists.length > 0 ? "" : rowFlexCenterAll}
        style={{ height: lists.length > 0 ? "" : "70vh" }}
      >
        {showProductSelectionPopUp && (
          <SearchableSelect
            isOpen={showProductSelectionPopUp}
            heading="Add Products"
            close={() => setShowProductSelectionPopUp(false)}
            ImageFallback={NoPicIcon}
            fetchData={handleProductDataFetch}
            initialItems={transformedData}
            maxSelectedItem={10}
            hasProduct={true}
            product={product}
            onSaveUpdate={onSave}
            setToast={setToast}
          />
        )}
        {lists.length > 0 && (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="productList">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {lists.map((product, index) => {
                    // Check and handle missing data keys
                    const imageUrl = product.image_url;
                    const displayName = product.name;
                    const price = product.sale_price;

                    return (
                      <Draggable
                        key={product.ssku} // Ensure unique key
                        draggableId={String(product.ssku)}
                        index={index}
                        isDragDisabled={product.auto_generated}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            className={`${rowFlexJustifyBetween} ${classes.card}`}
                            style={{
                              ...provided.draggableProps.style, // Add drag styles
                            }}
                          >
                            {/* Product Image and Details */}
                            <div
                              className={rowFlexAlignStart}
                              style={{ width: "65%" }}
                            >
                              {product.auto_generated &&
                                !isOnlyauto_generated && (
                                  <Box style={{ width: "22px" }} />
                                )}
                              {!product.auto_generated && (
                                <div
                                  style={{
                                    paddingRight: "10px",
                                    margin: "auto",
                                  }}
                                  {...provided.dragHandleProps}
                                >
                                  <DragIndicatorOutlined
                                    className={classes.dragIndicator}
                                  />
                                </div>
                              )}
                              {imageUrl ? (
                                <img
                                  src={imageUrl}
                                  alt={displayName}
                                  style={{
                                    width: 40,
                                    height: 40,
                                    borderRadius: "8px",
                                    alignSelf: "center",
                                  }}
                                />
                              ) : (
                                <div
                                  style={{
                                    borderRadius: "8px",
                                    width: 40,
                                    height: 40,
                                    alignSelf: "center",
                                  }}
                                  className={`${rowFlexAlignCenter} ${classes.noPicIcon}`}
                                >
                                  <NoPicIcon />
                                </div>
                              )}

                              <CardContent
                                style={{
                                  flex: "1",
                                  marginLeft: "10px",
                                }}
                              >
                                <Typography
                                  className={classes.productName}
                                  style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    width: "190px",
                                  }}
                                >
                                  {displayName}
                                </Typography>
                                <Typography style={{ fontSize: 12 }}>
                                  <b>
                                    {`${
                                      String(price).startsWith(countrySymbol)
                                        ? ""
                                        : countrySymbol
                                    }${String(price).replace(
                                      countrySymbol,
                                      ""
                                    )}`}
                                  </b>
                                  | {product.ssku}
                                </Typography>
                              </CardContent>
                            </div>

                            {/* Auto-generated Tag */}
                            {product.auto_generated !== undefined &&
                              product.auto_generated && (
                                <Typography
                                  className={classes.auto_Generated_text}
                                >
                                  Auto-generated
                                </Typography>
                              )}
                            {!product.auto_generated && (
                              <div style={{ cursor: "pointer" }}>
                                <CancelOutlinedIcon
                                  color="#1641DB"
                                  onClick={() => {
                                    handleDelete(product);
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}

        {lists.length === 0 && (
          <Typography>No related products added</Typography>
        )}
      </div>
      <Toast
        open={toast.open}
        severity={toast.severity}
        message={toast.message}
        close={() => setToast({ open: false, severity: "", message: "" })}
      />
    </div>
  );
};

export default RelatedProducts;
