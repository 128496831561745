import {
  Typography,
  makeStyles,
  TextField,
  Drawer,
  IconButton,
  Button,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { getStoreIdFromUrl } from "../../utils/storeUtil";
import { rowFlexJustifyBetween } from "../../styles/common";
import DrawerCloseIcon from "../../icons/DrawerCloseIcon";
import SolidButton from "../buttons/SolidButton";
import OutlinedButton from "../buttons/OutlinedButton";
import PageLoader from "../Layout/PageLoader";
import Controls from "../controls/Controls";
import GenrateTextIcon from "../../icons/GenrateTextIcon";
import GenrateMessage from "./GenrateMessage";
import Checkbox from "../controls/Checkbox";
import { ArrowRightAlt } from "@material-ui/icons";
import { convertToSlug } from "./CommonUtils";
import { genrateDesc } from "../../utils/descriptionUtils";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontWeight: 700,
    fontSize: 24,
    lineHeight: "32px",
    color: "#1A1A1A",
  },
  subHeading: {
    fontSize: 14,
  },
  dividerGrid: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "space-between",
  },
  helperText: {
    fontWeight: 400,
    fontSize: 12,
    color: "#999999",
    paddingTop: 10,
    marginBottom: -4,
  },
  headerBorder: {
    paddingBottom: 10,
    borderBottom: "1px solid #E1E1E1",
  },
  card: {
    marginTop: "12px",
    marginBottom: 8,
    border: "1px solid #E1E1E1",
    borderRadius: "8px",
    boxShadow: "none",
  },
  input: {
    "& .MuiOutlinedInput-root": {
      border: "none",
      padding: 0,
    },
    "& .MuiOutlinedInput-input": {
      padding: 0,
    },

    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    marginBottom: 10,
    fontSize: 14,
  },
  slugTextField: {
    "& .MuiOutlinedInput-input": {
      paddingLeft: "2px !important",
    },
  },
  generateButton: {
    display: "flex",
    padding: "0 7px",
    color: "#1641DB",
    fontSize: "12px",
    fontWeight: "600",
    height: "30px",
    "&:hover": {
      background: "#E9EEFF",
      borderRadius: "40px",
    },
  },
  previewBox: {
    width: "100%",
    background: "white",
    borderRadius: 8,
    border: "1px #E1E1E1 solid",
    padding: 8,
    marginTop: "14px",
    overflowWrap: "break-word",
  },
  slug: {
    width: "100%",
    color: "#666666",
    fontSize: 12,
    fontWeight: "400",
    letterSpacing: 0.24,
    marginBottom: 5,
  },
  title: {
    color: "#1A0DAB",
    fontSize: 16,
    fontWeight: "600",
    marginBottom: 5,
  },
  description: {
    width: "100%",
    color: "#1A1A1A",
    fontSize: 14,
    fontWeight: "400",
  },
  slugfrom: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    gap: "10px",
    minWidth: "50px",
    position: "relative",
    top: "-16px",
    left: "28px",
    width: "95%",
  },
}));

const SEOModal = ({
  open,
  setOpen,
  sku,
  setToast,
  setMessage,
  setSeverity,
}) => {
  const classes = useStyles();
  const store = useSelector((state) => state.store.store);
  const storeId = getStoreIdFromUrl();
  const [initialValues, setInitialValues] = useState({
    title: "",
    description: "",
    slug: "",
  });
  const [values, setValues] = useState({
    title: "",
    description: "",
    slug: "",
  });

  const [showCheckbox, setShowCheckbox] = useState(false);
  const [product, setPorduct] = useState({});
  const [fetching, setFetching] = useState(true);
  const [genrateModalOpen, setGenrateModalOpen] = useState(false);
  const [textGenrateLoading, setTextGenrateLoading] = useState(false);
  const [textMessage, setTextMessage] = useState(false);
  const [checkBox, setCheckBox] = useState(true);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  const init = async () => {
    let storeId = getStoreIdFromUrl();
    let accessToken = localStorage.getItem("access_token");
    let res = await axios.get(
      `${process.env.REACT_APP_API}/api/v2/business/store/${storeId}/product/find-by-sku/${sku}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    setFetching(false);
    let payload = res.data.payload;
    const initialData = {
      title: payload.seo_title ? payload.seo_title : payload.name,
      description: payload.seo_description ? payload.seo_description : "",
      slug: payload.slug ? payload.slug : "",
    };
    setValues(initialData);
    setPorduct(payload);
    setCheckBox(payload.allow_redirect ? payload.allow_redirect : true);
    setInitialValues(initialData);
  };
  useEffect(() => {
    if (store.id) {
      init();
    }
    // eslint-disable-next-line
  }, [store]);
  const checkSourceExists = async (slug) => {
    try {
      let accessToken = localStorage.getItem("access_token");
      const res = await axios.get(
        `${process.env.REACT_APP_API}/api/v2/business/store/${storeId}/products/slug-available?slug=${slug}`,
        {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (res.status === 200) {
        if (res.data.payload) {
          setError(null);
          return false;
        } else {
          setError(
            `/${slug} already in use. Please provide a different URL Slug `
          );
          return true;
        }
      }
    } catch (error) {
      setError(null);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let updatedValue = value;
    if (name === "slug") {
      updatedValue = convertToSlug(value);
      setValues({ ...values, [name]: updatedValue });
      if (
        initialValues.slug &&
        initialValues.slug !== "" &&
        initialValues.slug !== "/" &&
        updatedValue &&
        updatedValue !== initialValues.slug
      ) {
        setShowCheckbox(true);
      } else {
        setShowCheckbox(false);
      }
    } else {
      setValues({ ...values, [name]: value });
    }
  };
  const handleBlur = async (e) => {
    const { name, value } = e.target;
    if (!e.target) return;
    if (name === "slug") {
      const updatedValue = convertToSlug(value);
      if (updatedValue === initialValues.slug) {
        return;
      }
      if (
        !initialValues.slug ||
        initialValues.slug === "" ||
        initialValues.slug === "/"
      ) {
        setShowCheckbox(false);
      }
      if (initialValues.slug && (updatedValue === "" || updatedValue === "/")) {
        setError("Slug cannot be empty.");
        setIsSaveDisabled(true);
        return;
      }
      setValues((prev) => ({ ...prev, slug: updatedValue }));
      if (initialValues.slug && updatedValue !== initialValues.slug) {
        setShowCheckbox(true);
        checkSourceExists(updatedValue);
      } else {
        setShowCheckbox(false);
      }
      setError("");
      setIsSaveDisabled(false);
    }
  };

  const handleCheckboxChange = (e) => {
    const checked = e.target.checked;
    setCheckBox(checked);
  };

  const GenreateDescription = async () => {
    const payload = {
      name: product.name,
      title: values.title,
      description: values.description,
      brand: product.brand,
      categories: product.categories,
    };
    setGenrateModalOpen(true);
    setTextGenrateLoading(true);
    const data = await genrateDesc(payload, "generate-meta-description", store);
    if (data) {
      setTextMessage(data);
    }
    setTextGenrateLoading(false);
  };
  const handleFocus = (e) => {
    const { name } = e.target;
    if (name === "slug") {
      setError("");
    }
  };

  const onSave = async () => {
    await handleBlur({
      target: { name: "slug", value: values.slug || "" },
    });
    let accessToken = localStorage.getItem("access_token");
    let isSlugExists;
    if (initialValues.slug !== values.slug) {
      isSlugExists = await checkSourceExists(values.slug);
    }
    if (isSlugExists) {
      return;
    }
    const isExistSlug =
      !initialValues.slug ||
      initialValues.slug === "" ||
      initialValues.slug === "/";
    const data = {
      seo_title: values.title,
      seo_description: values.description,
      ...(values.slug && { slug: values.slug }),
      allow_redirect: isExistSlug ? false : checkBox,
    };

    let storeId = getStoreIdFromUrl();
    try {
      setLoading(true);
      await axios.patch(
        `${process.env.REACT_APP_API}/api/v2/business/store/${storeId}/product/${sku}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      setMessage("SEO settings updated successfully");
      setOpen(false);
      setToast(true);
      setLoading(false);
    } catch (err) {
      setMessage(err?.response?.data?.message || "Failed to save SEO Changes");
      setToast(true);
      setSeverity("error");
      setLoading(false);
    }
  };
  const checkForChanges = () => {
    const isChanged =
      values.title === initialValues?.title &&
      values.description === initialValues?.description &&
      values.slug === initialValues?.slug;
    setIsSaveDisabled(isChanged);
  };
  useEffect(() => {
    if (values.title && initialValues.title) {
      checkForChanges();
    }
  }, [values]);
  return (
    <>
      <Drawer
        anchor="right"
        PaperProps={{
          style: {
            width: 570,
          },
        }}
        open={open}
        onClose={() => setOpen(false)}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {fetching ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              width: "100%",
            }}
          >
            <PageLoader />
          </div>
        ) : (
          <>
            <div className={classes.headerBorder} style={{ paddingBottom: 16 }}>
              <div
                className={rowFlexJustifyBetween}
                style={{
                  paddingLeft: 28,
                  paddingRight: 28,
                  paddingTop: 20,
                }}
              >
                <Typography style={{ fontWeight: 700, fontSize: 20 }}>
                  Search Engine Listing
                </Typography>

                <IconButton
                  aria-label="Close"
                  aria-haspopup="true"
                  color="inherit"
                  onClick={() => setOpen(false)}
                >
                  <DrawerCloseIcon />
                </IconButton>
              </div>
            </div>
            <div
              style={{
                height: "calc(100vh - 160px)",
                overflow: "auto",
                padding: "24px 28px 0px 28px",
              }}
            >
              <div>
                <Typography style={{ fontSize: 14 }}>
                  Preview on Search Engine
                </Typography>
                <Typography style={{ fontSize: 12, color: "#666666" }}>
                  (Google search displays the product page as follows.)
                </Typography>
              </div>
              <div className={classes.previewBox}>
                <div className={classes.slug}>
                  {values.slug ? (
                    <div
                      dangerouslySetInnerHTML={{ __html: ` / ${values.slug}` }}
                    />
                  ) : (
                    <span style={{ color: "#666666" }}>Add Url Slug</span>
                  )}
                </div>
                <div className={classes.title}>{values.title}</div>
                <div className={classes.description}>
                  {values.description ? (
                    <div
                      dangerouslySetInnerHTML={{ __html: values.description }}
                    />
                  ) : (
                    <span style={{ color: "#666666" }}>
                      Include a meta description for enhanced SEO, summarizing
                      your page in a concise yet engaging manner.
                    </span>
                  )}
                </div>
              </div>

              <div
                style={{ borderTop: "1px solid #e1e1e1", margin: "20px 0px" }}
              ></div>
              <div className={classes.dividerGrid} style={{ display: "block" }}>
                <Typography className={classes.subHeading}>
                  SEO Title
                </Typography>
                <TextField
                  name="title"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  value={values.title}
                  onChange={handleChange}
                  style={{ marginTop: 8 }}
                  FormHelperTextProps={{
                    style: { marginTop: 4 },
                  }}
                  helperText="Optimize Page Titles for Better SEO | 50-60 Characters"
                  inputProps={{ style: { fontSize: 14 } }}
                />
                <Typography
                  style={{
                    fontSize: 14,
                    textAlign: "right",
                    position: "relative",
                    top: "-19px",
                    color: "#666666",
                    right: "5",
                  }}
                >
                  {values?.title?.length} Chars
                </Typography>
              </div>
              <div
                className={classes.dividerGrid}
                style={{ display: "block", marginTop: "-10px" }}
              >
                <div
                  className={rowFlexJustifyBetween}
                  style={{ marginBottom: "-4px" }}
                >
                  <Typography className={classes.subHeading}>
                    SEO Description
                  </Typography>
                  <Button
                    className={classes.generateButton}
                    onClick={() => GenreateDescription()}
                  >
                    <span style={{ marginRight: 6 }}>
                      <GenrateTextIcon />
                    </span>
                    Generate
                  </Button>
                </div>
                <Controls.Input
                  name="description"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  value={values.description}
                  onChange={handleChange}
                  style={{ marginTop: 8 }}
                  inputProps={{ style: { fontSize: 14 } }}
                  placeholder="Include a meta description for enhanced SEO, summarizing your page in a concise yet engaging manner."
                  rows="3"
                  multiline
                />
                <div
                  className={rowFlexJustifyBetween}
                  style={{ marginBottom: "12px", marginTop: "-18px" }}
                >
                  <Typography
                    style={{ fontSize: 12, marginTop: "4px", color: "#666666" }}
                  >
                    {" "}
                    <span style={{ display: "block" }}>
                      Boost SEO with Optimized Meta Descriptions | 150-160 Chars
                    </span>
                  </Typography>
                  <Typography
                    style={{
                      fontSize: 14,
                      textAlign: "right",
                      color: "#666666",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {values?.description?.length} Chars
                  </Typography>
                </div>
              </div>
              <div style={{ display: "block" }}>
                <Typography className={classes.subHeading}>URL Slug</Typography>
                <Controls.Input
                  type="text"
                  name="slug"
                  labelFontWeight={400}
                  labelPlacement="top"
                  value={values.slug}
                  startAdornment={"/"}
                  placeholder={"Add Url Slug"}
                  onChange={handleChange}
                  onBlur={(e) => handleBlur(e)}
                  onFocus={handleFocus}
                  style={{ marginTop: 8 }}
                  className={classes.slugTextField}
                  FormHelperTextProps={{
                    style: { marginTop: 4, width: "80%" },
                  }}
                  error={!!error}
                  maxlength={256}
                  helperText={
                    error
                      ? error
                      : "Use Short, Clear Slugs for SEO-Friendly URLs | 3-5 Words"
                  }
                />
                <Typography
                  style={{
                    fontSize: 14,
                    textAlign: "right",
                    position: "relative",
                    color: "#666666",
                    top: error ? "-22px" : "-39px",
                    right: "5",
                  }}
                >
                  {values?.slug?.length ? values?.slug?.length : "0"} Chars
                </Typography>
              </div>
              {showCheckbox && !error && (
                <div
                  style={{
                    display: "block",
                    marginTop: error ? "-21px" : "-34px",
                  }}
                >
                  <div
                    style={{
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox
                      value={checkBox}
                      onChange={handleCheckboxChange}
                      color="primary"
                      label="Create a URL redirect from"
                      checkboxStyles={{
                        color: checkBox ? "blue" : "#666666",
                        fontWeight: "400",
                      }}
                      style={{ fontWeight: "400" }}
                    />
                    <div className={classes.slugfrom}>
                      <Typography
                        style={{
                          background: "#FFEFEF",
                          padding: "2px 6px",
                          borderRadius: "4px",
                          fontSize: 14,
                          wordBreak: "break-word",
                        }}
                      >
                        /{initialValues.slug}
                      </Typography>
                      <ArrowRightAlt />
                      <Typography
                        style={{
                          background: "#FFEFEF",
                          padding: "2px 6px",
                          borderRadius: "4px",
                          fontSize: "14px",
                          wordBreak: "break-word",
                        }}
                      >
                        /{values.slug}
                      </Typography>
                    </div>
                  </div>
                </div>
              )}

              <div
                style={{
                  height: "76px",
                  position: "absolute",
                  right: "43px",
                  background: "#fff",
                  bottom: "0px",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    padding: "20px 0px",
                    bottom: 0,
                    right: 0,
                  }}
                >
                  <div
                    style={{
                      marginTop: 40,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "end",
                      gap: "16px",
                    }}
                  >
                    <OutlinedButton
                      style={{
                        height: 36,
                        minWidth: "4rem",
                        padding: "16px 0px",
                      }}
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </OutlinedButton>
                    <SolidButton
                      onClick={onSave}
                      style={{
                        height: 36,
                        minWidth: "91px",
                        padding: "16px 10px",
                      }}
                      disabled={error || isSaveDisabled}
                      loading={loading}
                    >
                      Save
                    </SolidButton>
                  </div>
                </div>
              </div>
            </div>{" "}
          </>
        )}
      </Drawer>
      {genrateModalOpen && (
        <GenrateMessage
          genrateModalOpen={genrateModalOpen}
          setGenrateModalOpen={setGenrateModalOpen}
          setProduct={setValues}
          textMessage={textMessage}
          setTextMessage={setTextMessage}
          textGenrateLoading={textGenrateLoading}
          heading="Meta Description Generator"
          loadingText=" The meta description is created using the title,description and any provided details to ensure clarity and
          accuracy."
        />
      )}
    </>
  );
};

export default SEOModal;
