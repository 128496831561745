// Core react imports
import React, { useState, useEffect } from "react";
// Store imports
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCollection,
  getCollections,
  reorderCollection,
  toggleActivation,
  setToast,
} from "../../redux/collections/collectionActions";
// Component imports
import { Grid } from "@material-ui/core";
import PageLoader from "../../components/Layout/PageLoader";
import { Header, CollectionCard } from "./components";
// Style imports
import { layoutBodyBox } from "../../styles/common";
import NoCollectionFigma from "../../icons/NoCollectionFigma";
import Toast from "../../components/Layout/Toast";
import ConfirmModal from "../../components/common/ConfirmModal";
import { getStoreIdFromUrl } from "../../utils/storeUtil";
import NoRecord from "../../components/common/NoRecord";
import CollectionTypeModal from "./CollectionTypeModal";

export default function Landing() {
  const dispatch = useDispatch();
  const storeId = getStoreIdFromUrl();
  const collectionStore = useSelector((state) => state.collections);
  const [infoToast, setInfoToast] = useState(false);
  const [isSuccessToast, setIsSuccessToast] = useState(false);
  const [deletingCollection, setDeletingCollection] = useState(null);
  const [isCollectionTypeModalOpen, setIsCollectionTypeModalOpen] =
    useState(false);
  //   Initial load
  useEffect(() => {
    if (collectionStore.list.length === 0) {
      dispatch(getCollections());
    }
    // eslint-disable-next-line
  }, [storeId]);

  const onToggleCollection = (id, newState) => {
    dispatch(toggleActivation(id, { active: newState }));
  };
  const onDeleteCollection = (id) => {
    setDeletingCollection(id);
  };

  if (collectionStore.isToast) {
    setIsSuccessToast(true);
    dispatch(setToast(false, collectionStore.message));
  }
  if (collectionStore.loading) {
    return <PageLoader />;
  }
  return (
    <div style={{ maxWidth: 1280, margin: "0 auto" }}>
      <Header
        onClick={() => {
          setIsCollectionTypeModalOpen(true);
        }}
      />
      {collectionStore.list.length === 0 ? (
        <NoRecord
          text="Start Creating Collections for your Online Store"
          arrow={true}
          image={<NoCollectionFigma />}
        />
      ) : (
        <div style={{ position: "relative", width: "100%" }}>
          <div className={layoutBodyBox}>
            <div style={{ padding: "8px 32px 32px" }}>
              <Grid container spacing={3}>
                {collectionStore.list.map((collection, index) => {
                  return (
                    <Grid item lg={4} md={6} sm={12} xs={12} key={index}>
                      <CollectionCard
                        collection={collection}
                        onToggleCollection={onToggleCollection}
                        onDeleteCollection={onDeleteCollection}
                        onMoveToTop={() =>
                          dispatch(reorderCollection(collection.id, 0, index))
                        }
                        onMoveAbove={() => {
                          let newPosition = index - 1 >= 0 ? index - 1 : 0;
                          dispatch(
                            reorderCollection(collection.id, newPosition, index)
                          );
                        }}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          </div>
        </div>
      )}
      <ConfirmModal
        open={deletingCollection ? true : false}
        message={<span>Are you sure you want to delete this collection ?</span>}
        closeBtnText="Cancel"
        confirmBtnText="Delete"
        onClose={() => {
          setDeletingCollection(null);
        }}
        onConfirm={() => {
          dispatch(deleteCollection(deletingCollection));
          setDeletingCollection(null);
        }}
      />
      <Toast
        open={infoToast}
        close={() => setInfoToast(false)}
        message="Maximum 20 collections allowed"
        severity="info"
      />
      <Toast
        open={isSuccessToast}
        close={() => setIsSuccessToast(false)}
        message={collectionStore.message}
      />
      <CollectionTypeModal
        open={isCollectionTypeModalOpen}
        onClose={() => setIsCollectionTypeModalOpen(false)}
      />
    </div>
  );
}
