export const convertUSTtoLocalTime = (USTtime) => {
  const date = new Date(USTtime);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const _date = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  const _month = month <= 9 ? `0${month}` : month;
  const __date = _date <= 9 ? `0${_date}` : _date;
  const _hours = hours <= 9 ? `0${hours}` : hours;
  const _minutes = minutes <= 9 ? `0${minutes}` : minutes;

  return `${year}-${_month}-${__date}T${_hours}:${_minutes}`;
};
