import React, { useEffect, useState } from "react";
import useStyles from "../components/ConfigStyles";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchApps,
  getPluginConfig,
  postPluginConfig,
} from "../../../redux/apps/appActions";
import PageLoader from "../../../components/Layout/PageLoader";
import { centerContainer, layoutCenterContainer } from "../../../styles/common";
import HeaderBreadcrumbs from "../../../components/common/HeaderBreadcrumbs";
import { Paper, Typography } from "@material-ui/core";
import Toast from "../../../components/Layout/Toast";
import CombinedButtons from "../../../components/buttons/CombinedButtons";
import { getCityAndState } from "../../../utils/customerUtils";
import LocationForm from "./components/LocationForm";
import { copyToClipboard } from "../../../utils/utils";
import WebhookDetails from "./components/WebhookDetails";
import Controls from "../../../components/controls/Controls";
import FilledEditButton from "../../../components/buttons/FilledEditButton";
import AddressCard from "./components/AddressCard";

const list = [
  {
    label: "App Store",
    link: "/apps",
  },
  {
    label: "Delhivery Logistics",
    link: "/apps/delhivery",
  },
  {
    label: "Configure",
  },
];

const DelhiveryLogistics = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const storeData = useSelector((state) => state.store);
  const appState = useSelector((state) => state.apps);
  const apps = appState.apps;

  const initialState = {
    api_token: {
      value: "",
      touched: false,
      error: "",
      required: true,
    },
    name: {
      value: "",
      touched: false,
      error: "",
      required: true,
    },
    registerd_name: {
      value: "",
      touched: false,
      error: "",
      required: false,
    },
    phone: {
      value: "",
      touched: false,
      error: "",
      required: true,
    },
    address: {
      value: "",
      touched: false,
      error: "",
      required: true,
    },
    pin: {
      value: "",
      touched: false,
      error: "",
      required: true,
    },
    city: {
      value: "",
      touched: false,
      error: "",
      required: true,
    },
    state: {
      value: "none",
      touched: false,
      error: "",
      required: true,
    },
    return_address: {
      value: "",
      touched: false,
      error: "",
      required: true,
    },
    return_pin: {
      value: "",
      touched: false,
      error: "",
      required: true,
    },
    return_city: {
      value: "",
      touched: false,
      error: "",
      required: true,
    },
    return_state: {
      value: "none",
      touched: false,
      error: "",
      required: true,
    },
  };

  const [formState, setFormState] = useState(initialState);
  const [sameAddress, setSameAddress] = useState(true);
  const [isEditing, setIsEditing] = useState(true);
  const [loading, setLoading] = useState(true);
  const [pluginLoading, setPluginLoading] = useState(true);
  const [savingConfig, setSavingConfig] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [isPhoneValid, setIsPhoneValid] = useState(true);

  useEffect(() => {
    if (apps.length === 0) {
      dispatch(fetchApps());
    }
    // eslint-disable-next-line
  }, [apps.length]);

  useEffect(() => {
    if (storeData.fetched) {
      dispatch(getPluginConfig("delhivery"));
    }
    //eslint-disable-next-line
  }, [storeData]);

  useEffect(() => {
    if (appState.delhivery && !Object.keys(appState.delhivery).length) {
      setPluginLoading(false);
    }

    if (Object.keys(appState.delhivery).length > 0) {
      setPluginLoading(false);
      const formData = Object.keys(appState.delhivery).reduce((acc, key) => {
        acc[key] = {
          value: appState.delhivery[key],
          touched: false,
          error: "",
        };
        return acc;
      }, {});

      setIsEditing(false);
      setFormState(formData);
    }

    setLoading(false);
    // eslint-disable-next-line
  }, [appState.delhivery]);

  const addressFieldMap = {
    address: "return_address",
    pin: "return_pin",
    city: "return_city",
    state: "return_state",
  };

  const updateAddress = (name, value) => {
    getCityAndState(value).then((response) => {
      if (response.data && response.data.state) {
        if (sameAddress) {
          setFormState((prevState) => ({
            ...prevState,
            city: {
              ...prevState.city,
              value: response.data.city || prevState.city.value,
              touched: true,
            },
            state: {
              ...prevState.state,
              value: response.data.state || prevState.state.value,
              touched: true,
            },
            return_city: {
              ...prevState.city,
              value: response.data.city || prevState.return_city.value,
              touched: true,
            },
            return_state: {
              ...prevState.state,
              value: response.data.state || prevState.return_state.value,
              touched: true,
            },
          }));
        } else {
          let cityField = name === "pin" ? "city" : "return_city";
          let stateField = name === "pin" ? "state" : "return_state";

          setFormState((prevState) => ({
            ...prevState,
            [cityField]: {
              ...prevState[cityField],
              value: response.data.city || prevState[cityField].value,
              touched: true,
            },
            [stateField]: {
              ...prevState[stateField],
              value: response.data.state || prevState[stateField].value,
              touched: true,
            },
          }));
        }
      }
    });
  };

  const handleChange = (event) => {
    let { name, value } = event.target;

    if (sameAddress && addressFieldMap[name]) {
      setFormState((prevState) => ({
        ...prevState,
        [name]: {
          value: value,
          touched: true,
        },
        [addressFieldMap[name]]: {
          value: value,
          touched: true,
        },
      }));

      if (name === "pin" && value.length === 6) {
        updateAddress(name, value);
      }
    } else {
      setFormState((prevState) => ({
        ...prevState,
        [name]: {
          ...prevState[name],
          value: value,
          touched: true,
        },
      }));

      if ((name === "pin" || name === "return_pin") && value.length === 6) {
        updateAddress(name, value);
      }
    }
  };

  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      for (const key in addressFieldMap) {
        const stateKey = addressFieldMap[key];

        setFormState((prevState) => ({
          ...prevState,
          [stateKey]: {
            ...prevState[stateKey],
          },
        }));
      }
    }

    setSameAddress(event.target.checked);
  };

  const getMessage = (name) => {
    const messages = {
      api_token: "Please enter a valid Delhivery Logistics API token.",
      name: "Location name must be at least 3 characters long.",
      registerd_name: "Please enter the contact name.",
      phone: "Please enter mobile number",
      address: "Please enter address.",
      pin: "Please enter pincode.",
      city: "Please enter city name.",
      state: "Please select state",
      return_address: "Please enter address.",
      return_pin: "Please enter pincode.",
      return_city: "Please enter city name.",
      return_state: "Please select state",
    };
    return messages[name] || "";
  };

  const onBlur = (event) => {
    const { name } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        error:
          prevState[name].required && !prevState[name].value
            ? getMessage(name)
            : "",
      },
    }));
  };

  const onFocus = (event) => {
    const { name } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        error: "",
      },
    }));
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCopy = (text) => () => {
    const result = copyToClipboard(text);
    if (!result) {
      setError("Could not copy to clipboard");
    } else {
      setSuccess("Copied to clipboard");
    }
  };

  const validatation = () => {
    let errorFlag = false;
    const updatedFormData = Object.keys(formState).reduce((acc, key) => {
      let isRequired = formState[key].required;
      let isEmpty = !formState[key].value;
      let isNameTooShort = key === "name" && formState[key].value.length < 3;
      let isInvalidState =
        (key === "state" || key === "return_state") &&
        formState[key].value === "none";

      if ((isRequired && isEmpty) || isNameTooShort || isInvalidState) {
        errorFlag = true;
      }
      const updatedField = {
        ...formState[key],
        error:
          isRequired && isEmpty
            ? getMessage(key)
            : isNameTooShort
            ? getMessage(key)
            : isInvalidState
            ? getMessage(key)
            : "",
      };
      return { ...acc, [key]: updatedField };
    }, {});
    setFormState(updatedFormData);
    return errorFlag;
  };

  const handleOutlined = () => {
    if (isEditing && Object.keys(appState.delhivery).length > 0) {
      const formData = Object.keys(appState.delhivery).reduce((acc, key) => {
        acc[key] = {
          value: appState.delhivery[key],
          touched: false,
          error: "",
        };
        return acc;
      }, {});
      setFormState(formData);
      setIsEditing(false);
    } else {
      history.goBack();
    }
  };

  const handleSave = () => {
    let isError = validatation();
    if (isError) return;

    setSavingConfig(true);
    dispatch(
      postPluginConfig("delhivery", {
        api_token: formState.api_token.value.trim(),
        name: formState.name.value,
        registerd_name: formState.registerd_name.value,
        phone: formState.phone.value,
        address: formState.address.value,
        pin: formState.pin.value,
        city: formState.city.value,
        state: formState.state.value,
        return_address: formState.return_address.value,
        return_pin: formState.return_pin.value,
        return_city: formState.return_city.value,
        return_state: formState.return_state.value,
      })
    ).then((configObj) => {
      if (configObj.error) {
        setError(configObj.message);
        setSavingConfig(false);
      } else {
        setSuccess("Delhivery configuration settings saved successfully.");
        setSavingConfig(false);
      }
    });
  };

  if (loading || pluginLoading) return <PageLoader />;

  return (
    <div className={layoutCenterContainer}>
      <div className={centerContainer}>
        <div className={classes.breadcrumbsContainer}>
          <div className={classes.backDiv}>
            <HeaderBreadcrumbs list={list} />
          </div>
        </div>
        <Paper elevation={0} className={classes.paper}>
          <div>
            <div className={classes.headingButtonSection}>
              <Typography className={classes.formHead}>
                Delhivery Logistics Configuration Settings
              </Typography>
              {!isEditing && <FilledEditButton onClick={handleEdit} />}
            </div>

            <Controls.Input
              name="api_token"
              label="API Token"
              labelPlacement="top"
              placeholder={"e.g., abcd1234efgh5678"}
              value={formState.api_token.value}
              onChange={handleChange}
              onFocus={onFocus}
              onBlur={onBlur}
              error={formState.api_token.error}
              helperText={
                !formState.api_token.error && "Enter your Delhivery API token."
              }
              extraSpace={formState.api_token.error && true}
              type={isEditing ? "text" : "password"}
            />

            {isEditing ? (
              <LocationForm
                formState={formState}
                sameAddress={sameAddress}
                onBlur={onBlur}
                onFocus={onFocus}
                handleChange={handleChange}
                handleCheckboxChange={handleCheckboxChange}
                setIsPhoneValid={setIsPhoneValid}
              />
            ) : (
              <>
                <AddressCard formData={formState} />
                <WebhookDetails formData={formState} handleCopy={handleCopy} />
              </>
            )}
          </div>

          {isEditing && (
            <div className={classes.button}>
              <CombinedButtons
                outlinedBtnText="Back"
                outlinedBtnAction={handleOutlined}
                solidBtnAction={handleSave}
                loading={savingConfig}
              />
            </div>
          )}
        </Paper>
      </div>
      <Toast
        autoClose
        message={success}
        open={!!success}
        close={() => setSuccess(null)}
      />
      <Toast
        autoClose
        message={error}
        open={!!error}
        close={() => setError(null)}
        severity="error"
      />
    </div>
  );
};

export default DelhiveryLogistics;
