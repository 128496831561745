import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import Moment from "react-moment";

import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";

const useStyles = makeStyles(() => ({
  flexBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  subHeading: {
    fontWeight: 500,
    fontSize: 14,
    color: "#1A1A1A",
    paddingTop: 6,
  },
}));

const PaymentAccordion = ({ payment, showEdit, onEdit }) => {
  const classes = useStyles();
  const store = useSelector((state) => state.store.store);
  const countrySymbol = store.currency_symbol;
  const onPaymentEdit = (event) => {
    if (payment != null && onEdit instanceof Function) {
      event.stopPropagation();
      onEdit(payment);
    }
  };
  return (
    <div style={{ marginTop: 4 }}>
      <Accordion
        style={{
          border: "none",
          boxShadow: "none",
          background: "none",
        }}
      >
        <AccordionSummary style={{ padding: 0 }}>
          <div
            className={classes.flexBox}
            style={{
              borderBottom: "1px dashed #e5e5e5",
              paddingTop: 0,
              paddingBottom: 16,
              width: "100%",
            }}
          >
            <Moment
              style={{
                width: "34%",
                fontSize: 12,
                lineHeight: "16px",
              }}
              format="DD-MMM-YYYY"
            >
              {payment.created_at}
            </Moment>
            <Typography
              style={{
                width: "33%",
                fontSize: 12,
                lineHeight: "16px",
                textAlign: "center",
              }}
            >
              {payment.payment_type.replace(/_/g, " ")}
            </Typography>
            <Typography
              style={{
                fontSize: 12,
                width: "33%",
                lineHeight: "16px",
                textAlign: "right",
                borderBottom: showEdit ? "1px dotted #1641DB" : "none",
                paddingBottom: 3,
              }}
              onClick={onPaymentEdit}
            >
              {countrySymbol} {payment.amount.toFixed(2)}
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails
          style={{
            paddingTop: 0,
            borderBottom: "1px dashed rgb(240,240,240)",
          }}
        >
          <div style={{ width: "100%" }}>
            <div style={{ width: "100%" }} className={classes.flexBox}>
              <Typography className={classes.subHeading}>
                Reference ID
              </Typography>
              <Typography
                className={classes.subHeading}
                style={{
                  color: payment.reference_id ? "#1A1A1A" : "#666666",
                }}
              >
                {payment.reference_id ? payment.reference_id : "Not Available"}
              </Typography>
            </div>
            <div style={{ width: "100%" }} className={classes.flexBox}>
              <Typography className={classes.subHeading}>Notes</Typography>
              <Typography
                className={classes.subHeading}
                style={{
                  color: payment.notes ? "#1A1A1A" : "#666666",
                }}
              >
                {payment.notes ? payment.notes : "Not Available"}
              </Typography>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default PaymentAccordion;
