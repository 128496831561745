import React, { useEffect, useState } from "react";
import { useStyles } from "./components/WalletStyle";
import WalletTable from "./components/WalletTable";
import WalletCard from "./components/WalletCard";
import {
  fetchTransactions,
  periodOptions,
  postRechargeRequest,
  sortOptions,
} from "./components/WalletUtils";

import { Paper } from "@material-ui/core";
import Filters from "./components/Filters";
import AddCreditModal from "./components/AddCreditModal";
import { layoutCenterContainer, layoutSubHeader } from "../../styles/common";
import HeaderBreadcrumbs from "../../components/common/HeaderBreadcrumbs";
import Toast from "../../components/Layout/Toast";
import Paginator from "../../components/Paginator/Paginator";
import { useDispatch, useSelector } from "react-redux";
import { fetchWalletBalance } from "../../redux/wallet/walletActions";
import { useLocation } from "react-router-dom";

export default function Wallet() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();

  const { progress, walletData } = useSelector((state) => state.wallet);

  const [transactions, setTransactions] = useState([]);
  const [selectedSort, setSelectedSort] = useState(sortOptions[0]);
  const [periodFilter, setPeriodFilter] = useState(periodOptions[0]);
  const [showCreditModal, setShowCreditModal] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalPages, setTotalPages] = useState(null);
  const [totalElements, setTotalElements] = useState(null);
  const [tableLoading, SetTableLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const queryString = location.search;
    const urlParams = new URLSearchParams(queryString);
    const action = urlParams.get("action");

    if (action === "buyCredits") {
      setShowCreditModal(true);
    }
  }, [location.search]);

  useEffect(() => {
    SetTableLoading(true);
    fetchAndSetTransactions(
      selectedSort,
      periodFilter,
      page,
      rowsPerPage
    ).finally(() => SetTableLoading(false));
  }, [selectedSort, periodFilter, page, rowsPerPage]);

  const fetchAndSetTransactions = async (sort, period, page, size) => {
    try {
      const filteredData = await fetchTransactions(sort, period, page, size);
      setTransactions(filteredData.content);
      setTotalPages(filteredData.totalPages);
      setTotalElements(filteredData.totalElements);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleRefetchData = async (requestId, payment) => {
    try {
      SetTableLoading(true);

      await postRechargeRequest({
        recharge_request_id: requestId,
        payment: payment,
      });

      setSuccess("Wallet recharged successfully");

      dispatch(fetchWalletBalance());

      const filteredData = await fetchTransactions(
        selectedSort,
        periodFilter,
        0,
        rowsPerPage
      );

      setTransactions(filteredData.content);
      setTotalElements(filteredData.numberOfElements);
    } catch (error) {
      setError(error.message);
    } finally {
      SetTableLoading(false);
    }
  };

  const handleCreditModal = () => {
    setShowCreditModal((prev) => !prev);
  };

  const handleChangeFilters = (newSort, newPeriod) => {
    setSelectedSort(newSort);
    setPeriodFilter(newPeriod);
  };

  const onNextPage = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const onPrevPage = () => {
    setPage((prevPage) => Math.max(0, prevPage - 1));
  };

  const handleRowsPerPage = (value) => {
    setRowsPerPage(value);
    setPage(0);
  };

  return (
    <div className={layoutCenterContainer}>
      <div style={{ width: "100%", maxWidth: 1024 }}>
        <div className={layoutSubHeader}>
          <HeaderBreadcrumbs list={[{ label: "Shoopy Wallet" }]} />
        </div>
        <div className={classes.container}>
          <WalletCard
            walletData={walletData}
            pointsLoading={progress}
            handleCreditModal={handleCreditModal}
          />
          <div>
            <Paper className={classes.listContainer}>
              <Filters
                selectedSort={selectedSort}
                periodFilter={periodFilter}
                onChangeFilters={handleChangeFilters}
              />
              <WalletTable
                tableLoading={tableLoading}
                displayedRows={transactions}
              />
            </Paper>
            <Paginator
              changeRecordsPerPage={handleRowsPerPage}
              possibleRecordsPerPage={[5, 10, 20, 50, 100]}
              totalCount={totalElements}
              recordsPerPage={rowsPerPage}
              onNext={onNextPage}
              onPrev={onPrevPage}
              total={totalPages === 0 ? 1 : totalPages}
              currentPage={page}
            />
          </div>
        </div>
      </div>
      {showCreditModal && (
        <AddCreditModal
          isOpen={showCreditModal}
          setError={setError}
          onClose={handleCreditModal}
          onPaymentSuccess={handleRefetchData}
        />
      )}

      <Toast open={!!success} message={success} close={setSuccess} />
      <Toast open={!!error} message={error} close={setError} severity="error" />
    </div>
  );
}
