import { makeStyles } from "@material-ui/styles";
export default makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
  bannerRoot: {
    position: "relative",
    height: "100%",
  },
  icon: {
    alignItems: "center",
    display: "flex",
    justifyContent: "left",
  },
  signin: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  heading: {
    fontWeight: 700,
    fontSize: 24,
    lineHeight: "32px",
    color: "black",
    marginTop: 24,
  },
  subheading: {
    fontWeight: "400",
    fontSize: "14px",
    color: "#666666",
    "& a": {
      color: "#1641DB",
    },
    "& span": {
      color: "#000000",
    },
  },
  subContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    "& div": {
      color: "#1641DB",
      fontWeight: "600",
      alignItems: "center",
      display: "flex",
      cursor: "pointer",
    },
  },
  input: {
    "& div": {
      background: "#F9F9F9",
      height: 44,
      borderRadius: "8px !important",
    },
  },
  form: {
    marginTop: 36,
    position: "relative",
  },
  disabled: {
    background: "rgba(22, 65, 219, 0.6) !important",
    color: "#FFFFFF !important",
    boxShadow: "none",
  },
  label: {
    fontSize: 14,
    lineHeight: "20px",
    color: "#666666",
    fontWeight: 600,
  },
  madeinindiactn: {
    display: "flex",
    justifyContent: "center",
    width: 384,
    position: "fixed",
    bottom: 40,
  },
  madeinindiamsg: {
    display: "flex",
    alignItems: "center",
    color: "rgba(153, 153, 153, 0.9)",
  },
  bannercontent: {
    position: "absolute",
    bottom: "30%",
    left: 120,
  },
  bannerheading: {
    fontSize: 32,
    color: "#666666",
    lineHeight: "36px",
    fontWeight: "bold",
  },
  bannersubheading: {
    fontSize: 16,
    lineHeight: "24px",
    color: "#666666",
    width: 208,
  },
  media: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: 12,
  },
  resend: {
    padding: 0,
    fontSize: 12,
    position: "absolute",
    right: 0,
    bottom: 50,
  },
  otpItem: {
    width: "52px !important",
    border: "1px solid rgba(0,0,0,0.3)",
    height: 52,
    textAlign: "center",
    margin: "0 auto",
    borderRadius: 6,
    "&::-webkit-inner-spin-button ": {
      "-webkit-appearance": "none",
    },
    "&::-webkit-outer-spin-button ": {
      "-webkit-appearance": "none",
    },
    "&:focus": {
      outline: "none",
      border: `1px solid ${theme.palette.secondary.main}`,
    },
    fontSize: 20,
  },
  errorText: {
    color: theme.palette.error.main,
  },
  divider: {
    width: "100%",
    textAlign: "center",
    margin: "1rem 0 !important",
    position: "relative",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundImage: `url("https://dms.mydukaan.io/original/webp/media/8e821c02-016d-404d-8dc1-fd55fdb834bc.png")`,
    // background:
    //   "linear-gradient(to right, rgba(0, 0, 255, 0.8), rgba(128, 0, 128, 0.8), rgba(255, 0, 0, 0.8))",
    mixBlendMode: "multiply",
    backgroundSize: "cover",
    backgroundPosition: "bottom",
    backgroundRepeat: "no-repeat",
    position: "relative",
  },
  upperDiv: {
    padding: 25,
    borderRadius: 12,
    paddingBottom: 16,
    position: "absolute",
    background: "#ffffff",
    width: 500,
  },
  div: {
    height: "50px",
    position: "relative",
    marginTop: 50,
    marginBottom: 7,
  },
  selectimg: {
    width: 22,
    height: 22,
    marginRight: 16,
    borderRadius: "50%",
  },
  businessmodal: {
    borderRadius: 12,
    position: "absolute",
    background: "#ffffff",
    width: 432,
    paddingBottom: 12,
    padding: 24,
    outline: 0,
    overflowY: "auto", // Enables scrolling only when needed
    minHeight: "450px",
    height: "auto", // Auto height to fit content
    maxHeight: "calc(100vh - 40px)", // Keeps 20px space on top and bottom
    marginTop: "20px",
    marginBottom: "20px",
  },
}));
