import React from "react";
import { Modal, Typography, makeStyles } from "@material-ui/core";
import CombinedButtons from "../buttons/CombinedButtons";
import { rowFlexAlignStart } from "../../styles/common";
import { InfoOutlined } from "@material-ui/icons";
import CloseButton from "../buttons/CloseButton";

const useStyles = makeStyles({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  upperDiv: {
    padding: 25,
    borderRadius: 12,
    paddingBottom: 16,
    position: "absolute",
    background: "#ffffff",
    width: 450,
  },
  div: {
    height: "40px",
    position: "relative",
    marginTop: 40,
    marginBottom: 7,
  },
  button: {
    position: "absolute",
    bottom: 0,
    right: 0,
  },
});

const DeleteModal = ({
  item,
  onCancel,
  onConfirmDelete,
  isDeleting,
  deleteMessage,
  customMessage,
  style,
  page = "",
  heading = "",
}) => {
  const classes = useStyles();
  return (
    <Modal
      open={item ? true : false}
      onClose={onCancel}
      className={classes.modal}
    >
      <div className={classes.upperDiv}>
        {heading && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 16,
              borderBottom: "1px solid #E0E0E0",
              paddingBottom: 16,
              marginTop: -8,
            }}
          >
            <p style={{ fontSize: 20, fontWeight: 600, marginBottom: 0 }}>
              {heading}
            </p>
            <CloseButton
              onClick={onCancel}
              style={{ minWidth: 0, padding: 6 }}
              iconStyle={{ width: 16, height: 16 }}
            />
          </div>
        )}
        {customMessage && <p style={{ fontSize: 14 }}> {customMessage} </p>}
        {!customMessage && (
          <p style={{ fontSize: 14 }}>
            Do you really want to delete <b> {item.name ? item.name : item}</b>{" "}
            {page}?
          </p>
        )}
        {deleteMessage && (
          <div className={rowFlexAlignStart} style={style}>
            <InfoOutlined style={{ color: "#FF6060", marginTop: "2px" }} />
            <Typography style={{ fontSize: 14, marginLeft: "8px" }}>
              {deleteMessage}
            </Typography>
          </div>
        )}
        <div className={classes.div}>
          <div className={classes.button}>
            <CombinedButtons
              loading={isDeleting}
              outlinedBtnAction={onCancel}
              solidBtnAction={onConfirmDelete}
              isDelete={true}
              solidBtnText="Delete"
              style={{
                fontSize: 14,
                height: 36,
                padding: "16px 0px",
                minWidth: 90,
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;
