import React from "react";

const LockIcon = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.5"
        y="0.5"
        width="19"
        height="19"
        rx="3.5"
        fill="#F9F9F9"
        stroke="#E1E1E1"
      />
      <path
        d="M6.24514 17C5.90116 17 5.60764 16.879 5.36458 16.6371C5.12153 16.3952 5 16.1044 5 15.7647V9.17647C5 8.83676 5.1224 8.54596 5.36719 8.30404C5.61198 8.06213 5.90625 7.94118 6.25 7.94118H6.66667V6.29412C6.66667 5.38275 6.99207 4.60588 7.64288 3.96353C8.29358 3.32118 9.08061 3 10.004 3C10.9273 3 11.713 3.32118 12.3611 3.96353C13.0093 4.60588 13.3333 5.38275 13.3333 6.29412V7.94118H13.75C14.0938 7.94118 14.388 8.06213 14.6328 8.30404C14.8776 8.54596 15 8.83676 15 9.17647V15.7647C15 16.1044 14.8775 16.3952 14.6326 16.6371C14.3877 16.879 14.0933 17 13.7493 17H6.24514ZM6.25 15.7647H13.75V9.17647H6.25V15.7647ZM10.0036 13.7059C10.3484 13.7059 10.6424 13.5846 10.8854 13.342C11.1285 13.0994 11.25 12.8077 11.25 12.467C11.25 12.1263 11.1273 11.8358 10.8818 11.5956C10.6363 11.3554 10.3411 11.2353 9.99635 11.2353C9.65156 11.2353 9.35764 11.3566 9.11458 11.5992C8.87153 11.8418 8.75 12.1335 8.75 12.4742C8.75 12.8149 8.87274 13.1054 9.11823 13.3456C9.36372 13.5858 9.65885 13.7059 10.0036 13.7059ZM7.91667 7.94118H12.0833V6.29412C12.0833 5.72222 11.8808 5.23611 11.4757 4.83578C11.0706 4.43546 10.5787 4.23529 10 4.23529C9.4213 4.23529 8.9294 4.43546 8.52431 4.83578C8.11921 5.23611 7.91667 5.72222 7.91667 6.29412V7.94118Z"
        fill="#666666"
      />
    </svg>
  );
};
export default LockIcon;
