import React, { useEffect, useRef } from "react";

const PreviewHelloBar = ({ storeUrl, outputConfigs }) => {
  const ref = useRef();
  const onIfrrameLoad = () => {
    if (ref?.current) {
      ref.current.contentWindow.postMessage(
        JSON.stringify({
          helloBar: outputConfigs,
          type: "hello-bar-externally",
          action: "setHelloBar",
        }),
        storeUrl
        // "http://little-dragon.localhost:3000"
      );
    }
  };

  useEffect(() => {
    onIfrrameLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outputConfigs]);

  return (
    <iframe
      ref={ref}
      onLoad={onIfrrameLoad}
      title="Theme Preview"
      id="my-this-ifrma"
      src={storeUrl + "?mobile=true"}
      // src={"http://little-dragon.localhost:3000?mobile=true"}
      frameBorder="0"
      width="100%"
      height="100%"
      scrolling="yes"
    />
  );
};

export default PreviewHelloBar;
