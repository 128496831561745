import React from "react";
import Input from "../../../../../components/controls/Input";
import { maxNumOfProducts } from "../../../utils";
import { makeStyles, Paper, Typography } from "@material-ui/core";
import OutlinedButton from "../../../../../components/buttons/OutlinedButton";
import useGenericCollection from "./hooks/useGenericCollection";
import { InputGridContainer, InputGridItem } from "../InputGridContainer";
import SubtitleSelect from "../../../../../components/controls/SubtitleSelect";
import { colorPicker, formLabel, formWrapperLeft } from "../../../style";
import { ColorPicker } from "material-ui-color";
import { colorPickerColors } from "../../../../../utils/colorPickerColors";
import AddCard from "./components/AddCard/AddCard";
import AddedCards from "./components/AddedCards/AddedCards";
import {
  rowFlexCenterAll,
  rowFlexOnlyJustifyBetweenCenter,
  rowFlexOnlyJustifyEnd,
} from "../../../../../styles/common";
import { useHistory } from "react-router-dom";
import CombinedButtons from "../../../../../components/buttons/CombinedButtons";
import Switch from "../../../../../components/controls/Switch";
import CartDisplaySettingsWrapper from "../CartDisplaySettingsWrapper";
import CollectionDrawer from "./components/CollectionDrawer";
import { useCardsPerRowHandler } from "./hooks/useCardsPerRowHandler";
import ResetIcon from "../../../../../icons/ResetIcon";
import DropdownIcon from "../../../../../icons/DropdownIcon";

const useStyle = makeStyles(() => ({
  formLabel: {
    fontWeight: "400 !important",
  },
  headingCtn: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "16px",
  },
  heading: {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 600,
  },
  reset: {
    color: "#1641db",
    cursor: "pointer",
    fontSize: 14,
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    fontWeight: 600,

    "& svg": {
      marginRight: 4,
      width: 16,
      height: 16,
    },

    "&.disabled": {
      opacity: 0.3,
      cursor: "auto",
      color: "#666666",
    },
  },
}));

const GenericCollection = ({ formState, record, setErrorToast }) => {
  const {
    showAddCard,
    setShowAddCard,
    error,
    bannerError,
    setBannerError,
    videoError,
    setVideoError,
    form,
    handleFormChange,
    handleFormMultiChange,
    bannerForm,
    videoForm,
    handleBannerFormChange,
    handleVideoFormChange,
    resetAddCardValues,
    addedCards,
    setAddedCards,
    titleForm,
    setTitleForm,
    handleTitleFormChange,
    handleEditCard,
    editingIndex,
    setEditingIndex,
    loading,
    onSave,
    drawerOpenTab,
    setDrawerOpenTab,
    cardsCollapsed,
    setCardsCollapsed,
    htmlForm,
    handleHtmlFormChange,
    htmlError,
    setHtmlError,
  } = useGenericCollection({ formState, record, setErrorToast });
  const history = useHistory();

  const { isSelectionDisabled, handleCardsPerRowChange } =
    useCardsPerRowHandler({
      addedCards,
      handleFormChange,
    });

  const classes = useStyle();

  const isMobileNoOfCardsPerRowDisabled = isSelectionDisabled(
    form.mobile_item_ratios.value,
    addedCards,
    form.max_products_mobile.value
  );

  const isDesktopNoOfCardsPerRowDisabled = isSelectionDisabled(
    form.desktop_item_ratios.value,
    addedCards,
    form.max_products_desktop.value
  );

  return (
    <div>
      <Paper className={formWrapperLeft} style={{ width: "100%" }}>
        <div>
          <div
            className={rowFlexOnlyJustifyBetweenCenter}
            style={{
              marginBottom: "15px",
            }}
          >
            <Typography
              style={{
                fontSize: "14px",
                lineHeight: "20px",
                fontWeight: 600,
              }}
            >
              Cards ({addedCards.length})
            </Typography>
            {addedCards.length > 0 && (
              <span
                className={classes.reset}
                variant="text"
                onClick={() => setShowAddCard(true)}
              >
                + Add Card
              </span>
            )}
          </div>
          {addedCards.length === 0 && (
            <div
              style={{ padding: "40px 32px", paddingTop: 25, color: "#666666" }}
              className={rowFlexCenterAll}
            >
              No Cards to display
            </div>
          )}
          <AddedCards
            addedCards={
              cardsCollapsed ? addedCards.concat().slice(0, 3) : addedCards
            }
            setAddedCards={setAddedCards}
            handleEditCard={handleEditCard}
            setCardsCollapsed={setCardsCollapsed}
          />
          {addedCards.length > 3 && cardsCollapsed && (
            <span
              className={classes.reset}
              onClick={() => setCardsCollapsed(false)}
              style={{ margin: "auto" }}
            >
              View all <DropdownIcon style={{ marginLeft: 8 }} />
            </span>
          )}
          {addedCards.length === 0 && (
            <div>
              <OutlinedButton
                fullWidth
                onClick={() => setShowAddCard(true)}
                style={{ height: 38 }}
              >
                <span style={{ fontWeight: "400" }}>+ Add Card</span>
              </OutlinedButton>
              {error.card && (
                <p
                  style={{
                    color: "#f44336",
                    fontSize: 12,
                    marginTop: 6,
                  }}
                >
                  {error.card}
                </p>
              )}
            </div>
          )}
        </div>
      </Paper>
      <CartDisplaySettingsWrapper>
        <hr
          style={{
            margin: "0 -32px",
            marginTop: "-8px",
            marginBottom: "16px",
          }}
        />
        <div>
          <div className={classes.headingCtn}>
            <Typography className={classes.heading}>Mobile View</Typography>
            <span
              variant="text"
              className={`${classes.reset} ${
                !isMobileNoOfCardsPerRowDisabled ? "disabled" : ""
              }`}
              {...(isMobileNoOfCardsPerRowDisabled && {
                onClick: () => {
                  handleCardsPerRowChange(
                    1,
                    "max_products_mobile",
                    "mobile_item_ratios"
                  );
                  handleFormChange("mobile_item_spacing", 4);
                },
              })}
            >
              <ResetIcon /> Reset
            </span>
          </div>
          <InputGridContainer>
            <InputGridItem>
              <SubtitleSelect
                optionList={maxNumOfProducts
                  .concat()
                  .splice(0, addedCards.length < 2 ? 2 : addedCards.length)}
                value={form.max_products_mobile.value}
                onChange={(val) =>
                  handleCardsPerRowChange(
                    val,
                    "max_products_mobile",
                    "mobile_item_ratios"
                  )
                }
                label="Number of Cards per row"
                labelClass={`${formLabel} ${classes.formLabel}`}
                wrapperStyle={{ marginBottom: "16px" }}
                disabled={isMobileNoOfCardsPerRowDisabled}
                helperText={
                  isMobileNoOfCardsPerRowDisabled
                    ? "Advanced customization active. Click Reset to revert."
                    : ""
                }
              />
            </InputGridItem>
            <InputGridItem>
              <Input
                label="Card Spacing"
                placeholder="2"
                color="secondary"
                variant="outlined"
                value={form.mobile_item_spacing.value}
                onChange={(e) => {
                  handleFormChange("mobile_item_spacing", e.target.value);
                }}
                onFocus={() => {}}
                onBlur={() => {}}
                labelPlacement="top"
                endAdornment={<span style={{ color: "#b9b9b9" }}>px</span>}
                type="number"
                labelFontWeight={400}
                labelStyle={{ marginBottom: 8 }}
                hideHelperText
              />
            </InputGridItem>
          </InputGridContainer>
        </div>
        <div>
          <div className={classes.headingCtn}>
            <Typography className={classes.heading}>Desktop View</Typography>
            <span
              variant="text"
              className={`${classes.reset} ${
                !isDesktopNoOfCardsPerRowDisabled ? "disabled" : ""
              }`}
              {...(isDesktopNoOfCardsPerRowDisabled && {
                onClick: () => {
                  handleCardsPerRowChange(
                    2,
                    "max_products_desktop",
                    "desktop_item_ratios"
                  );
                  handleFormChange("desktop_item_spacing", 8);
                },
              })}
            >
              <ResetIcon /> Reset
            </span>
          </div>
          <InputGridContainer>
            <InputGridItem>
              <SubtitleSelect
                optionList={maxNumOfProducts
                  .concat()
                  .splice(0, addedCards.length < 2 ? 2 : addedCards.length)}
                value={form.max_products_desktop.value}
                onChange={(val) =>
                  handleCardsPerRowChange(
                    val,
                    "max_products_desktop",
                    "desktop_item_ratios"
                  )
                }
                label="Number of Cards per row"
                labelClass={`${formLabel} ${classes.formLabel}`}
                wrapperStyle={{ marginBottom: "16px" }}
                disabled={isDesktopNoOfCardsPerRowDisabled}
                helperText={
                  isDesktopNoOfCardsPerRowDisabled
                    ? "Advanced customization active. Click Reset to revert."
                    : ""
                }
              />
            </InputGridItem>
            <InputGridItem>
              <Input
                label="Card Spacing"
                placeholder="2"
                color="secondary"
                variant="outlined"
                value={form.desktop_item_spacing.value}
                onChange={(e) => {
                  handleFormChange("desktop_item_spacing", e.target.value);
                }}
                onFocus={() => {}}
                onBlur={() => {}}
                labelPlacement="top"
                labelFontWeight={400}
                endAdornment={<span style={{ color: "#b9b9b9" }}>px</span>}
                type="number"
                labelStyle={{ marginBottom: 8 }}
                hideHelperText
              />
            </InputGridItem>
          </InputGridContainer>
          <InputGridContainer>
            <InputGridItem>
              <Typography
                style={{
                  fontSize: "14px",
                  lineHeight: "20px",
                  marginBottom: "8px",
                }}
              >
                Background Color
              </Typography>
              <div className={colorPicker}>
                <ColorPicker
                  hideTextfield
                  value={form.background_color.value}
                  onChange={(value) => {
                    handleFormChange("background_color", value);
                  }}
                  palette={colorPickerColors}
                />
                <Typography style={{ fontSize: 14, marginLeft: 10 }}>
                  {form.background_color.value.css.backgroundColor}
                </Typography>
              </div>
            </InputGridItem>
            <InputGridItem>
              <Typography
                style={{
                  fontSize: "14px",
                  lineHeight: "20px",
                  marginBottom: "8px",
                }}
              >
                Text Color
              </Typography>
              <div className={colorPicker}>
                <ColorPicker
                  hideTextfield
                  value={form.text_color.value}
                  onChange={(value) => {
                    handleFormChange("text_color", value);
                  }}
                  palette={colorPickerColors}
                />
                <Typography style={{ fontSize: 14, marginLeft: 10 }}>
                  {form.text_color.value.css.backgroundColor}
                </Typography>
              </div>
            </InputGridItem>
          </InputGridContainer>
          <div style={{ marginTop: "20px" }}>
            <Switch
              label="Show Title"
              value={form?.show_title?.value}
              onChange={() =>
                handleFormChange("show_title", !form?.show_title?.value)
              }
              style={{ margin: 0 }}
              helperText="Show title inside banner"
            />
          </div>
          <div style={{ marginTop: "16px" }}>
            <OutlinedButton
              fullWidth
              onClick={() => {
                setDrawerOpenTab("mobile");
              }}
              disabled={addedCards.length === 0}
              style={{
                ...(addedCards.length === 0 && { opacity: 0.3 }),
                height: 38,
              }}
            >
              <span style={{ fontWeight: "400" }}>
                Advance Layout Customization
              </span>
            </OutlinedButton>
          </div>
        </div>
      </CartDisplaySettingsWrapper>
      <div
        className={`${rowFlexOnlyJustifyEnd}`}
        style={{ maxWidth: 650, marginTop: 32 }}
      >
        <CombinedButtons
          solidBtnAction={onSave}
          outlinedBtnAction={() => history.goBack()}
          loading={loading}
          disabled={!formState.name.value || addedCards.length === 0}
        />
      </div>
      <CollectionDrawer
        drawerOpenTab={drawerOpenTab}
        setDrawerOpenTab={setDrawerOpenTab}
        addedCards={addedCards}
        form={form}
        handleFormMultiChange={handleFormMultiChange}
      />
      {showAddCard && (
        <AddCard
          open={showAddCard}
          onClose={() => {
            setShowAddCard(false);
            resetAddCardValues(true);
          }}
          bannerForm={bannerForm}
          handleBannerFormChange={handleBannerFormChange}
          videoForm={videoForm}
          handleVideoFormChange={handleVideoFormChange}
          resetAddCardValues={resetAddCardValues}
          form={form}
          handleFormChange={handleFormChange}
          setAddedCards={setAddedCards}
          titleForm={titleForm}
          videoError={videoError}
          setVideoError={setVideoError}
          bannerError={bannerError}
          setBannerError={setBannerError}
          setTitleForm={setTitleForm}
          handleTitleFormChange={handleTitleFormChange}
          editingIndex={editingIndex}
          setEditingIndex={setEditingIndex}
          htmlForm={htmlForm}
          handleHtmlFormChange={handleHtmlFormChange}
          htmlError={htmlError}
          setHtmlError={setHtmlError}
        />
      )}
    </div>
  );
};

export default GenericCollection;
