import React from "react";
import { Button, Grid, Box, Typography } from "@material-ui/core";
import useStyles from "./styles";
import { getStoreIdFromUrl } from "../../../../utils/storeUtil";
import { useHistory } from "react-router-dom";
import { isCustomDomain } from "../../../../utils/utils";
import { getDay, MONTHS } from "../../../../utils/dateUtils";
import { useSelector } from "react-redux";
const RENEWAL_DAYS_LIMIT = 30;
const Header = ({ plans, renewObj }) => {
  const classes = useStyles();
  const history = useHistory();
  const storeId = getStoreIdFromUrl();
  const customDomain = isCustomDomain();
  const store = useSelector((state) => state.store.store);
  const countrySymbol = store.currency_symbol;
  const calculateRemainingDays = (renewDate) => {
    if (!renewDate) return null;
    const renewTime = new Date(renewDate).getTime();
    const nowTime = getDay(new Date()).getTime();
    return Math.floor((renewTime - nowTime) / (1000 * 3600 * 24));
  };
  const dueIn = renewObj ? calculateRemainingDays(renewObj.renew_date) : null;

  const redirectToRenew = () => {
    history.push(
      customDomain
        ? `/admin/${storeId}/billing/plans/info`
        : `/${storeId}/billing/plans/info`
    );
  };

  // Plan subtitles
  const planSubTitles = {
    FREE: "Lifetime free",
    BASIC:
      "Perfect for those who want to get started quickly and set up a store with their own domain.",
    BUSINESS_APP_PRO:
      "Ideal for those who want to connect their domain, have android app, and need staff management.",
    BUSINESS:
      "Best for those who want store setup by experts, android app, premium support, blog and custom pages.",
  };

  // Format date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = MONTHS[date.getMonth()];
    const year = date.getFullYear() % 100;
    return (
      <span>
        <span style={{ fontWeight: "normal" }}>Valid till: </span>
        <span style={{ fontWeight: "bold" }}>
          {day} {month} {year}
        </span>
      </span>
    );
  };

  // Get subscription status
  const getSubscriptionStatus = () => {
    if (!renewObj) return null;

    if (renewObj.status === "NOTDUE" && renewObj.plan === "FREE") {
      return <Typography className={classes.activeforever}>Active</Typography>;
    }

    if (dueIn < 0) {
      return (
        <Typography className={classes.planexpired}>
          <img
            src="/subscribtion_page/info.svg"
            alt="icon"
            style={{ width: "20px", height: "20px", marginRight: "8px" }}
          />
          Plan Expired
        </Typography>
      );
    }

    if (dueIn > RENEWAL_DAYS_LIMIT) {
      return (
        <Typography className={classes.runningplan}>
          <img
            src="/subscribtion_page/schedule.svg"
            alt="icon"
            style={{ width: "20px", height: "20px", marginRight: "8px" }}
          />
          {formatDate(renewObj.renew_date)}
        </Typography>
      );
    }

    if (dueIn <= RENEWAL_DAYS_LIMIT) {
      const iconSrc =
        dueIn > 15
          ? "/subscribtion_page/info.svg"
          : "/subscribtion_page/schedule.svg";
      return (
        <Typography
          className={dueIn > 15 ? classes.planexpired : classes.runningplan}
        >
          <img
            src={iconSrc}
            alt="icon"
            style={{ width: "20px", height: "20px", marginRight: "8px" }}
          />
          {dueIn === 0 ? (
            "Plan Expires Today"
          ) : dueIn > 0 ? (
            <>
              <span style={{ fontWeight: 600, marginRight: "4px" }}>
                {dueIn} {dueIn === 1 ? "day" : "days"}
              </span>{" "}
              left to Expire
            </>
          ) : (
            `Plan Expired - ${Math.abs(dueIn)} days ago`
          )}
        </Typography>
      );
    }

    return null;
  };

  // Get renew plan button
  const getRenewPlan = () => {
    if (dueIn >= -7 && dueIn <= RENEWAL_DAYS_LIMIT) {
      return (
        <Button
          onClick={redirectToRenew}
          className={classes.upgrade}
          style={{
            marginLeft:
              dueIn >= -7 && dueIn <= RENEWAL_DAYS_LIMIT ? "10px" : "0px",
          }}
        >
          Renew Plan
        </Button>
      );
    }

    return null;
  };

  // Active plan message
  const getActivePlanMessage = () => {
    if (renewObj?.plan_title) {
      return (
        <Typography
          className={classes.activePlan}
          style={{ fontSize: "20px", fontWeight: 700 }}
        >
          {renewObj.plan_title} <span>Plan</span>
        </Typography>
      );
    }
    return null;
  };

  // Plan subtitle
  const getPlanSubTitle = () => {
    const plan = renewObj?.plan;
    const message = planSubTitles[plan] || "dd";

    if (dueIn > RENEWAL_DAYS_LIMIT || dueIn < -7) {
      return (
        <Typography style={{ fontSize: "14px", fontWeight: 400 }}>
          {message}
        </Typography>
      );
    }
    return null;
  };

  // Renewal message
  const getRenewalMessage = () => {
    if (dueIn <= RENEWAL_DAYS_LIMIT && dueIn >= -7) {
      const matchingPlan = plans.find((plan) => plan.plan === renewObj?.plan);
      if (matchingPlan) {
        return (
          <Typography>
            Renewal at {countrySymbol}
            {(matchingPlan.renewal_amount / 100).toLocaleString()} yearly
          </Typography>
        );
      }
    }
    return null;
  };

  return (
    <Grid
      container
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div>
        {getActivePlanMessage()}
        {getRenewalMessage()}
        {getPlanSubTitle()}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box className={classes.plansmessage}>
          {getSubscriptionStatus()}
          {getRenewPlan()}
        </Box>
      </div>
    </Grid>
  );
};

export default Header;
