import React from "react";
export function Footer({ fill = "#666666", strokeWidth = 0 }) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="17" height="17" rx="1.5" stroke="#666666" />
      <rect x="3.5" y="3.5" width="11" height="7" rx="0.5" stroke="#666666" />
      <rect
        x="3.5"
        y="12.5"
        width="11.1"
        height="2.5"
        rx="0.5"
        fill="#F9F9F9"
        stroke="#666666"
      />
    </svg>
  );
}
export function SelFooter({ fill = "#666666", strokeWidth = 0 }) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="17" height="17" rx="1.5" stroke="#1641DB" />
      <rect x="3.5" y="3.5" width="11" height="7" rx="0.5" stroke="#1641DB" />
      <rect
        x="3.5"
        y="12.5"
        width="11.1"
        height="2.5"
        rx="0.5"
        fill="#F9F9F9"
        stroke="#1641DB"
      />
    </svg>
  );
}
