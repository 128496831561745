import React from "react";

const DownArrow = (props) => {
  return (
    <svg
      width="8"
      height="5"
      viewBox="0 0 8 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4 2.875L7.06667 0L8 0.875L4 4.625L-2.38419e-07 0.875L0.933333 0L4 2.875Z"
        fill="white"
      />
    </svg>
  );
};

export default DownArrow;
