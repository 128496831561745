import React from "react";
import { Box, Typography, Button } from "@material-ui/core";
import { Check } from "@material-ui/icons";
import { useStyles } from "../styles";
import { useSelector } from "react-redux";
export default function AddonCard({
  addon,
  handleAddonSelection,
  selectedAddons,
}) {
  const isAddonAdded =
    selectedAddons.filter((_) => _.id === addon.id).length > 0;
  const classes = useStyles();
  const store = useSelector((state) => state.store.store);
  const countrySymbol = store.currency_symbol;
  return (
    <Box
      className={
        isAddonAdded
          ? [classes.domainCard, classes.highlightCard].join(" ")
          : classes.domainCard
      }
    >
      <Box>
        <img
          src={addon.icon_url}
          alt="addon icon"
          style={{ width: "50px", height: "50px", marginRight: "10px" }}
        />
      </Box>
      <Box style={{ flexGrow: 2 }}>
        <Typography className={classes.domainName}>{addon.title}</Typography>
        <Typography className={classes.helpText} style={{ marginTop: "4px" }}>
          {addon.short_description}
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          marginTop="10px"
        >
          <Typography className={classes.addonPrice}>
            <span style={{ fontWeight: 400 }}>{countrySymbol}</span>
            {addon.amount / 100} /-
            <span style={{ fontSize: "12px", fontWeight: "400" }}>
              {" "}
              one time
            </span>
          </Typography>
          {isAddonAdded ? (
            <Typography className={classes.addedItem}>
              <Check
                style={{ height: "12px", width: "12px", marginRight: "8px" }}
              />
              Added
            </Typography>
          ) : (
            <Button
              style={{ width: "35%" }}
              className={classes.domainBtn}
              onClick={handleAddonSelection}
            >
              + Add
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
}
