import React from "react";
import {
  FormControlLabel,
  IconButton,
  Switch as MuiSwitch,
  Typography,
  Tooltip,
} from "@material-ui/core";
import { EditOutlined } from "@material-ui/icons";

const Switch = (props) => {
  const {
    name,
    label,
    value,
    color,
    onChange,
    width = "auto",
    labelPlacement = "start",
    style = {},
    labelClass,
    helperText = "",
    labelFontWeight = 400,
    editButton = false,
    disabled = false, // <-- Disabled prop
    tooltipText = "",
    onEditClick,
  } = props;

  return (
    <FormControlLabel
      control={
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          {editButton && (
            <Tooltip title={tooltipText} arrow>
              <span>
                <IconButton
                  size="small"
                  onClick={onEditClick}
                  style={{ padding: "12px", color: "#1641DB" }}
                  disabled={disabled}
                >
                  <EditOutlined
                    style={{ opacity: disabled ? 0.4 : 1 }}
                    fontSize="small"
                  />
                </IconButton>
              </span>
            </Tooltip>
          )}
          <MuiSwitch
            checked={value}
            onChange={onChange}
            name={name}
            color={color}
          />
        </div>
      }
      labelPlacement={labelPlacement}
      label={
        helperText ? (
          <div>
            <Typography
              style={{ fontSize: "14px", fontWeight: labelFontWeight }}
            >
              {label}
            </Typography>
            <Typography
              style={{
                fontSize: "12px",
                fontWeight: 400,
                color: "#666666",
                width: props.helperTextWidth || "auto",
              }}
            >
              {helperText}
            </Typography>
          </div>
        ) : (
          <Typography style={{ fontSize: "14px", fontWeight: labelFontWeight }}>
            {label}
          </Typography>
        )
      }
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: width,
        ...style,
      }}
      classes={{ label: labelClass }}
    />
  );
};

export default Switch;
