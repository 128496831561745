import { Typography } from "@material-ui/core";
import React from "react";
import MonacoEditor from "../../../MonacoEditor";

const Html = ({ htmlForm, handleHtmlFormChange }) => {
  return (
    <div style={{ marginBottom: "16px", marginLeft: -25, marginRight: -16 }}>
      <Typography style={{ fontSize: 14, marginBottom: 16, padding: "0 25px" }}>
        Enter HTML Code
      </Typography>
      <MonacoEditor
        code={htmlForm.html_content.value}
        onChange={(value) => {
          handleHtmlFormChange("html_content", value);
        }}
      />
    </div>
  );
};

export default Html;
