import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import {
  Button,
  CircularProgress,
  Grid,
  LinearProgress,
  Typography,
  Box,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory, Link } from "react-router-dom";
import {
  fetchPurchases,
  startPurchaseLoader,
  stopPurchaseLoader,
  clearPurchases,
  deletePurchase,
  setPurchaseDateSelector,
  setPurchaseStatusSelector,
  setPurchaseDateRange,
  setPurchasesPerPage,
  setPurchasesCurrentPage,
  setPurchasesMaxPageSoFar,
  setPurchasesInputKeyword,
  setPurchasesSearchKeyword,
  setPurchasesCustomerId,
} from "../../redux/purchaseOrders/purchaseActions";
import DownArrowIcon from "../../icons/DownArrowIcon";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import Moment from "react-moment";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { downloadDocument } from "../../utils/documentUtil";
import {
  Menu,
  MenuItem,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  TableBody,
} from "@material-ui/core";
import Paginator from "../../components/Paginator/Paginator";
import {
  PURCHASES_PER_PAGE,
  PURCHASES_KEY,
  DEFAULT_PURCHASES_PER_PAGE,
  getDateRange,
} from "../../utils/utils";
import StatusDotIcon from "../../icons/StatusDotIcon";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { Fragment } from "react";
import { getPdfLink } from "../../utils/invoiceUtil";
import { getStoreIdFromUrl } from "../../utils/storeUtil";
import {
  setReloadFalse,
  setReloadTrue,
} from "../../redux/reload/reloadActions";
import ConfirmModal from "../../components/common/ConfirmModal";
import {
  anchor,
  layoutBodyBox,
  layoutContainer,
  layoutLoaderHeader,
  layoutSubHeader,
} from "../../styles/common";
import SolidButton from "../../components/buttons/SolidButton";
import PageLoader from "../../components/Layout/PageLoader";
import NoOrderFigma from "../../icons/NoOrderFigma";
import { getWhatsAppShareLink } from "../../utils/orderUtil";
import HeaderBreadcrumbs from "../../components/common/HeaderBreadcrumbs";
import { useQuery } from "../coupons/Components/CouponForm/formUtils";
import NoRecord from "../../components/common/NoRecord";

const Purchases = () => {
  const classes = useStyles();
  const params = useParams();
  const history = useHistory();
  const queryParams = useQuery();
  const purchaseState = useSelector((state) => state.purchases);
  const store = useSelector((state) => state.store);
  const purchases = purchaseState.purchases;
  const numberOfPages = purchaseState.numberOfPages;
  const loader = purchaseState.loader;
  const dateSelector = purchaseState.dateSelector;
  const statusSelector = purchaseState.statusSelector;
  const dateRange = purchaseState.dateRange;
  const purchasesPerPage = purchaseState.purchasesPerPage;
  const currentPage = purchaseState.currentPage;
  const maxPageSoFar = purchaseState.maxPageSoFar;
  const searchKeyword = purchaseState.searchKeyword;
  const inputKeyword = purchaseState.inputKeyword;
  const customerId = purchaseState.customerId;

  const [anchorEl, setAnchorEl] = useState(null);
  const [statusAnchorEl, setStatusAnchorEl] = useState(null);

  const [whiteSpaceDiv, setWhiteSpaceDiv] = useState(false);
  const [anchoredOrder, setAnchoredOrder] = useState(null);

  const [isDeleting, setIsDeleting] = useState(false);
  const [pageProgress, setPageProgress] = useState(false);

  const refetch = queryParams.get("refetch");
  const paramCustomerId = queryParams.get("customer-id");
  const onChangePurchasesPerPage = (updatedPurchasesPerPage) => {
    localStorage.setItem(PURCHASES_KEY, updatedPurchasesPerPage);
    dispatch(clearPurchases());
    dispatch(setPurchasesCurrentPage(0));
    dispatch(setPurchasesMaxPageSoFar(0));
    dispatch(setPurchasesPerPage(updatedPurchasesPerPage));
    dispatch(
      fetchPurchases(
        0,
        textToStatus(statusSelector),
        dateRange.aoe,
        dateRange.boe,
        searchKeyword,
        updatedPurchasesPerPage
      )
    );
  };

  let startIndex = currentPage * purchasesPerPage;
  let endIndex =
    startIndex + purchasesPerPage < purchases.length
      ? startIndex + purchasesPerPage
      : purchases.length;

  const dispatch = useDispatch();
  const storeId = getStoreIdFromUrl();

  useEffect(() => {
    if (!purchasesPerPage) {
      dispatch(setPurchasesPerPage(PURCHASES_PER_PAGE));
    }
    // eslint-disable-next-line
  }, [purchasesPerPage]);

  useEffect(() => {
    if (
      refetch ||
      (purchases.length === 0 && !dateSelector && !statusSelector)
    ) {
      dispatch(setPurchaseDateSelector("All"));
      dispatch(setPurchaseStatusSelector("All"));
      dispatch(setPurchaseDateRange({ aoe: null, boe: null }));
      if (paramCustomerId || customerId) {
        dispatch(
          setPurchasesCustomerId(paramCustomerId ? paramCustomerId : customerId)
        );
        dispatch(
          fetchPurchases(
            0,
            "All",
            null,
            null,
            null,
            PURCHASES_PER_PAGE,
            paramCustomerId ? paramCustomerId : customerId
          )
        ).then(() => {
          setPageProgress(false);
        });
      } else {
        dispatch(fetchPurchases()).then(() => {
          setPageProgress(false);
        });
      }
    }
    // eslint-disable-next-line
  }, [storeId, refetch]);

  const onNextPage = () => {
    setWhiteSpaceDiv(false);
    if (maxPageSoFar < currentPage + 1) {
      dispatch(
        fetchPurchases(
          currentPage + 1,
          textToStatus(statusSelector),
          dateRange.aoe,
          dateRange.boe,
          searchKeyword,
          purchasesPerPage,
          customerId
        )
      );
      dispatch(
        setPurchasesMaxPageSoFar(
          maxPageSoFar < currentPage + 1 ? currentPage + 1 : maxPageSoFar
        )
      );
      dispatch(setPurchasesCurrentPage(currentPage + 1));
    } else {
      dispatch(setPurchasesCurrentPage(currentPage + 1));
    }
  };

  const onPrevPage = () => {
    dispatch(setPurchasesCurrentPage(currentPage - 1));
  };

  const handleClick = (event, tableitem) => {
    setAnchoredOrder(tableitem);
    setAnchorEl(event.currentTarget);
  };

  const handleChangeDateSelector = (range) => {
    setWhiteSpaceDiv(true);
    if (range === dateSelector) return;
    dispatch(setPurchasesMaxPageSoFar(0));
    dispatch(setPurchasesCurrentPage(0));
    let res = getDateRange(range);
    let aoe = res.aoe;
    let boe = res.boe;
    dispatch(
      fetchPurchases(
        0,
        textToStatus(statusSelector),
        aoe,
        boe,
        searchKeyword,
        purchasesPerPage,
        customerId
      )
    );
    dispatch(setPurchaseDateSelector(range));
    dispatch(setPurchaseDateRange({ aoe: aoe, boe: boe }));
  };

  const handleCustomDateSelector = (rangePicker) => {
    setWhiteSpaceDiv(true);
    dispatch(setPurchasesMaxPageSoFar(0));
    dispatch(setPurchasesCurrentPage(0));
    dispatch(
      fetchPurchases(
        0,
        textToStatus(statusSelector),
        rangePicker.startDate._d.toISOString(),
        rangePicker.endDate._d.toISOString(),
        searchKeyword,
        purchasesPerPage,
        customerId
      )
    );
    dispatch(setPurchaseDateSelector("Custom"));
    dispatch(
      setPurchaseDateRange({
        aoe: rangePicker.startDate._d.toISOString(),
        boe: rangePicker.endDate._d.toISOString(),
      })
    );
  };

  const handleChangeStatusSelector = (status) => {
    setWhiteSpaceDiv(true);
    dispatch(setPurchasesMaxPageSoFar(0));
    dispatch(setPurchasesCurrentPage(0));
    dispatch(
      fetchPurchases(
        0,
        textToStatus(status),
        dateRange.aoe,
        dateRange.boe,
        searchKeyword,
        purchasesPerPage,
        customerId
      )
    );
    dispatch(setPurchaseStatusSelector(status));
    setStatusAnchorEl(null);
  };

  const statusToText = (status) => {
    if (status === "CONFIRMED") return { text: "Pending", fill: "#FF0000" };
    if (status === "COMPLETE") return { text: "Completed", fill: "#72C472" };
    return { text: "-", fill: "white" };
  };

  const textToStatus = (text) => {
    if (text === "Pending") return "CONFIRMED";
    if (text === "Completed") return "COMPLETE";
    return "ALL";
  };

  const onSearch = (keyword) => {
    setWhiteSpaceDiv(true);
    if (keyword) {
      dispatch(setPurchasesMaxPageSoFar(0));
      dispatch(setPurchasesCurrentPage(0));
      dispatch(setPurchaseStatusSelector("All"));
      dispatch(setPurchaseDateSelector("All"));
      dispatch(setPurchaseDateRange({ aoe: null, boe: null }));
      dispatch(
        fetchPurchases(
          0,
          "All",
          null,
          null,
          keyword,
          purchasesPerPage,
          customerId
        )
      );
    }
  };

  const viewPdf = () => {
    dispatch(startPurchaseLoader());
    getPdfLink(anchoredOrder.id).then((link) => {
      window.open(link);
      dispatch(stopPurchaseLoader());
    });
  };

  const whatsAppShare = async () => {
    dispatch(startPurchaseLoader());
    await getWhatsAppShareLink(anchoredOrder.store.store).then((link) => {
      window.open(link);
      dispatch(stopPurchaseLoader());
    });
  };

  const downloadInvoice = async () => {
    let filename = anchoredOrder.invoice_id + ".pdf";
    dispatch(startPurchaseLoader());
    await downloadDocument(
      store.store,
      "purchase_order",
      anchoredOrder.id,
      filename
    );
    dispatch(stopPurchaseLoader());
  };

  const handleDelete = () => {
    dispatch(startPurchaseLoader());
    dispatch(deletePurchase(anchoredOrder.id));
  };

  if (pageProgress || (purchaseState.progress && purchases.length === 0)) {
    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} className={layoutLoaderHeader}>
            <div className={layoutContainer}>
              <HeaderBreadcrumbs list={[{ label: "Purchases" }]} />
              <PageLoader />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
  return (
    <div>
      <Grid container spacing={2}>
        {loader && (
          <div
            style={{
              position: "fixed",
              left: "50%",
              top: "50%",
              zIndex: "900",
            }}
          >
            <CircularProgress color="secondary" />
          </div>
        )}
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className={layoutContainer}>
            <div className={layoutSubHeader}>
              <HeaderBreadcrumbs list={[{ label: "Purchases" }]} />
              <SolidButton
                style={{ width: 185 }}
                onClick={() => {
                  history.push("./purchases/create");
                }}
              >
                Create Purchase
              </SolidButton>
            </div>

            {purchases.length === 0 &&
            statusSelector === "All" &&
            dateSelector === "All" &&
            searchKeyword === "" &&
            !customerId ? (
              <NoRecord
                text="Start creating Purchases for your business"
                image={<NoOrderFigma />}
                arrow={true}
              />
            ) : (
              <div style={{ position: "relative", width: "100%" }}>
                <div className={layoutBodyBox}>
                  <div className={classes.tableHeader}>
                    <div className={classes.search}>
                      <SearchIcon />
                      <input
                        type="text"
                        placeholder="Enter Purchase Number, Customer Name, Mobile"
                        className={classes.searchBox}
                        value={
                          customerId
                            ? `Customer ID: ${customerId}`
                            : inputKeyword
                        }
                        onChange={(e) =>
                          dispatch(setPurchasesInputKeyword(e.target.value))
                        }
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            if (inputKeyword.trim() !== "") {
                              dispatch(
                                setPurchasesSearchKeyword(inputKeyword.trim())
                              );
                              onSearch(inputKeyword.trim());
                            }
                          }
                          if (e.key === "Escape") {
                            setWhiteSpaceDiv(true);
                            dispatch(setPurchasesSearchKeyword(""));
                            dispatch(setPurchasesInputKeyword(""));
                            dispatch(setPurchasesMaxPageSoFar(0));
                            dispatch(setPurchasesCurrentPage(0));
                            dispatch(
                              fetchPurchases(
                                0,
                                "ALL",
                                null,
                                null,
                                null,
                                purchasesPerPage,
                                customerId
                              )
                            );
                            dispatch(setPurchaseStatusSelector("All"));
                            dispatch(setPurchaseDateSelector("All"));
                            dispatch(
                              setPurchaseDateRange({ aoe: null, boe: null })
                            );
                          }
                        }}
                      />
                      {(searchKeyword !== "" || customerId) && (
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setWhiteSpaceDiv(true);
                            dispatch(setPurchasesSearchKeyword(""));
                            dispatch(setPurchasesInputKeyword(""));
                            dispatch(setPurchasesMaxPageSoFar(0));
                            dispatch(setPurchasesCurrentPage(0));
                            dispatch(fetchPurchases());
                            dispatch(setPurchaseStatusSelector("All"));
                            dispatch(setPurchaseDateSelector("All"));
                            dispatch(setPurchasesCustomerId(null));
                            dispatch(
                              setPurchaseDateRange({ aoe: null, boe: null })
                            );
                            if (customerId) history.push(`./purchases`);
                          }}
                        >
                          <CloseIcon fontSize="small" />
                        </span>
                      )}
                    </div>
                    <div className={classes.tableHeaderRight}>
                      <div>
                        <DateRangePicker
                          initialSettings={{
                            startDate: new Date(
                              new Date().setMonth(new Date().getMonth() - 1)
                            ),
                            endDate: new Date(),
                            maxDate: new Date(),
                            applyButtonClasses: classes.applyBtn,
                          }}
                          onApply={(fn, rangePicker) => {
                            handleCustomDateSelector(rangePicker);
                          }}
                        >
                          <Typography
                            className={classes.dateFilter}
                            style={{
                              color:
                                dateSelector === "Custom"
                                  ? "#1641DB"
                                  : "#666666",
                              fontSize: 12,
                              fontWeight: dateSelector === "Custom" ? 600 : 400,
                              cursor: "pointer",
                            }}
                          >
                            Custom
                          </Typography>
                        </DateRangePicker>
                      </div>
                      <div
                        onClick={() => {
                          handleChangeDateSelector("All");
                        }}
                      >
                        <Typography
                          className={classes.dateFilter}
                          style={{
                            color:
                              dateSelector === "All" ? "#1641DB" : "#666666",
                            fontWeight: dateSelector === "All" ? 600 : 400,
                            fontSize: 12,
                            cursor: "pointer",
                          }}
                        >
                          All
                        </Typography>
                      </div>
                      <div
                        onClick={() => {
                          handleChangeDateSelector("Today");
                        }}
                      >
                        <Typography
                          className={classes.dateFilter}
                          style={{
                            color:
                              dateSelector === "Today" ? "#1641DB" : "#666666",
                            fontWeight: dateSelector === "Today" ? 600 : 400,
                            fontSize: 12,
                            cursor: "pointer",
                          }}
                        >
                          Today
                        </Typography>
                      </div>
                      <div
                        onClick={() => {
                          handleChangeDateSelector("Yesterday");
                        }}
                      >
                        <Typography
                          className={classes.dateFilter}
                          style={{
                            color:
                              dateSelector === "Yesterday"
                                ? "#1641DB"
                                : "#666666",
                            fontWeight:
                              dateSelector === "Yesterday" ? 600 : 400,
                            fontSize: 12,
                            cursor: "pointer",
                          }}
                        >
                          Yesterday
                        </Typography>
                      </div>
                      <div
                        onClick={() => {
                          handleChangeDateSelector("Last Week");
                        }}
                      >
                        <Typography
                          className={classes.dateFilter}
                          style={{
                            color:
                              dateSelector === "Last Week"
                                ? "#1641DB"
                                : "#666666",
                            fontWeight:
                              dateSelector === "Last Week" ? 600 : 400,
                            fontSize: 12,
                            cursor: "pointer",
                          }}
                        >
                          Last Week
                        </Typography>
                      </div>
                      <div
                        onClick={() => {
                          handleChangeDateSelector("Last Month");
                        }}
                      >
                        <Typography
                          className={classes.dateFilter}
                          style={{
                            color:
                              dateSelector === "Last Month"
                                ? "#1641DB"
                                : "#666666",
                            fontWeight:
                              dateSelector === "Last Month" ? 600 : 400,
                            fontSize: 12,
                            cursor: "pointer",
                          }}
                        >
                          Last Month
                        </Typography>
                      </div>
                      <Button
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={(e) => {
                          dispatch(setReloadTrue());
                          setStatusAnchorEl(e.currentTarget);
                        }}
                        className={classes.statusSelector}
                        style={{ width: 105, outline: "none" }}
                      >
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <Typography
                              style={{
                                fontWeight: 400,
                                fontSize: 10,
                                textAlign: "left",
                              }}
                            >
                              Status
                            </Typography>
                            <Typography
                              style={{
                                fontWeight: 600,
                                fontSize: 12,
                                textAlign: "left",
                              }}
                            >
                              {statusSelector}
                            </Typography>
                          </div>
                          <DownArrowIcon stroke="#1A1A1A" />
                        </div>
                      </Button>
                      <Menu
                        anchorEl={statusAnchorEl}
                        keepMounted
                        open={Boolean(statusAnchorEl)}
                        onClose={() => {
                          dispatch(setReloadFalse());
                          setStatusAnchorEl(null);
                        }}
                        getContentAnchorEl={null}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            handleChangeStatusSelector("All");
                          }}
                          style={{ fontSize: 12, color: "#1641DB" }}
                          selected={statusSelector === "All"}
                        >
                          All
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            handleChangeStatusSelector("Pending");
                          }}
                          style={{ fontSize: 12, color: "#1641DB" }}
                          selected={statusSelector === "Pending"}
                        >
                          Pending
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            handleChangeStatusSelector("Completed");
                          }}
                          style={{ fontSize: 12, color: "#1641DB" }}
                          selected={statusSelector === "Completed"}
                        >
                          Completed
                        </MenuItem>
                      </Menu>
                    </div>
                  </div>
                  <TableContainer>
                    <Table>
                      <TableHead className={classes.tablehead}>
                        <TableRow className={classes.headrow}>
                          <TableCell style={{ width: "18%" }}>
                            Purchase #
                          </TableCell>
                          <TableCell style={{ width: "12%" }}>Amount</TableCell>
                          <TableCell style={{ width: "12%" }}>
                            Balance
                          </TableCell>
                          <TableCell style={{ width: "13%" }}>
                            Create Date
                          </TableCell>
                          <TableCell style={{ width: "17%" }}>
                            Customer Name
                          </TableCell>
                          <TableCell style={{ width: "10%" }}>Status</TableCell>
                          <TableCell style={{ width: "11%" }}></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className={classes.tablebody}>
                        {purchases
                          .slice(startIndex, endIndex)
                          .map((tableitem, index) => (
                            <TableRow
                              key={tableitem.id}
                              style={{
                                borderBottom:
                                  (index + 1) % purchasesPerPage === 0
                                    ? "none"
                                    : "auto",
                              }}
                            >
                              <TableCell>
                                <Link
                                  style={{
                                    color: "#1641DB",
                                    textDecoration: "none",
                                  }}
                                  to={`./purchases/${encodeURIComponent(
                                    tableitem.invoice_id
                                  )}`}
                                >
                                  {tableitem.invoice_id}
                                </Link>
                              </TableCell>
                              <TableCell>
                                {tableitem.final_sale_price.toFixed(2)}
                              </TableCell>
                              <TableCell>
                                {tableitem.due_amount.toFixed(2)}
                              </TableCell>
                              <TableCell>
                                <Moment format="DD-MMM-YYYY">
                                  {tableitem.created_at}
                                </Moment>
                              </TableCell>
                              <TableCell>
                                <Link
                                  to={`./customers/${tableitem.org_store_customer_id}`}
                                  style={{
                                    color: "#1641DB",
                                    textDecoration: "none",
                                  }}
                                >
                                  {tableitem.org_customer_name}
                                </Link>
                              </TableCell>
                              <TableCell>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <StatusDotIcon
                                    fill={statusToText(tableitem.state).fill}
                                  />
                                  &nbsp;&nbsp;
                                  {statusToText(tableitem.state).text}
                                </div>
                              </TableCell>
                              <TableCell align="center">
                                <Box display="flex" justifyContent="flex-end">
                                  <Box>
                                    <MoreHorizIcon
                                      style={{ cursor: "pointer" }}
                                      aria-controls="simple-menu"
                                      aria-haspopup="true"
                                      onClick={(event) => {
                                        dispatch(setReloadTrue());
                                        handleClick(event, tableitem);
                                      }}
                                    />
                                    <Menu
                                      id="simple-menu"
                                      anchorEl={anchorEl}
                                      keepMounted
                                      open={Boolean(anchorEl)}
                                      onClose={() => {
                                        dispatch(setReloadFalse());
                                        setAnchorEl(null);
                                      }}
                                      className={anchor}
                                    >
                                      <MenuItem
                                        onClick={() => {
                                          viewPdf();
                                          setAnchorEl(null);
                                        }}
                                      >
                                        View
                                      </MenuItem>
                                      <MenuItem
                                        onClick={() => {
                                          downloadInvoice();
                                          setAnchorEl(null);
                                        }}
                                      >
                                        Download
                                      </MenuItem>
                                      <MenuItem
                                        onClick={() => {
                                          whatsAppShare();
                                          setAnchorEl(null);
                                        }}
                                      >
                                        WhatsApp Share
                                      </MenuItem>
                                      <MenuItem
                                        onClick={() => {
                                          setIsDeleting(true);
                                        }}
                                      >
                                        Delete
                                      </MenuItem>
                                    </Menu>
                                  </Box>
                                </Box>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>

                    {purchaseState.progress && (
                      <Fragment>
                        {!whiteSpaceDiv && (
                          <div
                            style={{
                              height: purchasesPerPage * 68 - 4,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          ></div>
                        )}
                        <LinearProgress color="secondary" />
                      </Fragment>
                    )}
                    {purchases.length === 0 && (
                      <div
                        style={{
                          height: PURCHASES_PER_PAGE * 68 - 4,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: 18,
                            fontWeight: 600,
                            marginTop: -34,
                          }}
                        >
                          No Purchase Order Found
                        </Typography>
                      </div>
                    )}
                  </TableContainer>
                </div>
                <Paginator
                  changeRecordsPerPage={onChangePurchasesPerPage}
                  possibleRecordsPerPage={[
                    DEFAULT_PURCHASES_PER_PAGE,
                    20,
                    50,
                    100,
                  ]}
                  recordsPerPage={purchasesPerPage}
                  onNext={onNextPage}
                  onPrev={onPrevPage}
                  total={numberOfPages === 0 ? 1 : numberOfPages}
                  currentPage={currentPage}
                />
              </div>
            )}
          </div>
        </Grid>
      </Grid>

      <ConfirmModal
        open={isDeleting}
        message={
          anchoredOrder
            ? `Are you sure you want to delete Purchase ${anchoredOrder.invoice_id} ?`
            : ""
        }
        closeBtnText="Cancel"
        confirmBtnText="Delete"
        onClose={() => {
          setIsDeleting(false);
          setAnchorEl(null);
        }}
        onConfirm={() => {
          handleDelete();
          setAnchorEl(null);
          setIsDeleting(false);
        }}
      />
    </div>
  );
};
export default Purchases;
