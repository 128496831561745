import React, { useState, useEffect } from "react";
import { Button, CircularProgress, Typography } from "@material-ui/core";
import useStyles from "./styles";
import axios from "axios";
import { useDispatch } from "react-redux";
import { customerLoginSuccess } from "../../../redux/customer/customerActions";
import SolidButton from "../../../components/buttons/SolidButton";
import OtpInput from "react-otp-input";
import { rowFlexOnlyJustifyBetweenCenter } from "../../../styles/common";
import { isCustomDomain } from "../../../utils/utils";

const OTPVerifyForm = ({ mobile, onSuccess, onSubmit, newAcc }) => {
  const classes = useStyles();
  const [progress, setProgress] = useState(5);
  const [resend, setResend] = useState(0);
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setProgress(30);
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress <= 0) {
          clearInterval(timer);
        }
        return prevProgress - 1;
      });
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [resend]);

  const handleSubmit = (otp) => {
    if (otp.length < 4) {
      setOtpError(true);
      return;
    }
    onSubmit(mobile, otp)
      .then((response) => {
        dispatch(customerLoginSuccess(response.data));
        onSuccess();
      })
      .catch(() => {
        setOtpError(true);
      });
  };

  const getOTP = async () => {
    let customDomain = isCustomDomain();
    let hostname = window.location.hostname;
    let slug = "";
    if (customDomain) {
      let slugResp = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/store/external/${hostname}/slug`,
        {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${process.env.REACT_APP_TRUSTED_TOKEN}`,
          },
        }
      );
      slug = slugResp.data.payload;
    }
    let response = await axios.post(
      `${process.env.REACT_APP_AUTH_API}/api/v1/auth/oauth/otp?slug=${slug}`,
      `${mobile}`,
      {
        headers: {
          "Content-Type": "text/plain",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${process.env.REACT_APP_TRUSTED_TOKEN}`,
        },
      }
    );
    setOtp("");
    setOtpError(false);
    setResend(resend + 1);
    return response.data;
  };

  return (
    <div className={classes.form}>
      <OtpInput
        value={otp}
        onChange={(otp) => {
          setOtp(otp);
          if (otp.length === 4) {
            handleSubmit(otp);
          }
        }}
        numInputs={4}
        separator={
          <span
            style={{
              position: "relative",
              fontSize: "10px",
              margin: "0px 12px",
            }}
          >
            —
          </span>
        }
        containerStyle={{
          display: "flex",
          justifyContent: "center",
          gap: "20",
          width: "100%",
        }}
        shouldAutoFocus
        isInputNum
        inputStyle={classes.otpItem}
      />
      <div
        className={rowFlexOnlyJustifyBetweenCenter}
        style={{ marginTop: 12 }}
      >
        {resend > 2 ? (
          <Typography component="p" variant="p">
            Resend limits exceeded
          </Typography>
        ) : (
          <>
            <div>
              <Typography variant="subtitle1" className={classes.errorText}>
                {otpError ? "INVALID OTP" : ""}
              </Typography>
            </div>
            <div>
              <Button
                onClick={getOTP}
                disabled={progress > 0}
                color="secondary"
                tabIndex={-1}
              >
                {progress > 0 && (
                  <CircularProgress
                    variant="determinate"
                    color="secondary"
                    value={progress / 0.3}
                    size={12}
                  />
                )}
                &nbsp; Resend
              </Button>
            </div>
          </>
        )}
      </div>
      <SolidButton
        style={{ width: "100%", marginTop: 10 }}
        onClick={() => handleSubmit(otp)}
      >
        Verify
      </SolidButton>
    </div>
  );
};

export default OTPVerifyForm;
