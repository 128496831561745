import React from "react";
import {
  Card,
  IconButton,
  Typography,
  CardMedia,
  Chip,
  Badge,
  CircularProgress,
  withStyles,
} from "@material-ui/core";
import useStyles from "./styles";
import DrawerCloseIcon from "../../icons/DrawerCloseIcon";
import { DraftsOutlined, MailOutline, Notifications } from "@material-ui/icons";
import {
  rowFlexCenterAll,
  rowFlexJustifyBetween,
  rowFlexOnlyJustifyBetween,
} from "../../styles/common";
import { Pagination } from "@material-ui/lab";

const Notification = ({
  onClose,
  notifications,
  handlePageChange,
  currentPage,
  notificationLoading,
  onClickNotification,
}) => {
  const classes = useStyles();
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "short", day: "numeric" };
    const formattedDate = new Intl.DateTimeFormat("en-GB", options).format(
      date
    );
    return formattedDate;
  };
  const StyledBadge = withStyles(() => ({
    dot: {
      backgroundColor: "red",
    },
  }))(Badge);
  return (
    <>
      <div>
        <div className={classes.headerBorder} style={{ paddingBottom: 16 }}>
          <div
            className={rowFlexJustifyBetween}
            style={{
              paddingLeft: 28,
              paddingRight: 28,
              paddingTop: 20,
            }}
          >
            <Typography style={{ fontWeight: 700, fontSize: 20 }}>
              Notifications
            </Typography>

            <IconButton
              aria-label="Close"
              aria-haspopup="true"
              color="inherit"
              onClick={onClose}
            >
              <DrawerCloseIcon />
            </IconButton>
          </div>
        </div>
        <div
          className={
            notifications?.content?.length === 0 ? rowFlexCenterAll : ""
          }
          style={{
            height:
              notifications?.content?.length === 0
                ? "calc(100vh - 113px)"
                : "calc(100vh - 150px)",
            overflow: "auto",
            paddingLeft: 28,
            paddingRight: 28,
          }}
        >
          <div style={{ marginTop: 28 }}>
            {notifications?.content?.length > 0 ? (
              notifications?.content?.map((notification, index) => {
                const videoId = notification.media_link?.split("v=")[1];
                const embedUrl = videoId
                  ? `https://www.youtube.com/embed/${videoId}`
                  : "";

                return (
                  <Card
                    key={index}
                    variant="outlined"
                    style={{
                      padding: 12,
                      borderRadius: 12,
                      display: "flex",
                      marginBottom:
                        index === notifications?.content?.length - 1 ? 0 : 16,
                      background:
                        notification?.status === "UNREAD" ? "#F9F9F9" : "",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      onClickNotification(
                        notification.id,
                        notification.detail_link,
                        notification.status
                      )
                    }
                  >
                    {notification?.status === "UNREAD" ? (
                      <StyledBadge variant="dot" style={{ top: "5px" }}>
                        <MailOutline
                          style={{
                            color: "#1641DB",
                            position: "relative",
                            left: "4px",
                            top: "-4px",
                          }}
                        />
                      </StyledBadge>
                    ) : (
                      <DraftsOutlined style={{ color: "#999999" }} />
                    )}
                    <div style={{ marginLeft: "16px", width: "100%" }}>
                      <div
                        className={rowFlexOnlyJustifyBetween}
                        style={{ height: "24px" }}
                      >
                        <Typography
                          style={{
                            color: "#666666",
                            fontSize: 12,
                            position: "relative",
                            top: "2px",
                          }}
                        >
                          {notification.category} {""}
                          {notification.event_date
                            ? `| ${formatDate(notification.event_date)}`
                            : null}
                        </Typography>
                        {notification.tag && (
                          <Chip
                            label={notification.tag}
                            style={{
                              background:
                                notification.tag === "New"
                                  ? "#E3F9E8"
                                  : "#F4EBFF",
                              color:
                                notification.tag === "New"
                                  ? "#21AC1D"
                                  : "#7F56D9",
                              borderRadius: 4,
                              height: "20px",
                            }}
                            size="small"
                          />
                        )}
                      </div>

                      {notification.media_link &&
                        notification.media_type === "IMAGE" && (
                          <CardMedia
                            component="img"
                            height="200"
                            image={notification.media_link}
                            alt={notification.title}
                            style={{
                              marginTop: 8,
                              marginBottom: 8,
                              borderRadius: 8,
                            }}
                          />
                        )}
                      {notification.media_link &&
                        notification.media_type === "VIDEO" &&
                        embedUrl && (
                          <iframe
                            width="100%"
                            height="200"
                            style={{
                              border: "none",
                              borderRadius: 8,
                              marginTop: notification.tag ? 6 : 8,
                              marginBottom: 4,

                              marginBottom: 8,
                            }}
                            src={embedUrl}
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          ></iframe>
                        )}
                      {notification.title && (
                        <Typography
                          style={{
                            fontSize: 14,
                            fontWeight:
                              notification?.status === "UNREAD" ? "600" : "400",
                          }}
                        >
                          {notification.title}
                        </Typography>
                      )}
                      <Typography
                        className={classes.description}
                        style={{
                          color: "#666666",
                        }}
                      >
                        {notification.description}
                      </Typography>
                      {notification?.detail_link && (
                        <Typography
                          className={classes.viewMore}
                          onClick={() =>
                            onClickNotification(
                              notification.id,
                              notification.detail_link,
                              notification.status
                            )
                          }
                        >
                          Read More
                        </Typography>
                      )}
                    </div>
                  </Card>
                );
              })
            ) : notificationLoading ? (
              <div className={classes.loading}>
                <CircularProgress />
              </div>
            ) : (
              <div style={{ textAlign: "center" }}>
                <IconButton
                  style={{ background: "#F9F9F9", marginBottom: "20px" }}
                >
                  <Notifications
                    style={{ height: "45px", width: "45px", color: "#999999" }}
                  />
                </IconButton>
                <Typography style={{ fontSize: 14 }}>
                  No Notifications at the moment
                </Typography>
              </div>
            )}
          </div>
          {notifications?.content?.length > 0 && (
            <div className={classes.pagination}>
              <Pagination
                count={notifications.totalPages}
                page={currentPage}
                onChange={(event, page) => handlePageChange(page)}
                size="small"
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Notification;
