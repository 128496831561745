import React, { Fragment, useState, useEffect } from "react";
import HeaderBreadcrumbs from "../../../components/common/HeaderBreadcrumbs";
import {
  layoutBodyBox,
  layoutContainer,
  rowFlexAlignCenter,
} from "../../../styles/common";
import useStyles from "../styles";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../components/controls/Input";
import CombinedButtons from "../../../components/buttons/CombinedButtons";
import Select from "../../../components/controls/Select";
import { Typography } from "@material-ui/core";
import PermissionIcon from "../../../icons/PermissionIcon";
import { getPermissions } from "./userUtils";
import {
  addUser,
  startUserLoader,
} from "../../../redux/usersAndRoles/userActions";
import { isWindows } from "react-device-detect";
import ReusablePhoneInput from "../../../components/common/PhoneInput";

const UserForm = ({ onSave, onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.users);
  const loader = userState.loader;
  const initState = {
    name: {
      value: "",
      hasError: false,
      error: "",
      touched: false,
    },
    mobile: {
      value: "",
      hasError: false,
      error: "",
      touched: false,
    },
    role: { value: "none", hasError: false, error: "" },
  };
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [formState, setFormState] = useState(initState);
  const [scale, setScale] = useState(1);
  useEffect(() => {
    if (isWindows) {
      setScale(window.devicePixelRatio - 0.07);
    }
  }, []);

  const validate = (formState, forceTouch = false) => {
    let requiredFields = ["name", "mobile", "role"];
    for (let field of requiredFields) {
      if (
        (formState[field].touched || forceTouch) &&
        (field === "role"
          ? formState[field].value === "none"
          : !formState[field].value)
      ) {
        formState[field].hasError = true;
        formState[field].error = "This field is required";
      } else if (!isPhoneValid) {
        formState[field].hasError = true;
        formState[field].error = "Invalid mobile number";
      } else if (
        formState[field].touched &&
        formState[field].value &&
        formState[field].hasError
      ) {
        formState[field].hasError = false;
        formState[field].error = "";
      }
    }
    return formState;
  };
  const onFormFieldChange = (field, value) => {
    let clonedState = { ...formState };
    let data = { ...clonedState[field] };
    data["value"] = value;
    data.touched = true;
    clonedState[field] = data;
    setFormState(validate(clonedState));
  };

  const handleFormSubmit = () => {
    let data = {};
    for (let key of Object.keys(formState)) {
      data[key] = { ...formState[key] };
    }
    let validatedData = validate(data, true);
    for (let key in validatedData) {
      if (validatedData[key].hasError) {
        setFormState(validatedData);
        return;
      }
    }
    let payload = {
      name: formState.name.value,
      mobile: formState.mobile.value,
      role: formState.role.value,
    };
    dispatch(startUserLoader());
    dispatch(addUser(payload)).then(() => {
      onSave();
      setFormState(initState);
    });
  };
  const handleCancel = () => {
    setFormState(initState);
    onClose();
  };

  return (
    <div className={layoutContainer}>
      <div style={{ marginBottom: 20 }}>
        <HeaderBreadcrumbs
          list={[
            { label: "Users And Roles", link: "/users" },
            {
              label: "Add",
            },
          ]}
        />
      </div>
      <div style={{ position: "relative", width: "100%" }}>
        <div
          className={layoutBodyBox}
          style={{
            width: 604,
            padding: 32,
            minHeight: `${80 * scale}vh`,
            position: "relative",
          }}
        >
          <div className={classes.formField}>
            <Input
              value={formState.name.value}
              label="Name"
              placeholder=""
              labelPlacement="top"
              error={formState.name.hasError ? formState.name.error : ""}
              onChange={(e) => onFormFieldChange("name", e.target.value)}
            />
          </div>
          <div className={classes.formField}>
            <ReusablePhoneInput
              value={formState.mobile.value}
              onChange={(value) => onFormFieldChange("mobile", value)}
              label="Whatsapp No."
              onValidation={(isValid) => setIsPhoneValid(isValid)}
              helperText=""
            />
          </div>
          <div className={classes.formField}>
            <Select
              name="role"
              label="Select Role"
              labelPlacement="top"
              value={formState.role.value}
              placeholder="Select "
              options={[
                { id: "ADMIN", title: "Admin" },
                { id: "BILLING_MANAGER", title: "Billing Manager" },
                { id: "CATALOG_MANAGER", title: "Catalog Manager" },
                { id: "MARKETING_MANAGER", title: "Marketing Manager" },
                { id: "DELIVERY_BOY", title: "Delivery Boy" },
              ]}
              color="secondary"
              onChange={(e) => onFormFieldChange("role", e.target.value)}
              error={formState.role.hasError ? formState.role.error : ""}
            />
          </div>
          <div style={{ marginBottom: 100 }}>
            {formState.role.value !== "none" && (
              <Fragment>
                <div
                  className={rowFlexAlignCenter}
                  style={{ marginBottom: 18 }}
                >
                  <PermissionIcon />
                  <Typography className={classes.permmisonHead}>
                    {getPermissions(formState.role.value).heading}
                  </Typography>
                </div>
                {getPermissions(formState.role.value).permissions.map(
                  (perm, idx) => {
                    return (
                      <div
                        key={idx}
                        className={rowFlexAlignCenter}
                        style={{ marginBottom: 12 }}
                      >
                        <li className={classes.indicator} />
                        <Typography className={classes.permmisonTxt}>
                          {perm}
                        </Typography>
                      </div>
                    );
                  }
                )}
              </Fragment>
            )}
          </div>
          <div
            style={{
              position: "absolute",
              bottom: 32,
              right: 32,
            }}
          >
            <CombinedButtons
              loading={loader}
              solidBtnAction={handleFormSubmit}
              outlinedBtnAction={handleCancel}
              solidBtnText={"Save"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserForm;
