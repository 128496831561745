import React from "react";
import ShoopyIcon from "../../icons/ShoopyIconLatest";
import { Grid, Modal, Typography } from "@material-ui/core";
import useStyles from "./components/styles";
import BusinessDetailsForm from "./components/BusinessDetailsForm";

const CreateAccount = () => {
  const classes = useStyles();
  return (
    <Modal
      open={true}
      BackdropProps={{
        style: { backgroundColor: "fff" },
      }}
      className={classes.modal}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh", // Full viewport height
        }}
      >
        <div className={classes.businessmodal}>
          <Grid>
            <div className={classes.icon}>
              <ShoopyIcon width={40} height={40} viewBox="0 0 32 32" />
              <span style={{ fontSize: 20, fontWeight: 700, marginLeft: 16 }}>
                Shoopy
              </span>
            </div>
            <div className={classes.signin}>
              <div style={{ width: 360 }}>
                <div>
                  <Typography variant="h2" className={classes.heading}>
                    Business Details
                  </Typography>
                  <Typography
                    className={classes.subheading}
                    style={{ fontWeight: 400, color: "#757575" }}
                  >
                    Start managing your store by signing in
                  </Typography>
                  <BusinessDetailsForm />
                </div>
              </div>
            </div>
          </Grid>
        </div>
      </div>
    </Modal>
  );
};
export default CreateAccount;
