import axios from "axios";

export const fetchPickupLocation = async (storeData, plugin) => {
  let storeSlug = storeData.store.slug;
  let accessToken = localStorage.getItem("access_token");
  let resp = await axios.get(
    `${process.env.REACT_APP_API}/api/v1/store/${storeSlug}/pickup-locations?app=${plugin}`,

    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    }
  );
  let loc = null;
  let locs = resp.data.payload;
  if (locs && locs.length > 0) {
    loc = locs[0];
  }
  return loc;
};
