import React from "react";
import { Route, Switch } from "react-router-dom/cjs/react-router-dom";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";
import DisplaySidebar from "./DisplaySidebar";
import DisplaySlug from "./DisplaySlug";
import { makeStyles, useMediaQuery } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  layoutContainer: {
    display: "flex",
  },
  content: {
    width: "100%",
    maxWidth: 815,
  },
}));

export default function DisplayLayout() {
  let { path } = useRouteMatch();
  const isSmallScreen = useMediaQuery("(max-width:1400px)");
  const classes = useStyles();
  return (
    <div className={classes.layoutContainer}>
      <DisplaySidebar />
      <div
        className={classes.content}
        style={{ marginLeft: isSmallScreen ? 268 : 328 }}
      >
        <Switch>
          <Route path={`${path}/:displaySlug`} component={DisplaySlug} />
        </Switch>
      </div>
    </div>
  );
}
