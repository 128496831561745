import React from "react";
export function MenuBar({ fill = "#666666", strokeWidth = 0 }) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_27415_34336"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="18"
        height="18"
      >
        <rect width="18" height="18" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_27415_34336)">
        <rect x="3" y="2" width="12" height="1" rx="0.5" fill="#666666" />
      </g>
      <rect x="0.5" y="0.5" width="17" height="17" rx="1.5" stroke="#666666" />
      <rect
        x="3.19983"
        y="5.05005"
        width="11.6"
        height="3.7"
        rx="0.5"
        fill="#F9F9F9"
        stroke="#666666"
      />
      <path
        d="M3.19983 11.3C3.19983 11.0239 3.42369 10.8 3.69983 10.8H14.2998C14.576 10.8 14.7998 11.0239 14.7998 11.3V14.3C14.7998 14.5762 14.576 14.8 14.2998 14.8H3.69983C3.42369 14.8 3.19983 14.5762 3.19983 14.3V11.3Z"
        stroke="#666666"
      />
    </svg>
  );
}

export function SelMenuBar({ fill = "#666666", strokeWidth = 0 }) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_29158_4506"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="18"
        height="18"
      >
        <rect width="18" height="18" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_29158_4506)">
        <rect x="3" y="2" width="12" height="1" rx="0.5" fill="#1641DB" />
      </g>
      <rect x="0.5" y="0.5" width="17" height="17" rx="1.5" stroke="#1641DB" />
      <rect
        x="3.19983"
        y="5.05005"
        width="11.6"
        height="3.7"
        rx="0.5"
        fill="#F9F9F9"
        stroke="#1641DB"
      />
      <path
        d="M3.19983 11.3C3.19983 11.0239 3.42369 10.8 3.69983 10.8H14.2998C14.576 10.8 14.7998 11.0239 14.7998 11.3V14.3C14.7998 14.5762 14.576 14.8 14.2998 14.8H3.69983C3.42369 14.8 3.19983 14.5762 3.19983 14.3V11.3Z"
        stroke="#1641DB"
      />
    </svg>
  );
}
