import React from "react";
export function ProductListing({ fill = "#666666", strokeWidth = 0 }) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_27415_34393"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="18"
        height="18"
      >
        <rect width="18" height="18" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_27415_34393)">
        <rect x="3" y="2" width="12" height="1" rx="0.5" fill="#666666" />
      </g>
      <rect x="0.5" y="0.5" width="17" height="17" rx="1.5" stroke="#666666" />
      <mask id="path-4-inside-1_27415_34393" fill="white">
        <rect x="3" y="10" width="3" height="5" rx="0.5" />
      </mask>
      <rect
        x="3"
        y="10"
        width="3"
        height="5"
        rx="0.5"
        fill="#F9F9F9"
        stroke="#666666"
        stroke-width="2"
        mask="url(#path-4-inside-1_27415_34393)"
      />
      <mask id="path-5-inside-2_27415_34393" fill="white">
        <rect x="3" y="5" width="3" height="4" rx="0.5" />
      </mask>
      <rect
        x="3"
        y="5"
        width="3"
        height="4"
        rx="0.5"
        fill="#F9F9F9"
        stroke="#666666"
        stroke-width="2"
        mask="url(#path-5-inside-2_27415_34393)"
      />
      <mask id="path-6-inside-3_27415_34393" fill="white">
        <rect x="7" y="5" width="4" height="4" rx="0.5" />
      </mask>
      <rect
        x="7"
        y="5"
        width="4"
        height="4"
        rx="0.5"
        fill="#F9F9F9"
        stroke="#666666"
        stroke-width="2"
        mask="url(#path-6-inside-3_27415_34393)"
      />
      <mask id="path-7-inside-4_27415_34393" fill="white">
        <rect x="7" y="10" width="4" height="5" rx="0.5" />
      </mask>
      <rect
        x="7"
        y="10"
        width="4"
        height="5"
        rx="0.5"
        fill="#F9F9F9"
        stroke="#666666"
        stroke-width="2"
        mask="url(#path-7-inside-4_27415_34393)"
      />
      <mask id="path-8-inside-5_27415_34393" fill="white">
        <rect x="12" y="5" width="3" height="4" rx="0.5" />
      </mask>
      <rect
        x="12"
        y="5"
        width="3"
        height="4"
        rx="0.5"
        fill="#F9F9F9"
        stroke="#666666"
        stroke-width="2"
        mask="url(#path-8-inside-5_27415_34393)"
      />
      <mask id="path-9-inside-6_27415_34393" fill="white">
        <rect x="12" y="10" width="3" height="5" rx="0.5" />
      </mask>
      <rect
        x="12"
        y="10"
        width="3"
        height="5"
        rx="0.5"
        fill="#F9F9F9"
        stroke="#666666"
        stroke-width="2"
        mask="url(#path-9-inside-6_27415_34393)"
      />
    </svg>
  );
}
export function SelProductListing({ fill = "#666666", strokeWidth = 0 }) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_29158_4563"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="18"
        height="18"
      >
        <rect width="18" height="18" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_29158_4563)">
        <rect x="3" y="2" width="12" height="1" rx="0.5" fill="#1641DB" />
      </g>
      <rect x="0.5" y="0.5" width="17" height="17" rx="1.5" stroke="#1641DB" />
      <mask id="path-4-inside-1_29158_4563" fill="white">
        <rect x="3" y="10" width="3" height="5" rx="0.5" />
      </mask>
      <rect
        x="3"
        y="10"
        width="3"
        height="5"
        rx="0.5"
        fill="#F9F9F9"
        stroke="#1641DB"
        stroke-width="2"
        mask="url(#path-4-inside-1_29158_4563)"
      />
      <mask id="path-5-inside-2_29158_4563" fill="white">
        <rect x="3" y="5" width="3" height="4" rx="0.5" />
      </mask>
      <rect
        x="3"
        y="5"
        width="3"
        height="4"
        rx="0.5"
        fill="#F9F9F9"
        stroke="#1641DB"
        stroke-width="2"
        mask="url(#path-5-inside-2_29158_4563)"
      />
      <mask id="path-6-inside-3_29158_4563" fill="white">
        <rect x="7" y="5" width="4" height="4" rx="0.5" />
      </mask>
      <rect
        x="7"
        y="5"
        width="4"
        height="4"
        rx="0.5"
        fill="#F9F9F9"
        stroke="#1641DB"
        stroke-width="2"
        mask="url(#path-6-inside-3_29158_4563)"
      />
      <mask id="path-7-inside-4_29158_4563" fill="white">
        <rect x="7" y="10" width="4" height="5" rx="0.5" />
      </mask>
      <rect
        x="7"
        y="10"
        width="4"
        height="5"
        rx="0.5"
        fill="#F9F9F9"
        stroke="#1641DB"
        stroke-width="2"
        mask="url(#path-7-inside-4_29158_4563)"
      />
      <mask id="path-8-inside-5_29158_4563" fill="white">
        <rect x="12" y="5" width="3" height="4" rx="0.5" />
      </mask>
      <rect
        x="12"
        y="5"
        width="3"
        height="4"
        rx="0.5"
        fill="#F9F9F9"
        stroke="#1641DB"
        stroke-width="2"
        mask="url(#path-8-inside-5_29158_4563)"
      />
      <mask id="path-9-inside-6_29158_4563" fill="white">
        <rect x="12" y="10" width="3" height="5" rx="0.5" />
      </mask>
      <rect
        x="12"
        y="10"
        width="3"
        height="5"
        rx="0.5"
        fill="#F9F9F9"
        stroke="#1641DB"
        stroke-width="2"
        mask="url(#path-9-inside-6_29158_4563)"
      />
    </svg>
  );
}
