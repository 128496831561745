import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import {
  Button,
  CircularProgress,
  Grid,
  LinearProgress,
  Typography,
  Box,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory, Link } from "react-router-dom";
import {
  fetchInvoices,
  startInvoiceLoader,
  stopInvoiceLoader,
  clearInvoices,
  deleteInvoice,
  setInvoiceDateSelector,
  setInvoiceStatusSelector,
  setInvoiceDateRange,
  setInvoicesPerPage,
  setInvoicesCurrentPage,
  setInvoicesMaxPageSoFar,
  setInvoicesInputKeyword,
  setInvoicesSearchKeyword,
  setInvoicesCustomerId,
} from "../../redux/invoices/invoiceActions";
import DownArrowIcon from "../../icons/DownArrowIcon";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import Moment from "react-moment";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { downloadDocument } from "../../utils/documentUtil";
import {
  Menu,
  MenuItem,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  TableBody,
} from "@material-ui/core";
import Paginator from "../../components/Paginator/Paginator";
import {
  INVOICES_PER_PAGE,
  INVOICES_KEY,
  DEFAULT_INVOICES_PER_PAGE,
  getDateRange,
} from "../../utils/utils";
import StatusDotIcon from "../../icons/StatusDotIcon";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { Fragment } from "react";
import { getPdfLink, getWhatsAppShareLink } from "../../utils/invoiceUtil";
import InvoiceSettings from "./components/InvoiceSettings";
import FirstInvoice from "./components/FirstInvoice";
import { getDefaultStore, getStoreIdFromUrl } from "../../utils/storeUtil";
import { fetchBillSettings } from "../../redux/billSettings/billSettingsActions";
import { fetchBillConfig } from "../../redux/billConfig/billConfigActions";
import { SettingsIcon } from "../../icons";
import {
  setReloadFalse,
  setReloadTrue,
} from "../../redux/reload/reloadActions";
import ConfirmModal from "../../components/common/ConfirmModal";
import Toast from "../../components/Layout/Toast";
import {
  anchor,
  blurRow,
  layoutBodyBox,
  layoutContainer,
  layoutLoaderHeader,
  layoutSubHeader,
  rowFlexOnlyJustifyCenter,
  tag,
} from "../../styles/common";
import OutlinedButton from "../../components/buttons/OutlinedButton";
import SolidButton from "../../components/buttons/SolidButton";
import PageLoader from "../../components/Layout/PageLoader";
import NoInvoiceFigma from "../../icons/NoInvoiceFigma";
import HeaderBreadcrumbs from "../../components/common/HeaderBreadcrumbs";
import { useQuery } from "../coupons/Components/CouponForm/formUtils";
import NoRecord from "../../components/common/NoRecord";
import HighlightWidget from "../../components/common/HighlightWidget";

const Invoices = () => {
  const classes = useStyles();
  const history = useHistory();
  const queryParams = useQuery();
  const invoiceState = useSelector((state) => state.invoices);
  const store = useSelector((state) => state.store);
  const wallet = useSelector((state) => state.wallet.walletData);
  const invoices = invoiceState.invoices;
  const numberOfPages = invoiceState.numberOfPages;
  const loader = invoiceState.loader;
  const dateSelector = invoiceState.dateSelector;
  const statusSelector = invoiceState.statusSelector;
  const dateRange = invoiceState.dateRange;
  const invoicesPerPage = invoiceState.invoicesPerPage;
  const currentPage = invoiceState.currentPage;
  const maxPageSoFar = invoiceState.maxPageSoFar;
  const searchKeyword = invoiceState.searchKeyword;
  const inputKeyword = invoiceState.inputKeyword;
  const customerId = invoiceState.customerId;

  const [anchorEl, setAnchorEl] = useState(null);
  const [statusAnchorEl, setStatusAnchorEl] = useState(null);

  const [openInvoiceSettings, setOpenInvoiceSettings] = useState(false);
  const [openFirstInvoice, setOpenFirstInvoice] = useState(false);

  const [whiteSpaceDiv, setWhiteSpaceDiv] = useState(false);
  const [anchoredInvoice, setAnchoredInvoice] = useState(null);

  const [toast, setToast] = useState(false);
  const [message, setMessage] = useState("");

  const [isDeleting, setIsDeleting] = useState(false);
  const [isSharing, setIsSharing] = useState(false);

  const [allowDelete, setAllowDelete] = useState(true);
  const [pageProgress, setPageProgress] = useState(false);

  const refetch = queryParams.get("refetch");
  const paramCustomerId = queryParams.get("customer-id");
  const onChangeInvoicesPerPage = (updatedInvoicesPerPage) => {
    localStorage.setItem(INVOICES_KEY, updatedInvoicesPerPage);
    dispatch(clearInvoices());
    dispatch(setInvoicesCurrentPage(0));
    dispatch(setInvoicesMaxPageSoFar(0));
    dispatch(setInvoicesPerPage(updatedInvoicesPerPage));
    dispatch(
      fetchInvoices(
        0,
        textToStatus(statusSelector),
        dateRange.aoe,
        dateRange.boe,
        searchKeyword,
        updatedInvoicesPerPage
      )
    );
  };

  let startIndex = currentPage * invoicesPerPage;
  let endIndex =
    startIndex + invoicesPerPage < invoices.length
      ? startIndex + invoicesPerPage
      : invoices.length;

  const dispatch = useDispatch();
  const storeId = getStoreIdFromUrl();

  useEffect(() => {
    let storeUser = getDefaultStore();
    if (storeUser && storeUser.role) {
      setAllowDelete(storeUser.role !== "BILLING_MANAGER" ? true : false);
    }
  }, [storeId]);

  useEffect(() => {
    if (!invoicesPerPage) {
      dispatch(setInvoicesPerPage(INVOICES_PER_PAGE));
    }
    // eslint-disable-next-line
  }, [invoicesPerPage]);

  useEffect(() => {
    if (
      refetch ||
      (invoices.length === 0 && !dateSelector && !statusSelector)
    ) {
      dispatch(setInvoiceDateSelector("All"));
      dispatch(setInvoiceStatusSelector("All"));
      dispatch(setInvoiceDateRange({ aoe: null, boe: null }));
      if (paramCustomerId || customerId) {
        dispatch(
          setInvoicesCustomerId(paramCustomerId ? paramCustomerId : customerId)
        );
        dispatch(
          fetchInvoices(
            0,
            "All",
            null,
            null,
            null,
            INVOICES_PER_PAGE,
            paramCustomerId ? paramCustomerId : customerId
          )
        ).then(() => {
          setPageProgress(false);
        });
      } else {
        dispatch(fetchInvoices()).then(() => {
          setPageProgress(false);
        });
      }
    }
    dispatch(fetchBillSettings());
    dispatch(fetchBillConfig());
    // eslint-disable-next-line
  }, [storeId, refetch]);

  const onNextPage = () => {
    setWhiteSpaceDiv(false);
    if (maxPageSoFar < currentPage + 1) {
      dispatch(
        fetchInvoices(
          currentPage + 1,
          textToStatus(statusSelector),
          dateRange.aoe,
          dateRange.boe,
          searchKeyword,
          invoicesPerPage,
          customerId
        )
      );
      dispatch(
        setInvoicesMaxPageSoFar(
          maxPageSoFar < currentPage + 1 ? currentPage + 1 : maxPageSoFar
        )
      );
      dispatch(setInvoicesCurrentPage(currentPage + 1));
    } else {
      dispatch(setInvoicesCurrentPage(currentPage + 1));
    }
  };

  const onPrevPage = () => {
    dispatch(setInvoicesCurrentPage(currentPage - 1));
  };

  const handleClick = (event, tableitem) => {
    setAnchoredInvoice(tableitem);
    setAnchorEl(event.currentTarget);
  };

  const handleChangeDateSelector = (range) => {
    setWhiteSpaceDiv(true);
    if (range === dateSelector) return;
    dispatch(setInvoicesMaxPageSoFar(0));
    dispatch(setInvoicesCurrentPage(0));
    let res = getDateRange(range);
    let aoe = res.aoe;
    let boe = res.boe;
    dispatch(
      fetchInvoices(
        0,
        textToStatus(statusSelector),
        aoe,
        boe,
        searchKeyword,
        invoicesPerPage,
        customerId
      )
    );
    dispatch(setInvoiceDateSelector(range));
    dispatch(setInvoiceDateRange({ aoe: aoe, boe: boe }));
  };

  const handleCustomDateSelector = (rangePicker) => {
    setWhiteSpaceDiv(true);
    dispatch(setInvoicesMaxPageSoFar(0));
    dispatch(setInvoicesCurrentPage(0));
    dispatch(
      fetchInvoices(
        0,
        textToStatus(statusSelector),
        rangePicker.startDate._d.toISOString(),
        rangePicker.endDate._d.toISOString(),
        searchKeyword,
        invoicesPerPage,
        customerId
      )
    );
    dispatch(setInvoiceDateSelector("Custom"));
    dispatch(
      setInvoiceDateRange({
        aoe: rangePicker.startDate._d.toISOString(),
        boe: rangePicker.endDate._d.toISOString(),
      })
    );
  };

  const handleChangeStatusSelector = (status) => {
    setWhiteSpaceDiv(true);
    dispatch(setInvoicesMaxPageSoFar(0));
    dispatch(setInvoicesCurrentPage(0));
    dispatch(
      fetchInvoices(
        0,
        textToStatus(status),
        dateRange.aoe,
        dateRange.boe,
        searchKeyword,
        invoicesPerPage,
        customerId
      )
    );
    dispatch(setInvoiceStatusSelector(status));
    setStatusAnchorEl(null);
  };

  const statusToText = (dueAmount, status) => {
    if (dueAmount === 0) {
      return { text: "Paid", fill: "#72C472" };
    }
    if (status === "PAID") return { text: "Paid", fill: "#72C472" };
    if (status === "UNPAID") return { text: "Unpaid", fill: "#FF0000" };
    if (status === "PART_PAID") return { text: "Part Paid", fill: "#FFB800" };
    return { text: "-", fill: "white" };
  };

  const textToStatus = (text) => {
    if (text === "Paid") return "PAID";
    if (text === "Unpaid") return "UNPAID";
    if (text === "Part Paid") return "PART_PAID";
    return "ALL";
  };

  const onSearch = (keyword) => {
    setWhiteSpaceDiv(true);
    if (keyword) {
      dispatch(setInvoicesMaxPageSoFar(0));
      dispatch(setInvoicesCurrentPage(0));
      dispatch(setInvoiceStatusSelector("All"));
      dispatch(setInvoiceDateSelector("All"));
      dispatch(setInvoiceDateRange({ aoe: null, boe: null }));
      dispatch(
        fetchInvoices(
          0,
          "All",
          null,
          null,
          keyword,
          invoicesPerPage,
          customerId
        )
      );
    }
  };

  const viewPdf = () => {
    dispatch(startInvoiceLoader());
    getPdfLink(anchoredInvoice.id).then((link) => {
      window.open(link);
      dispatch(stopInvoiceLoader());
    });
  };

  const whatsAppShare = async () => {
    dispatch(startInvoiceLoader());
    await getWhatsAppShareLink(anchoredInvoice).then((link) => {
      window.open(link);
      dispatch(stopInvoiceLoader());
    });
  };

  const downloadInvoice = async () => {
    let filename = anchoredInvoice.invoice_id + ".pdf";
    dispatch(startInvoiceLoader());
    await downloadDocument(
      store.store,
      "invoice",
      anchoredInvoice.id,
      filename
    );
    dispatch(stopInvoiceLoader());
  };

  const handleDelete = () => {
    dispatch(startInvoiceLoader());
    dispatch(deleteInvoice(anchoredInvoice.id));
  };

  if (pageProgress || (invoiceState.progress && invoices.length === 0)) {
    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} className={layoutLoaderHeader}>
            <div className={layoutContainer}>
              <HeaderBreadcrumbs list={[{ label: "Invoices" }]} />
              <PageLoader />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
  return (
    <div>
      <Grid container spacing={2}>
        {loader && (
          <div
            style={{
              position: "fixed",
              left: "50%",
              top: "50%",
              zIndex: "900",
            }}
          >
            <CircularProgress color="secondary" />
          </div>
        )}
        <HighlightWidget screen="invoices" />
        <Grid item xs={12} className={rowFlexOnlyJustifyCenter}>
          <div className={layoutContainer}>
            <div className={layoutSubHeader}>
              <HeaderBreadcrumbs list={[{ label: "Invoices" }]} />
              <OutlinedButton
                endIcon={<SettingsIcon stroke="#1641DB" />}
                style={{ width: 185, marginRight: 20 }}
                onClick={() => setOpenInvoiceSettings(true)}
              >
                Manage Settings
              </OutlinedButton>
              <SolidButton
                style={{ width: 185 }}
                onClick={() => {
                  if (invoices.length !== 0) {
                    history.push("./invoices/create");
                    return;
                  }
                  setOpenFirstInvoice(true);
                }}
              >
                Create Invoice
              </SolidButton>
            </div>
            {invoices.length === 0 &&
            statusSelector === "All" &&
            dateSelector === "All" &&
            searchKeyword === "" &&
            !customerId ? (
              <NoRecord
                text="Start creating Invoices for your customers"
                image={<NoInvoiceFigma />}
                arrow={true}
              />
            ) : (
              <div style={{ position: "relative", width: "100%" }}>
                <div className={layoutBodyBox}>
                  <div className={classes.tableHeader}>
                    <div className={classes.search}>
                      <SearchIcon />
                      <input
                        type="text"
                        placeholder="Enter Invoice Number, Customer Name, Mobile"
                        className={classes.searchBox}
                        value={
                          customerId
                            ? `Customer ID: ${customerId}`
                            : inputKeyword
                        }
                        onChange={(e) =>
                          dispatch(setInvoicesInputKeyword(e.target.value))
                        }
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            if (inputKeyword.trim() !== "") {
                              dispatch(
                                setInvoicesSearchKeyword(inputKeyword.trim())
                              );
                              onSearch(inputKeyword.trim());
                            }
                          }
                          if (e.key === "Escape") {
                            setWhiteSpaceDiv(true);
                            dispatch(setInvoicesSearchKeyword(""));
                            dispatch(setInvoicesInputKeyword(""));
                            dispatch(setInvoicesMaxPageSoFar(0));
                            dispatch(setInvoicesCurrentPage(0));
                            dispatch(
                              fetchInvoices(
                                0,
                                "ALL",
                                null,
                                null,
                                null,
                                invoicesPerPage,
                                customerId
                              )
                            );
                            dispatch(setInvoiceStatusSelector("All"));
                            dispatch(setInvoiceDateSelector("All"));
                            dispatch(
                              setInvoiceDateRange({ aoe: null, boe: null })
                            );
                          }
                        }}
                      />
                      {(searchKeyword !== "" || customerId) && (
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setWhiteSpaceDiv(true);
                            dispatch(setInvoicesSearchKeyword(""));
                            dispatch(setInvoicesInputKeyword(""));
                            dispatch(setInvoicesMaxPageSoFar(0));
                            dispatch(setInvoicesCurrentPage(0));
                            dispatch(fetchInvoices());
                            dispatch(setInvoiceStatusSelector("All"));
                            dispatch(setInvoiceDateSelector("All"));
                            dispatch(setInvoicesCustomerId(null));
                            dispatch(
                              setInvoiceDateRange({ aoe: null, boe: null })
                            );
                            if (customerId) history.push(`./invoices`);
                          }}
                        >
                          <CloseIcon fontSize="small" />
                        </span>
                      )}
                    </div>
                    <div className={classes.tableHeaderRight}>
                      <div>
                        <DateRangePicker
                          initialSettings={{
                            startDate: new Date(
                              new Date().setMonth(new Date().getMonth() - 1)
                            ),
                            endDate: new Date(),
                            maxDate: new Date(),
                            applyButtonClasses: classes.applyBtn,
                          }}
                          onApply={(fn, rangePicker) => {
                            handleCustomDateSelector(rangePicker);
                          }}
                        >
                          <Typography
                            className={classes.dateFilter}
                            style={{
                              color:
                                dateSelector === "Custom"
                                  ? "#1641DB"
                                  : "#666666",
                              fontSize: 12,
                              fontWeight: dateSelector === "Custom" ? 600 : 400,
                              cursor: "pointer",
                            }}
                          >
                            Custom
                          </Typography>
                        </DateRangePicker>
                      </div>
                      <div
                        onClick={() => {
                          handleChangeDateSelector("All");
                        }}
                      >
                        <Typography
                          className={classes.dateFilter}
                          style={{
                            color:
                              dateSelector === "All" ? "#1641DB" : "#666666",
                            fontWeight: dateSelector === "All" ? 600 : 400,
                            fontSize: 12,
                            cursor: "pointer",
                          }}
                        >
                          All
                        </Typography>
                      </div>
                      <div
                        onClick={() => {
                          handleChangeDateSelector("Today");
                        }}
                      >
                        <Typography
                          className={classes.dateFilter}
                          style={{
                            color:
                              dateSelector === "Today" ? "#1641DB" : "#666666",
                            fontWeight: dateSelector === "Today" ? 600 : 400,
                            fontSize: 12,
                            cursor: "pointer",
                          }}
                        >
                          Today
                        </Typography>
                      </div>
                      <div
                        onClick={() => {
                          handleChangeDateSelector("Yesterday");
                        }}
                      >
                        <Typography
                          className={classes.dateFilter}
                          style={{
                            color:
                              dateSelector === "Yesterday"
                                ? "#1641DB"
                                : "#666666",
                            fontWeight:
                              dateSelector === "Yesterday" ? 600 : 400,
                            fontSize: 12,
                            cursor: "pointer",
                          }}
                        >
                          Yesterday
                        </Typography>
                      </div>
                      <div
                        onClick={() => {
                          handleChangeDateSelector("Last Week");
                        }}
                      >
                        <Typography
                          className={classes.dateFilter}
                          style={{
                            color:
                              dateSelector === "Last Week"
                                ? "#1641DB"
                                : "#666666",
                            fontWeight:
                              dateSelector === "Last Week" ? 600 : 400,
                            fontSize: 12,
                            cursor: "pointer",
                          }}
                        >
                          Last Week
                        </Typography>
                      </div>
                      <div
                        onClick={() => {
                          handleChangeDateSelector("Last Month");
                        }}
                      >
                        <Typography
                          className={classes.dateFilter}
                          style={{
                            color:
                              dateSelector === "Last Month"
                                ? "#1641DB"
                                : "#666666",
                            fontWeight:
                              dateSelector === "Last Month" ? 600 : 400,
                            fontSize: 12,
                            cursor: "pointer",
                          }}
                        >
                          Last Month
                        </Typography>
                      </div>
                      <Button
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={(e) => {
                          dispatch(setReloadTrue());
                          setStatusAnchorEl(e.currentTarget);
                        }}
                        className={classes.statusSelector}
                        style={{ width: 105, outline: "none" }}
                      >
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <Typography
                              style={{
                                fontWeight: 400,
                                fontSize: 10,
                                textAlign: "left",
                              }}
                            >
                              Status
                            </Typography>
                            <Typography
                              style={{
                                fontWeight: 600,
                                fontSize: 12,
                                textAlign: "left",
                              }}
                            >
                              {statusSelector}
                            </Typography>
                          </div>
                          <DownArrowIcon stroke="#1A1A1A" />
                        </div>
                      </Button>
                      <Menu
                        anchorEl={statusAnchorEl}
                        keepMounted
                        open={Boolean(statusAnchorEl)}
                        onClose={() => {
                          dispatch(setReloadFalse());
                          setStatusAnchorEl(null);
                        }}
                        getContentAnchorEl={null}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            handleChangeStatusSelector("All");
                          }}
                          style={{ fontSize: 12, color: "#1641DB" }}
                          selected={statusSelector === "All"}
                        >
                          All
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            handleChangeStatusSelector("Paid");
                          }}
                          style={{ fontSize: 12, color: "#1641DB" }}
                          selected={statusSelector === "Paid"}
                        >
                          Paid
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            handleChangeStatusSelector("Unpaid");
                          }}
                          style={{ fontSize: 12, color: "#1641DB" }}
                          selected={statusSelector === "Unpaid"}
                        >
                          Unpaid
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            handleChangeStatusSelector("Part Paid");
                          }}
                          style={{ fontSize: 12, color: "#1641DB" }}
                          selected={statusSelector === "Part Paid"}
                        >
                          Part Paid
                        </MenuItem>
                      </Menu>
                    </div>
                  </div>
                  <TableContainer>
                    <Table>
                      <TableHead className={classes.tablehead}>
                        <TableRow>
                          <TableCell style={{ width: "18%" }}>
                            Invoice #
                          </TableCell>
                          <TableCell style={{ width: "12%" }}>Amount</TableCell>
                          <TableCell style={{ width: "12%" }}>
                            Balance
                          </TableCell>
                          <TableCell style={{ width: "13%" }}>
                            Create Date
                          </TableCell>
                          <TableCell style={{ width: "17%" }}>
                            Customer Name
                          </TableCell>
                          <TableCell style={{ width: "10%" }}>Status</TableCell>
                          <TableCell style={{ width: "11%" }}></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className={classes.tablebody}>
                        {invoices
                          .slice(startIndex, endIndex)
                          .map((tableitem, index) => (
                            <TableRow
                              key={tableitem.id}
                              className={
                                wallet?.balance < wallet?.order_balance
                                  ? blurRow
                                  : ""
                              }
                              style={{
                                borderBottom:
                                  (index + 1) % invoicesPerPage === 0
                                    ? "none"
                                    : "auto",
                              }}
                            >
                              <TableCell>
                                <Link
                                  style={{
                                    color: "#1641DB",
                                    textDecoration: "none",
                                  }}
                                  to={`invoices/${encodeURIComponent(
                                    tableitem.invoice_id
                                  )}`}
                                >
                                  {tableitem.invoice_id}
                                </Link>
                                <span
                                  className={tag}
                                  style={{
                                    background:
                                      tableitem.type === "INVOICE"
                                        ? "rgba(22, 65, 219, 0.15)"
                                        : "rgba(22, 172, 103, 0.15)",
                                  }}
                                >
                                  {tableitem.type === "INVOICE"
                                    ? "In Store"
                                    : "Online"}
                                </span>
                              </TableCell>
                              <TableCell>
                                {tableitem.final_sale_price.toFixed(2)}
                              </TableCell>
                              <TableCell>
                                {tableitem.due_amount.toFixed(2)}
                              </TableCell>
                              <TableCell>
                                <Moment format="DD-MMM-YYYY">
                                  {tableitem.created_at}
                                </Moment>
                              </TableCell>
                              <TableCell>
                                <Link
                                  to={`./customers/${tableitem.org_store_customer_id}`}
                                  style={{
                                    color: "#1641DB",
                                    textDecoration: "none",
                                  }}
                                >
                                  {tableitem.org_customer_name}
                                </Link>
                              </TableCell>
                              <TableCell>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <StatusDotIcon
                                    fill={
                                      statusToText(
                                        tableitem.due_amount,
                                        tableitem.term
                                      ).fill
                                    }
                                  />
                                  &nbsp;&nbsp;
                                  {
                                    statusToText(
                                      tableitem.due_amount,
                                      tableitem.term
                                    ).text
                                  }
                                </div>
                              </TableCell>
                              <TableCell align="center">
                                <Box display="flex" justifyContent="flex-end">
                                  <Box>
                                    <MoreHorizIcon
                                      style={{ cursor: "pointer" }}
                                      aria-controls="simple-menu"
                                      aria-haspopup="true"
                                      onClick={(event) => {
                                        dispatch(setReloadTrue());
                                        handleClick(event, tableitem);
                                      }}
                                    />
                                    <Menu
                                      id="simple-menu"
                                      anchorEl={anchorEl}
                                      keepMounted
                                      open={Boolean(anchorEl)}
                                      onClose={() => {
                                        dispatch(setReloadFalse());
                                        setAnchorEl(null);
                                      }}
                                      className={anchor}
                                    >
                                      <MenuItem
                                        onClick={() => {
                                          viewPdf();
                                          setAnchorEl(null);
                                        }}
                                      >
                                        View
                                      </MenuItem>
                                      <MenuItem
                                        onClick={() => {
                                          downloadInvoice();
                                          setAnchorEl(null);
                                        }}
                                      >
                                        Download
                                      </MenuItem>
                                      {anchoredInvoice &&
                                      anchoredInvoice.type === "INVOICE" &&
                                      allowDelete ? (
                                        <MenuItem
                                          onClick={() => {
                                            setIsDeleting(true);
                                          }}
                                        >
                                          Delete
                                        </MenuItem>
                                      ) : (
                                        <></>
                                      )}
                                      <MenuItem
                                        onClick={() => {
                                          setIsSharing(true);
                                          setAnchorEl(null);
                                        }}
                                      >
                                        SMS Share
                                      </MenuItem>
                                      <MenuItem
                                        onClick={() => {
                                          whatsAppShare();
                                          setAnchorEl(null);
                                        }}
                                      >
                                        WhatsApp Share
                                      </MenuItem>
                                      {/*<MenuItem
                                    onClick={() => {
                                      printPdf();
                                      setAnchorEl(null);
                                    }}
                                  >
                                    Print
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => {
                                      setAnchorEl(null);
                                    }}
                                  >
                                    Share
                                  </MenuItem>*/}
                                    </Menu>
                                  </Box>
                                </Box>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>

                    {invoiceState.progress && (
                      <Fragment>
                        {!whiteSpaceDiv && (
                          <div
                            style={{
                              height: invoicesPerPage * 68 - 4,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          ></div>
                        )}
                        <LinearProgress color="secondary" />
                      </Fragment>
                    )}
                    {invoices.length === 0 && (
                      <div
                        style={{
                          height: INVOICES_PER_PAGE * 68 - 4,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: 18,
                            fontWeight: 600,
                            marginTop: -34,
                          }}
                        >
                          No Invoice Found
                        </Typography>
                      </div>
                    )}
                  </TableContainer>
                </div>
                <Paginator
                  changeRecordsPerPage={onChangeInvoicesPerPage}
                  possibleRecordsPerPage={[
                    DEFAULT_INVOICES_PER_PAGE,
                    20,
                    50,
                    100,
                  ]}
                  totalCount={invoiceState.numberOfElements}
                  recordsPerPage={invoicesPerPage}
                  onNext={onNextPage}
                  onPrev={onPrevPage}
                  total={numberOfPages === 0 ? 1 : numberOfPages}
                  currentPage={currentPage}
                />
              </div>
            )}
          </div>
        </Grid>
      </Grid>
      <InvoiceSettings
        openInvoiceSettings={openInvoiceSettings}
        setOpenInvoiceSettings={setOpenInvoiceSettings}
        setToast={setToast}
        setMessage={setMessage}
      />
      <FirstInvoice
        openFirstInvoice={openFirstInvoice}
        setOpenFirstInvoice={setOpenFirstInvoice}
      />
      <ConfirmModal
        open={isDeleting}
        message={
          anchoredInvoice
            ? `Are you sure you want to delete invoice ${anchoredInvoice.invoice_id} ?`
            : ""
        }
        closeBtnText="Cancel"
        confirmBtnText="Delete"
        onClose={() => {
          setIsDeleting(false);
          setAnchorEl(null);
        }}
        onConfirm={() => {
          handleDelete();
          setAnchorEl(null);
          setIsDeleting(false);
        }}
      />
      <ConfirmModal
        open={isSharing}
        title="Share"
        message={
          anchoredInvoice ? (
            <span>
              Invoice <b>{anchoredInvoice.invoice_id}</b> will be sent to &nbsp;
              {anchoredInvoice.org_customer_name} via SMS to their phone number{" "}
              {anchoredInvoice.org_customer_mobile}.
            </span>
          ) : (
            ""
          )
        }
        intent="positive"
        closeBtnText="Cancel"
        confirmBtnText="Send"
        onClose={() => {
          setIsSharing(false);
          setAnchoredInvoice(null);
        }}
        onConfirm={async () => {
          try {
            let accessToken = localStorage.getItem("access_token");
            let response = await fetch(
              `${process.env.REACT_APP_API}/api/v1/org/order/${anchoredInvoice.id}/invoice/share`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${accessToken}`,
                },
              }
            );
            let data = await response.json();
            if (!response.ok) {
              throw data;
            }
            setMessage("Message sent successfully");
            setToast(true);
            setIsSharing(false);
            setAnchoredInvoice(null);
          } catch (err) {
            setMessage("Failed to send SMS");
            setToast(true);
          }
        }}
      />
      <Toast open={toast} close={setToast} message={message} />
    </div>
  );
};
export default Invoices;
