import { Divider, Paper, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import FilledEditButton from "../../../components/buttons/FilledEditButton";
import CombinedButtons from "../../../components/buttons/CombinedButtons";
import useStyles from "../components/ConfigStyles";
import {
  fetchApps,
  getPluginConfig,
  postPluginConfig,
} from "../../../redux/apps/appActions";
import { useDispatch, useSelector } from "react-redux";
import { copyToClipboard } from "../../../utils/utils";
import {
  RemoveRedEyeOutlined,
  VisibilityOffOutlined,
} from "@material-ui/icons";
import Controls from "../../../components/controls/Controls";
import Toast from "../../../components/Layout/Toast";
import { useHistory } from "react-router-dom";
import { centerContainer, layoutCenterContainer } from "../../../styles/common";
import HeaderBreadcrumbs from "../../../components/common/HeaderBreadcrumbs";
import OrderSyncSettings from "./components/AramexOrderSyncSettings";
import OutlinedLocationButton from "../../../components/buttons/OutlinedLocationButton";
import LocationFormModal from "../../../components/common/LocationFormModal";
import { fetchPickupLocation } from "../../../utils/locationUtils";
import axios from "axios";

const list = [
  {
    label: "App Store",
    link: "/apps",
  },
  { label: "Aramex", link: "/apps/aramex" },
  {
    label: "Configure",
  },
];

const AramexConfig = () => {
  const classes = useStyles();
  const history = useHistory();
  const storeData = useSelector((state) => state.store);
  const dispatch = useDispatch();
  const appState = useSelector((state) => state.apps);
  const apps = appState.apps;
  const [showPass, setShowPass] = useState(false);
  const initialState = {
    email: {
      value: "",
      touched: false,
      error: false,
    },
    password: {
      value: "",
      touched: false,
      error: false,
    },
    account_number: {
      value: "",
      touched: false,
      error: false,
    },
    entity: {
      value: "",
      touched: false,
      error: false,
    },
    pin: {
      value: "",
      touched: false,
      error: false,
    },
  };

  const [formState, setFormState] = useState(initialState);
  const [isEditing, setIsEditing] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [initialData, setInitialData] = useState(initialState);
  const [openModal, setOpenModal] = useState(false);
  const [location, setLocation] = useState(null);

  useEffect(() => {
    if (apps.length === 0) {
      dispatch(fetchApps());
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (storeData.fetched) {
      dispatch(getPluginConfig("aramex"));
    }
    // eslint-disable-next-line
  }, [storeData]);

  useEffect(() => {
    if (storeData.fetched) {
      dispatch(getPluginConfig("aramex"));
      fetchPickupLocation(storeData, "aramex").then((loc) => {
        if (loc != null) {
          setLocation(loc);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeData]);

  useEffect(() => {
    if (Object.keys(appState.aramex).length > 0) {
      let form = {};
      Object.keys(appState.aramex).forEach((key) => {
        form[key] = {
          value: appState.aramex[key],
          touched: false,
          error: "",
        };
      });
      setFormState(form);
      setInitialData(form);
    }

    // eslint-disable-next-line
  }, [appState.aramex]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let error = "";

    if (name === "email") {
      if (value.length === 0 || !/^\S+@\S+\.\S+$/.test(value)) {
        error = "Please ensure to enter a valid email";
      }
    } else if (name === "password") {
      if (value.length === 0) {
        error = "Please enter a password";
      }
    }

    setFormState((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        value: value,
        touched: true,
        error: error,
      },
    }));
  };

  const getMessage = (name) => {
    const messages = {
      email: "Please enter a valid email.",
      password: "please enter a valid password.",
      account_number: "Account number must contain only digits",
      entity: "Entity field cannot be empty",
      pin: "PIN must be alphanumeric",
    };
    return messages[name] || "";
  };

  const onBlur = (event) => {
    const { name } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        error: !prevState[name].value
          ? getMessage(name)
          : prevState[name].error,
      },
    }));
  };

  const onFocus = (event) => {
    const { name } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        touched: true,
        error: "",
      },
    }));
  };

  const handleCopyToClipboard = (text) => () => {
    const result = copyToClipboard(text);
    if (!result) {
      setShowError(true);
    } else {
      setShowSuccess(true);
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
    let clonedForm = { ...initialState };
    clonedForm.email.value = formState.email.value;
    setFormState(clonedForm);
    setInitialData(clonedForm);
  };

  const validattion = () => {
    let errorFlag = false;
    Object.keys(formState).forEach((key) => {
      let cloneData = formState[key];
      if (!cloneData.value) {
        errorFlag = true;
        cloneData.error = getMessage(key);
      }
      setFormState({ ...formState, [key]: cloneData });
    });
    return errorFlag;
  };

  const navigateToUrl = () => {
    if (isEditing) {
      setIsEditing(false);
      let form = {};
      Object.keys(appState.aramex).forEach((key) => {
        form[key] = {
          value: appState.aramex[key],
          touched: false,
          error: "",
        };
      });
      setFormState(form);
      setInitialData(form);
    } else {
      history.goBack();
    }
  };
  const hasChanges = () => {
    // If we're in edit mode but no token exists (initial setup)
    if (!formState["token"]) {
      return true; // Allow saving since it's initial setup
    }

    // Check if any field has changed from the original values
    return Object.keys(formState).some((key) => {
      return formState[key].value !== appState.aramex[key];
    });
  };

  const handleSave = async () => {
    let isError = validattion();
    if (isError) return;
    setLoading(true);
    dispatch(
      postPluginConfig("aramex", {
        email: formState.email.value,
        password: formState.password.value,
        account_number: formState.account_number.value,
        entity: formState.entity.value,
        pin: formState.pin.value,
      })
    )
      .then((cobj) => {
        if (cobj.error) {
          setErrorMessage(cobj.message);
          setShowError(true);
        }
        setLoading(false);
      })
      .catch((error) => {
        setErrorMessage(error.message || "An error occurred.");
        setShowError(true);
        setLoading(false);
      });
  };

  const onLocationSave = async (data) => {
    let storeSlug = storeData.store.slug;
    let accessToken = localStorage.getItem("access_token");
    let resp = {};
    if (location && location.id) {
      resp = await axios.put(
        `${process.env.REACT_APP_API}/api/v1/store/${storeSlug}/pickup-locations/${location.id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );
    } else {
      resp = await axios.post(
        `${process.env.REACT_APP_API}/api/v1/store/${storeSlug}/pickup-locations`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );
    }
    setLocation(resp.data.payload);
  };

  return (
    <div className={layoutCenterContainer}>
      <div className={centerContainer}>
        <div className={classes.breadcrumbsContainer}>
          <div className={classes.backDiv}>
            <HeaderBreadcrumbs list={list} />
          </div>
        </div>
        <Paper elevation={0} className={classes.paperCard}>
          <div>
            <div className={classes.headingButtonSection}>
              <Typography className={classes.formHead}>
                Aramex Configuration Settings
              </Typography>

              {initialData["password"].value ? (
                <FilledEditButton onClick={handleEdit} />
              ) : (
                <OutlinedLocationButton onClick={() => setOpenModal(true)} />
              )}
            </div>
            <Controls.Input
              name="email"
              label="Email"
              value={formState.email ? formState.email.value : ""}
              onChange={handleInputChange}
              error={
                formState.email && formState.email.error.length > 0
                  ? formState.email.error
                  : ""
              }
              onFocus={onFocus}
              onBlur={onBlur}
              labelPlacement="top"
              disabled={initialData["password"].value ? true : false}
            />
            <Controls.Input
              name="password"
              label="Password"
              value={
                formState.password
                  ? formState.password.value
                  : formState.password
                  ? formState.password.value
                  : ""
              }
              onChange={handleInputChange}
              error={
                formState.password && formState.password.error.length > 0
                  ? formState.password.error
                  : ""
              }
              onFocus={onFocus}
              onBlur={onBlur}
              labelPlacement="top"
              disabled={initialData["password"].value ? true : false}
              endAdornment={
                initialData["password"].value ? (
                  <></>
                ) : !showPass ? (
                  <RemoveRedEyeOutlined
                    style={{ cursor: "pointer", color: "#2238A8" }}
                    onClick={() => {
                      setShowPass(true);
                    }}
                  />
                ) : (
                  <VisibilityOffOutlined
                    style={{ cursor: "pointer", color: "#2238A8" }}
                    onClick={() => {
                      setShowPass(false);
                    }}
                  />
                )
              }
              type={showPass ? "text" : "password"}
            />
            <Controls.Input
              name="account_number"
              label="Account Number"
              value={
                formState.account_number ? formState.account_number.value : ""
              }
              onChange={handleInputChange}
              error={
                formState.account_number &&
                formState.account_number.error.length > 0
                  ? formState.account_number.error
                  : ""
              }
              onFocus={onFocus}
              onBlur={onBlur}
              labelPlacement="top"
              disabled={initialData["password"].value ? true : false}
            />
            <Controls.Input
              name="entity"
              label="Entity"
              value={formState.entity ? formState.entity.value : ""}
              onChange={handleInputChange}
              error={
                formState.entity && formState.entity.error.length > 0
                  ? formState.entity.error
                  : ""
              }
              onFocus={onFocus}
              onBlur={onBlur}
              labelPlacement="top"
              disabled={initialData["password"].value ? true : false}
            />
            <Controls.Input
              name="pin"
              label="Pin"
              value={formState.pin ? formState.pin.value : ""}
              onChange={handleInputChange}
              error={
                formState.pin && formState.pin.error.length > 0
                  ? formState.pin.error
                  : ""
              }
              onFocus={onFocus}
              onBlur={onBlur}
              labelPlacement="top"
              disabled={initialData["password"].value ? true : false}
            />
            {/* 
            {formState["token"] && (
              <WebhookDetails
                formData={formState}
                handleCopyToClipboard={handleCopyToClipboard}
              />
            )} */}
            {
              initialData[
                "password" && <Divider style={{ margin: "32px 0px" }} />
              ]
            }
          </div>

          {!initialData["password"].value && (
            <>
              <div className={classes.button}>
                <CombinedButtons
                  outlinedBtnText="Back"
                  outlinedBtnAction={navigateToUrl}
                  solidBtnAction={handleSave}
                  loading={loading}
                  disabled={!hasChanges()}
                />
              </div>
            </>
          )}
        </Paper>
        <LocationFormModal
          open={openModal}
          setOpen={setOpenModal}
          title={"Pickup Location"}
          record={location}
          onSave={onLocationSave}
        />
        <Toast
          message={errorMessage ? errorMessage : "Could not copy to clipboard"}
          open={showError}
          close={() => setShowError(false)}
          severity="error"
          autoClose
        />
        <Toast
          message={"Copied to clipboard"}
          open={showSuccess}
          close={() => setShowSuccess(false)}
          autoClose
        />
        {formState["token"] && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              padding: "32px",
              boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.04)",
              background: "#fff",
              marginTop: "20px",
              borderRadius: "6px",
              minWidth: "370px",
            }}
          >
            <OrderSyncSettings />
          </div>
        )}
      </div>
    </div>
  );
};

export default AramexConfig;
