import { makeStyles } from "@material-ui/styles";
export default makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: "20px 30px",
    marginLeft: "260px",
    marginTop: 64,
    [theme.breakpoints.down("md")]: {
      marginLeft: "192px",
    },
  },
  contentShift: {
    width: `calc(100vw - ${240 + theme.spacing(6)}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  fakeToolbar: {
    ...theme.mixins.toolbar,
  },
}));
