import { createColor } from "material-ui-color";

export const config = {
  where_to_display: {
    value: "home-page",
    touched: false,
    error: "",
    required: true,
    options: [
      {
        title: "Home Page",
        id: "home-page",
        helperText: "Hello bar will be displayed in Homepage only",
      },
      {
        title: "All Pages",
        id: "all-pages",
        helperText: "Hello bar will be displayed in All pages",
      },
    ],
    willPersist: true,
  },
  initial_delay: {
    value: "0",
    touched: false,
    error: "",
    required: true,
    options: [
      {
        title: "Immediately",
        id: "0",
        helperText: "Display Immediately Without Delay",
      },
      {
        title: "2 Secs",
        id: "2000",
        helperText: "Hello Bar will display after 2 seconds",
      },
      {
        title: "5 Secs",
        id: "5000",
        helperText: "Hello Bar will display after 5 seconds",
      },
      {
        title: "10 Secs",
        id: "10000",
        helperText: "Hello Bar will display after 10 seconds",
      },
      {
        title: "15 Secs",
        id: "15000",
        helperText: "Hello Bar will display after 15 seconds",
      },
    ],
    willPersist: true,
  },
  marquee_effect: {
    value: false,
    touched: false,
    error: "",
  },
  allow_to_close: {
    value: false,
    touched: false,
    error: "",
    willPersist: true,
  },
  make_it_sticky: {
    value: false,
    touched: false,
    error: "",
    willPersist: true,
  },
  description: {
    label: "Description",
    value: "30% off on all clothes. Use code - FLAT30",
    touched: false,
    error: "",
    required: true,
    helperText: "",
    willPersist: true,
  },
  url: {
    label: "Url (Optional)",
    value: "",
    touched: false,
    error: "",
    helperText: "Adding Url will make your card act as a button",
    willPersist: true,
  },
  text_font_color: {
    label: "Text Font Color",
    value: createColor("#FFFFFF").css.backgroundColor,
    touched: false,
    error: "",
    color: createColor("#FFFFFF"),
  },
  background_color: {
    label: "Background Color",
    value: createColor("#000000").css.backgroundColor,
    touched: false,
    error: "",
    color: createColor("#000000"),
  },
  close_icon_color: {
    label: "Close Icon Color",
    value: createColor("#FFFFFF").css.backgroundColor,
    name: "close_icon_color",
    touched: false,
    error: "",
    color: createColor("#FFFFFF"),
  },
};

export const generateHtml = (config) => {
  const spanText = config.url.value
    ? `<a class="notice-ancher" target="_blank" href="${config.url.value}">
      <span style="color: ${config.text_font_color.value};">${config.description.value}</span>
    </a>`
    : `<span style="color: ${config.text_font_color.value};">${config.description.value}</span>`;

  const rawHtml = `<style>
  .hellobar-btnstyle1 .close {
    width: 22px;
    height: 22px;
    display: block;
    position: relative;
    margin-right: 8px;
    padding: 6px;
    border-radius: 4px;
    cursor: pointer;
  }
  .hellobar-btnstyle1 .close svg {
    width: 100%;
    height: 100%;
    display: block;
  }
  .hellobar-btnstyle1 .notice-ancher:hover {
    text-decoration: none;
  }
  .hellobar-btnstyle1 .notice-ancher:hover p,
  .hellobar-btnstyle1 .notice-ancher:hover strong,
  .hellobar-btnstyle1 .notice-ancher:hover span
  {
    text-decoration: underline;
    text-decoration-color: inherit;
  }
</style>
<div class="hellobar-btnstyle1" style="width: 100%; position: relative;">
  <div style="display: flex; align-items: center; background-color: ${
    config.background_color.value
  };">
    <div style="display: flex; flex: 1; align-items: center; justify-content: center; padding: 12px 16px;">
      ${
        config.marquee_effect.value
          ? `<marquee direction="left" style="display: block; width: fit-content; padding: 0 60px;">${spanText}</marquee>`
          : spanText
      }
    </div>
    ${
      config.allow_to_close.value
        ? `<div class="close" onclick="window.helloBar.closeHelloBar()">
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.4 14L0 12.6L5.6 7L0 1.4L1.4 0L7 5.6L12.6 0L14 1.4L8.4 7L14 12.6L12.6 14L7 8.4L1.4 14Z"
          fill="${config.close_icon_color.value}"
        />
      </svg>
    </div>`
        : ""
    }
  </div>
</div>`;

  return rawHtml;
};
