import {
  CircularProgress,
  Drawer,
  Grid,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import React from "react";
import CloseIcon from "../../../icons/CloseIcon";
import "react-image-crop/dist/ReactCrop.css";
import OrderSummary from "./OrderSummary";
import { iconStyle, rowFlexCenterAll } from "../../../styles/common";
import { EditIcon } from "../../../icons";
import useStyles from "../styles";
import PrintIcon from "../../../icons/PrintIcon";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { printDocument } from "../../../utils/documentUtil";
import WhatsappOutlinedIcon from "../../../icons/WhatsappOutlinedIcon";
import { getWhatsAppShareLink } from "../../../utils/orderUtil";

const ViewOrder = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const store = useSelector((state) => state.store.store);

  const editOrder = async () => {
    history.push(`./orders/${props.order.display_id}/edit`);
  };

  const printInvoice = async () => {
    await printDocument(store, "invoice", props.order.id);
  };
  const shareOrder = async () => {
    await getWhatsAppShareLink(props.order, store).then((link) => {
      window.open(link);
    });
  };

  return (
    <Drawer
      open={props.openViewOrder}
      onClose={() => {
        props.setOpenViewOrder(false);
        props.setAnchoredOrder(null);
      }}
      anchor="right"
      PaperProps={{
        style: {
          borderTopLeftRadius: 16,
          borderBottomLeftRadius: 16,
          padding: 52,
          paddingTop: props.loading ? 52 : 0,
          paddingRight: 42,
          width: 570,
        },
      }}
    >
      {props.loading ? (
        <Grid container style={{ position: "relative", height: "100%" }}>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              alignItems: "center",
            }}
          >
            <IconButton
              aria-label="Close"
              aria-haspopup="true"
              color="inherit"
              onClick={() => {
                props.setOpenViewOrder(false);
                props.setAnchoredOrder(null);
              }}
              style={{ marginTop: -26, outline: "none" }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>

          <Grid
            item
            xs={12}
            className={rowFlexCenterAll}
            style={{
              height: "100%",
              flexDirection: "column",
            }}
          >
            <CircularProgress color="secondary" />
          </Grid>
        </Grid>
      ) : (
        <Grid container style={{ position: "relative" }}>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
              position: "sticky",
              padding: "40px 0px 10px",
              background: "#FFFFFF",
              zIndex: 1,
              top: 0,
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <p style={{ fontWeight: 700, fontSize: 24, marginBottom: 0 }}>
                {props.order.type === "ONLINE"
                  ? props.order.display_id
                  : `${props.order.invoice_id} (${props.order.display_id})`}
              </p>
              <Tooltip title="Edit" TransitionComponent={"Fade"}>
                <span className={iconStyle} onClick={editOrder}>
                  <EditIcon stroke="#1641DB" />
                </span>
              </Tooltip>
              <Tooltip title="Share Order" TransitionComponent={"Fade"}>
                <span className={iconStyle} onClick={shareOrder}>
                  <WhatsappOutlinedIcon />
                </span>
              </Tooltip>
              {props?.order?.state !== "NEW" && (
                <Tooltip title="Print Invoice" TransitionComponent={"Fade"}>
                  <span className={iconStyle} onClick={printInvoice}>
                    <PrintIcon stroke="#1641DB" />
                  </span>
                </Tooltip>
              )}
            </div>
            <IconButton
              aria-label="Close"
              aria-haspopup="true"
              color="inherit"
              onClick={() => {
                props.setOpenViewOrder(false);
                props.setAnchoredOrder(null);
              }}
              style={{ outline: "none" }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <OrderSummary {...props} />
        </Grid>
      )}
    </Drawer>
  );
};

export default ViewOrder;
