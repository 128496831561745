import {
  CircularProgress,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { NoPicIcon, DownArrowIcon } from "../../../../../../../icons";
import UpArrowIcon from "../../../../../../../icons/UpArrowIcon";
import useStyles from "../../../../styles";
import { cdnUrl } from "../../../../../../../utils/cdnUtils";
import Controls from "../../../../../../../components/controls/Controls";
import { useSelector } from "react-redux";

const SuperProductList = ({
  optionList,
  handleSelect,
  handleUnselect,
  endIndex,
  startIndex,
  productsPerPage,
  noProductsAvailable,
  selectedProducts,
  setSelectedProducts,
  progress,
}) => {
  const classes = useStyles();
  const store = useSelector((state) => state.store.store);
  const countrySymbol = store.currency_symbol;
  const [expandedProduct, setExpandedProduct] = useState([]);
  const selected = optionList?.map((item) => item);
  const allVariantSKUs = new Set(
    selected?.flatMap((item) =>
      item.product_variants.map((variant) => variant.prod_sku)
    )
  );
  const handleExpandClick = (sku) => {
    setExpandedProduct((prev) => {
      // Toggle the expanded state for the clicked SKU
      if (prev.includes(sku)) {
        return prev.filter((item) => item !== sku); // If already expanded, close it
      } else {
        return [...prev, sku]; // If not expanded, expand it
      }
    });
  };

  return (
    <div>
      <TableContainer
        style={{ border: "1px solid #e1e1e1", marginBottom: "25px" }}
      >
        <TableHead className={classes.tablehead}>
          <TableRow className={classes.headrow}>
            <TableCell
              className={classes.tableCell}
              style={{
                width: "0%",
                marginLeft: "16px",
                padding: "0px !important",
              }}
            >
              <Checkbox
                style={{ marginLeft: "5px" }}
                checked={
                  allVariantSKUs.size > 0 &&
                  Array.from(allVariantSKUs).every((sku) =>
                    selectedProducts.some((p) => p.sku === sku)
                  )
                }
                onChange={(e) => {
                  const selectedRange = optionList.slice(startIndex, endIndex);
                  if (e.target.checked) {
                    const newSelectedProducts = selectedRange.flatMap((item) =>
                      item.product_variants.map((variant) => ({
                        ssku: item.super_prod_sku,
                        sku: variant.prod_sku,
                        product: variant,
                      }))
                    );

                    setSelectedProducts((prevSelectedProducts) => {
                      const updatedSelectedProducts = [
                        ...prevSelectedProducts,
                        ...newSelectedProducts,
                      ];
                      const uniqueSelectedProducts =
                        updatedSelectedProducts.filter(
                          (product, index, self) =>
                            index ===
                            self.findIndex(
                              (p) =>
                                p.sku === product.sku && p.ssku === product.ssku
                            )
                        );
                      return uniqueSelectedProducts;
                    });
                  } else {
                    const deselectedSKUs = selectedRange.flatMap((item) =>
                      item.product_variants.map((variant) => variant.prod_sku)
                    );

                    setSelectedProducts((prevSelectedProducts) =>
                      [...prevSelectedProducts].filter(
                        (product) => !deselectedSKUs.includes(product.sku)
                      )
                    );
                  }
                }}
              />
            </TableCell>
            <TableCell className={classes.tableCell} style={{ width: "25%" }}>
              Product Name
            </TableCell>
            <TableCell className={classes.tableCell} style={{ width: "8%" }}>
              Sale Price
            </TableCell>
            <TableCell className={classes.tableCell} style={{ width: "5%" }}>
              Sku
            </TableCell>
            <TableCell
              className={classes.tableCell}
              style={{ width: "7%" }}
            ></TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          className={progress || noProductsAvailable ? classes.tbody : ""}
        >
          {!progress ? (
            optionList.slice(startIndex, endIndex).map((tableitem, index) => {
              const isExpanded = expandedProduct.includes(
                tableitem.super_prod_sku
              );
              if (
                tableitem.product_variants &&
                tableitem.product_variants.length === 1
              ) {
                const variant = tableitem;

                return (
                  <TableRow key={variant.prod_sku}>
                    {tableitem.product_variants.map((variant, index) => (
                      <>
                        <TableCell
                          className={classes.tableCell}
                          style={{ width: "0%", paddingLeft: "16px" }}
                        >
                          <Controls.Checkbox
                            value={selectedProducts.some(
                              (product) => product.sku === variant.prod_sku
                            )}
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              const currentVariant =
                                tableitem.product_variants.find(
                                  (product) =>
                                    product.prod_sku === variant.prod_sku
                                );
                              const selectedVariantSku =
                                currentVariant.prod_sku;

                              if (isChecked) {
                                handleSelect(
                                  tableitem.product_variants,
                                  tableitem.super_prod_sku,
                                  selectedVariantSku
                                );
                              } else {
                                handleUnselect(
                                  tableitem.product_variants,
                                  tableitem.super_prod_sku,
                                  selectedVariantSku
                                );
                              }
                            }}
                          />
                        </TableCell>
                        <TableCell className={classes.tableCell} align="left">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                              marginRight: "10px",
                            }}
                          >
                            {variant.thumbnail ? (
                              <img
                                className={classes.prodimage}
                                alt="Thumbnail"
                                src={cdnUrl(variant.thumbnail, 200, 200, 1)}
                              />
                            ) : (
                              <div className={classes.noPicIcon}>
                                <NoPicIcon />
                              </div>
                            )}
                            <div>
                              <div style={{ marginBottom: 4 }}>
                                {variant.name}
                              </div>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell className={classes.tableCell} align="left">
                          {countrySymbol}
                          {variant.sale_price || variant.mrp}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="left">
                          {variant.prod_sku}
                        </TableCell>
                        <TableCell
                          className={classes.tableCell}
                          align="left"
                        ></TableCell>
                      </>
                    ))}
                  </TableRow>
                );
              }

              return (
                <React.Fragment key={tableitem.sku}>
                  <TableRow
                    style={{
                      borderBottom:
                        (index + 1) % productsPerPage === 0 ? "none" : "auto",
                      background: "#f9f9f9",
                    }}
                  >
                    <TableCell
                      className={classes.tableCell}
                      style={{ width: "0%", paddingLeft: "16px" }}
                    >
                      <Controls.Checkbox
                        value={tableitem.product_variants.every((variant) =>
                          selectedProducts.some(
                            (product) => product.sku === variant.prod_sku
                          )
                        )}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setExpandedProduct((prev) => [
                              ...(prev || []),
                              tableitem.super_prod_sku,
                            ]);
                            handleSelect(
                              tableitem.product_variants,
                              tableitem.super_prod_sku,
                              tableitem.product_variants.map(
                                (variant) => variant.prod_sku
                              )
                            );
                          } else {
                            setExpandedProduct((prev) =>
                              (prev || []).filter(
                                (sku) => sku !== tableitem.super_prod_sku
                              )
                            );
                            handleUnselect(
                              tableitem.prod_sku,
                              tableitem.super_prod_sku,
                              tableitem.product_variants.map(
                                (variant) => variant.prod_sku
                              )
                            );
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell className={classes.tableCell} align="left">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                          marginRight: "10px",
                        }}
                      >
                        {tableitem.thumbnail ? (
                          <img
                            className={classes.prodimage}
                            alt="Thumbnail"
                            src={tableitem.thumbnail}
                          />
                        ) : (
                          <div className={classes.noPicIcon}>
                            <NoPicIcon />
                          </div>
                        )}
                        <div>
                          <div style={{ marginBottom: 4 }}>
                            {tableitem.name}
                          </div>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell className={classes.tableCell} align="left">
                      {tableitem.product_variants.length > 0
                        ? (() => {
                            const salePrices = tableitem.product_variants.map(
                              (variant) => variant.sale_price
                            );
                            const minPrice = Math.min(...salePrices);
                            const maxPrice = Math.max(...salePrices);
                            return minPrice === maxPrice
                              ? `${countrySymbol}${minPrice}`
                              : `${countrySymbol}${minPrice} - {countrySymbol}${maxPrice}`;
                          })()
                        : "N/A"}
                    </TableCell>

                    <TableCell className={classes.tableCell} align="left">
                      {tableitem.super_prod_sku}
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      style={{ textAlign: "center" }}
                    >
                      <span
                        onClick={() =>
                          handleExpandClick(tableitem.super_prod_sku)
                        }
                        style={{
                          color: "#1641DB",
                          padding: "10px",
                          borderRadius: "50%",
                          background: "#EEEEEE",
                        }}
                      >
                        {isExpanded ? <UpArrowIcon /> : <DownArrowIcon />}
                      </span>
                    </TableCell>
                  </TableRow>

                  {isExpanded &&
                    tableitem.product_variants &&
                    tableitem.product_variants.length > 1 &&
                    tableitem.product_variants.map((variant) => (
                      <TableRow
                        key={variant.prod_sku}
                        style={{ borderBottom: "none" }}
                      >
                        <TableCell
                          className={classes.tableCell}
                          style={{
                            width: "40px",
                            position: "relative",
                            left: "8%",
                            padding: 0,
                          }}
                        >
                          <Controls.Checkbox
                            style={{ padding: 0 }}
                            value={selectedProducts.some(
                              (product) => product.sku === variant.prod_sku
                            )}
                            onChange={(e) => {
                              const currentVariant =
                                tableitem.product_variants.find(
                                  (product) =>
                                    product.prod_sku === variant.prod_sku
                                );
                              const selectedVariantSku =
                                currentVariant.prod_sku;

                              if (e.target.checked) {
                                handleSelect(
                                  currentVariant,
                                  tableitem.super_prod_sku,
                                  selectedVariantSku
                                );
                              } else {
                                handleUnselect(
                                  selectedVariantSku,
                                  tableitem.super_prod_sku,
                                  selectedVariantSku
                                );
                              }
                            }}
                          />
                        </TableCell>
                        <TableCell
                          className={classes.tableCell}
                          align="left"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            marginLeft: "45px",
                            paddingRight: "10px",
                            padding: 0,
                            height: "56px",
                          }}
                        >
                          {variant.thumbnail ? (
                            <img
                              className={classes.prodimage}
                              src={cdnUrl(variant.thumbnail, 200, 200, 1)}
                              alt="Variant Thumbnail"
                            />
                          ) : (
                            <div className={classes.noPicIcon}>
                              <NoPicIcon />
                            </div>
                          )}
                          <div>
                            <div>{variant.name}</div>
                          </div>
                        </TableCell>
                        <TableCell className={classes.tableCell} align="left">
                          {countrySymbol}
                          {variant.sale_price || 0}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="left">
                          {variant.prod_sku}
                        </TableCell>
                        <TableCell
                          className={classes.tableCell}
                          align="left"
                        ></TableCell>
                      </TableRow>
                    ))}
                </React.Fragment>
              );
            })
          ) : (
            <div>
              <CircularProgress style={{ color: "blue" }} />
            </div>
          )}

          {noProductsAvailable && (
            <div>
              <Typography variant="body1" color="textSecondary">
                No products available
              </Typography>
            </div>
          )}
        </TableBody>
      </TableContainer>
    </div>
  );
};

export default SuperProductList;
