import React, { Suspense } from "react";
import { useParams } from "react-router-dom";

export default function SettingSlug() {
  const { settingSlug } = useParams();
  const Component = React.lazy(() => import(`./pages/${settingSlug}`));

  return (
    <Suspense fallback={""}>
      <Component />
    </Suspense>
  );
}
