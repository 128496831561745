// Importing types
import {
  TOGGLEAPPSLOADING,
  INSTALLFAILED,
  INSTALLLOADING,
  INSTALLSUCCESS,
  CLEARAPPS,
  SETAPPS,
  LOADFAILED,
  CHECKOUTFIELDACTIONLOADING,
  SETCHECKOUTFIELDS,
  DELETECHECKOUTFIELD,
  SETPIXELVALUES,
  CHECKOUTFIELDACTIONFAILED,
  CHECKOUTFIELDACTIONSUCCESS,
  RESETCHECKOUTACTION,
  SETPLUGINCONFIG,
  CONFIGACTIONSTATUS,
  AMENDCHECKOUTFIELD,
  CLEARAPPSTATE,
} from "./appTypes";
import { CLEAR_COMPLETE, CLEAR_DATA } from "../commom/commonTypes";
// init state
const initState = {
  apps: [],
  checkoutFields: [],
  loaded: null,
  loading: false,
  installloading: false,
  installsuccess: null,
  installfailed: null,
  checkoutLoading: false,
  checkoutActionSuccess: false,
  checkoutActionFailed: false,
  checkoutMessage: "",
  pixels: {
    ga: "",
    fb: "",
    tawkto: "",
  },
  gmc: {},
  conversion: {},
  shiprocket: {},
  xpressbees: {},
  ithink: {},
  baat: {},
  delhivery: {},
  shipway: {},
  gokwik: {},
  phonepe: {},
  easebuzz: {},
  aramex: {},
  configActionStatus: "initial",
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case SETAPPS:
      return { ...state, apps: [...action.apps], loaded: true, loading: false };
    case LOADFAILED:
      return { ...state, apps: [], loaded: false, loading: false };
    case TOGGLEAPPSLOADING:
      return { ...state, loading: true };
    case INSTALLLOADING:
      return {
        ...state,
        installloading: true,
        installsuccess: null,
        installfailed: null,
      };
    case INSTALLSUCCESS:
      let applications = [...state.apps];
      applications.forEach((app) => {
        if (app.slug === action.slug) {
          app.installed = action.status;
        }
      });
      return {
        ...state,
        apps: applications,
        installsuccess: true,
        installloading: false,
      };
    case INSTALLFAILED:
      return { ...state, installfailed: true, installloading: false };
    case CHECKOUTFIELDACTIONFAILED:
      return {
        ...state,
        checkoutActionFailed: true,
        checkoutMessage: action.message,
      };
    case CHECKOUTFIELDACTIONSUCCESS:
      return {
        ...state,
        checkoutActionSuccess: true,
        checkoutMessage: action.message,
      };
    case CHECKOUTFIELDACTIONLOADING:
      return { ...state, checkoutLoading: !state.checkoutLoading };
    case DELETECHECKOUTFIELD:
      return {
        ...state,
        checkoutFields: state.checkoutFields.filter(
          (field) => field.slug !== action.payload
        ),
        checkoutLoading: false,
      };
    case AMENDCHECKOUTFIELD:
      return {
        ...state,
        checkoutFields: state.checkoutFields.map((field) => {
          if (field.slug === action.payload.slug) {
            return { slug: field.slug, ...action.payload.data };
          } else {
            return field;
          }
        }),
        checkoutLoading: false,
      };
    case SETCHECKOUTFIELDS:
      return {
        ...state,
        checkoutFields: action.payload,
        checkoutLoading: false,
      };
    case SETPIXELVALUES:
      return {
        ...state,
        pixels: {
          ...state.pixels,
          [action.payload.name]: action.payload.data,
        },
      };

    case RESETCHECKOUTACTION:
      return {
        ...state,
        checkoutLoading: false,
        checkoutActionSuccess: false,
        checkoutActionFailed: false,
        checkoutMessage: "",
      };
    case SETPLUGINCONFIG:
      let plugin = action.plugin;
      let config = {};
      action.payload.map((pair) => (config[`${pair.name}`] = pair.value));
      return { ...state, [plugin]: config };
    case CONFIGACTIONSTATUS:
      return { ...state, configActionStatus: action.payload };
    case CLEARAPPSTATE:
      if (initState.hasOwnProperty(action.payload)) {
        return {
          ...state,
          [action.payload]: {},
        };
      } else {
        return state;
      }

    case CLEARAPPS:
    case CLEAR_COMPLETE:
    case CLEAR_DATA:
      return initState;
    default:
      return state;
  }
};
export default reducer;
