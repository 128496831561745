import React from "react";

const AccessTimeIcon = ({ width = "22px", height = "22px" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 0.291666C10.468 0.291666 8.95087 0.588045 7.5354 1.16388C6.11994 1.73972 4.83381 2.58373 3.75046 3.64773C1.56254 5.79659 0.333374 8.71106 0.333374 11.75C0.333374 14.7889 1.56254 17.7034 3.75046 19.8523C4.83381 20.9163 6.11994 21.7603 7.5354 22.3361C8.95087 22.912 10.468 23.2083 12 23.2083C15.0942 23.2083 18.0617 22.0011 20.2496 19.8523C22.4375 17.7034 23.6667 14.7889 23.6667 11.75C23.6667 10.2453 23.3649 8.75527 22.7786 7.36509C22.1923 5.9749 21.333 4.71174 20.2496 3.64773C19.1663 2.58373 17.8801 1.73972 16.4647 1.16388C15.0492 0.588045 13.5321 0.291666 12 0.291666ZM16.9 16.5625L10.8334 12.8958V6.02083H12.5834V11.9792L17.8334 15.0729L16.9 16.5625Z"
        fill="#FF6060"
      />
    </svg>
  );
};
export default AccessTimeIcon;
