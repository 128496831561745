import axios from "axios";
import { getDefaultStore } from "../../utils/storeUtil";
import {
  FETCH_CURRENCY,
  FETCH_CURRENCY_SUCCESS,
  FETCH_CURRENCY_FAILURE,
} from "./currencyTypes";

export const fetchCurrency = () => async (dispatch) => {
  dispatch({ type: FETCH_CURRENCY });

  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();

  if (store && store.store_id) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/web/currency-meta`,
        {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      dispatch({
        type: FETCH_CURRENCY_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: FETCH_CURRENCY_FAILURE,
        payload: error.message,
      });
    }
  }
};
