import { makeStyles, Paper, Typography } from "@material-ui/core";
import React from "react";
import { formWrapperLeft } from "../../../style";
import { useHtmlCollection } from "./hooks/useHtmlCollection";
import { rowFlexOnlyJustifyEnd } from "../../../../../styles/common";
import CombinedButtons from "../../../../../components/buttons/CombinedButtons";
import { useHistory } from "react-router-dom";
import MonacoEditor from "../MonacoEditor";

const useStyles = makeStyles(() => ({
  editorCtn: {},
}));

const HtmlCollection = ({ formState, record, setErrorToast }) => {
  const classes = useStyles();
  const { form, handleFormChange, disabled, onSave, loading } =
    useHtmlCollection({
      formState,
      record,
      setErrorToast,
    });
  const history = useHistory();
  return (
    <div>
      <Paper
        className={formWrapperLeft}
        style={{ width: "100%", padding: "20px 0" }}
      >
        <div className={classes.editorCtn}>
          <Typography
            style={{ fontSize: 14, marginBottom: 16, padding: "0 25px" }}
          >
            Enter HTML Code
          </Typography>
          <MonacoEditor
            code={form.html_content.value}
            onChange={(value) => {
              handleFormChange("html_content", value);
            }}
          />
        </div>
      </Paper>
      <div
        className={`${rowFlexOnlyJustifyEnd}`}
        style={{ maxWidth: 650, marginTop: 32 }}
      >
        <CombinedButtons
          solidBtnAction={onSave}
          outlinedBtnAction={() => history.goBack()}
          loading={loading}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default HtmlCollection;
