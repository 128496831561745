import React from "react";
import AddImageIcon from "../../../icons/AddImageIcon";
import useStyles from "../styles";
import { Typography } from "@material-ui/core";

const BannerImgContainer = ({
  height,
  width,
  aspectRatio,
  recommendSize = "",
  text = "Add Image",
  showSmall = false,
}) => {
  const classes = useStyles();
  return (
    <>
      <div
        className={classes.imgContainer}
        style={{
          width: width,
          height: height,
        }}
      >
        <AddImageIcon showSmall style={{ color: "#666666" }} />
        <Typography
          className={showSmall ? classes.addImgTxtSmall : classes.addImgTxt}
        >
          {text}
        </Typography>
      </div>

      {recommendSize.length > 0 && (
        <Typography className={classes.sizeNote}>
          Recommend Size - {recommendSize}
        </Typography>
      )}
    </>
  );
};

export default BannerImgContainer;
