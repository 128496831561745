import React from "react";
import { useStyles } from "./WalletStyle";
import { Paper } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import SolidButton from "../../../components/buttons/SolidButton";
import { ErrorOutlineOutlined } from "@material-ui/icons";
import CoinIcon from "../../../icons/CoinIcon";
import { Skeleton } from "@material-ui/lab";
import { useSelector } from "react-redux";

const WalletCard = ({ walletData, pointsLoading, handleCreditModal }) => {
  const classes = useStyles();
  const store = useSelector((state) => state.store.store);
  const countrySymbol = store.currency_symbol;
  return (
    <Paper className={classes.InfoContainer}>
      {walletData?.balance < 50 && (
        <div style={{ padding: "10px 0px" }}>
          <ErrorOutlineOutlined style={{ color: "#F87231" }} />
          <b> Low Wallet balance:</b> To ensure smooth Order processing and
          notification please recharge your wallet.
        </div>
      )}
      <div
        className={`${classes.flexCenterBetween} ${classes.creditContainer}`}
        style={{
          backgroundColor: walletData?.balance < 50 ? "#FFF2E2" : "#E9EEFF",
        }}
      >
        <div style={{ display: "flex", gap: "5px" }}>
          <div style={{ marginTop: 3 }}>
            <CoinIcon />
          </div>
          <div>
            <div style={{ height: "26px" }}>
              {pointsLoading ? (
                <Skeleton
                  variant="rounded"
                  animation="wave"
                  height={24}
                  width={90}
                />
              ) : (
                <div
                  style={{
                    fontSize: "20px",
                    fontWeight: 700,
                  }}
                >
                  {walletData?.balance || 0}
                </div>
              )}
            </div>
            <div style={{ fontSize: "14px", fontWeight: 600 }}>Credits</div>
          </div>
        </div>
        <div>
          <SolidButton
            onClick={handleCreditModal}
            style={{ backgroundColor: walletData?.balance < 50 && "#F87231" }}
          >
            Buy Credits
          </SolidButton>
        </div>
      </div>
      <div className={classes.flexCenterBetween} style={{ marginTop: 10 }}>
        <div style={{ fontSize: 12, fontWeight: 400, color: "#666666" }}>
          Note: <b>{countrySymbol}1 = 3</b> Credits. Min recharge amount{" "}
          <b>{countrySymbol}50</b>
        </div>
        <div>
          <InfoOutlined color="secondary" />
          <a
            href="https://www.shoopy.in/fair-use-policy"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.knowMoreText}
            style={{ textDecoration: "none" }}
          >
            Know more about credits
          </a>
        </div>
      </div>
    </Paper>
  );
};

export default WalletCard;
