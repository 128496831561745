import React from "react";
export function ProductCard({ fill = "#666666", strokeWidth = 0 }) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_27415_34364"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="18"
        height="18"
      >
        <rect width="18" height="18" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_27415_34364)">
        <rect x="3" y="3" width="12" height="1" rx="0.5" fill="#666666" />
      </g>
      <rect x="0.5" y="0.5" width="17" height="17" rx="1.5" stroke="#666666" />
      <rect
        x="3.5"
        y="6.5"
        width="2"
        height="8"
        rx="0.5"
        fill="#F9F9F9"
        stroke="#666666"
      />
      <rect
        x="7.5"
        y="6.5"
        width="3"
        height="8"
        rx="0.5"
        fill="#F9F9F9"
        stroke="#666666"
      />
      <rect
        x="12.5"
        y="6.5"
        width="2"
        height="8"
        rx="0.5"
        fill="#F9F9F9"
        stroke="#666666"
      />
    </svg>
  );
}

export function SelProductCard({ fill = "#666666", strokeWidth = 0 }) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_29158_4533"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="18"
        height="18"
      >
        <rect width="18" height="18" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_29158_4533)">
        <rect x="3" y="3" width="12" height="1" rx="0.5" fill="#1641DB" />
      </g>
      <rect x="0.5" y="0.5" width="17" height="17" rx="1.5" stroke="#1641DB" />
      <rect
        x="3.5"
        y="6.5"
        width="2"
        height="8"
        rx="0.5"
        fill="#F9F9F9"
        stroke="#1641DB"
      />
      <rect
        x="7.5"
        y="6.5"
        width="3"
        height="8"
        rx="0.5"
        fill="#F9F9F9"
        stroke="#1641DB"
      />
      <rect
        x="12.5"
        y="6.5"
        width="2"
        height="8"
        rx="0.5"
        fill="#F9F9F9"
        stroke="#1641DB"
      />
    </svg>
  );
}
