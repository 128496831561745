import React, { useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DeleteModal from "../../../../../../../components/common/DeleteModal";
import Card from "./Card";
import { BANNER, BANNER_VIDEO, HTML_COLLECTION } from "../../../../../utils";
import HtmlCollectionCardIcon from "../../../../../assets/HtmlCollectionCardIcon";

const AddedCards = ({
  addedCards,
  setAddedCards,
  handleEditCard,
  setCardsCollapsed,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const deleteIndex = useRef(null);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const newCards = addedCards;
    const [reorderedItem] = newCards.splice(result.source.index, 1);
    newCards.splice(result.destination.index, 0, reorderedItem);
    setAddedCards(newCards);
  };

  const handleDeleteCard = (index) => {
    deleteIndex.current = index;
    setShowDeleteModal(true);
  };
  return (
    <>
      <DragDropContext
        onDragEnd={onDragEnd}
        onBeforeCapture={() => setCardsCollapsed(false)}
      >
        <Droppable droppableId="parentcat" type="PARENTCAT">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{
                backgroundColor: snapshot.isDraggingOver ? "" : "",
                position: "relative",
                width: "100%",
              }}
            >
              {addedCards.map((card, index) => (
                <Draggable
                  key={index}
                  draggableId={`draggable-img-${index}`}
                  index={index}
                >
                  {(provided) => (
                    <div {...provided.draggableProps} ref={provided.innerRef}>
                      {card.type === BANNER && (
                        <Card
                          img={card.desktop_image || card.mobile_image}
                          title={card.title}
                          provided={provided}
                          onDelete={() => handleDeleteCard(index)}
                          onEdit={() => handleEditCard(index)}
                          type={BANNER}
                        />
                      )}
                      {card.type === BANNER_VIDEO && (
                        <Card
                          img={card.desktop_image || card.mobile_image}
                          title={card.title}
                          provided={provided}
                          onDelete={() => handleDeleteCard(index)}
                          onEdit={() => handleEditCard(index)}
                          type={BANNER_VIDEO}
                        />
                      )}
                      {card.type === HTML_COLLECTION && (
                        <Card
                          icon={
                            <HtmlCollectionCardIcon style={{ height: 40 }} />
                          }
                          title={card.title}
                          provided={provided}
                          onDelete={() => handleDeleteCard(index)}
                          onEdit={() => handleEditCard(index)}
                          type={HTML_COLLECTION}
                        />
                      )}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {showDeleteModal && (
        <DeleteModal
          item={{ name: "Card" }}
          onCancel={() => setShowDeleteModal(false)}
          onConfirmDelete={() => {
            setAddedCards((prev) =>
              prev.filter((_, i) => i !== deleteIndex.current)
            );
            setShowDeleteModal(false);
          }}
          isDeleting={false}
        />
      )}
    </>
  );
};

export default AddedCards;
