import React, { useState } from "react";
import { Chip, CircularProgress, IconButton, Modal } from "@material-ui/core";
import { CloseIcon } from "../../../icons";
import Controls from "../../../components/controls/Controls";
import SolidButton from "../../../components/buttons/SolidButton";
import { razorPayWallet, rechargeRequest } from "./WalletUtils";
import { circular, progressbar } from "../../../styles/common";
import { Helmet } from "react-helmet";
import { useStyles } from "./WalletStyle";
import BulbIcon from "../../../icons/BulbIcon";
import { useSelector } from "react-redux";

const AddCreditModal = ({ isOpen, onClose, setError, onPaymentSuccess }) => {
  const classes = useStyles();
  const store = useSelector((state) => state.store.store);
  const countrySymbol = store.currency_symbol;
  const [inputAmount, setInputAmount] = useState(100);
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [amountError, setAmountError] = useState(null);

  const [loading, setLoading] = useState(false);

  const handleChipClick = (amount) => {
    setSelectedAmount(amount);
    setInputAmount(amount);
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    if (!value || value.match(/^\d*$/)) {
      const intValue = value ? parseInt(value) : "";
      setInputAmount(intValue);
      setSelectedAmount(intValue);
    }
  };

  const onBlur = () => {
    if (!inputAmount || (inputAmount && Number(inputAmount) < 50)) {
      setAmountError(
        `Minimum recharge amount is ${countrySymbol}50. Please increase your amount.`
      );
    }
  };

  const onFocus = () => {
    setAmountError(null);
  };

  const handleMakePayment = () => {
    if (!inputAmount || (inputAmount && Number(inputAmount) < 50)) {
      setAmountError(
        `Minimum recharge amount is ${countrySymbol}50. Please increase your amount.`
      );
      return;
    }

    setLoading(true);
    rechargeRequest(inputAmount)
      .then((rechargeReq) =>
        razorPayWallet(rechargeReq, onPaymentSuccess, setLoading)
      )
      .catch((error) => setError(error.message))
      .finally(() => onClose());
  };

  if (loading) {
    return (
      <CircularProgress
        color="secondary"
        className={progressbar}
        classes={{ colorSecondary: circular }}
      />
    );
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <script src="https://checkout.razorpay.com/v1/checkout.js"></script>
      </Helmet>
      <Modal open={isOpen} onClose={onClose} className={classes.modal}>
        <div className={classes.upperDiv}>
          <div className={classes.flexCenterBetween}>
            <div style={{ fontSize: 20, fontWeight: 600 }}>
              Add Credit Points
            </div>
            <IconButton
              aria-label="Close"
              aria-haspopup="true"
              color="inherit"
              onClick={onClose}
            >
              <CloseIcon height={16} width={16} />
            </IconButton>
          </div>

          <div className={classes.infoDiv}>
            <BulbIcon />
            <div>
              <b>{countrySymbol}1 = 3 </b>Credit points. Min recharge amount{" "}
              <b>{countrySymbol}50</b> .
            </div>
          </div>
          <Controls.Input
            name="amount"
            label="Enter Amount"
            labelPlacement="top"
            labelFontWeight={400}
            startAdornment={countrySymbol}
            value={inputAmount}
            onBlur={onBlur}
            onFocus={onFocus}
            onChange={handleInputChange}
            error={!!amountError}
            helperText={
              amountError
                ? amountError
                : `${
                    inputAmount * 3 || 0
                  } points will be credited in your wallet.`
            }
            extraSpace={!!amountError}
          />
          <div className={classes.chipContainer}>
            {["500", "800", "1000", "2000"].map((amount) => (
              <Chip
                key={amount}
                className={classes.chip}
                label={`${countrySymbol}${amount}`}
                onClick={() => handleChipClick(amount)}
                clickable
                style={{
                  border: "1px solid  #E1E1E1",
                  backgroundColor:
                    selectedAmount === amount ? "#1641DB" : "white",
                  color: selectedAmount === amount ? "white" : "#1A1A1A",
                }}
              />
            ))}
          </div>
          <SolidButton style={{ marginBottom: 10 }} onClick={handleMakePayment}>
            Make Payment
          </SolidButton>
        </div>
      </Modal>
    </>
  );
};

export default AddCreditModal;
