import React, { useEffect, useState } from "react";
import FormModal from "../../../components/common/FormModal";
import { updateAttributes } from "../../../utils/storeUtil";
import { useDispatch, useSelector } from "react-redux";
import SolidButton from "../../../components/buttons/SolidButton";
import { Link, Typography } from "@material-ui/core";
import { rowFlexCenterAll } from "../../../styles/common";
import { useHistory } from "react-router-dom";
import { fetchStore } from "../../../redux/store/storeActions";
import { fetchApps } from "../../../redux/apps/appActions";

const Promotions = [
  {
    heading: "Gokwik Plugin is available",
    media:
      "https://storage.googleapis.com/shy-pub/static/shoopy-app/go-kwiki-banner.jpeg",
    text: [
      {
        html: "<strong>It reduces RTO</strong> by providing AI-based recommendations for payment methods, such as allowing COD, prepaid, and partial payment based on customers' past orders.",
      },
      {
        html: "Enjoy a <strong>30-day free trial,</strong> then a <strong>0.4%</strong> charge applies.",
        marginTop: 16,
      },
    ],
    button: "Activate",
    precedence: 1,
    link: {
      text: "Learn more",
      href: "https://www.gokwik.co/blog/rto-actions-by-gokwik",
    },
    onCTA: async (store, history, setOpen, dispatch, isGoKwikInstalled) => {
      const storeId = store.id;
      await updateAttributes(store.slug, "site_settings", [
        { name: "gokwik_plugin_closed_at", value: Date.now() },
      ]);
      dispatch(fetchStore());
      if (isGoKwikInstalled) {
        history.push(`/${storeId}/apps/gokwik/configure`);
      } else {
        history.push(`/${storeId}/apps/gokwik`);
      }
      setOpen(false);
    },
    criteria: (store) => {
      const closedAt =
        store?.attributes?.site_settings?.gokwik_plugin_closed_at;
      if (closedAt) {
        return false;
      }
      return true;
    },
    onClose: async (store, setOpen, dispatch) => {
      setOpen(false);
      await updateAttributes(store.slug, "site_settings", [
        { name: "gokwik_plugin_closed_at", value: Date.now() },
      ]);
      dispatch(fetchStore());
    },
  },
];

const PromotionModal = () => {
  const store = useSelector((state) => state.store.store);
  const dispatch = useDispatch();
  const history = useHistory();
  const [highlight, setHighlight] = useState(null);
  const [open, setOpen] = useState(false);

  const apps = useSelector((state) => state.apps.apps);
  useEffect(() => {
    if (!apps.length) {
      dispatch(fetchApps());
    }
    // eslint-disable-next-line
  }, [apps.length]);
  const isGoKwikInstalled = apps.some(
    (app) => app.slug === "gokwik" && app.installed
  );

  useEffect(() => {
    if (store?.plan) {
      const matchedPromotion = Promotions.filter((h) => h.criteria(store));
      const selectedHighlight =
        matchedPromotion.length > 0 ? matchedPromotion[0] : null;
      setHighlight(selectedHighlight);
      setOpen(!!selectedHighlight);
    }
  }, [store]);

  if (!highlight || !open) {
    return null;
  }

  return (
    <FormModal
      heading={highlight.heading}
      open={open}
      onClose={() => highlight.onClose(store, setOpen, dispatch)}
      hideCancel
      hideCombinedButton={true}
      hideBottomStickyButton={true}
      overlay={false}
      maxHeight={94}
    >
      <div>
        <div style={{ marginBottom: 16 }}>
          <img
            alt="Gokwik Plugin"
            style={{ width: "100%", height: 175, borderRadius: 8 }}
            src={highlight.media}
          />
        </div>
        {highlight.text.map((textItem, index) => (
          <Typography
            key={index}
            style={{
              fontSize: 14,
              fontWeight: 400,
              marginTop: textItem.marginTop || 0,
            }}
          >
            <span dangerouslySetInnerHTML={{ __html: textItem.html }} />
          </Typography>
        ))}
        {highlight.link && (
          <Typography style={{ fontSize: 14, marginTop: 8 }}>
            <Link
              style={{
                fontWeight: 600,
                textDecoration: "none",
                color: "#0044CC",
              }}
              target="_blank"
              href={highlight.link.href}
            >
              {highlight.link.text}
            </Link>
          </Typography>
        )}
        <div
          style={{ marginBottom: 24, marginTop: 16 }}
          className={rowFlexCenterAll}
        >
          <SolidButton
            onClick={() =>
              highlight.onCTA(
                store,
                history,
                setOpen,
                dispatch,
                isGoKwikInstalled
              )
            }
            style={{ height: 36 }}
          >
            {highlight.button}
          </SolidButton>
        </div>
      </div>
    </FormModal>
  );
};

export default PromotionModal;
