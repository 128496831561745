// icon:bx-mobile | Boxicons https://boxicons.com/ | Atisa
import * as React from "react";

export default function ResetIcon(props) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7 14C5.05556 14 3.40278 13.3194 2.04167 11.9583C0.680556 10.5972 0 8.94444 0 7H1.5C1.5 8.51389 2.03819 9.80903 3.11458 10.8854C4.19097 11.9618 5.48611 12.5 7 12.5C8.51389 12.5 9.80903 11.9618 10.8854 10.8854C11.9618 9.80903 12.5 8.51389 12.5 7C12.5 5.48611 11.9618 4.19097 10.8854 3.11458C9.80903 2.03819 8.51389 1.5 7 1.5C6.13889 1.5 5.34342 1.67778 4.6136 2.03333C3.8838 2.38889 3.26954 2.87778 2.77083 3.5H5V5H0V0H1.5V2.70833C2.13889 1.875 2.93403 1.21528 3.88542 0.729167C4.83681 0.243056 5.875 0 7 0C7.97222 0 8.88287 0.184722 9.73194 0.554166C10.581 0.923611 11.3199 1.42269 11.9486 2.0514C12.5773 2.68009 13.0764 3.41898 13.4458 4.26806C13.8153 5.11713 14 6.02778 14 7C14 7.97222 13.8153 8.88287 13.4458 9.73194C13.0764 10.581 12.5773 11.3199 11.9486 11.9486C11.3199 12.5773 10.581 13.0764 9.73194 13.4458C8.88287 13.8153 7.97222 14 7 14ZM9.08333 9.83333L6.25 7V3H7.75V6.375L10.1458 8.77083L9.08333 9.83333Z"
        fill="#1641DB"
      />
    </svg>
  );
}
