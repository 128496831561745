import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import StoreSwitcher from "../Sidebar/components/StoreSwitcher";
import useStyles from "./styles";
import { rowFlexAlignCenter } from "../../styles/common";
import ProfileIcon from "../../icons/ProfileIcon";
import {
  Badge,
  Button,
  ClickAwayListener,
  Drawer,
  IconButton,
  withStyles,
} from "@material-ui/core";
import { CircleOutIcon } from "../../icons";
import SubscriptionIcon from "../../icons/SubscriptionIcon";
import { CLEAR_COMPLETE } from "../../redux/commom/commonTypes";
import { customerLogout } from "../../redux/customer/customerActions";
import { PLANS } from "../../pages/plans/constants";
import {
  getDefaultStore,
  getStoreIdFromUrl,
  getStoreUrl,
} from "../../utils/storeUtil";
import RoadmapIcon from "../../icons/RoadmapIcon";
import { fetchPlans, getPlanRenewalInfo } from "../../redux/store/storeActions";
import { isCustomDomain } from "../../utils/utils";
import { getDay } from "../../utils/dateUtils";
import Notification from "./Notification";
import {
  fetchNotifications,
  newNotificationsCount,
  readNotification,
} from "./NotificationUtils";
import { NotificationsOutlined } from "@material-ui/icons";

const SHOOPY_FEEDBACK_URL = "https://feedback.shoopy.in";
const StyledBadge = withStyles(() => ({
  dot: {
    backgroundColor: "red",
  },
}))(Badge);
const Header = () => {
  const classes = useStyles();
  const header = useSelector((state) => state.header);
  const store = useSelector((state) => state.store.store);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const NOTIFICATIONS_PAGE_SIZE = 8;
  const [openProfileMenu, setOpenProfileMenu] = React.useState(false);
  const [renewObj, setRenewObj] = useState({});
  const [showUpgradeBtn, setShowUpgradeBtn] = useState(true);
  const [openNotification, setOpenNotification] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [notificationLoading, setNotificationLoading] = useState(false);
  const [notificationCount, setNotificationCount] = useState(null);
  const localStore = getDefaultStore();
  const storeId = getStoreIdFromUrl();
  const customDomain = isCustomDomain();

  useEffect(() => {
    if (store && store.id && PLANS[store.plan]?.weight > 0) {
      dispatch(getPlanRenewalInfo(store.id)).then((renewInfo) => {
        setRenewObj(renewInfo);
      });
    }
    dispatch(fetchPlans());
    setNotificationLoading(true);
    fetchNotifications({ page: 0, size: NOTIFICATIONS_PAGE_SIZE })
      .then((notifications) => {
        setNotifications(notifications);
      })
      .finally(() => {
        setNotificationLoading(false);
      });
    newNotificationsCount().then((count) => {
      setNotificationCount(count);
    });
  }, [store]);

  const redirectPlans = () => {
    setOpenProfileMenu(false);
    history.push(
      customDomain
        ? `/admin/${storeId}/billing/plans`
        : `/${storeId}/billing/plans`
    );
  };

  const redirectRenewPlan = () => {
    setOpenProfileMenu(false);
    history.push(
      customDomain
        ? `/admin/${storeId}/billing/plans/info`
        : `/${storeId}/billing/plans/info`
    );
  };

  const initList = [
    {
      icon: <SubscriptionIcon stroke="#000000" />,
      label: "My Billing Info",
      hideCustomDomain: true,
      onClick: redirectRenewPlan,
    },
    {
      icon: <RoadmapIcon stroke="#000000" />,
      label: "Shoopy Roadmap",
      hideCustomDomain: true,
      onClick: () => {
        setOpenProfileMenu(false);
        window.open(SHOOPY_FEEDBACK_URL, "_blank");
      },
    },
    {
      icon: <CircleOutIcon stroke="#FF1D1D" />,
      label: "Sign Out",
      color: "#FF1D1D",
      onClick: () => {
        setOpenProfileMenu(false);
        dispatch(customerLogout());
        dispatch({
          type: CLEAR_COMPLETE,
          payload: {},
        });
        history.push(customDomain ? "/admin/login" : "/login");
      },
    },
  ];

  const [list, setList] = useState(initList);
  useEffect(() => {
    let storeUser = getDefaultStore();
    if (storeUser && storeUser.role && storeUser.role !== "SUPER_ADMIN") {
      let modList = initList.filter((li) => li.label !== "Billing Plans");
      setList(modList);
      setShowUpgradeBtn(false);
    }
  }, [storeId]);

  const storeUrl = getStoreUrl(store);

  // Determine if we are on the billing page
  const isBillingPage = location.pathname.includes("/billing/plans");
  const handlePageChange = (page) => {
    setCurrentPage(page);
    setNotificationLoading(true);
    fetchNotifications({ page: page - 1, size: NOTIFICATIONS_PAGE_SIZE }).then(
      (notifications) => {
        setNotifications(notifications);
        setNotificationLoading(false);
      }
    );
  };
  const onClickNotification = async (id, detail_link, status) => {
    try {
      if (detail_link) {
        window.open(detail_link, "_blank");
      }
      if (status === "UNREAD") {
        const response = await readNotification(id);
        if (response?.status === 200) {
          setNotifications((prevNotifications) => {
            const updatedContent = prevNotifications.content.map(
              (notification) =>
                notification.id === id
                  ? { ...notification, status: "READ" }
                  : notification
            );
            return {
              ...prevNotifications,
              content: updatedContent,
            };
          });
        } else {
          console.error("Error marking notification as READ:", response);
        }
      }

      // Open the link if available
    } catch (error) {
      console.error("Error in onClickNotification:", error);
    }
  };
  const onClose = () => {
    setOpenNotification(false);
    newNotificationsCount().then((count) => {
      setNotificationCount(count);
    });
  };
  return (
    <>
      <div className={classes.root}>
        <div className={classes.title}>{header.title}</div>
        <div className={rowFlexAlignCenter}>
          {!isBillingPage && showUpgradeBtn ? (
            <PlanComponent
              store={store}
              redirectPlans={redirectPlans}
              renewPlan={redirectRenewPlan}
              currentPlan={store?.plan}
              renewObj={renewObj}
            />
          ) : (
            <></>
          )}
          <StoreSwitcher storeUrl={storeUrl} />
          <Divider />
          <IconButton
            onClick={() => setOpenNotification(true)}
            style={{
              marginLeft: "-12px",
              marginRight: "-12px",
            }}
            disabled={notificationLoading}
          >
            <Badge
              variant="dot"
              badgeContent={notificationCount}
              classes={{ root: classes.badge }}
            >
              <NotificationsOutlined style={{ color: "#1C1B1F" }} />
            </Badge>
          </IconButton>
          <Divider />
          <ClickAwayListener
            onClickAway={() => {
              setOpenProfileMenu(false);
            }}
          >
            <div style={{ position: "relative" }}>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => setOpenProfileMenu(!openProfileMenu)}
              >
                <ProfileIcon />
              </div>
              <div
                className={classes.arrow}
                style={{
                  visibility: openProfileMenu ? "visible" : "hidden",
                }}
              />
              <div
                className={classes.sheet}
                style={{
                  visibility: openProfileMenu ? "visible" : "hidden",
                  width: localStore?.email
                    ? `${Math.min(
                        300,
                        Math.max(150, localStore.email.length * 8 + 90)
                      )}px`
                    : "203px", // Default width if no email
                }}
              >
                <div className={classes.profileCard}>
                  <ProfileIcon size={34} />
                  <div style={{ marginLeft: 12 }}>
                    <div>{localStore?.name}</div>
                    <div className={classes.phone}>
                      {localStore?.mobile
                        ? localStore.mobile
                        : localStore?.email}
                    </div>
                  </div>
                </div>
                {list
                  .filter(
                    (obj) =>
                      !customDomain || (customDomain && !obj.hideCustomDomain)
                  )
                  .map((obj, i) => (
                    <div key={i} className={classes.item} onClick={obj.onClick}>
                      <div className={classes.icon}>{obj.icon}</div>
                      <div
                        className={classes.label}
                        style={{ color: obj.color }}
                      >
                        {obj.label}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </ClickAwayListener>
        </div>
      </div>
      <Drawer
        open={openNotification}
        anchor="right"
        PaperProps={{
          style: {
            width: 570,
          },
        }}
      >
        <Notification
          onClose={onClose}
          notifications={notifications}
          handlePageChange={handlePageChange}
          notificationLoading={notificationLoading}
          currentPage={currentPage}
          onClickNotification={onClickNotification}
          setOpenNotification={setOpenNotification}
        />
      </Drawer>
    </>
  );
};

const Divider = () => {
  const classes = useStyles();
  return <div className={classes.divider}></div>;
};

const PlanComponent = ({
  store,
  redirectPlans,
  renewPlan,
  currentPlan,
  renewObj,
}) => {
  const classes = useStyles();
  const calculateRemainingDays = (renewDate) => {
    if (!renewDate) return null;
    const renewTime = getDay(new Date(renewDate)).getTime();
    const nowTime = getDay(new Date()).getTime();
    return Math.floor((renewTime - nowTime) / (1000 * 3600 * 24));
  };
  const dueIn = renewObj ? calculateRemainingDays(renewObj.renew_date) : null;

  const getCTA = () => {
    if (
      store &&
      currentPlan &&
      PLANS[currentPlan]?.weight === 0 &&
      store.partner_slug !== "ninjacart"
    ) {
      return (
        <div className={rowFlexAlignCenter}>
          <Button
            onClick={redirectPlans}
            disableRipple
            className={classes.upgrade}
          >
            Select Plan
          </Button>
        </div>
      );
    }
    if (dueIn < 0) {
      const daysAgo = Math.abs(dueIn);
      return (
        <Button
          onClick={renewPlan}
          disableRipple
          className={classes.expired}
          style={{ marginRight: "10px" }}
        >
          Plan Expired {daysAgo}
          <span style={{ marginLeft: "2px" }}>
            {daysAgo === 1 ? "day ago" : "days ago"}
          </span>
        </Button>
      );
    }

    if (dueIn <= 30) {
      let message;
      let className;
      if (dueIn === 0) {
        message = "Plan Expires Today";
        className = classes.dueInWarn;
      } else if (dueIn > 0) {
        message = `Plan expires In - ${dueIn} ${dueIn === 1 ? "day" : "days"}`;
        className = classes.dueInWarn;
      } else {
        return null;
      }
      return (
        <Button onClick={renewPlan} disableRipple className={className}>
          {message}
        </Button>
      );
    }
    return null;
  };
  const cta = getCTA();
  if (!cta) return <></>;
  return <>{cta}</>;
};

export default Header;
