import PaymentIcon from "@material-ui/icons/Payment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormModal from "../../../components/common/FormModal";
import Controls from "../../../components/controls/Controls";
import {
  recordOrderPayment,
  startOrderLoader,
  stopOrderLoader,
} from "../../../redux/orders/orderActions";
import { toLocaleISOString } from "../../../utils/dateUtils";
import useStyles from "../styles";

const RecordOrderPaymentModal = ({
  openRecordPayment,
  setOpenRecordPayment,
  order,
  setReloadPayment,
  reloadPayment,
  setToastRec,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const paymentModes = [
    { id: "CASH", title: "Cash" },
    { id: "WALLET", title: "Wallet" },
    { id: "UPI", title: "UPI" },
    { id: "CARD", title: "Card" },
    { id: "CHEQUE", title: "Cheque" },
    { id: "NETBANKING", title: "Net Banking" },
    { id: "OTHER", title: "Other" },
  ];
  const store = useSelector((state) => state.store.store);
  const countrySymbol = store.currency_symbol;
  const [invDateError, setInvDateError] = useState(false);
  const [error, setError] = useState("");
  const [invDateFocus, setInvDateFocus] = useState(false);
  const [btnError, setBtnError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [payment, setPayment] = useState({
    amount: order.due_amount.toFixed(2),
    createdAt: new Date().toISOString(),
    order_id: null,
    payment_type: "none",
    reference_id: "",
    notes: "",
    id: null,
  });

  const onComplete = () => {
    if (error || invDateError) {
      return;
    }
    if (payment.amount.trim() === "") {
      setError("Enter the paid amount");
      return;
    }
    if (!payment.payment_type || payment.payment_type === "none") {
      setBtnError(true);
      return;
    }
    dispatch(startOrderLoader());
    setLoading(true);
    dispatch(
      recordOrderPayment({ ...payment, order_id: order.id }, order.id)
    ).then(() => {
      setReloadPayment(!reloadPayment);
      setToastRec(true);
      dispatch(stopOrderLoader());
      setLoading(false);
      setPayment({
        amount: order.due_amount.toFixed(2),
        createdAt: new Date().toISOString(),
        order_id: null,
        payment_type: "none",
        reference_id: "",
        notes: "",
        id: null,
      });
      setOpenRecordPayment(false);
    });
  };

  return (
    <FormModal
      open={openRecordPayment}
      onClose={() => {
        setPayment({
          amount: order.due_amount.toFixed(2),
          createdAt: new Date().toISOString(),
          order_id: null,
          payment_type: "none",
          reference_id: "",
          notes: "",
          id: null,
        });
        setOpenRecordPayment(false);
      }}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      heading="Record Payment"
      btnTxt={
        <span>
          &nbsp; Record &nbsp;
          <span style={{ fontSize: 12, fontWeight: 500 }}>
            {order && parseFloat(order.due_amount) - payment.amount > 0
              ? `(Due ${countrySymbol}${(
                  parseFloat(order.due_amount) - payment.amount
                ).toFixed(2)})`
              : ""}
          </span>
        </span>
      }
      onSave={onComplete}
      startIcon={<PaymentIcon fontSize="small" />}
      loading={loading}
      btnStyle={{ minWidth: 156 }}
    >
      <div
        className={classes.dividerGrid}
        style={{ display: "block", paddingBottom: 2 }}
      >
        <Controls.Input
          name="id"
          label="Amount"
          value={payment.amount}
          onChange={(e) => {
            setPayment({ ...payment, amount: e.target.value });
            if (error) setError("");
          }}
          onFocus={() => {
            setError("");
          }}
          onBlur={() => {
            if (payment.amount.trim() === "") {
              setError("Enter the paid amount");
            }
          }}
          error={error}
          type="number"
          placeholder={`${countrySymbol}0.00`}
          onEnter={onComplete}
        />
      </div>
      <div
        className={classes.dividerGrid}
        style={{ display: "block", paddingBottom: 2 }}
      >
        <Controls.Select
          label="Payment Type"
          value={payment.payment_type}
          placeholder="Select "
          options={paymentModes}
          color="secondary"
          onChange={(e) => {
            setBtnError(false);
            setPayment({ ...payment, payment_type: e.target.value });
          }}
          error={btnError ? "Select Payment Mode" : ""}
        />
      </div>
      <div
        className={classes.dividerGrid}
        style={{ display: "block", paddingBottom: 2 }}
      >
        <Controls.Input
          InputProps={{
            defaultValue: toLocaleISOString(
              payment.created_at ? new Date(payment.created_at) : new Date()
            ),
          }}
          label="Payment Date"
          inputProps={{
            style: {
              color: invDateFocus ? "#1A1A1A" : "#999999",
              fontSize: 12,
            },
          }}
          type="date"
          variant="outlined"
          onFocus={() => {
            setInvDateError(false);
            setInvDateFocus(true);
          }}
          onChange={() => {
            if (invDateError) setInvDateError(false);
          }}
          onBlur={(e) => {
            if (Date.parse(e.target.value)) {
              setPayment({
                ...payment,
                createdAt: new Date(e.target.value).toISOString(),
              });
            } else {
              setInvDateError(true);
            }
            setInvDateFocus(false);
          }}
          onEnter={(e) => {
            if (Date.parse(e.target.value)) {
              setPayment({
                ...payment,
                createdAt: new Date(e.target.value).toISOString(),
              });
            } else {
              setInvDateError(true);
              return;
            }
            setInvDateFocus(false);
            onComplete();
          }}
          error={invDateError ? "Enter Valid Date" : ""}
        />
      </div>

      <div
        className={classes.dividerGrid}
        style={{ display: "block", paddingBottom: 2 }}
      >
        <Controls.Input
          name="id"
          label="Reference ID"
          onChange={(e) => {
            setPayment({ ...payment, reference_id: e.target.value });
          }}
          type="text"
          onEnter={onComplete}
        />
      </div>
      <div
        className={classes.dividerGrid}
        style={{ display: "block", paddingBottom: 2 }}
      >
        <Controls.Input
          name="id"
          label="Notes"
          onChange={(e) => {
            setPayment({
              ...payment,
              notes: e.target.value,
            });
          }}
          type="text"
          onEnter={onComplete}
        />
      </div>
    </FormModal>
  );
};

export default RecordOrderPaymentModal;
