export const useCardsPerRowHandler = ({ addedCards, handleFormChange }) => {
  // Utility function to calculate expected item ratios
  const calculateExpectedRatios = (numCards, cardsPerRow) => {
    const baseValue = Math.floor(12 / cardsPerRow);
    const remainder = 12 - baseValue * cardsPerRow;
    return numCards.map((_, index) =>
      index < remainder ? baseValue + 1 : baseValue
    );
  };

  // Utility function to check if selection should be disabled
  const isSelectionDisabled = (currentRatios, numCards, cardsPerRow) => {
    const expectedRatios = calculateExpectedRatios(numCards, cardsPerRow);
    return JSON.stringify(currentRatios) !== JSON.stringify(expectedRatios);
  };

  // Reusable onChange handler
  const handleCardsPerRowChange = (val, key, ratioKey) => {
    const expectedRatios = calculateExpectedRatios(addedCards, val);
    handleFormChange(key, val);
    handleFormChange(ratioKey, expectedRatios);
  };

  return {
    calculateExpectedRatios,
    isSelectionDisabled,
    handleCardsPerRowChange,
  };
};
