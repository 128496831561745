import { createColor } from "material-ui-color";

export const config = {
  where_to_display: {
    value: "home-page",
    touched: false,
    error: "",
    required: true,
    options: [
      {
        title: "Home Page",
        id: "home-page",
        helperText: "Hello bar will be displayed in Homepage only",
      },
      {
        title: "All Pages",
        id: "all-pages",
        helperText: "Hello bar will be displayed in All pages",
      },
    ],
    willPersist: true,
  },
  initial_delay: {
    value: "0",
    touched: false,
    error: "",
    required: true,
    options: [
      {
        title: "Immediately",
        id: "0",
        helperText: "Display Immediately Without Delay",
      },
      {
        title: "2 Secs",
        id: "2000",
        helperText: "Hello Bar will display after 2 seconds",
      },
      {
        title: "5 Secs",
        id: "5000",
        helperText: "Hello Bar will display after 5 seconds",
      },
      {
        title: "10 Secs",
        id: "10000",
        helperText: "Hello Bar will display after 10 seconds",
      },
      {
        title: "15 Secs",
        id: "15000",
        helperText: "Hello Bar will display after 15 seconds",
      },
    ],
    willPersist: true,
  },
  html_code: {
    label: "HTML Code",
    value: "",
    touched: false,
    error: "",
    required: true,
    helperText: "",
  },
  allow_to_close: {
    value: false,
    touched: false,
    error: "",
    willPersist: true,
  },
  make_it_sticky: {
    value: false,
    touched: false,
    error: "",
    willPersist: true,
  },
  close_icon_color: {
    label: "Close Icon Color",
    value: createColor("#1C1B1F").css.backgroundColor,
    name: "close_icon_color",
    touched: false,
    error: "",
    color: createColor("#1C1B1F"),
  },
};

export const generateHtml = (config) => {
  const html = `
  <style>
    .hello-bar-htmlstyle {
      display: flex;
      align-items: center;
    }
    .hello-bar-htmlstyle .html-wrapper {
      flex: 1;
    }
    .hello-bar-htmlstyle .close {
      width: 22px;
      height: 22px;
      display: block;
      position: relative;
      padding: 6px;
      border-radius: 4px;
      cursor: pointer;
    }
    .hello-bar-htmlstyle .close svg {
      width: 100%;
      height: 100%;
      display: block;
    }
  </style>
  <div class="hello-bar-htmlstyle" style="position: relative;">
    <div class="html-wrapper">
      ${config.html_code.value}
    </div>
     ${
       config.allow_to_close.value
         ? `<div class="close" onclick="window.helloBar.closeHelloBar()">
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.4 14L0 12.6L5.6 7L0 1.4L1.4 0L7 5.6L12.6 0L14 1.4L8.4 7L14 12.6L12.6 14L7 8.4L1.4 14Z"
          fill="${config.close_icon_color.value}"
        />
      </svg>
    </div>`
         : ""
     }
  </div>
`;

  return html;
};
