import React, { useState, useEffect, Fragment } from "react";
import SolidButton from "../../components/buttons/SolidButton";
import HeaderBreadcrumbs from "../../components/common/HeaderBreadcrumbs";
import useStyles from "./styles";
import {
  layoutBodyBox,
  layoutContainer,
  layoutLoaderHeader,
  layoutSubHeader,
} from "../../styles/common";
import {
  Grid,
  LinearProgress,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Typography,
} from "@material-ui/core";
import PageLoader from "../../components/Layout/PageLoader";
import { useHistory } from "react-router-dom";
import { AddIcon, DeleteIcon } from "../../icons";
import NoUserFigma from "../../icons/NoUserFigma";
import { useDispatch, useSelector } from "react-redux";
import { getStoreIdFromUrl } from "../../utils/storeUtil";
import Toast from "../../components/Layout/Toast";
import ConfirmModal from "../../components/common/ConfirmModal";
import {
  fetchUsers,
  setUsersPerPage,
  setUsersMaxPageSoFar,
  setUsersCurrentPage,
  clearUsers,
  deleteUser,
  setSuccessToast,
} from "../../redux/usersAndRoles/userActions";
import {
  DEFAULT_USERS_PER_PAGE,
  USERS_KEY,
  USERS_PER_PAGE,
} from "../../utils/utils";
import Paginator from "../../components/Paginator/Paginator";
import { BUSINESS_APP_PRO, PLANS } from "../plans/constants";
import PaidPopUp from "../../components/PaidPopUp";
import NoRecord from "../../components/common/NoRecord";

const Users = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const storeId = getStoreIdFromUrl();
  const userState = useSelector((state) => state.users);
  const store = useSelector((state) => state.store.store);
  const renewObj = useSelector((state) => state.store.storePlanRenewInfo);
  const users = userState.users;
  const numberOfPages = userState.numberOfPages;
  const usersPerPage = userState.usersPerPage;
  const currentPage = userState.currentPage;
  const maxPageSoFar = userState.maxPageSoFar;
  const [whiteSpaceDiv, setWhiteSpaceDiv] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeleteToast, setIsDeleteToast] = useState(false);
  const [isInfoToast, setIsInfoToast] = useState(false);
  const [isSuccessToast, setIsSuccessToast] = useState(false);
  const [isPaidPopUpOpen, setIsPaidPopUpOpen] = useState(false);

  if (userState.successToast) {
    setIsSuccessToast(true);
    dispatch(setSuccessToast(false));
  }

  const onChangeUsersPerPage = (updatedUsersPerPage) => {
    localStorage.setItem(USERS_KEY, updatedUsersPerPage);
    dispatch(clearUsers());
    dispatch(setUsersCurrentPage(0));
    dispatch(setUsersMaxPageSoFar(0));
    dispatch(setUsersPerPage(updatedUsersPerPage));
    dispatch(fetchUsers(0, updatedUsersPerPage));
  };

  useEffect(() => {
    if (!usersPerPage) {
      dispatch(setUsersPerPage(USERS_PER_PAGE));
    }
    // eslint-disable-next-line
  }, [usersPerPage]);

  useEffect(() => {
    if (
      store &&
      store.plan &&
      PLANS[store.plan].weight < PLANS.BUSINESS_APP_PRO.weight
    ) {
      setIsPaidPopUpOpen(true);
    } else if (users.length === 0) {
      dispatch(fetchUsers());
    }
    // eslint-disable-next-line
  }, [storeId]);

  const onNextPage = () => {
    setWhiteSpaceDiv(false);
    if (maxPageSoFar < currentPage + 1) {
      dispatch(fetchUsers(currentPage + 1, usersPerPage));
      dispatch(
        setUsersMaxPageSoFar(
          maxPageSoFar < currentPage + 1 ? currentPage + 1 : maxPageSoFar
        )
      );
      dispatch(setUsersCurrentPage(currentPage + 1));
    } else {
      dispatch(setUsersCurrentPage(currentPage + 1));
    }
  };

  const onPrevPage = () => {
    dispatch(setUsersCurrentPage(currentPage - 1));
  };

  const onConfirmDeletePopup = () => {
    dispatch(deleteUser(anchorEl.id));
    setAnchorEl(null);
    setIsDeleting(false);
    setIsDeleteToast(true);
  };

  const roleToText = (role) => {
    const roleMap = {
      ADMIN: "Admin",
      DELIVERY_BOY: "Delivery Boy",
      BILLING_MANAGER: "Billing Manager",
      CATALOG_MANAGER: "Catalog Manager",
      MARKETING_MANAGER: "Marketing Manager",
    };
    return roleMap[role] || role;
  };

  if (
    store &&
    store.plan &&
    PLANS[store.plan].weight < PLANS.BUSINESS_APP_PRO.weight
  ) {
    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} className={layoutLoaderHeader}>
            <div className={layoutContainer}>
              <HeaderBreadcrumbs list={[{ label: "Users And Roles" }]} />
              <NoRecord
                text={
                  <div>
                    Please{" "}
                    <span
                      className={classes.upgradeLink}
                      onClick={() => setIsPaidPopUpOpen(true)}
                    >
                      Upgrade Plan
                    </span>{" "}
                    to view Users and Roles
                  </div>
                }
                image={<NoUserFigma />}
                arrow={false}
              />
            </div>
          </Grid>
        </Grid>
        <PaidPopUp
          open={isPaidPopUpOpen}
          close={() => setIsPaidPopUpOpen(false)}
          plan={BUSINESS_APP_PRO}
          subtxt="Manage your business by adding users with their respective roles"
        />
      </div>
    );
  }
  if (userState.progress && users.length === 0) {
    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} className={layoutLoaderHeader}>
            <div className={layoutContainer}>
              <HeaderBreadcrumbs list={[{ label: "Users And Roles" }]} />
              <PageLoader />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
  return (
    <div className={layoutContainer}>
      <div className={layoutSubHeader}>
        <HeaderBreadcrumbs list={[{ label: "Users And Roles" }]} />
        <SolidButton
          startIcon={<AddIcon />}
          onClick={() => {
            users.length >=
            (!renewObj?.agent_count
              ? PLANS[store.plan]?.users
              : renewObj.agent_count)
              ? setIsInfoToast(true)
              : history.push("./users/add");
          }}
          style={{ width: 185 }}
        >
          Add User
        </SolidButton>
      </div>
      {users.length === 0 ? (
        <NoRecord
          arrow={true}
          text="Add Users based on their role"
          image={<NoUserFigma />}
        />
      ) : (
        <div style={{ position: "relative", width: "100%" }}>
          <div className={layoutBodyBox} style={{ paddingTop: 10 }}>
            <TableContainer>
              <Table>
                <TableHead className={classes.tablehead}>
                  <TableRow className={classes.headrow}>
                    <TableCell style={{ width: "30%" }}>Name</TableCell>
                    <TableCell style={{ width: "30%" }}>Phone Number</TableCell>
                    <TableCell style={{ width: "20%" }}>Role</TableCell>
                    <TableCell style={{ width: "20%", textAlign: "center" }}>
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={classes.tablebody}>
                  {users.map((tableitem, index) => (
                    <TableRow
                      key={tableitem.id}
                      style={{
                        borderBottom:
                          (index + 1) % usersPerPage === 0 ? "none" : "auto",
                      }}
                    >
                      <TableCell>{tableitem.name}</TableCell>
                      <TableCell>{tableitem.mobile}</TableCell>
                      <TableCell>{roleToText(tableitem.role)}</TableCell>
                      <TableCell style={{ width: "20%", textAlign: "center" }}>
                        <span
                          onClick={() => {
                            setWhiteSpaceDiv(true);
                            setAnchorEl(tableitem);
                            setIsDeleting(true);
                            // dispatch(deleteCoupon(tableitem.id));
                          }}
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          <DeleteIcon />
                        </span>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>

              {userState.progress && (
                <Fragment>
                  {!whiteSpaceDiv && (
                    <div
                      style={{
                        height: usersPerPage * 68 - 4,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    ></div>
                  )}
                  <LinearProgress color="secondary" />
                </Fragment>
              )}
              {users.length === 0 && (
                <div
                  style={{
                    height: USERS_PER_PAGE * 68 - 4,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    style={{
                      fontSize: 18,
                      fontWeight: 600,
                      marginTop: -34,
                    }}
                  >
                    No Users Found
                  </Typography>
                </div>
              )}
            </TableContainer>
          </div>
          <Paginator
            changeRecordsPerPage={onChangeUsersPerPage}
            possibleRecordsPerPage={[DEFAULT_USERS_PER_PAGE, 20, 50, 100]}
            recordsPerPage={usersPerPage}
            onNext={onNextPage}
            onPrev={onPrevPage}
            total={numberOfPages === 0 ? 1 : numberOfPages}
            currentPage={currentPage}
          />
        </div>
      )}
      <ConfirmModal
        open={isDeleting}
        message={<span>Are you sure you want to delete user ?</span>}
        closeBtnText="Cancel"
        confirmBtnText="Delete"
        onClose={() => {
          setIsDeleting(false);
          setAnchorEl(null);
        }}
        onConfirm={onConfirmDeletePopup}
      />
      <Toast
        open={isDeleteToast}
        close={() => setIsDeleteToast(false)}
        message="User deleted successfully"
      />
      <Toast
        open={isInfoToast}
        close={() => setIsInfoToast(false)}
        message={`Maximum ${
          !renewObj?.agent_count
            ? PLANS[store.plan]?.users
            : renewObj.agent_count
        } users allowed`}
        severity="info"
      />
      <Toast
        open={isSuccessToast}
        close={() => setIsSuccessToast(false)}
        message="User added successfully"
      />
    </div>
  );
};

export default Users;
