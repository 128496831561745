export const getPermissions = (role) => {
  const rolePermissions = {
    ADMIN: {
      heading: "Admin Permissions",
      permissions: [
        "Can Perform all Operations",
        "Can not Delete Store",
        "Can not Manage Users",
      ],
    },
    CATALOG_MANAGER: {
      heading: "Catalog Manager Permissions",
      permissions: [
        "Can Manage Store Catalog",
        "Can Create and Delete Products",
        "Can Manage Stock",
        "Can not Manage Orders/Invoices",
      ],
    },
    BILLING_MANAGER: {
      heading: "Billing Manager Permissions",
      permissions: [
        "Can Manage Orders/Invoices",
        "Can not Delete Orders/Invoices",
        "Can Manage Store Catalog",
        "Can Create and Delete Products",
        "Can Manage Stock",
      ],
    },
    MARKETING_MANAGER: {
      heading: "Marketing Manager Permissions",
      permissions: [
        "Can Manage Store Catalog",
        "Can Manage Coupons and Banners",
        "Can Create and Publish Blogs",
        "Can Create and Publish Pages",
      ],
    },
    DELIVERY_BOY: {
      heading: "Delivery Boy Permissions",
      permissions: [
        "Can Manage Order Deliveries only",
        "Can Login to Delivery App",
        "Can See only the Assigned Orders",
        "Can Mark Assigned Orders Delivered",
        "Can not Access Business App",
      ],
    },
  };
  return rolePermissions[role] || { heading: "Unknown Role", permissions: [] };
};
