import React, { useState, useEffect } from "react";
import { ClickAwayListener, Grid, Typography } from "@material-ui/core";
import useStyles from "../styles";
import { Skeleton } from "@material-ui/lab";
import { getStoreIdFromUrl } from "../../../utils/storeUtil";
import { useDispatch, useSelector } from "react-redux";
import { setInsightsFilter } from "../../../redux/dashboard/dashboardActions";
import { rowFlexJustifyBetween } from "../../../styles/common";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import TextButton from "../../../components/buttons/TextButton";
import {
  getRefereeDiscount,
  getRefereeOrder,
  getReferredDiscount,
  getReferredOrder,
  REFER_EARN_SUMMARY_FILTER,
  REFER_EARN_SUMMARY_FILTER_KEY,
} from "./referralUtils";

const ReferralDashboard = () => {
  const dispatch = useDispatch();
  const dashboardState = useSelector((state) => state.dashboard);
  const store = useSelector((state) => state.store.store);
  const countrySymbol = store.currency_symbol;
  const referEarnSummaryFilter =
    dashboardState.referEarnSummaryFilter ?? "Today";
  const [refereeOrder, setRefereeOrder] = useState(null);
  const [referredOrder, setReferredOrder] = useState(null);
  const [refereeDscount, setRefereeDiscount] = useState(null);
  const [refrredDscount, setReferredDiscount] = useState(null);
  const [openDropdown, setOpenDropdown] = React.useState(false);
  const storeId = getStoreIdFromUrl();

  useEffect(() => {
    if (!referEarnSummaryFilter) {
      dispatch(setInsightsFilter(REFER_EARN_SUMMARY_FILTER));
    }
    // eslint-disable-next-line
  }, [referEarnSummaryFilter]);

  useEffect(() => {
    if (referEarnSummaryFilter) {
      getRefereeOrder(referEarnSummaryFilter).then((val) => {
        setRefereeOrder(val);
      });
      getReferredOrder(referEarnSummaryFilter).then((val) => {
        setReferredOrder(val);
      });

      getRefereeDiscount(referEarnSummaryFilter).then((val) => {
        setRefereeDiscount(val);
      });
      getReferredDiscount(referEarnSummaryFilter).then((val) => {
        setReferredDiscount(val);
      });
    }
  }, [storeId, referEarnSummaryFilter]);

  const referralDetails = [
    {
      bgColor: "#FFE9ED",
      title: "Referee Order Value",
      value: refereeOrder ?? null,
    },
    {
      bgColor: "#E3F5ED",
      title: "Referral Order Value",
      value: referredOrder ?? null,
    },
    {
      bgColor: "#E9EEFF",
      title: "Referee Discount Used",
      value: refereeDscount ?? null,
    },
    {
      bgColor: "#FFF0DD",
      title: "Referral Discount Used",
      value: refrredDscount ?? null,
    },
  ];

  const filterOptions = [
    {
      id: "Today",
      title: "Today",
    },
    {
      id: "Yesterday",
      title: "Yesterday",
    },
    {
      id: "Last Week",
      title: "Last Week",
    },
    {
      id: "Last Month",
      title: "Last Month",
    },
  ];

  const handleFilter = (filBy) => {
    localStorage.setItem(REFER_EARN_SUMMARY_FILTER_KEY, filBy);
    dispatch(setInsightsFilter(filBy));

    setRefereeOrder(null);
    setReferredOrder(null);
    setRefereeDiscount(null);
    setReferredDiscount(null);

    getRefereeOrder(filBy).then((val) => {
      setRefereeOrder(val);
    });
    getReferredOrder(filBy).then((val) => {
      setReferredOrder(val);
    });

    getRefereeDiscount(filBy).then((val) => {
      setRefereeDiscount(val);
    });
    getReferredDiscount(filBy).then((val) => {
      setReferredDiscount(val);
    });
  };

  const classes = useStyles();
  return (
    <div style={{ padding: "0px 24px 24px" }}>
      <div
        className={rowFlexJustifyBetween}
        style={{ alignItems: "flex-start" }}
      >
        <Typography className={classes.heading}>Summary</Typography>
        <ClickAwayListener
          onClickAway={() => {
            setOpenDropdown(false);
          }}
        >
          <div style={{ position: "relative" }}>
            <TextButton
              style={{
                textDecoration: "none",
                fontSize: 12,
                fontWeight: 400,
                color: "#1A1A1A",
                "&:hover": {
                  background: "#FFFFFF !important",
                },
              }}
              className={classes.textBtn}
              endIcon={
                openDropdown ? (
                  <ExpandLess style={{ color: "#7D7D7D" }} />
                ) : (
                  <ExpandMore style={{ color: "#7D7D7D" }} />
                )
              }
              onClick={() => {
                setOpenDropdown(!openDropdown);
              }}
            >
              {referEarnSummaryFilter}
            </TextButton>
            <div
              className={classes.sheet}
              style={{
                visibility: openDropdown ? "visible" : "hidden",
                display: "block",
                width: 93,
                padding: 4,
                top: 25,
              }}
            >
              {filterOptions.map((opt) => {
                return (
                  <div
                    key={opt.id}
                    className={classes.bulkAction}
                    onClick={(e) => {
                      handleFilter(opt.id);
                      setOpenDropdown(false);
                    }}
                  >
                    <span>{opt.title}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </ClickAwayListener>
      </div>
      <Grid container spacing={2}>
        {referralDetails.map((detail, index) => {
          return (
            <Grid item xs={3} key={index}>
              <div
                className={classes.card}
                style={{ backgroundColor: detail.bgColor }}
              >
                <div>
                  {detail.value === null ? (
                    <>
                      <Skeleton animation="wave" width={70} height={25} />
                      <Skeleton animation="wave" width={120} height={20} />
                    </>
                  ) : (
                    <>
                      <Typography
                        style={{
                          fontWeight: 600,
                          fontSize: 18,
                          lineHeight: "30px",
                        }}
                      >
                        {countrySymbol}
                        {detail.value % 1 !== 0
                          ? detail.value.toFixed(2)
                          : detail.value}
                      </Typography>
                      <Typography
                        style={{
                          fontWeight: 400,
                          fontSize: 14,
                          lineHeight: "20px",
                        }}
                      >
                        {detail.title}
                      </Typography>
                    </>
                  )}
                </div>
              </div>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default ReferralDashboard;
