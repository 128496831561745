import React from "react";

export default function GenericCollectionIcon() {
  return (
    <svg
      width="73"
      height="60"
      viewBox="0 0 73 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.675581"
        y="0.675581"
        width="71.6488"
        height="58.6488"
        rx="4.72906"
        fill="white"
        stroke="#666666"
        stroke-width="1.35116"
      />
      <rect x="5" y="6" width="30" height="29" rx="2.70232" fill="#8994FF" />
      <rect x="37" y="22" width="29" height="13" rx="2.70232" fill="#FF7D7D" />
      <rect x="37" y="6" width="17" height="14" rx="2.70232" fill="#FF7D7D" />
      <rect x="57" y="6" width="9" height="14" rx="2.70232" fill="#8994FF" />
      <rect
        x="4.86719"
        y="40.4062"
        width="63.2667"
        height="4.89796"
        fill="#C4C4C4"
      />
      <rect
        x="4.86719"
        y="50.2031"
        width="63.2667"
        height="4.89796"
        fill="#C4C4C4"
      />
    </svg>
  );
}
