import React from "react";

const Devider = ({ style = {} }) => {
  return (
    <div
      style={{ height: "1px", background: "#E1E1E1", width: "100%", ...style }}
    ></div>
  );
};

export default Devider;
