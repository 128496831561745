import React from "react";
import states from "../../../../constants/states.json";
import Controls from "../../../../components/controls/Controls";
import { Typography, makeStyles } from "@material-ui/core";
import ReusablePhoneInput from "../../../../components/common/PhoneInput";

const useStyles = makeStyles(() => ({
  flexRowWrap: {
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    justifyContent: "space-between",
  },
  divider: {
    border: "1px solid #E0E0E0",
    marginBottom: "15px",
  },
}));

const LocationForm = ({
  formState,
  sameAddress,
  onBlur,
  onFocus,
  handleChange,
  handleCheckboxChange,
  setIsPhoneValid,
}) => {
  const classes = useStyles();
  return (
    <div>
      <div>
        <Typography style={{ fontWeight: 600, fontSize: 14, marginBottom: 15 }}>
          Pickup Location
        </Typography>

        <Controls.Input
          name="name"
          label="Location Name"
          type="string"
          value={formState.name.value}
          onChange={handleChange}
          labelPlacement="top"
          onBlur={onBlur}
          onFocus={onFocus}
          error={formState.name.error}
          extraSpace={!!formState.name.error}
          helperText={
            !formState.name.error &&
            "Location name is unique for a pickup address."
          }
          labelFontWeight={400}
        />

        <div className={classes.flexRowWrap}>
          <div style={{ flexBasis: "55%" }}>
            <Controls.Input
              name="registerd_name"
              label="Contact Name"
              type="string"
              labelPlacement="top"
              value={formState.registerd_name.value}
              onChange={handleChange}
              onBlur={onBlur}
              onFocus={onFocus}
              error={formState.registerd_name.error}
              extraSpace={!!formState.registerd_name.error}
              helperText={!formState.registerd_name.error && " "}
              labelFontWeight={400}
            />
          </div>

          <div style={{ flexBasis: "40%", marginTop: "-16px" }}>
            <ReusablePhoneInput
              value={formState.phone.value}
              onChange={(value) =>
                handleChange({ target: { name: "phone", value } })
              }
              onValidation={(isValid) => setIsPhoneValid(isValid)}
              helperText=""
              onlyCountries={["in"]}
            />
          </div>
        </div>
        <div style={{ marginTop: -20 }}>
          <Controls.Input
            name="address"
            label="Address"
            type="string"
            value={formState.address.value}
            onChange={handleChange}
            maxLength={255}
            labelPlacement="top"
            onBlur={onBlur}
            onFocus={onFocus}
            error={formState.address.error}
            extraSpace={!!formState.address.error}
            helperText={!formState.address.error && " "}
            labelFontWeight={400}
          />
        </div>

        <div
          className={classes.flexRowWrap}
          style={{ marginTop: -20, marginBottom: -20 }}
        >
          <div style={{ flexBasis: "30%" }}>
            <Controls.Input
              name="pin"
              maxLength={6}
              label="Pincode"
              type="number"
              value={formState.pin.value}
              onChange={handleChange}
              labelPlacement="top"
              onBlur={onBlur}
              onFocus={onFocus}
              error={formState.pin.error}
              extraSpace={!!formState.pin.error}
              helperText={!formState.pin.error && " "}
              labelFontWeight={400}
            />
          </div>

          <div style={{ flexBasis: "30%" }}>
            <Controls.Input
              name="city"
              label="City"
              type="string"
              value={formState.city.value}
              onChange={handleChange}
              labelPlacement="top"
              maxLength={64}
              onBlur={onBlur}
              onFocus={onFocus}
              error={formState.city.error}
              extraSpace={!!formState.city.error}
              helperText={!formState.city.error && " "}
              labelFontWeight={400}
            />
          </div>

          <div style={{ flexBasis: "30%", marginBottom: 15 }}>
            <Controls.Select
              name="state"
              label="State"
              color="secondary"
              labelPlacement="top"
              value={formState.state.value}
              placeholder="Select State"
              options={states.map((state) => ({ id: state, title: state }))}
              onChange={handleChange}
              onBlur={onBlur}
              onFocus={onFocus}
              error={formState.state.error}
              extraSpace={!!formState.state.error}
              helperText={!formState.state.error && " "}
              labelFontWeight={400}
            />
          </div>
        </div>
      </div>

      <div>
        <Typography style={{ fontWeight: 600, fontSize: 14 }}>
          Return Location
        </Typography>

        <div style={{ marginTop: 5 }}>
          <Controls.Checkbox
            name="same_address"
            label={
              <span style={{ fontWeight: 400 }}>Same as pickup location</span>
            }
            checkboxStyles={{ padding: 0, marginLeft: 11, marginRight: 12 }}
            value={sameAddress}
            color="secondary"
            onChange={handleCheckboxChange}
          />
        </div>

        {!sameAddress && (
          <div>
            <Controls.Input
              name="return_address"
              label="Address"
              type="string"
              value={formState.return_address.value}
              onChange={handleChange}
              maxLength={255}
              labelPlacement="top"
              onBlur={onBlur}
              onFocus={onFocus}
              error={formState.return_address.error}
              extraSpace={!!formState.return_address.error}
              helperText={!formState.return_address.error && " "}
              labelFontWeight={400}
            />
            <div
              className={classes.flexRowWrap}
              style={{ marginBottom: 15, marginTop: -20 }}
            >
              <div style={{ flexBasis: "30%" }}>
                <Controls.Input
                  name="return_pin"
                  maxLength={6}
                  label="Pincode"
                  type="number"
                  value={formState.return_pin.value}
                  onChange={handleChange}
                  labelPlacement="top"
                  onBlur={onBlur}
                  onFocus={onFocus}
                  error={formState.return_pin.error}
                  extraSpace={!!formState.return_pin.error}
                  helperText={!formState.return_pin.error && " "}
                  labelFontWeight={400}
                />
              </div>

              <div style={{ flexBasis: "30%" }}>
                <Controls.Input
                  name="return_city"
                  label="City"
                  type="string"
                  value={formState.return_city.value}
                  onChange={handleChange}
                  labelPlacement="top"
                  maxLength={64}
                  onBlur={onBlur}
                  onFocus={onFocus}
                  error={formState.return_city.error}
                  extraSpace={!!formState.return_city.error}
                  helperText={!formState.return_city.error && " "}
                  labelFontWeight={400}
                />
              </div>

              <div style={{ flexBasis: "30%", marginBottom: 15 }}>
                <Controls.Select
                  name="return_state"
                  label="State"
                  color="secondary"
                  labelPlacement="top"
                  value={formState.return_state.value}
                  placeholder="Select State"
                  options={states.map((state) => ({ id: state, title: state }))}
                  onChange={handleChange}
                  onBlur={onBlur}
                  onFocus={onFocus}
                  error={formState.return_state.error}
                  extraSpace={!!formState.return_state.error}
                  helperText={!formState.return_state.error && " "}
                  labelFontWeight={400}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LocationForm;
