import {
  makeStyles,
  MenuItem,
  Select as MuiSelect,
  Typography,
} from "@material-ui/core";
import React from "react";
import { rowFlexAlignCenter } from "../../styles/common";
import { StarRate } from "@material-ui/icons";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  select: {
    display: "flex",
    alignItems: "center",
    fontSize: "14px !important",
  },
  menuPaper: {
    maxHeight: "350px",
  },
}));

export const CourierSelect = ({
  label = "",
  slug,
  labelPlacement = "start",
  labelFontWeight = 600,
  helperText = "",
  imgClass,
  labelStyles = {},
  errorElm = true,
  ...props
}) => {
  const classes = useStyles();
  const store = useSelector((state) => state.store.store);
  const countrySymbol = store.currency_symbol;
  const error = props.error;
  delete props.error;

  return (
    <div className={labelPlacement === "start" ? rowFlexAlignCenter : ""}>
      {label && (
        <Typography
          style={{
            fontSize: 14,
            fontWeight: labelFontWeight,
            minWidth:
              labelPlacement === "start"
                ? props.mainLabelWidth
                  ? props.mainLabelWidth
                  : props.labelWidth
                  ? props.labelWidth
                  : 110
                : 0,
            maxWidth: labelPlacement === "start" ? 110 : "auto",
            marginRight: labelPlacement === "start" ? 8 : 0,
            marginTop: labelPlacement === "start" ? -18 : 0,
            marginBottom: labelPlacement === "top" ? 12 : 0,
            ...labelStyles,
          }}
        >
          {label}
        </Typography>
      )}
      <div
        style={{
          width: props.width ? props.width : "100%",
          ...(!props.width && { flexGrow: 1 }),
        }}
      >
        <MuiSelect
          color="secondary"
          variant={props?.variant ?? "outlined"}
          error={!!error}
          fullWidth
          classes={{ select: classes.select }}
          {...props}
          renderValue={(value) => {
            if (value === "none" || !value) {
              return (
                <Typography
                  style={{
                    fontSize: 14,
                    color: "black",
                  }}
                >
                  {props.placeholder}
                </Typography>
              );
            } else {
              const item = props.options.find((item) => item.id === value);
              if (item) {
                return (
                  <Typography
                    style={{
                      fontSize: 14,
                      color: "black",
                      fontWeight: 500,
                    }}
                  >
                    {props.bulkOrder || slug === "ESHIPZ"
                      ? `${item.courier_name}`
                      : `${item.courier_name} - ${countrySymbol}${item.rate}`}
                  </Typography>
                );
              }
            }
          }}
          MenuProps={{
            classes: {
              paper: classes.menuPaper,
            },
          }}
        >
          {!props.options.length && props.placeholder && (
            <MenuItem
              key="none"
              value="none"
              style={{
                fontSize: 12,
                color: "#999999",
              }}
            >
              {props.placeholder}
            </MenuItem>
          )}
          {props.loading ? (
            <MenuItem
              key="none"
              value="none"
              style={{
                fontSize: 12,
                color: "#999999",
              }}
            >
              Loading...
            </MenuItem>
          ) : (
            props.options.map((item) =>
              props.bulkOrder ? (
                <MenuItem
                  key={item.id}
                  value={item.id}
                  style={{
                    fontSize: 12,
                    color: "#1a1a1a",
                    ...(props.indent && item.indent ? { marginLeft: 16 } : {}),
                  }}
                >
                  {item.img && (
                    <div>
                      <img
                        src={item.img}
                        alt={item.title}
                        className={imgClass}
                      />
                    </div>
                  )}
                  {item.courier_name}{" "}
                </MenuItem>
              ) : (
                <MenuItem
                  style={{
                    borderBottom: "1px solid #E0E0E0",
                  }}
                  key={item.id}
                  value={item.id}
                >
                  <div
                    style={{
                      display: "flex",
                      flexFlow: "column nowrap",
                      marginLeft: -10,
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexFlow: "row nowrap",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: 12,
                          color: "#666666",
                          fontWeight: 600,
                        }}
                      >
                        {item.courier_name}
                      </Typography>
                      {item.rating > 0 && (
                        <div
                          style={{
                            display: "flex",
                            flexFlow: "row nowrap",
                            alignItems: "center",
                            marginRight: -35,
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: 12,
                              color: "#666666",
                            }}
                          >
                            {item.rating}
                          </Typography>
                          <StarRate
                            style={{
                              color: "#FFC107",
                              fontSize: 20,
                              marginRight: 25,
                            }}
                          />
                        </div>
                      )}
                    </div>

                    {/* Conditional Rendering for fields based on slug */}
                    {slug !== "ESHIPZ" && (
                      <>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: 12,
                              color: "#666666",
                              width: "40%",
                            }}
                          >
                            Courier fee :{" "}
                            <span
                              style={{
                                fontWeight: 600,
                              }}
                            >
                              {countrySymbol}
                              {item.freight_charge}
                            </span>
                          </Typography>
                          <Typography
                            style={{
                              fontSize: 12,
                              color: "#666666",
                              width: "35%",
                            }}
                          >
                            COD fee :{" "}
                            <span
                              style={{
                                fontWeight: 600,
                              }}
                            >
                              {countrySymbol}
                              {item.cod_charges}
                            </span>
                          </Typography>

                          <Typography
                            style={{
                              fontSize: 12,
                              color: "#666666",
                              width: "25%",
                            }}
                          >
                            Total fee :{" "}
                            <span
                              style={{
                                fontWeight: 600,
                              }}
                            >
                              {countrySymbol}
                              {item.rate}
                            </span>
                          </Typography>
                        </div>
                      </>
                    )}
                  </div>
                </MenuItem>
              )
            )
          )}
        </MuiSelect>
        {helperText && (
          <Typography style={{ color: "#666666", fontSize: 12, margin: 2 }}>
            {helperText}
          </Typography>
        )}
        {errorElm && (
          <Typography
            style={{ color: error ? "#F44336" : "#FFFFFF", fontSize: 12 }}
          >
            {error ? error : "\u00A0"}
          </Typography>
        )}
      </div>
    </div>
  );
};
