import React from "react";
import GokwikBuyIcon from "../../../../icons/GokwikBuyIcon";
import SolidButton from "../../../../components/buttons/SolidButton";
import { Box, Button, Typography } from "@material-ui/core";
import AccessTimeIcon from "../../../../icons/AccessTimeIcon";
import styles from "../gokwik.module.css";

const GokwikHeder = ({
  handleActivate,
  setOpenStartFree,
  daysRemaining,
  isExpired,
  status,
  endDate,
  trailLoading,
  activateLoading,
  gokwikSettings,
}) => {
  const currentDate = new Date();
  const trialEndDate = endDate ? new Date(endDate) : null;
  const isTrialExpired =
    isExpired || (trialEndDate && trialEndDate < currentDate);
  const isTrialActive = gokwikSettings.some(
    (setting) => setting.name === "status" && setting.value === "TRIAL"
  );
  return (
    <>
      {!status ? (
        <div className={styles.headerSection}>
          <div className={styles.headerContent}>
            <GokwikBuyIcon />
            <div>
              <h2 className={styles.headerText}>
                Buy Plan Now or Enjoy a 30-Day Free Trial
              </h2>
              <p className={styles.subtext}>
                Secure COD orders, reduce RTO, and experience GoKwik risk-free
                for 30 days.
              </p>
              <div className={styles.buttonGroup}>
                <SolidButton
                  style={{
                    fontSize: 12,
                    padding: "15px 0px",
                    borderRadius: "44px",
                    height: 28,
                    minWidth: 111,
                  }}
                  onClick={() => handleActivate("ACTIVATED")}
                >
                  Activate Now
                </SolidButton>
                <Button
                  variant="text"
                  className={styles.trialButton}
                  onClick={() => setOpenStartFree(true)}
                  loading={trailLoading}
                >
                  Start a 30 days free trial
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : isTrialExpired ? (
        <div
          style={{
            backgroundColor: "#FFE7E7",
            padding: "12px 12px 16px 12px",
            borderRadius: "8px",
            marginBottom: "20px",
            marginTop: "24px",
          }}
        >
          <Box style={{ display: "flex" }}>
            <AccessTimeIcon width="24px" height="24px" />
            <Box style={{ marginLeft: 8 }}>
              <Typography style={{ fontWeight: 600, fontSize: "14px" }}>
                Trial expired
              </Typography>
              <Typography style={{ fontSize: "12px", color: "#666666" }}>
                Your trial period has ended. Purchase a plan now to continue
                making changes and unlock all features without interruptions.
              </Typography>
            </Box>
          </Box>
          <div style={{ marginTop: "12px", marginLeft: "33px" }}>
            <SolidButton
              style={{
                fontSize: 12,
                padding: "15px 0px",
                borderRadius: "44px",
                height: 28,
                minWidth: 84,
              }}
              onClick={() => handleActivate("ACTIVATED")}
              loading={activateLoading}
            >
              Upgrade
            </SolidButton>
          </div>
        </div>
      ) : isTrialActive ? (
        <div
          style={{
            backgroundColor: "#FFEACC",
            padding: "12px 12px 16px 12px",
            borderRadius: "8px",
            marginBottom: "20px",
            marginTop: "24px",
          }}
        >
          <Box style={{ display: "flex" }}>
            <AccessTimeIcon />
            <Box style={{ marginLeft: 8 }}>
              <Typography style={{ fontWeight: 600, fontSize: "14px" }}>
                Trial {daysRemaining} days left
              </Typography>
              <Typography style={{ fontSize: "12px", color: "#666666" }}>
                You're enjoying your 30-day free trial. Cancel anytime to avoid
                automatic plan activation.
              </Typography>
            </Box>
          </Box>
          <div style={{ marginTop: "12px", marginLeft: "33px" }}>
            <SolidButton
              style={{
                fontSize: 12,
                padding: "15px 0px",
                borderRadius: "44px",
                height: 28,
                minWidth: 111,
              }}
              onClick={() => handleActivate("ACTIVATED")}
              loading={activateLoading}
            >
              Activate Now
            </SolidButton>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default GokwikHeder;
