import React from "react";

export default function HtmlCollectionIcon() {
  return (
    <svg
      width="73"
      height="60"
      viewBox="0 0 73 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.675581"
        y="0.675581"
        width="71.6488"
        height="58.6488"
        rx="4.72906"
        fill="white"
        stroke="#666666"
        stroke-width="1.35116"
      />
      <rect x="5" y="6" width="63" height="29" rx="2.70232" fill="#8994FF" />
      <path
        d="M32.35 23.53L25.644 20.562V19.694L32.35 16.334V17.776L27.45 20.058L32.35 22.088V23.53ZM38.9857 15.004L35.2617 25H33.6237L37.3477 15.004H38.9857ZM40.2338 22.088L45.1338 20.072L40.2338 17.776V16.334L46.9398 19.694V20.562L40.2338 23.53V22.088Z"
        fill="white"
      />
      <rect
        x="4.86719"
        y="40.4062"
        width="63.2667"
        height="4.89796"
        fill="#C4C4C4"
      />
      <rect
        x="4.86719"
        y="50.2031"
        width="63.2667"
        height="4.89796"
        fill="#C4C4C4"
      />
    </svg>
  );
}
