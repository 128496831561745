// icon:bx-mobile | Boxicons https://boxicons.com/ | Atisa
import * as React from "react";

export default function PcIcon(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.9707 14.9091L17.5162 14.9091C18.3195 14.9091 18.9707 14.2171 18.9707 13.3636V2.54541C18.9707 1.69187 18.3195 0.99995 17.5162 0.99995L2.9707 0.99995C2.16738 0.99995 1.51616 1.69187 1.51616 2.54541V13.3636C1.51616 14.2171 2.16738 14.9091 2.9707 14.9091Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.1531 18H7.33496"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
