import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  FormControlLabel,
  Typography,
  Link,
  makeStyles,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import styles from "./gokwik.module.css";
import {
  centerContainer,
  layoutCenterContainer,
  rowFlexCenterAll,
} from "../../../styles/common";
import HeaderBreadcrumbs from "../../../components/common/HeaderBreadcrumbs";
import SolidButton from "../../../components/buttons/SolidButton";
import GokwikWorksIcon from "../../../icons/GokwikWorksIcon";
import FormModal from "../../../components/common/FormModal";
import CODRiskComponent from "../components/GokwikRiskOrder";
import CombinedButtons from "../../../components/buttons/CombinedButtons";
import { useHistory } from "react-router-dom";
import {
  getStoreAttributes,
  getStoreIdFromUrl,
} from "../../../utils/storeUtil";
import { useDispatch, useSelector } from "react-redux";
import {
  getPluginConfig,
  postPluginConfig,
} from "../../../redux/apps/appActions";
import Toast from "../../../components/Layout/Toast";
import PageLoader from "../../../components/Layout/PageLoader";
import GokwikHeder from "./components/GokwikHeder";
const useStyles = makeStyles((theme) => ({
  accordionSummary: {
    padding: "12px 20px",
    "& .MuiAccordionSummary-content": {
      margin: 0,
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: 0,
    },
  },
}));
const Gokwik = () => {
  const list = [
    {
      label: "App Store",
      link: "/apps",
    },
    {
      label: "GoKwik",
      link: "/apps/gokwik",
    },
    {
      label: "Settings",
    },
  ];
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const storeData = useSelector((state) => state.store);
  const storeId = getStoreIdFromUrl();
  const store = useSelector((state) => state.store.store);
  const [paymentSettings, setPaymentSettings] = useState({
    isCODEnabled: false,
    isPrepaidEnabled: false,
    isPartialPaymentAllowed: false,
  });
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openStartFree, setOpenStartFree] = useState(false);
  const [gokwikSettings, setGokwikSettings] = useState([]);
  const [openToast, setOpenToast] = useState(false);
  const [message, setMessage] = useState("");
  const [initialRiskLevels, setInitialRiskLevels] = useState(null); // Store original data
  const [isChanged, setIsChanged] = useState(false);
  const [severity, setServerity] = useState("sucess");
  const [fetching, setFetching] = useState(true);
  const [trailLoading, setTrailLoading] = useState(false);
  const [activateLoading, setActivateLoading] = useState(false);
  const [riskLevels, setRiskLevels] = useState({
    low: { COD: false, PART_PAID: false, PREPAID: false },
    medium: { COD: false, PART_PAID: false, PREPAID: false },
    high: { COD: false, PART_PAID: false, PREPAID: false },
  });

  useEffect(() => {
    if (storeData.fetched) {
      const fetchData = async () => {
        const response = await dispatch(getPluginConfig("gokwik"));
        setGokwikSettings(response);
        const isChecked = response.some(
          (item) => item.name === "trial_start_date"
        );
        setOpenStartFree(!isChecked);
        setChecked(isChecked);
        const newRiskLevels = {
          low: { COD: false, PART_PAID: false, PREPAID: false },
          medium: { COD: false, PART_PAID: false, PREPAID: false },
          high: { COD: false, PART_PAID: false, PREPAID: false },
        };

        response.forEach((item) => {
          if (["low_risk", "medium_risk", "high_risk"].includes(item.name)) {
            const riskCategory = item.name.replace("_risk", "");
            let allowedValues = [];

            try {
              // Try parsing JSON array string
              allowedValues = JSON.parse(item.value);
            } catch (error) {
              console.error("Error parsing risk values:", item.value, error);
            }

            newRiskLevels[riskCategory] = {
              COD: allowedValues.includes("COD"),
              PART_PAID: allowedValues.includes("PART_PAID"),
              PREPAID: allowedValues.includes("PREPAID"),
            };
          }
        });

        setRiskLevels(newRiskLevels);
        setInitialRiskLevels(newRiskLevels);
        setFetching(false);
      };

      fetchData();
    }
    // eslint-disable-next-line
  }, [storeData]);

  useEffect(() => {
    const fetchData = async () => {
      if (!store.slug) return;
      setFetching(true);
      const attrs = await getStoreAttributes(store.slug, "payment_settings");
      setPaymentSettings({
        isCODEnabled: attrs?.cod_enabled !== "false",
        isPrepaidEnabled:
          attrs?.shoopy_pay === "true" || attrs?.razor_pay === "true",
        isPartialPaymentAllowed: attrs?.adv_cod_payment === "true",
      });
      setFetching(false);
    };
    fetchData();
  }, [store.slug]);

  const handleCheckboxChange = (risk, field) => {
    setRiskLevels((prev) => {
      const updatedRiskLevels = {
        ...prev,
        [risk]: {
          ...prev[risk],
          [field]: !prev[risk][field],
        },
      };
      setIsChanged(
        JSON.stringify(updatedRiskLevels) !== JSON.stringify(initialRiskLevels)
      );
      return updatedRiskLevels;
    });
  };
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const onSave = () => {
    setLoading(true);

    const formatRiskLevels = (riskLevel) =>
      JSON.stringify(
        ["COD", "PART_PAID", "PREPAID"].filter((key) => riskLevel[key])
      );

    const riskLevelsPayload = {
      low_risk: formatRiskLevels(riskLevels.low),
      medium_risk: formatRiskLevels(riskLevels.medium),
      high_risk: formatRiskLevels(riskLevels.high),
    };

    const gokwikConfig = gokwikSettings.reduce((acc, item) => {
      acc[item.name] = item.value;
      return acc;
    }, {});

    const payload = {
      ...gokwikConfig,
      ...riskLevelsPayload,
    };

    dispatch(postPluginConfig("gokwik", payload)).then(
      ({ error, message, data }) => {
        setLoading(false);
        setMessage(error ? error.message : "Settings updated successfully.");
        setOpenToast(true);
        setServerity(error ? "error" : "success");
        setIsChanged(false);
        if (!error && data) {
          setGokwikSettings(data);
          setInitialRiskLevels(data);
        }
      }
    );
  };

  const handleFreeplanActivate = (type) => {
    const today = new Date();
    const trialStartDate = today.toISOString().split("T")[0];
    const trialEndDate = new Date(today.setDate(today.getDate() + 30))
      .toISOString()
      .split("T")[0];
    const updatedSettings = [
      ...gokwikSettings.filter(
        (item) =>
          !["status", "trial_start_date", "trial_end_date"].includes(item.name)
      ),
      { name: "status", value: "TRIAL" },
      { name: "trial_start_date", value: trialStartDate },
      { name: "trial_end_date", value: trialEndDate },
    ];
    setTrailLoading(true);
    const formattedConfig = Object.fromEntries(
      updatedSettings.map((item) => [item.name, item.value])
    );
    dispatch(postPluginConfig("gokwik", formattedConfig)).then((configObj) => {
      if (configObj.error) {
        setMessage(configObj.message);
        setOpenToast(true);
        setServerity("error");
        setActivateLoading(false);
      } else {
        setMessage("Free trial activated successfully.");
        setOpenToast(true);
        setServerity("success");
        setOpenStartFree(false);
        setGokwikSettings(updatedSettings);
        setChecked(true);
        setActivateLoading(false);
      }
    });
  };

  const handleActivate = (type) => {
    const today = new Date();
    const trialStartDate = today.toISOString().split("T")[0];
    const trialEndDate = new Date(today.setDate(today.getDate() + 30))
      .toISOString()
      .split("T")[0];

    let updatedSettings = gokwikSettings.filter(
      (item) =>
        !["status", "trial_start_date", "trial_end_date"].includes(item.name)
    );

    updatedSettings.push(
      { name: "status", value: type },
      { name: "trial_start_date", value: trialStartDate },
      { name: "trial_end_date", value: trialEndDate }
    );

    setActivateLoading(true);

    dispatch(
      postPluginConfig(
        "gokwik",
        Object.fromEntries(
          updatedSettings.map((item) => [item.name, item.value])
        )
      )
    ).then((configObj) => {
      if (configObj.error) {
        setMessage(configObj.message);
        setOpenToast(true);
        setActivateLoading(false);
        setServerity("error");
      } else {
        setMessage("Plan activated successfully.");
        setOpenToast(true);
        setServerity("success");
        setOpenStartFree(false);
        setGokwikSettings(updatedSettings);
        setChecked(true);
        setActivateLoading(false);
      }
    });
  };

  const isActivated = gokwikSettings.some(
    (setting) => setting.name === "status" && setting.value === "ACTIVATED"
  );
  const isExpired = gokwikSettings.some(
    (setting) => setting.name === "status" && setting.value === "EXPIRED"
  );
  const trialEndDateObj = gokwikSettings.find(
    (setting) => setting.name === "trial_end_date"
  );
  const isStatusAvailable = gokwikSettings.some(
    (setting) => setting.name === "trial_start_date"
  );

  let daysRemaining = 0;
  if (trialEndDateObj) {
    const trialEndDate = new Date(trialEndDateObj.value);
    const today = new Date();
    const timeDiff = trialEndDate - today;
    daysRemaining = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
  }
  if (fetching) {
    return <PageLoader />;
  }
  return (
    <div className={layoutCenterContainer}>
      <div className={centerContainer}>
        <div
          className={styles.breadcrumbsContainer}
          style={{ marginBottom: "24px" }}
        >
          <div className={styles.backDiv}>
            <HeaderBreadcrumbs list={list} />
          </div>
        </div>
        <GokwikHeder
          isActivated={isActivated}
          gokwikSettings={gokwikSettings}
          handleActivate={handleActivate}
          setOpenStartFree={setOpenStartFree}
          daysRemaining={daysRemaining}
          trialEndDateObj={trialEndDateObj}
          isExpired={isExpired}
          status={isStatusAvailable}
          isStatusAvailable={isStatusAvailable}
          trailLoading={trailLoading}
          activateLoading={activateLoading}
        />
        <div className={styles.howItWorks}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore style={{ padding: 0 }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ padding: "12px 20px" }}
              className={classes.accordionSummary}
            >
              <div className={styles.accordionHeader}>
                <GokwikWorksIcon />
                <Typography
                  className={styles.headerText}
                  style={{ fontWeight: 600, fontSize: 14 }}
                >
                  How it works
                </Typography>
              </div>
            </AccordionSummary>
            <div style={{ borderTop: "1px solid #E1E1E1" }}></div>
            <AccordionDetails style={{ padding: 20 }}>
              <div>
                <p>
                  It reduces RTO by providing AI-based recommendations for
                  payment methods.
                </p>
                <ul className={styles.featureList}>
                  <li className={styles.featureItem}>
                    <strong>Real-Time Analysis:</strong> Detects risks instantly
                    with ML algorithms.
                  </li>
                  <li className={styles.featureItem}>
                    <strong>Data-Driven Insights:</strong> Flags high-risk
                    orders using historical data.
                  </li>
                  <li className={styles.featureItem}>
                    <strong>Automated Risk Flagging:</strong> Identifies issues
                    to prevent fraud and returns.
                  </li>
                  <li className={styles.featureItem}>
                    <strong>Seamless Integration:</strong> Plug-and-play with
                    Shoopy for smooth operations.
                  </li>
                </ul>
                <p style={{ marginBottom: 0 }}>
                  Experience proactive risk management that protects your
                  revenue and streamlines your COD operations—
                  <span style={{ textDecoration: "underline" }}>
                    all at a minimal cost of <strong>0.4% </strong> of the COD
                    order value. For eg: if order value is ₹500, then ₹2 will be
                    deducted from your wallet.
                  </span>
                </p>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
        <div className={styles.container} style={{ marginTop: 16 }}>
          {" "}
          <Typography style={{ fontSize: 16, fontWeight: 600 }}>
            GoKwik Settings
          </Typography>
        </div>
        <div className={styles.container} style={{ marginTop: "16px" }}>
          <CODRiskComponent
            title="Low Risk COD Orders"
            description="Minimal RTO risk in processing COD orders for the customers"
            checked={checked}
            onChange={(field) => handleCheckboxChange("low", field)}
            riskLevels={riskLevels.low}
            paymentSettings={paymentSettings}
          />
        </div>
        <div className={styles.container}>
          <CODRiskComponent
            title="Medium Risk COD Order"
            description="Moderate RTO risk involved, proceed with caution for COD orders."
            checked={checked}
            onChange={(field) => handleCheckboxChange("medium", field)}
            riskLevels={riskLevels.medium}
            paymentSettings={paymentSettings}
          />
        </div>
        <div className={styles.container}>
          <CODRiskComponent
            title="High Risk COD Order"
            description="Significant RTO risk, COD orders are not recommended for these Customers."
            checked={checked}
            onChange={(field) => handleCheckboxChange("high", field)}
            riskLevels={riskLevels.high}
            paymentSettings={paymentSettings}
          />
        </div>
        <div
          className={styles.container}
          style={{ display: "flex", justifyContent: "end" }}
        >
          <CombinedButtons
            solidBtnAction={onSave}
            loading={loading}
            outlinedBtnAction={() => {
              history.push(`/${storeId}/apps`);
            }}
            style={{
              height: 40,
              minWidth: "100px",
              fontSize: 14,
              padding: "0px 8px",
            }}
            disabled={!checked || !isChanged}
          />
        </div>
      </div>
      <FormModal
        heading="GoKwik RTO optimizer"
        open={openStartFree}
        onClose={() => {
          setOpenStartFree(false);
          setChecked(false);
        }}
        hideCancel={true}
        hideCombinedButton={true}
        hideBottomStickyButton={true}
        overlay={false}
        maxHeight={94}
      >
        <div
          style={{ borderTop: "1px solid #E1E1E1", margin: "0px -24px 20px" }}
        ></div>

        <Typography style={{ fontSize: 14, fontWeight: 400 }}>
          It reduces RTO by providing AI based recommendation for payment
          methods. Such as allowing COD, Prepaid and partial payment based on
          customers past orders.
        </Typography>
        <Typography style={{ fontSize: 14, marginTop: 8 }}>
          Enjoy a <strong>30-day free trial,</strong> then a{" "}
          <strong>0.4%</strong> charge applies.
          <Link
            style={{
              fontWeight: 500,
              textDecoration: "none",
              color: "#0044CC",
              marginLeft: "5px",
            }}
            target="_blank"
            href="https://www.gokwik.co/blog/rto-actions-by-gokwik"
          >
            Learn more
          </Link>
        </Typography>
        <div
          style={{
            paddingTop: "16px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <FormControlLabel
            control={<Checkbox checked={checked} onChange={handleChange} />}
            label={
              <span style={{ color: "#666666", fontSize: 14, fontWeight: 500 }}>
                By checking, you agree to our
                <Link
                  style={{
                    fontWeight: 500,
                    textDecoration: "none",
                    color: "#0044CC",
                    marginLeft: 4,
                  }}
                  target="_blank"
                  href="https://www.gokwik.co/terms"
                >
                  Terms & Conditions
                </Link>
              </span>
            }
          />
          <div style={{ borderTop: "1px solid #E1E1E1" }}></div>
        </div>
        <div
          style={{ marginBottom: 24, marginTop: 24 }}
          className={rowFlexCenterAll}
        >
          <SolidButton
            onClick={() => handleFreeplanActivate("TRIAL")}
            style={{ height: 36, fontSize: 14, padding: 0 }}
            disabled={!checked}
          >
            Start 30 days free trial
          </SolidButton>
        </div>
      </FormModal>
      <Toast
        open={openToast}
        close={setOpenToast}
        message={message}
        severity={severity}
      />
    </div>
  );
};
export default Gokwik;
