// Core react imports
import React, { useState, useRef, useEffect } from "react";
// Mui imports
import {
  Box,
  Typography,
  makeStyles,
  MenuItem,
  MenuList,
  ClickAwayListener,
} from "@material-ui/core";
// Icon imports
import { ArrowDropDown } from "@material-ui/icons";
import PaidTab from "../common/PaidTab";
import { rowFlexAlignCenter, rowFlexAlignStart } from "../../styles/common";
// Style declarations
const useStyles = makeStyles(() => ({
  displayBox: {
    padding: "6px 9px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
    backgroundColor: "#FFF",
    borderRadius: "4px",
  },
  selectedValue: {
    fontSize: "14px",
    lineHeight: "14px",
    color: "#1A1A1A",
    "-webkit-user-select": "none",
    "-webkit-touch-callout": "none",
    "-khtml-user-select": "none",
    "-ms-user-select:": "none",
    "-moz-user-select": "none",
    "user-select": "none",
    fontWeight: "600",
  },
  menuBox: {
    backgroundColor: "#fff",
    position: "absolute",
    zIndex: 1,
    boxShadow:
      "0px 3px 11px 0px #e8eafc, 0 3px 3px -2px #b2b2b21a, 0 1px 8px 0 #9a9a9a1a",
  },
  listItem: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    padding: "8px !important",
  },
  title: {
    fontSize: "12px",
    lineHeight: "16px",
    fontWeight: "600",
    color: "#1A1A1A",
  },
  subtitle: {
    fontSize: "12px",
    lineHeight: "16px",
    color: "#666666",
    marginTop: "4px",
  },
  errorText: {
    color: "red",
    fontSize: "12px",
    margin: "2px",
  },
  helperText: {
    color: "#666",
    fontSize: "12px",
    margin: "2px",
  },
  detailsText: {
    margin: "2px",
    fontSize: "12px",
    color: "#666",
  },
  Option: {
    fontSize: "12px",
    lineHeight: "16px",
    color: "#1A1A1A",
    fontWeight: "400",
  },
  displayValue: {
    color: "#666",
    fontSize: "14px",
    lineHeight: "14px",
  },
}));
// Exporting component
export default function SubtitleSelect({
  optionList,
  value,
  displayBoxClasses = [],
  width = "100%",
  onChange,
  label = "",
  labelPlacement = "top",
  labelClass = "",
  error = "",
  boldDisplayValue = false,
  details = "",
  wrapperStyle = {},
  imgClass,
  disabled = false,
  helperText = "",
  ...props
}) {
  // State to handle if menu is open
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [boxWidth, setBoxWidth] = useState(0);
  const boxRef = useRef(null);

  // Classes declaration
  const classes = useStyles();
  // Extracting display value
  let options = optionList.filter((listItem) => listItem.value === value);
  let displayValue = options.length > 0 ? options[0].displayValue : "";

  // Menu handler methods
  const handleOpen = (e) => {
    setIsMenuOpen(true);
  };
  const handleClose = () => {
    setIsMenuOpen(false);
  };
  const handleValueSelect = (value, plan) => {
    onChange(value, plan);
    setIsMenuOpen(false);
  };
  // Effect
  useEffect(() => {
    let boxEl = document.getElementById("box");
    ro.observe(boxEl);
    return () => ro.unobserve(boxEl);
    //eslint-disable-next-line
  }, []);

  const ro = new ResizeObserver(() => {
    setBoxWidth(boxRef?.current?.getBoundingClientRect()?.width);
  });

  // Returing JSX
  let selectBox = (
    <>
      <Box
        className={[classes.displayBox, ...displayBoxClasses].join(" ")}
        {...(!disabled && { onClick: isMenuOpen ? handleClose : handleOpen })}
        style={{
          width: width,
          border: error.length
            ? "1px solid red"
            : isMenuOpen
            ? "1px solid #1641DB"
            : "1px solid #E6E6E6",
          ...(disabled && { cursor: "auto", opacity: 0.6 }),
        }}
        id="box"
        ref={boxRef}
        {...props}
      >
        <Typography
          className={
            boldDisplayValue ? classes.selectedValue : classes.displayValue
          }
        >
          {displayValue}
        </Typography>
        <ArrowDropDown
          style={{
            transform: isMenuOpen ? "rotateX(180deg)" : "",
            color: "#B9B9B9",
          }}
        />
      </Box>
      {details && details.length && !error.length && (
        <p className={classes.detailsText}>{details}</p>
      )}
      {error && error.length && <p className={classes.errorText}>{error}</p>}
      {!error && helperText.length > 0 && (
        <p className={classes.helperText}>{helperText}</p>
      )}
      {isMenuOpen && (
        <ClickAwayListener onClickAway={handleClose}>
          <Box
            className={classes.menuBox}
            style={
              details.length || error.length
                ? { width: boxWidth, marginTop: "-20px" }
                : { width: boxWidth }
            }
          >
            <MenuList id="custom-select-menu">
              {optionList.map((option) => (
                <MenuItem
                  disabled={disabled}
                  onClick={handleValueSelect.bind(
                    this,
                    option.value,
                    option.plan
                  )}
                  key={option.value}
                  style={{
                    background:
                      option.displayValue === displayValue && "#F3F5FF",
                  }}
                  className={classes.listItem}
                >
                  <div
                    style={{ maxWidth: boxWidth, whiteSpace: "normal" }}
                    className={
                      option.subtitle ? rowFlexAlignStart : rowFlexAlignCenter
                    }
                  >
                    {option.icon && <div>{option.icon()}</div>}
                    {option.img && (
                      <img
                        src={option.img}
                        alt={option.title}
                        className={imgClass}
                      />
                    )}
                    <div style={{ marginLeft: "12px" }}>
                      <Typography
                        className={
                          option.subtitle ? classes.title : classes.Option
                        }
                      >
                        {option.title}{" "}
                        {option.plan && <PaidTab plan={option.plan} />}
                      </Typography>
                      {option.subtitle && (
                        <Typography className={classes.subtitle}>
                          {option.subtitle}
                        </Typography>
                      )}
                    </div>
                  </div>
                </MenuItem>
              ))}
            </MenuList>
          </Box>
        </ClickAwayListener>
      )}
    </>
  );
  if (label === "") {
    return selectBox;
  } else {
    if (labelPlacement === "top") {
      return (
        <Box style={wrapperStyle}>
          <Typography className={labelClass}>{label}</Typography>
          {selectBox}
        </Box>
      );
    } else if (labelPlacement === "left") {
      return (
        <Box display="flex" flexDirection="row" style={wrapperStyle}>
          <Typography className={labelClass}>{label}</Typography>
          {selectBox}
        </Box>
      );
    }
  }
}
