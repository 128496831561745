// MonacoEditor.js
import React, { useEffect, useRef } from "react";
import { editor } from "monaco-editor";

const MonacoEditor = ({ code, language, theme, onChange = () => {} }) => {
  const editorRef = useRef(null);
  const monacoRef = useRef(null);

  useEffect(() => {
    if (editorRef.current) {
      // Initialize Monaco editor
      monacoRef.current = editor.create(editorRef.current, {
        value: code || "",
        language: language || "html",
        theme: theme || "vs-light", // You can choose vs-light or other themes as well
        automaticLayout: true,
      });

      // Update content on editor change
      monacoRef.current.onDidChangeModelContent(() => {
        const value = monacoRef.current.getValue();
        onChange(value);
      });

      return () => {
        // Dispose of the editor on component unmount
        if (monacoRef.current) {
          monacoRef.current.dispose();
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div ref={editorRef} style={{ height: "500px", width: "100%" }} />;
};

export default MonacoEditor;
