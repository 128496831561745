import React, { useEffect, useState } from "react";
import { Button, Typography } from "@material-ui/core";
import {
  rowFlexAlignCenter,
  rowFlexOnlyJustifyBetweenCenter,
} from "../../styles/common";
import SolidButton from "../buttons/SolidButton";
import { useSelector } from "react-redux";
import { CloseIcon } from "../../icons";
import CoinIcon from "../../icons/CoinIcon";
import { getStoreIdFromUrl, updateAttributes } from "../../utils/storeUtil";
import { useHistory } from "react-router-dom";

const highlights = [
  {
    icon: <CoinIcon />,
    title: () => "Credits Low",
    subTitle:
      "You've hit your credit limit. Recharge your wallet now to resume functionality",
    ctaText: "Recharge",
    showClose: false,
    precedence: 1,
    criteria: (_, wallet) => {
      return wallet && wallet.balance < wallet.order_balance;
    },
    onCTA: (_, history) => {
      const storeId = getStoreIdFromUrl();
      const action = "buyCredits";
      history.push(`/${storeId}/wallet?action=${action}`);
    },
    style: {
      backgroundColor: " #FF60601F",
      border: "1px solid #FF60601F",
      ctaColor: "#FF6060",
    },
  },
  {
    icon: <CoinIcon />,
    title: (_, wallet) => `${wallet.balance} Credits`,
    subTitle: "Customers won't receive order SMS when wallet balance hits O",
    ctaText: "Buy Credits",
    showClose: false,
    precedence: 2,
    criteria: (_, wallet, screen) => {
      return (
        screen === "dashboard" &&
        wallet &&
        wallet.balance > 0 &&
        wallet.balance <= 50
      );
    },
    onCTA: (_, history) => {
      const storeId = getStoreIdFromUrl();
      const action = "buyCredits";
      history.push(`/${storeId}/wallet?action=${action}`);
    },
    style: {
      backgroundColor: "#FFF2E2",
      border: "1px solid #FF60601F",
    },
  },
  {
    icon: <CoinIcon />,
    title: (_, wallet) => `${wallet.balance} Credits`,
    subTitle: "Customers are not receiving any SMS, including OTP.",
    ctaText: "Buy Credits",
    showClose: false,
    precedence: 2,
    criteria: (_, wallet, screen) => {
      return screen === "dashboard" && wallet && wallet.balance <= 0;
    },
    onCTA: (_, history) => {
      const storeId = getStoreIdFromUrl();
      const action = "buyCredits";
      history.push(`/${storeId}/wallet?action=${action}`);
    },
    style: {
      backgroundColor: "#FFEFEF",
      border: "1px solid #FF60601F",
    },
  },
  {
    icon: (
      <img
        src="https://storage.googleapis.com/shy-pub/static/shoopy-app/confetti.png"
        alt="Book Demo"
        width="40px"
        height="40px"
        style={{ marginRight: "12px" }}
      />
    ),
    title: () => "Looking to Upgrade",
    subTitle:
      "Book a free demo, and our agent will guide you through the Shoopy premium features",
    ctaText: "Book Demo",
    showClose: true,
    precedence: 3,
    criteria: (store, wallet, screen) => {
      if (!store || screen !== "dashboard") return false;
      const demoWidgetClosedAtStr =
        store.attributes?.site_settings?.demo_widget_closed_at;
      const demoWidgetClosedAt = demoWidgetClosedAtStr
        ? Number(demoWidgetClosedAtStr)
        : null;
      const oneMonthAgo = Date.now() - 14 * 24 * 60 * 60 * 1000;
      return (
        store.plan === "FREE" &&
        (!demoWidgetClosedAt || demoWidgetClosedAt < oneMonthAgo)
      );
    },
    onCTA: () => {
      window.open(
        "https://www.shoopy.in/book-a-demo?utm_source=shoopy-desktop&utm_medium=web&utm_campaign=book-a-demo"
      );
    },
    onClose: async (store) => {
      await updateAttributes(store.slug, "site_settings", [
        { name: "demo_widget_closed_at", value: Date.now() },
      ]);
    },
    style: {
      backgroundColor: "#E9EEFF",
      border: "1px solid #D1D9FF",
    },
  },
  {
    icon: (
      <img
        src="https://storage.googleapis.com/shy-pub/static/shoopy-app/refer-and-earn.png"
        alt="Refer & Earn"
        width="40px"
        height="40px"
        style={{ marginRight: "12px" }}
      />
    ),
    title: () => "Refer & Earn",
    subTitle:
      "Refer your friends and earn 1 month extension on your current plan!",
    ctaText: "Refer Now",
    showClose: false,
    precedence: 4,
    criteria: (store, _, screen) =>
      store && store.plan !== "FREE" && screen === "dashboard",
    onCTA: (store, _) => {
      window.open(
        `https://share-eu1.hsforms.com/1EFXlAcn2SYGfTTYYhqAHlA2eczjm?store_id=${store.id}`
      );
    },
    style: {
      backgroundColor: " #E9EEFF",
      border: "1px solid #D1D9FF",
    },
  },
];

const HighlightWidget = ({ screen }) => {
  const store = useSelector((state) => state.store.store);
  const wallet = useSelector((state) => state.wallet.walletData);
  const [highlight, setHighlight] = useState(null);
  const history = useHistory();
  useEffect(() => {
    if (store.plan && wallet) {
      const selectedHighlight = highlights
        .sort((a, b) => a.precedence - b.precedence)
        .find((h) => h.criteria(store, wallet, screen));
      setHighlight(selectedHighlight || null);
    }
  }, [store, wallet, screen]);

  if (!highlight) {
    return null;
  }

  return (
    <div
      className={rowFlexOnlyJustifyBetweenCenter}
      style={{
        width: "100%",
        backgroundColor: highlight.style.backgroundColor,
        border: highlight.style.border,
        borderRadius: "8px",
        padding: "12px",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      }}
    >
      <div className={rowFlexAlignCenter}>
        {highlight.icon}
        <div style={{ marginLeft: 8 }}>
          <Typography
            variant="h3"
            style={{
              fontSize: "16px",
              fontWeight: "600",
            }}
          >
            {highlight.title(store, wallet)}
          </Typography>
          <Typography
            style={{
              fontSize: "14px",
              color: "#666666",
              whiteSpace: "normal",
            }}
          >
            {highlight.subTitle}
          </Typography>
        </div>
      </div>
      <div className={rowFlexAlignCenter}>
        <SolidButton
          onClick={() => highlight.onCTA(store, history)}
          style={{
            backgroundColor: highlight.style.ctaColor,
            height: 36,
            padding: 8,
            fontSize: 14,
          }}
        >
          {highlight.ctaText}
        </SolidButton>
        {highlight.showClose && (
          <Button
            onClick={async () => {
              setHighlight(null);
              if (highlight.onClose) {
                await highlight.onClose(store);
              }
            }}
            style={{ outline: "none" }}
          >
            <CloseIcon width={12} height={12} />
          </Button>
        )}
      </div>
    </div>
  );
};

export default HighlightWidget;
