import {
  config as BasicStyle1Config,
  generateHtml as BasicStyle1Html,
} from "./BasicStyle1/config";
import {
  config as ButtonStyle1Config,
  generateHtml as ButtonStyle1Html,
} from "./ButtonStyle1/config";
import {
  config as ButtonStyle2Config,
  generateHtml as ButtonStyle2Html,
} from "./ButtonStyle2/config";
import {
  config as TimerStyle1Config,
  generateHtml as TimerStyle1ConfigHtml,
  generateJs as TimerStyle1ConfigJs,
  generateHtmlForJs as TimerStyle1ConfigHtmlForJs,
} from "./TimerStyle1/config";
import {
  config as TimerStyle2Config,
  generateHtml as TimerStyle2ConfigHtml,
  generateJs as TimerStyle2ConfigJs,
  generateHtmlForJs as TimerStyle2ConfigHtmlForJs,
} from "./TimerStyle2/config";
import {
  config as TimerStyle3Config,
  generateHtml as TimerStyle3ConfigHtml,
  generateJs as TimerStyle3ConfigJs,
  generateHtmlForJs as TimerStyle3ConfigHtmlForJs,
} from "./TimerStyle3/config";
import {
  config as HTMLStyleConfig,
  generateHtml as HTMLStyleHtml,
} from "./HtmlStyle/config";

export const templateConfigs = {
  style1: {
    label: "Hello Boost",
    config: BasicStyle1Config,
    generateHtml: BasicStyle1Html,
    generateJs: () => "",
    previewHtml: BasicStyle1Html,
    plan: "FREE",
  },
  style2: {
    label: "Action Ignite",
    config: ButtonStyle1Config,
    generateHtml: ButtonStyle1Html,
    generateJs: () => "",
    previewHtml: ButtonStyle1Html,
    plan: "FREE",
  },
  style3: {
    label: "Action Knight",
    config: ButtonStyle2Config,
    generateHtml: ButtonStyle2Html,
    generateJs: () => "",
    previewHtml: ButtonStyle2Html,
    plan: "FREE",
  },
  style4: {
    label: "Engage Elevate",
    config: TimerStyle1Config,
    generateHtml: TimerStyle1ConfigHtml,
    generateJs: TimerStyle1ConfigJs,
    previewHtml: TimerStyle1ConfigHtmlForJs,
    plan: "BUSINESS_APP_PRO",
  },
  style5: {
    label: "Engage Pulse",
    config: TimerStyle2Config,
    generateHtml: TimerStyle2ConfigHtml,
    generateJs: TimerStyle2ConfigJs,
    previewHtml: TimerStyle2ConfigHtmlForJs,
    plan: "BUSINESS_APP_PRO",
  },
  style6: {
    label: "Engage Surge",
    config: TimerStyle3Config,
    generateHtml: TimerStyle3ConfigHtml,
    generateJs: TimerStyle3ConfigJs,
    previewHtml: TimerStyle3ConfigHtmlForJs,
    plan: "BUSINESS_APP_PRO",
  },
  style7: {
    label: "Custom HTML",
    config: HTMLStyleConfig,
    generateHtml: HTMLStyleHtml,
    generateJs: () => "",
    previewHtml: HTMLStyleHtml,
    plan: "BUSINESS",
  },
};
