import axios from "axios";

export const genrateDesc = async (payload, desName, store) => {
  const accessToken = localStorage.getItem("access_token");
  const response = await axios.post(
    `${process.env.REACT_APP_API}/api/v2/org/${store.org_id}/super-products/${desName}`,
    payload,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return response.data.product_description;
};
