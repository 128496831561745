export const colorProfile = {
  "engage-surge": {
    text_font_color: "#1A1A1A",
    timer_font_color: "#FFFFFF",
    timer_background_color: "#73388F",
    background_color: "#F6F1FF",
    texture_color: "#E4C2F4",
    close_icon_color: "#73388F",
  },
  "action-ignite": {
    text_font_color: "#1A1A1A",
    timer_font_color: "#FFFFFF",
    timer_background_color: "#FF9736",
    background_color: "#FFEEDE",
    texture_color: "#FFE7CE",
    close_icon_color: "#FF9736",
  },
  "engage-elevate": {
    text_font_color: "#196F82",
    timer_font_color: "#FFFFFF",
    timer_background_color: "#0B9FBF",
    background_color: "#F1FDFF",
    texture_color: "#84D9EB",
    close_icon_color: "#0B9FBF",
  },
  "engage-pulse": {
    text_font_color: "#FFFFFF",
    timer_font_color: "#FFFFFF",
    timer_background_color: "#7F70DE",
    background_color: "#140763",
    texture_color: "#817C99",
    close_icon_color: "#FFFFFF",
  },
};
