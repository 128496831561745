import React, { Fragment } from "react";
import { FormControl, Paper, Typography, makeStyles } from "@material-ui/core";
import Input from "../../../../../../components/controls/Input";
import Switch from "../../../../../../components/controls/Switch";
import Select from "../../../../../../components/controls/Select";
import Devider from "../../Devider";
import { InputGridContainer, InputGridItem } from "../../InputGridContainer";
import { colorPickerColors } from "../../../../../../utils/colorPickerColors";
import { ColorPicker } from "material-ui-color";

const useStyle = makeStyles(() => ({
  colorPicker: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #E6E6E6",
    padding: 4,
    borderRadius: 4,
    height: 36,
  },
}));

const HtmlStyle = ({ formState, handleFormChnage }) => {
  const classes = useStyle();
  return (
    <Fragment>
      <div style={{ padding: "10px" }}>
        <Paper
          elevation={0}
          style={{
            padding: "20px",
            boxShadow: "0px 1px 3px 0px #0000001A",
            borderRadius: 8,
          }}
        >
          <div
            style={{
              fontSize: "14px",
              fontWeight: 700,
              marginBottom: 20,
            }}
          >
            Hello Bar Display
          </div>
          <Input
            label={formState.html_code.label}
            labelFontWeight={400}
            labelPlacement="top"
            value={formState.html_code.value}
            onChange={(e) =>
              handleFormChnage("html_code", {
                value: e.target.value,
              })
            }
            onFocus={(e) => {
              handleFormChnage("html_code", {
                error: "",
              });
            }}
            onBlur={(e) => {
              formState.html_code.required &&
                handleFormChnage("html_code", {
                  error: e.target.value ? "" : "This Field is Required!",
                });
            }}
            error={formState.html_code.error}
            extraSpace={formState.html_code.error && true}
            multiline={true}
            maxRows={16}
            minRows={10}
            placeholder={`<div style="width: 100%">
  <div
    style="
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 12px 16px;
      background-color: #ffebe0;
    "
  >
    <div style="display: flex; align-items: center">
      <span style="color: #000000; margin-right: 6px"
        >30% off on all clothes. Use code- Fashion 30</span
      >
      <button
        style="
          color: #dc5328;
          background-color: transparent;
          text-decoration: underline;
          border: none;
          font-weight: 600;
        "
      >
        Button
      </button>
    </div>
  </div>
</div>`}
          />
        </Paper>
      </div>
      {formState.allow_to_close.value && (
        <div style={{ padding: "10px" }}>
          <Paper
            elevation={0}
            style={{
              padding: "20px",
              boxShadow: "0px 1px 3px 0px #0000001A",
              borderRadius: 8,
            }}
          >
            <div
              style={{
                fontSize: "14px",
                fontWeight: 700,
                marginBottom: 20,
              }}
            >
              Style
            </div>
            <InputGridContainer style={{ marginBottom: 20 }}>
              <InputGridItem>
                <Typography
                  style={{
                    fontSize: 14,
                    marginBottom: 8,
                  }}
                >
                  {formState.close_icon_color.label}
                </Typography>
                <div className={classes.colorPicker}>
                  <ColorPicker
                    hideTextfield
                    value={formState.close_icon_color.value}
                    onChange={(value) => {
                      handleFormChnage("close_icon_color", {
                        color: value,
                        value: value.css.backgroundColor,
                      });
                    }}
                    palette={colorPickerColors}
                  />
                  <Typography style={{ fontSize: 14, marginLeft: 10 }}>
                    {formState.close_icon_color.value}
                  </Typography>
                </div>
              </InputGridItem>
            </InputGridContainer>
          </Paper>
        </div>
      )}
      <div style={{ padding: "10px" }}>
        <Paper
          elevation={0}
          style={{
            padding: "20px",
            boxShadow: "0px 1px 3px 0px #0000001A",
            borderRadius: 8,
          }}
        >
          <div
            style={{
              fontSize: "14px",
              fontWeight: 700,
              marginBottom: 20,
            }}
          >
            Hello Bar behaviour
          </div>
          <InputGridContainer>
            <InputGridItem>
              <FormControl fullWidth color="secondary">
                <Select
                  name="type"
                  variant="outlined"
                  value={formState.where_to_display.value}
                  label="Show Hello Bar"
                  labelStyles={{ fontWeight: 400 }}
                  labelPlacement="top"
                  onChange={(e) => {
                    handleFormChnage("where_to_display", {
                      value: e.target.value,
                    });
                  }}
                  color="secondary"
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                  renderValue={(selected) => {
                    let value = formState.where_to_display.options.filter(
                      (o) => o.id === selected
                    )[0].title;
                    return (
                      <Typography
                        style={{
                          fontSize: 14,
                        }}
                      >
                        {value}
                      </Typography>
                    );
                  }}
                  helperText={
                    formState.where_to_display.options.filter(
                      (o) => o.id === formState.where_to_display.value
                    )[0].helperText
                  }
                  options={formState.where_to_display.options}
                  height={36}
                />
              </FormControl>
            </InputGridItem>
          </InputGridContainer>
          <InputGridContainer>
            <InputGridItem>
              <FormControl fullWidth color="secondary">
                <Select
                  name="type"
                  variant="outlined"
                  value={formState.initial_delay.value}
                  label="Show Hello Bar"
                  labelStyles={{ fontWeight: 400 }}
                  labelPlacement="top"
                  onChange={(e) => {
                    handleFormChnage("initial_delay", {
                      value: e.target.value,
                    });
                  }}
                  color="secondary"
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                  renderValue={(selected) => {
                    let value = formState.initial_delay.options.filter(
                      (o) => o.id === selected
                    )[0].title;
                    return (
                      <Typography
                        style={{
                          fontSize: 14,
                        }}
                      >
                        {value}
                      </Typography>
                    );
                  }}
                  helperText={
                    formState.initial_delay.options.filter(
                      (o) => o.id === formState.initial_delay.value
                    )[0].helperText
                  }
                  options={formState.initial_delay.options}
                  height={36}
                />
              </FormControl>
            </InputGridItem>
          </InputGridContainer>
          <Devider style={{ marginBottom: 20 }} />
          <div style={{ marginBottom: 20 }}>
            <Switch
              name="allow_to_close"
              label="Allow users to close it"
              labelFontWeight={400}
              helperText="It will allow  users to close Hello bar using cross button"
              color="secondary"
              value={formState.allow_to_close.value}
              onChange={() => {
                handleFormChnage("allow_to_close", {
                  value: !formState.allow_to_close.value,
                });
              }}
              width="100%"
            />
          </div>
          <div>
            <Switch
              name="make_it_sticky"
              label="Make it sticky"
              labelFontWeight={400}
              helperText="It will allow to users to make Hello bar sticky on top"
              color="secondary"
              value={formState.make_it_sticky.value}
              onChange={() => {
                handleFormChnage("make_it_sticky", {
                  value: !formState.make_it_sticky.value,
                });
              }}
              width="100%"
            />
          </div>
        </Paper>
      </div>
    </Fragment>
  );
};

export default HtmlStyle;
