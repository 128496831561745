import React from "react";
export function AnnouncementBar({ fill = "#666666", strokeWidth = 0 }) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="17" height="17" rx="1.5" stroke="#666666" />
      <rect
        x="3"
        y="3"
        width="12"
        height="1"
        rx="0.5"
        fill="#666666"
        stroke="#666666"
        stroke-width="0.4"
      />
      <path
        d="M3.19989 7.30017C3.19989 7.02403 3.42375 6.80017 3.69989 6.80017H14.2999C14.576 6.80017 14.7999 7.02403 14.7999 7.30017V14.3002C14.7999 14.5763 14.576 14.8002 14.2999 14.8002H3.69989C3.42375 14.8002 3.19989 14.5763 3.19989 14.3002V7.30017Z"
        fill="#F9F9F9"
        stroke="#666666"
      />
    </svg>
  );
}

export function SelAnnouncementBar({ fill = "#666666", strokeWidth = 0 }) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="17" height="17" rx="1.5" stroke="#1641DB" />
      <rect
        x="3"
        y="3"
        width="12"
        height="1"
        rx="0.5"
        fill="#1641DB"
        stroke="#1641DB"
        stroke-width="0.4"
      />
      <path
        d="M3.19989 7.30017C3.19989 7.02403 3.42375 6.80017 3.69989 6.80017H14.2999C14.576 6.80017 14.7999 7.02403 14.7999 7.30017V14.3002C14.7999 14.5763 14.576 14.8002 14.2999 14.8002H3.69989C3.42375 14.8002 3.19989 14.5763 3.19989 14.3002V7.30017Z"
        fill="#F9F9F9"
        stroke="#1641DB"
      />
    </svg>
  );
}
