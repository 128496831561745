import { makeStyles, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { rowFlexAlignStart } from "../../../../../../../styles/common";
import DisplayBannerImg from "../../../../../../banners/components/DisplayBannerImg";
import BannerImgContainer from "../../../../../../banners/components/BannerImgContainer";
import ImageHandler from "../../../../../../../components/ImageHandler";
import Input from "../../../../../../../components/controls/Input";
import { InputGridContainer, InputGridItem } from "../../../InputGridContainer";
import { colorPicker } from "../../../../../style";
import { ColorPicker } from "material-ui-color";
import { colorPickerColors } from "../../../../../../../utils/colorPickerColors";

const useStyles = makeStyles(() => ({
  addThumbnail: {
    fontSize: "14px",
    marginBottom: "12px",
    lineHeight: "20px",
  },
  bgColor: {
    fontSize: "14px",
    lineHeight: "20px",
    marginBottom: "15px",
  },
  textColor: {
    fontSize: "14px",
    lineHeight: "20px",
    marginBottom: "15px",
  },
}));

const Video = ({ videoForm, handleVideoFormChange, videoError }) => {
  const classes = useStyles();
  const [openMobImageHandler, setOpenMobImageHandler] = useState(false);
  const [openMobImageHandlerIndex, setOpenMobImageHandlerIndex] = useState(0);
  const [openDeskImageHandler, setOpenDeskImageHandler] = useState(false);
  const [openDeskImageHandlerIndex, setOpenDeskImageHandlerIndex] = useState(0);
  const [openMobModal, setOpenMobModal] = useState(false);
  const [openDeskModal, setOpenDeskModal] = useState(false);
  return (
    <div style={{ marginBottom: "16px" }}>
      <div style={{ marginBottom: "16px" }}>
        <Typography className={classes.addThumbnail}>Thumbnail</Typography>
        <div className={rowFlexAlignStart} style={{ marginBottom: "16px" }}>
          <div
            style={{
              ...(!videoForm.mobile_image.value && {
                width: "68px",
              }),
              height: "68px",
              marginRight: "20px",
            }}
          >
            {videoForm.mobile_image.value && (
              <DisplayBannerImg
                height={68}
                {...(!videoForm.mobile_image.value && {
                  width: 68,
                })}
                src={videoForm?.mobile_image?.value}
                removeCropImg={() => {
                  handleVideoFormChange("mobile_image_name", "");
                  handleVideoFormChange("mobile_image", null);
                }}
                removeImgFilename={() => {}}
                onImageClick={() => {
                  setOpenMobImageHandler(true);
                  setOpenMobImageHandlerIndex(0);
                }}
              />
            )}
            <ImageHandler
              cropImg={videoForm?.mobile_image?.value}
              setCropImg={(val) => handleVideoFormChange("mobile_image", val)}
              defaultCrop={{
                aspect: 1 / 1,
                x: 0,
                y: 0,
                width: 250,
                height: 250,
              }}
              setFilename={(val) =>
                handleVideoFormChange("mobile_image_name", val)
              }
              uploadContainer={
                <BannerImgContainer
                  showSmall
                  height="68px"
                  width="68px"
                  text="Mobile"
                />
              }
              maxSize={1920}
              openImageHandler={openMobImageHandler}
              setOpenImageHandler={setOpenMobImageHandler}
              openImageHandlerIndex={openMobImageHandlerIndex}
              openModal={openMobModal}
              setOpenModal={setOpenMobModal}
            />
          </div>
          <div
            style={{
              ...(!videoForm.desktop_image.value && {
                width: "148px",
              }),
              height: "68px",
            }}
          >
            {videoForm?.desktop_image?.value && (
              <DisplayBannerImg
                height={68}
                {...(!videoForm.desktop_image.value && {
                  width: 148,
                })}
                src={videoForm?.desktop_image?.value}
                removeCropImg={() => {
                  handleVideoFormChange("desktop_image_name", "");
                  handleVideoFormChange("desktop_image", null);
                }}
                removeImgFilename={() => {}}
                onImageClick={() => {
                  setOpenDeskImageHandler(true);
                  setOpenDeskImageHandlerIndex(0);
                }}
              />
            )}
            <ImageHandler
              cropImg={videoForm?.desktop_image?.value}
              setCropImg={(val) => handleVideoFormChange("desktop_image", val)}
              defaultCrop={{
                aspect: 16 / 9,
                x: 0,
                y: 0,
                width: 250,
                height: (250 * 9) / 16,
              }}
              setFilename={(val) =>
                handleVideoFormChange("desktop_image_name", val)
              }
              uploadContainer={
                <BannerImgContainer
                  showSmall
                  height="68px"
                  width="148px"
                  text="Desktop"
                />
              }
              maxSize={1920}
              openImageHandler={openDeskImageHandler}
              setOpenImageHandler={setOpenDeskImageHandler}
              openImageHandlerIndex={openDeskImageHandlerIndex}
              openModal={openDeskModal}
              setOpenModal={setOpenDeskModal}
            />
          </div>
        </div>
        {videoError && videoError.thumbnail && (
          <Typography
            style={{ color: "red", fontSize: "12px", marginTop: "4px" }}
          >
            {videoError.thumbnail}
          </Typography>
        )}
      </div>
      <Input
        label="Youtube Link *"
        placeholder="https://"
        color="secondary"
        variant="outlined"
        value={videoForm.video_url.value}
        onChange={(e) => {
          handleVideoFormChange("video_url", e.target.value);
        }}
        onFocus={() => {}}
        onBlur={() => {}}
        helperText={
          videoError && videoError.video_url ? videoError.video_url : ""
        }
        error={videoError && videoError.video_url ? true : false}
        labelPlacement="top"
      />
      <InputGridContainer>
        <InputGridItem>
          <Typography className={classes.bgColor}>Background Color</Typography>
          <div className={colorPicker}>
            <ColorPicker
              hideTextfield
              value={videoForm.background_color.value}
              onChange={(value) => {
                handleVideoFormChange("background_color", value);
              }}
              palette={colorPickerColors}
            />
            <Typography style={{ fontSize: 14, marginLeft: 10 }}>
              {videoForm.background_color.value.css.backgroundColor}
            </Typography>
          </div>
        </InputGridItem>
        <InputGridItem>
          <Typography className={classes.textColor}>Text Color</Typography>
          <div className={colorPicker}>
            <ColorPicker
              hideTextfield
              value={videoForm.text_color.value}
              onChange={(value) => {
                handleVideoFormChange("text_color", value);
              }}
              palette={colorPickerColors}
            />
            <Typography style={{ fontSize: 14, marginLeft: 10 }}>
              {videoForm.text_color.value.css.backgroundColor}
            </Typography>
          </div>
        </InputGridItem>
      </InputGridContainer>
    </div>
  );
};

export default Video;
