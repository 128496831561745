// icon:bx-mobile | Boxicons https://boxicons.com/ | Atisa
import * as React from "react";

export default function MobIcon(props) {
  return (
    <svg
      width="13"
      height="20"
      viewBox="0 0 13 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.1051 1C1.94367 1 1 1.94367 1 3.1051V16.8949C1 18.0563 1.94367 19 3.1051 19H9.92854C11.09 19 12.0336 18.0563 12.0336 16.8949V3.1051C12.0336 1.94367 11.09 1 9.92854 1H3.1051ZM3.1051 1.58072H9.92854C10.7694 1.58072 11.4529 2.26422 11.4529 3.1051V14.9372H1.58072V3.1051C1.58072 2.26306 2.26422 1.58072 3.1051 1.58072ZM5.33724 3.34783V3.92854H7.67826V3.34783H5.33724ZM1.58072 15.518H11.4529V16.8972C11.4529 17.738 10.7694 18.4216 9.92855 18.4216H3.1051C2.26422 18.4216 1.58072 17.738 1.58072 16.8972V15.518Z"
        fill="#1641DB"
        stroke="currentColor"
        strokeWidth="0.5"
      />
    </svg>
  );
}
