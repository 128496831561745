import { Drawer, IconButton, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import MobIcon from "../../../../../../icons/MobIcon";
import PcIcon from "../../../../../../icons/PcIcon";
import DrawerCloseIcon from "../../../../../../icons/DrawerCloseIcon";
import CardsToDisplayInRow from "./CardsToDisplayInRow/CardsToDisplayInRow";
import CombinedButtons from "../../../../../../components/buttons/CombinedButtons";
import Input from "../../../../../../components/controls/Input";
import PaddingControl from "./PaddingControl/PaddingControl";

const useStyle = makeStyles(() => ({
  formLabel: {
    fontWeight: "400 !important",
  },
  headerBorder: {
    paddingBottom: 10,
    borderBottom: "1px solid #E1E1E1",
  },
  flexCenterBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  tabSwitcher: {
    padding: "6px 16px",
    position: "relative",
    cursor: "pointer",
    borderRadius: "100px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "50%",
  },
}));

const CollectionDrawer = ({
  drawerOpenTab,
  setDrawerOpenTab,
  addedCards,
  form,
  handleFormMultiChange,
}) => {
  const classes = useStyle();
  const [duplicatedForm, setDuplicatedForm] = useState(form);
  const duplicatedHandleFormChange = (key, value) => {
    setDuplicatedForm((prev) => ({ ...prev, [key]: { value } }));
  };
  const handleDone = () => {
    handleFormMultiChange([
      ["mobile_item_ratios", duplicatedForm.mobile_item_ratios.value],
      ["desktop_item_ratios", duplicatedForm.desktop_item_ratios.value],
      ["mobile_item_spacing", duplicatedForm.mobile_item_spacing.value],
      ["desktop_item_spacing", duplicatedForm.desktop_item_spacing.value],
      ["mobile_row_padding", duplicatedForm.mobile_row_padding.value],
      ["desktop_row_padding", duplicatedForm.desktop_row_padding.value],
    ]);
  };

  useEffect(() => {
    if (drawerOpenTab === null) {
      setDuplicatedForm(form);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpenTab, form]);

  return (
    <Drawer
      open={drawerOpenTab === "mobile" || drawerOpenTab === "desktop"}
      anchor="right"
      PaperProps={{
        style: {
          paddingLeft: 28,
          paddingRight: 28,
          paddingBottom: 28,
          paddingTop: 20,
          width: 570,
          justifyContent: "space-between",
        },
      }}
    >
      <div className={classes.modalStyles}>
        <div className={classes.headerBorder}>
          <div className={classes.flexCenterBetween}>
            <Typography style={{ fontWeight: 700, fontSize: 20 }}>
              Advance Layout Customization
            </Typography>
            <IconButton
              aria-label="Close"
              aria-haspopup="true"
              color="inherit"
              onClick={() => setDrawerOpenTab(null)}
            >
              <DrawerCloseIcon />
            </IconButton>
          </div>
        </div>
        <div>
          <div
            style={{
              fontSize: "14px",
              fontWeight: 700,
              padding: "12px 0",
              paddingTop: 20,
              paddingBottom: 16,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                background: "#E9EEFF",
                borderRadius: "100px",
                display: "flex",
                alignItems: "center",
                padding: "4px",
                position: "relative",
                width: "100%",
              }}
            >
              <div
                style={{
                  borderRadius: "100px",
                  background: "white",
                  position: "absolute",
                  top: "4px",
                  bottom: "4px",
                  left: drawerOpenTab === "desktop" ? "54px" : "4px",
                  transition: "200ms ease-in-out",
                }}
              ></div>
              <div
                className={classes.tabSwitcher}
                {...(drawerOpenTab === "mobile" && {
                  style: { background: "#fff" },
                })}
                onClick={() => setDrawerOpenTab("mobile")}
              >
                <MobIcon
                  style={{
                    width: "18px",
                    marginRight: "10px",
                    ...(drawerOpenTab === "mobile" && { color: "#1641DB" }),
                  }}
                />
                <span>Mobile</span>
              </div>
              <div
                className={classes.tabSwitcher}
                {...(drawerOpenTab === "desktop" && {
                  style: { background: "#fff" },
                })}
                onClick={() => setDrawerOpenTab("desktop")}
              >
                <PcIcon
                  style={{
                    width: "18px",
                    marginRight: "10px",
                    ...(drawerOpenTab === "desktop" && {
                      color: "#1641DB",
                    }),
                  }}
                />
                <span>Desktop</span>
              </div>
            </div>
          </div>
          <div>
            {drawerOpenTab === "mobile" && (
              <>
                <div style={{ marginBottom: 20 }}>
                  <div
                    style={{
                      borderBottom: "1px solid #e1e1e1",
                      paddingBottom: "16px",
                    }}
                  >
                    <Input
                      label="Space between Cards"
                      placeholder="2"
                      color="secondary"
                      variant="outlined"
                      value={duplicatedForm.mobile_item_spacing.value}
                      onChange={(e) => {
                        parseInt(e.target.value) >= 0 &&
                          duplicatedHandleFormChange(
                            "mobile_item_spacing",
                            e.target.value
                          );
                      }}
                      onFocus={() => {}}
                      onBlur={() => {}}
                      labelPlacement="start"
                      labelFontWeight={400}
                      endAdornment={
                        <span style={{ color: "#b9b9b9" }}>px</span>
                      }
                      type="number"
                      labelStyle={{ marginTop: 0, whiteSpace: "nowrap" }}
                      hideHelperText
                      inputCtnCss={{
                        flex: "unset",
                        width: "110px",
                      }}
                      labelCtnStyle={{ width: "100%" }}
                    />
                    <PaddingControl
                      padding={duplicatedForm.mobile_row_padding.value}
                      setPadding={(newPadding) => {
                        duplicatedHandleFormChange(
                          "mobile_row_padding",
                          newPadding
                        );
                      }}
                    />
                  </div>
                  <p
                    style={{
                      fontSize: 14,
                      marginTop: 16,
                      fontStyle: "italic",
                      color: "#666666",
                      textAlign: "center",
                    }}
                  >
                    Set each card's width using a scale of 1-12.
                  </p>
                </div>
                <CardsToDisplayInRow
                  spaceBetweenCards={duplicatedForm.mobile_item_spacing.value}
                  paddingAround={duplicatedForm.mobile_row_padding.value}
                  cardCapacityMap={duplicatedForm.mobile_item_ratios.value}
                  onChange={(value) => {
                    duplicatedHandleFormChange("mobile_item_ratios", value);
                  }}
                  addedCards={addedCards}
                  isMobile={true}
                />
              </>
            )}
            {drawerOpenTab === "desktop" && (
              <>
                <div style={{ marginBottom: 20 }}>
                  <div
                    style={{
                      borderBottom: "1px solid #e1e1e1",
                      paddingBottom: "16px",
                    }}
                  >
                    <Input
                      label="Space between Cards"
                      placeholder="2"
                      color="secondary"
                      variant="outlined"
                      value={duplicatedForm.desktop_item_spacing.value}
                      onChange={(e) => {
                        duplicatedHandleFormChange(
                          "desktop_item_spacing",
                          e.target.value
                        );
                      }}
                      onFocus={() => {}}
                      onBlur={() => {}}
                      labelPlacement="start"
                      endAdornment={
                        <span style={{ color: "#b9b9b9" }}>px</span>
                      }
                      type="number"
                      labelFontWeight={400}
                      labelStyle={{ marginTop: 0, whiteSpace: "nowrap" }}
                      hideHelperText
                      inputCtnCss={{
                        flex: "unset",
                        width: "110px",
                      }}
                      labelCtnStyle={{ width: "100%" }}
                    />
                    <PaddingControl
                      padding={duplicatedForm.desktop_row_padding.value}
                      setPadding={(newPadding) => {
                        duplicatedHandleFormChange(
                          "desktop_row_padding",
                          newPadding
                        );
                      }}
                    />
                  </div>
                  <p
                    style={{
                      fontSize: 14,
                      marginTop: 16,
                      fontStyle: "italic",
                      color: "#666666",
                      textAlign: "center",
                    }}
                  >
                    Set each card's width using a scale of 1-12.
                  </p>
                </div>
                <CardsToDisplayInRow
                  spaceBetweenCards={duplicatedForm.desktop_item_spacing.value}
                  paddingAround={duplicatedForm.desktop_row_padding.value}
                  cardCapacityMap={duplicatedForm.desktop_item_ratios.value}
                  onChange={(value) => {
                    duplicatedHandleFormChange("desktop_item_ratios", value);
                  }}
                  addedCards={addedCards}
                  isMobile={false}
                />
              </>
            )}
          </div>
        </div>
      </div>
      <div
        style={{
          marginTop: 32,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <CombinedButtons
          solidBtnText="Done"
          hideOutlineBtn
          solidBtnAction={() => {
            handleDone();
            setDrawerOpenTab(null);
          }}
        />
      </div>
    </Drawer>
  );
};

export default CollectionDrawer;
