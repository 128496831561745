import React from "react";
import { Box, Typography, Button } from "@material-ui/core";
import { Check } from "@material-ui/icons";
import { useStyles } from "../styles";
import { RenderDomainInfo } from "./renderDomainInfo";
export default function DomainCard({
  domain,
  handleDomainSelect,
  selectedDomain,
}) {
  const classes = useStyles();
  return (
    <Box
      className={
        selectedDomain?.domainName === domain.domainName
          ? [classes.domainCard, classes.highlightCard].join(" ")
          : classes.domainCard
      }
    >
      <RenderDomainInfo domain={domain} style={{ marginTop: "8px" }} />
      <Box display="flex" width={"30%"}>
        {selectedDomain?.domainName === domain.domainName ? (
          <Typography className={classes.addedItem}>
            <Check
              style={{ height: "12px", width: "12px", marginRight: "8px" }}
            />
            Added
          </Typography>
        ) : (
          <Button className={classes.domainBtn} onClick={handleDomainSelect}>
            + Add
          </Button>
        )}
      </Box>
    </Box>
  );
}
