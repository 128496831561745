import React, { useEffect, useState } from "react";
import axios from "axios";
import SolidButton from "../../../components/buttons/SolidButton";
import useStyles from "./styles";
import { isCustomDomain } from "../../../utils/utils";
import DynamicInput from "./EmailPhoneInput";
import { validatePhoneNumber } from "../../../utils/phoneUtils";
const MobileForm = ({
  onOtpSuccess,
  formType,
  record,
  inputType,
  setInputType,
  countryInfo,
  setCountryInfo,
}) => {
  const classes = useStyles();
  const [inputs, setInputs] = useState({
    mobile: record.mobile || "",
    email: record.email || "",
  });
  const validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  const [errors, setErrors] = useState({
    mobile: "",
    email: "",
  });

  useEffect(() => {
    setErrors({ mobile: "", email: "" });
  }, [formType]);
  const [country, setCountry] = useState("in");
  useEffect(() => {
    const fetchUserCountry = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}/api/v1/web/ip-info`
        );
        const data = response.data.payload;
        const userCountryCode = data.countryCode?.toLowerCase();
        if (userCountryCode) {
          setCountry(userCountryCode);
        }
      } catch (error) {
        console.error("Error fetching country:", error);
      }
    };
    fetchUserCountry();
  }, []);

  const handleInputChange = (value, name, country = null) => {
    const newInputType = /^[a-zA-Z@]/.test(value)
      ? "email"
      : value.trim()
      ? "phone"
      : "text";

    setInputs((prev) => ({
      ...prev,
      countryCode: country ? `+${country.dialCode}` : prev.countryCode,
      email: newInputType === "email" ? value : "",
      mobile: newInputType === "phone" ? value : "",
    }));

    if (country)
      setCountryInfo({
        code: country.countryCode.toUpperCase(),
        dialCode: country.dialCode,
      });
    setInputType(newInputType);
  };

  const validateFields = () => {
    let isValid = true;
    let newErrors = { email: "", mobile: "" };
    if (inputType === "email") {
      if (!inputs.email || !validRegex.test(inputs.email)) {
        newErrors.email = "Invalid email address";
        isValid = false;
      }
    } else if (inputType === "phone") {
      const phoneNumber = inputs.mobile ? inputs.mobile.toString() : "";
      if (!validatePhoneNumber(phoneNumber, countryInfo.code)) {
        newErrors.mobile = "Invalid phone number";
        isValid = false;
      }
    }
    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateFields()) return;
    if (window.location.search.includes("nootp")) {
      onOtpSuccess(inputs.mobile || inputs.email, "");
      return;
    }
    if (formType === "SIGNIN") {
      const exists =
        inputType === "email"
          ? await findUserByContact(inputs.email)
          : await findUserByContact(`+${inputs.mobile}`);

      if (!exists) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [inputType === "email" ? "email" : "mobile"]:
            inputType === "email"
              ? "Email not registered plese Sign Up"
              : "Number not registered plese Sign Up",
        }));
        return;
      }
    }
    const payload =
      inputType === "email"
        ? { email: inputs.email, channel: "email" }
        : { mobile: `+${inputs.mobile}`, channel: "mobile" };
    const res = await getOTP(payload);
    onOtpSuccess(
      inputType === "phone" ? `+${inputs.mobile}` : inputs.email,
      res.is_new
    );
  };

  const findUserByContact = async (value, type) => {
    try {
      let url = `${process.env.REACT_APP_API}/api/v1/store/user/find/${value}?key=ABCFER545343434GRTEWERT456087612333ACD`;
      let resp = await axios.get(url, {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${process.env.REACT_APP_TRUSTED_TOKEN}`,
          "Content-Type": "application/json",
        },
      });

      return resp.data;
    } catch (error) {
      console.error("Error in findUserByContact:", error);
      return null;
    }
  };

  const getOTP = async (payload) => {
    let customDomain = isCustomDomain();
    let hostname = window.location.hostname;
    let slug = "";
    if (customDomain) {
      let slugResp = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/store/external/${hostname}/slug`,
        {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${process.env.REACT_APP_TRUSTED_TOKEN}`,
          },
        }
      );
      slug = slugResp.data.payload;
    }
    let response = await axios.post(
      `${process.env.REACT_APP_AUTH_API}/api/v1/auth/oauth/v2/otp?slug=${slug}`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${process.env.REACT_APP_TRUSTED_TOKEN}`,
        },
      }
    );
    return response.data;
  };
  return (
    <form
      className={classes.form}
      onSubmit={handleSubmit}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
          handleSubmit(e);
        }
      }}
    >
      <>
        <DynamicInput
          handleInputChange={handleInputChange}
          inputType={inputType}
          inputs={inputs}
          errors={errors}
          country={country}
        />
        <div>
          <SolidButton
            type="submit"
            style={{ width: "100%", borderRadius: 7, background: "#0057FF" }}
          >
            Request OTP
          </SolidButton>
        </div>
      </>
    </form>
  );
};

export default MobileForm;
