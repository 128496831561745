// Core react imports
import React, { useState } from "react";
// Component imports
import HeaderBreadcrumbs from "../../components/common/HeaderBreadcrumbs";
import Form from "./Form";
import {
  BANNER,
  BANNER_PRODUCT,
  BANNER_VIDEO,
  GENERIC_COLLECTION,
  HTML_COLLECTION,
  MINI_BANNER,
  PRODUCT_GRID,
  PRODUCT_LIST,
} from "./utils";
import { useParams } from "react-router-dom";
import PageLoader from "../../components/Layout/PageLoader";
// Exporting default component

export const collectionUrlMap = {
  banner: BANNER,
  "banner-product": BANNER_PRODUCT,
  "mini-banner": MINI_BANNER,
  "product-grid": PRODUCT_GRID,
  "product-list": PRODUCT_LIST,
  "banner-video": BANNER_VIDEO,
  "generic-collection": GENERIC_COLLECTION,
  "html-collection": HTML_COLLECTION,
};

export default function Create() {
  const params = useParams();
  const type = params.type;
  const [collectionType] = useState(collectionUrlMap[type]);

  if (!collectionUrlMap[type]) {
    return <PageLoader />;
  }

  return (
    <div style={{ maxWidth: 1130, margin: "0 auto" }}>
      <div
        {...([GENERIC_COLLECTION, BANNER_VIDEO, HTML_COLLECTION].includes(
          collectionType
        ) && {
          style: { width: "60%", margin: "auto" },
        })}
      >
        <HeaderBreadcrumbs
          list={[
            { label: "Collections", link: "/collections" },
            {
              label: "Create",
            },
          ]}
        />
      </div>
      <Form collectionType={collectionType} mode="create" />
    </div>
  );
}
