import React from "react";
import { createColor } from "material-ui-color";
import EngageSurgeIcon from "./assets/EngageSurgeIcon";
import ActionIgniteIcon from "./assets/ActionIgniteIcon";
import EngageElevateIcon from "./assets/EngageElevateIcon";
import EngagePulseIcon from "./assets/EngagePulseIcon";

export const colorProfileOptions = [
  {
    title: "Engage Surge",
    id: "engage-surge",
    icon: <EngageSurgeIcon style={{ marginRight: 8 }} />,
  },
  {
    title: "Action Ignite",
    id: "action-ignite",
    icon: <ActionIgniteIcon style={{ marginRight: 8 }} />,
  },
  {
    title: "Engage Elevate",
    id: "engage-elevate",
    icon: <EngageElevateIcon style={{ marginRight: 8 }} />,
  },
  {
    title: "Engage Pulse",
    id: "engage-pulse",
    icon: <EngagePulseIcon style={{ marginRight: 8 }} />,
  },
];

export const applyColor = (colors, handleFormMultipleValueChange) => {
  handleFormMultipleValueChange(
    Object.entries(colors).map(([key, value]) => {
      const colorValue = createColor(value);
      return {
        field: key,
        payload: {
          color: colorValue,
          value: colorValue.css.backgroundColor,
        },
      };
    })
  );
};
