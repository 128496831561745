import { makeStyles, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { rowFlexAlignStart } from "../../../../../../../styles/common";
import DisplayBannerImg from "../../../../../../banners/components/DisplayBannerImg";
import BannerImgContainer from "../../../../../../banners/components/BannerImgContainer";
import ImageHandler from "../../../../../../../components/ImageHandler";
import Input from "../../../../../../../components/controls/Input";
import { InputGridContainer, InputGridItem } from "../../../InputGridContainer";
import { colorPicker } from "../../../../../style";
import { ColorPicker } from "material-ui-color";
import { colorPickerColors } from "../../../../../../../utils/colorPickerColors";

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: "16px",
  },
  col: {
    marginBottom: "16px",
  },
  selectBannerImage: {
    fontSize: "14px",
    marginBottom: "12px",
    lineHeight: "20px",
  },
  bannerError: {
    color: "red",
    fontSize: "12px",
    marginTop: "4px",
  },
  textColor: {
    fontSize: "14px",
    lineHeight: "20px",
    marginBottom: "15px",
  },
  bgColor: {
    fontSize: "14px",
    lineHeight: "20px",
    marginBottom: "15px",
  },
}));

const Banner = ({ bannerForm, handleBannerFormChange, bannerError }) => {
  const classes = useStyles();
  const [openMobImageHandler, setOpenMobImageHandler] = useState(false);
  const [openMobImageHandlerIndex, setOpenMobImageHandlerIndex] = useState(0);
  const [openDeskImageHandler, setOpenDeskImageHandler] = useState(false);
  const [openDeskImageHandlerIndex, setOpenDeskImageHandlerIndex] = useState(0);
  const [openMobModal, setOpenMobModal] = useState(false);
  const [openDeskModal, setOpenDeskModal] = useState(false);
  return (
    <div className={classes.root}>
      <div className={classes.col}>
        <Typography className={classes.selectBannerImage}>
          Banner Image
        </Typography>
        <div className={rowFlexAlignStart}>
          <div
            style={{
              ...(!bannerForm.mobile_image.value && {
                width: "68px",
              }),
              height: "68px",
              marginRight: "20px",
            }}
          >
            {bannerForm.mobile_image.value && (
              <DisplayBannerImg
                height={68}
                {...(!bannerForm.mobile_image.value && {
                  width: 68,
                })}
                src={bannerForm?.mobile_image?.value}
                removeCropImg={() => {
                  handleBannerFormChange("mobile_image_name", "");
                  handleBannerFormChange("mobile_image", null);
                }}
                removeImgFilename={() => {}}
                onImageClick={() => {
                  setOpenMobImageHandler(true);
                  setOpenMobImageHandlerIndex(0);
                }}
              />
            )}
            <ImageHandler
              cropImg={bannerForm?.mobile_image?.value}
              setCropImg={(val) => handleBannerFormChange("mobile_image", val)}
              defaultCrop={{
                aspect: 1 / 1,
                x: 0,
                y: 0,
                width: 250,
                height: 250,
              }}
              setFilename={(val) =>
                handleBannerFormChange("mobile_image_name", val)
              }
              uploadContainer={
                <BannerImgContainer
                  showSmall
                  height="68px"
                  width="68px"
                  text="Mobile"
                />
              }
              maxSize={1920}
              openImageHandler={openMobImageHandler}
              setOpenImageHandler={setOpenMobImageHandler}
              openImageHandlerIndex={openMobImageHandlerIndex}
              openModal={openMobModal}
              setOpenModal={setOpenMobModal}
            />
          </div>
          <div
            style={{
              ...(!bannerForm.desktop_image.value && {
                width: "148px",
              }),
              height: "68px",
            }}
          >
            {bannerForm?.desktop_image?.value && (
              <DisplayBannerImg
                height={68}
                {...(!bannerForm.desktop_image.value && {
                  width: 148,
                })}
                src={bannerForm?.desktop_image?.value}
                removeCropImg={() => {
                  handleBannerFormChange("desktop_image_name", "");
                  handleBannerFormChange("desktop_image", null);
                }}
                removeImgFilename={() => {}}
                onImageClick={() => {
                  setOpenDeskImageHandler(true);
                  setOpenDeskImageHandlerIndex(0);
                }}
              />
            )}
            <ImageHandler
              cropImg={bannerForm?.desktop_image?.value}
              setCropImg={(val) => handleBannerFormChange("desktop_image", val)}
              defaultCrop={{
                aspect: 16 / 9,
                x: 0,
                y: 0,
                width: 250,
                height: (250 * 9) / 16,
              }}
              setFilename={(val) =>
                handleBannerFormChange("desktop_image_name", val)
              }
              uploadContainer={
                <BannerImgContainer
                  showSmall
                  height="68px"
                  width="148px"
                  text="Desktop"
                />
              }
              maxSize={1920}
              openImageHandler={openDeskImageHandler}
              setOpenImageHandler={setOpenDeskImageHandler}
              openImageHandlerIndex={openDeskImageHandlerIndex}
              openModal={openDeskModal}
              setOpenModal={setOpenDeskModal}
            />
          </div>
        </div>
        {bannerError && (
          <Typography className={classes.bannerError}>{bannerError}</Typography>
        )}
      </div>
      <Input
        label="Link - (optional)"
        placeholder="https://"
        color="secondary"
        variant="outlined"
        value={bannerForm.link.value}
        onChange={(e) => {
          handleBannerFormChange("link", e.target.value);
        }}
        onFocus={() => {}}
        onBlur={() => {}}
        labelPlacement="top"
      />
      <InputGridContainer>
        <InputGridItem>
          <Typography className={classes.bgColor}>Background Color</Typography>
          <div className={colorPicker}>
            <ColorPicker
              hideTextfield
              value={bannerForm.background_color.value}
              onChange={(value) => {
                handleBannerFormChange("background_color", value);
              }}
              palette={colorPickerColors}
            />
            <Typography style={{ fontSize: 14, marginLeft: 10 }}>
              {bannerForm.background_color.value.css.backgroundColor}
            </Typography>
          </div>
        </InputGridItem>
        <InputGridItem>
          <Typography className={classes.textColor}>Text Color</Typography>
          <div className={colorPicker}>
            <ColorPicker
              hideTextfield
              value={bannerForm.text_color.value}
              onChange={(value) => {
                handleBannerFormChange("text_color", value);
              }}
              palette={colorPickerColors}
            />
            <Typography style={{ fontSize: 14, marginLeft: 10 }}>
              {bannerForm.text_color.value.css.backgroundColor}
            </Typography>
          </div>
        </InputGridItem>
      </InputGridContainer>
    </div>
  );
};

export default Banner;
