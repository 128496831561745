import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "./editor.css";

const Editor = ({
  editorText,
  setEditorText,
  height,
  toolbar = true,
  minHeight = undefined,
  maxHeight = undefined,
  placeholder = "",
}) => {
  const editorConfig = {
    toolbar: toolbar
      ? [
          "heading",
          "|",
          "bold",
          "italic",
          "|",
          "bulletedList",
          "numberedList",
          "|",
          "undo",
          "codeBlock",
          "redo",
        ]
      : [],
    placeholder: placeholder,
  };

  return (
    <CKEditor
      editor={ClassicEditor}
      config={editorConfig}
      data={editorText}
      onChange={(event, editor) => {
        const data = editor.getData();
        setEditorText(data);
      }}
      onReady={(editor) => {
        editor.editing.view.change((writer) => {
          writer.setStyle(
            "height",
            height,
            editor.editing.view.document.getRoot()
          );
          const editableElement = editor.editing.view.document.getRoot();
          if (minHeight) {
            writer.setStyle("min-height", minHeight, editableElement);
          }
          if (maxHeight) {
            writer.setStyle("max-height", maxHeight, editableElement);
          }
        });
      }}
    />
  );
};

export default Editor;
