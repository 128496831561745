import { makeStyles, Tooltip, Typography } from "@material-ui/core";
import React from "react";
import Reorder from "../../../../../../../icons/Reorder";
import { DeleteIcon, EditIcon } from "../../../../../../../icons";
import { rowFlexJustifyBetween } from "../../../../../../../styles/common";
import { cardHeadingObj } from "../AddCard/AddCard";

export const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    padding: "10px",
    border: "1px solid #E1E1E1",
    borderRadius: "8px",
    marginBottom: "16px",
  },
  imageCtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    width: "40px",
    height: "40px",
    objectFit: "contain",
  },
  action: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  titleCtn: {
    marginLeft: "10px",
  },
  title: {
    fontSize: "14px",
    lineHeight: "14px",
    marginBottom: "5px",
  },
  subTitle: {
    color: "#666",
    fontSize: "12px",
    lineHeight: "12px",
    textTransform: "capitalize",
  },
}));

const Card = ({ img, icon, title, provided, onDelete, onEdit, type }) => {
  const classes = useStyles();
  return (
    <div className={`${classes.root} ${rowFlexJustifyBetween}`}>
      <div className={classes.imageCtn}>
        <span {...provided.dragHandleProps} style={{ marginRight: "10px" }}>
          <Reorder
            style={{
              width: "10px",
              height: "16",
            }}
          />
        </span>
        {img && <img className={classes.image} src={img} alt="" />}
        {icon ?? <></>}
        <div className={classes.titleCtn}>
          <Typography variant="h6" className={classes.title}>
            {title ?? "--"}
          </Typography>
          <Typography variant="subtitle1" className={classes.subTitle}>
            {cardHeadingObj[type]}
          </Typography>
        </div>
      </div>
      <div className={classes.action}>
        <div style={{ cursor: "pointer" }} onClick={onEdit}>
          <Tooltip title="Edit" TransitionComponent={"Fade"}>
            <EditIcon />
          </Tooltip>
        </div>
        <div style={{ cursor: "pointer" }} onClick={onDelete}>
          <Tooltip title="Delete" TransitionComponent={"Fade"}>
            <DeleteIcon />
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default Card;
