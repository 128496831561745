import React, { useState } from "react";
import { makeStyles, useMediaQuery } from "@material-ui/core";
import HeaderBreadcrumbs from "../../components/common/HeaderBreadcrumbs";
import { NavLink, useRouteMatch, useLocation } from "react-router-dom";
import {
  AnnouncementBar,
  SelAnnouncementBar,
} from "../../icons/DisplaySettingsIcons/AnnouncementBar";
import { Header, SelHeader } from "../../icons/DisplaySettingsIcons/Header";
import { MenuBar, SelMenuBar } from "../../icons/DisplaySettingsIcons/Menubar";
import { Banner, SelBanner } from "../../icons/DisplaySettingsIcons/Banner";
import {
  ProductCard,
  SelProductCard,
} from "../../icons/DisplaySettingsIcons/ProductCard";
import {
  ProductDetailPage,
  SelProductDetailPage,
} from "../../icons/DisplaySettingsIcons/ProductDetailPage";
import {
  ProductListing,
  SelProductListing,
} from "../../icons/DisplaySettingsIcons/ProductListing";
import { Footer, SelFooter } from "../../icons/DisplaySettingsIcons/Footer";
import {
  ColorAndFont,
  SelColorAndFont,
} from "../../icons/DisplaySettingsIcons/ColorAndFont";
import {
  CustomCss,
  SelCustomCss,
} from "../../icons/DisplaySettingsIcons/CustomCss";
import {
  Categories,
  SelCategories,
} from "../../icons/DisplaySettingsIcons/Categories";

const navigationItems = [
  {
    title: "Announcement Bar",
    slug: "announcement-bar",
    icon: <AnnouncementBar />,
    selctedicon: <SelAnnouncementBar />,
  },
  {
    title: "Header",
    slug: "header",
    icon: <Header />,
    selctedicon: <SelHeader />,
  },
  {
    title: "Menu bar",
    slug: "menu-bar",
    icon: <MenuBar />,
    selctedicon: <SelMenuBar />,
  },
  {
    title: "Banner",
    slug: "banner",
    icon: <Banner />,
    selctedicon: <SelBanner />,
  },
  {
    title: "Product Card",
    slug: "product-card",
    icon: <ProductCard />,
    selctedicon: <SelProductCard />,
  },
  {
    title: "Product Page",
    slug: "product-page",
    icon: <ProductDetailPage />,
    selctedicon: <SelProductDetailPage />,
  },
  {
    title: "Product Listing",
    slug: "product-list",
    icon: <ProductListing />,
    selctedicon: <SelProductListing />,
  },
  {
    title: "Categories",
    slug: "categories",
    icon: <Categories />,
    selctedicon: <SelCategories />,
  },
  {
    title: "Footer",
    slug: "footer",
    icon: <Footer />,
    selctedicon: <SelFooter />,
  },
  {
    title: "Color & Font",
    slug: "color-font",
    icon: <ColorAndFont />,
    selctedicon: <SelColorAndFont />,
  },
  {
    title: "Custom CSS",
    slug: "custom-css",
    icon: <CustomCss />,
    selctedicon: <SelCustomCss />,
  },
];

const useStyles = makeStyles((theme) => ({
  sidebar: {
    backgroundColor: "#FFFFFF",
    borderRadius: 8,
    position: "fixed",
    top: 85,
    padding: 12,
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    overflowY: "auto",
    width: 300,
    maxHeight: "calc(100vh - 136px)",
    scrollbarWidth: "none", // Hide scrollbar for Firefox
    "-ms-overflow-style": "none", // Hide scrollbar for IE and Edge
    "&::-webkit-scrollbar": {
      display: "none", // Hide scrollbar for WebKit-based browsers
    },
  },
  breadcrumbContainer: {
    position: "fixed",
    zIndex: 1000,
  },

  link: {
    cursor: "pointer",
    width: "100%",
    display: "flex",
    alignItems: "center",
    gap: 12,
    textDecoration: "none",
    color: "#666666",
    fontSize: 14,
    padding: "4px 8px",
    borderRadius: 6,
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#f4f6f9",
      textDecoration: "none",
      color: "#666666",
      fontWeight: 500,
    },
  },
  activeLink: {
    fontWeight: 600,
    color: "#1A1A1A",
    backgroundColor: "#F1F4FF",
    "&:hover": {
      fontWeight: 600,
      backgroundColor: "#F1F4FF",
      color: "#1A1A1A",
    },
  },
  listItem: {
    marginBottom: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  title: {
    fontSize: 14,
    lineHeight: "20px",
    display: "flex",
    alignItems: "center",
    height: 20,
  },
  icon: {
    fill: "#666666",
    transition: "fill 0.3s ease",
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

function getLastSegment(pathname) {
  let segments = pathname.replace(/\/$/, "").split("/");
  return segments[segments.length - 1];
}

export default function DisplaySidebar() {
  const { url } = useRouteMatch();
  const location = useLocation();
  const isSmallScreen = useMediaQuery("(max-width:1400px)");

  const classes = useStyles();

  const [activeItem, setActiveItem] = useState(
    getLastSegment(location.pathname)
  );

  const handleNavLinkClick = (slug) => setActiveItem(slug);

  return (
    <div>
      <div
        className={classes.sidebar}
        style={{ width: isSmallScreen ? "240px" : "300px" }}
      >
        {navigationItems.map((item) => (
          <div key={item.slug} className={classes.listItem}>
            <NavLink
              to={`${url}/${item.slug}`}
              className={`${classes.link} ${
                activeItem === item.slug ? classes.activeLink : ""
              }`}
              onClick={() => handleNavLinkClick(item.slug)}
            >
              <div className={classes.icon}>
                {activeItem === item.slug ? item.selctedicon : item.icon}
              </div>
              <div className={classes.title}>{item.title}</div>
            </NavLink>
          </div>
        ))}
      </div>
    </div>
  );
}
