import React, { useState, useEffect } from "react";
import { useLocation, withRouter } from "react-router-dom";
import SignIn from "./components/SignIn";
import { fetchUserStores } from "../../redux/userstore/userStoreActions";
import { useDispatch, useSelector } from "react-redux";
import { getHostedSubPath, getStores } from "../../utils/storeUtil";
import {
  customerLoginSuccess,
  customerLogout,
} from "../../redux/customer/customerActions";
import { CLEAR_COMPLETE } from "../../redux/commom/commonTypes";
import Toast from "../../components/Layout/Toast";
import axios from "axios";

const NewLogin = ({ history }) => {
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.customer);
  const [newAcc, setNewAcc] = useState(false);
  const [isOpenToast, setIsOpenToast] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const location = useLocation();

  let query = new URLSearchParams(location.search);
  let callback = query.get("callback");
  let token = query.get("token");

  const onSuccess = async () => {
    localStorage.setItem("is_signup_shown", true);
    dispatch(fetchUserStores()).then((res) => {
      if (!newAcc) {
        if (res?.length > 0) {
          window.location =
            callback && callback.startsWith("/")
              ? callback
              : getHostedSubPath(res[0].store_id, "/dashboard");
          document.body.style.backgroundColor = "#F9F9F9";
        } else {
          history.push("/onboarding/create-store");
        }
      } else {
        history.push("/onboarding/create-store");
      }
    });
  };

  const onSubmit = async (contact, otp) => {
    return await axios.post(
      `${process.env.REACT_APP_AUTH_API}/api/v1/auth/oauth/token`,
      `grant_type=password&username=${encodeURIComponent(
        contact
      )}&password=${otp}`,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Basic ${process.env.REACT_APP_DEVICE_TOKEN}`,
        },
      }
    );
  };

  useEffect(() => {
    let stores = getStores();
    if (token) {
      dispatch(customerLogout());
      dispatch({
        type: CLEAR_COMPLETE,
        payload: {},
      });
      const [mobile, otp] = Buffer.from(token, "base64")
        .toString("ascii")
        .split(":");
      onSubmit(mobile, otp).then((response) => {
        dispatch(customerLoginSuccess(response.data));
        onSuccess();
      });
    } else if (stores && stores.length > 0) {
      if (customer.isAuthenticated) {
        window.location =
          callback && callback.startsWith("/")
            ? callback
            : getHostedSubPath(stores[0].store_id, "/dashboard");
      }
    } else {
      document.body.style.backgroundColor = "#FFFFFF";
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <SignIn
        onSuccess={onSuccess}
        newAcc={newAcc}
        setNewAcc={setNewAcc}
        otpSent={otpSent}
        setOtpSent={setOtpSent}
        onSubmit={onSubmit}
      />
      <Toast
        open={isOpenToast}
        close={() => setIsOpenToast(false)}
        message="Account is not registered with this number"
        severity="error"
      />
    </div>
  );
};

export default withRouter(NewLogin);
