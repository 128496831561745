// Importing types
import {
  SET_COLLECTIONS,
  SET_COLLECTION_TOAST,
  TOGGLE_COLLECTION_LOADING,
} from "./collectionTypes";

// Util imports
import { getDefaultStore } from "../../utils/storeUtil";
import {
  deleteWithToken,
  getWithToken,
  patchWithToken,
  postWithToken,
  putWithToken,
} from "../../utils/apiUtils";

// Action creatorts
const setCollections = (collectionList) => {
  return { type: SET_COLLECTIONS, payload: collectionList };
};
const toggleLoading = (value) => {
  return { type: TOGGLE_COLLECTION_LOADING, payload: value };
};
export const setToast = (isToast, message) => {
  return {
    type: SET_COLLECTION_TOAST,
    payload: { isToast: isToast, message: message },
  };
};

// Actions

export const getCollections = () => async (dispatch, getState) => {
  dispatch(toggleLoading(true));
  const store = getDefaultStore();
  const path = `/api/v1/org/${store.org_id}/stores/${store.store_id}/product-collections/list?super-products=true&size=100`;
  const { error, data } = await getWithToken(path);
  if (error) {
    // dispatch error action or show toast
    return { error: error, errorMsg: data.message };
  }
  dispatch(setCollections(data.payload));
  dispatch(toggleLoading(false));
  return data.payload;
};

export const createCollections = (body) => async (dispatch, getState) => {
  const store = getDefaultStore();
  const path = `/api/v1/org/${store.org_id}/stores/${store.store_id}/product-collections`;
  const { error, data } = await postWithToken(path, body);
  if (error) {
    // dispatch error action for show toast
    return { error: error, errorMsg: data.message };
  }
  let collections = getState().collections.list;
  if (collections.length === 0) {
    getCollections();
  } else {
    collections = [data.payload, ...collections];
    dispatch(setCollections(collections));
  }
  dispatch(setToast(true, "Collection created successfully"));
  return data.payload;
};

export const updateCollection =
  (collectionId, body) => async (dispatch, getState) => {
    const store = getDefaultStore();
    const path = `/api/v1/org/${store.org_id}/stores/${store.store_id}/product-collections/${collectionId}`;
    const { error, data } = await putWithToken(path, body);
    if (error) {
      return { error: error, errorMsg: data.message };
    }
    let collections = getState().collections.list;
    collections = collections.map((collection) => {
      if (collection.id === collectionId) {
        return data.payload;
      }
      return collection;
    });
    dispatch(setCollections(collections));
    dispatch(setToast(true, "Collection updated successfully"));
    return data.payload;
  };

export const toggleActivation =
  (collectionId, body) => async (dispatch, getState) => {
    const store = getDefaultStore();
    const path = `/api/v1/org/${store.org_id}/stores/${store.store_id}/product-collections/${collectionId}`;
    const { error } = await patchWithToken(path, body);
    if (error) {
      // dispatch error action for show toast
      return;
    }
    let collections = getState().collections.list;
    collections = collections.map((collection) => {
      if (collection.id === collectionId) {
        collection = { ...collection, ...body };
      }
      return collection;
    });
    dispatch(setCollections(collections));
    dispatch(setToast(true, "Collection updated successfully"));
  };

export const deleteCollection =
  (collectionId) => async (dispatch, getState) => {
    const store = getDefaultStore();
    const path = `/api/v1/org/${store.org_id}/stores/${store.store_id}/product-collections/${collectionId}`;
    const { error } = await deleteWithToken(path);
    if (error) {
      // dispatch error action for show toast
      return;
    }
    let collections = getState().collections.list;
    collections = collections.filter(
      (collection) => collection.id !== collectionId
    );
    dispatch(setCollections(collections));
    dispatch(setToast(true, "Collection deleted successfully"));
  };

export const reorderCollection =
  (collectionId, body, originalIndex) => async (dispatch, getState) => {
    const store = getDefaultStore();
    const path = `/api/v1/org/${store.org_id}/stores/${store.store_id}/product-collections/${collectionId}/position`;
    const { error } = await patchWithToken(path, body);
    if (error) {
      return;
    }
    let collections = [...getState().collections.list];
    let collectionItemCopy = { ...collections.splice(originalIndex, 1)[0] };
    collections.splice(body, 0, collectionItemCopy);
    dispatch(setCollections(collections));
    dispatch(setToast(true, "Collection updated successfully"));
  };
