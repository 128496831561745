import { combineReducers } from "redux";
import productReducer from "./product/productReducer";
import storeReducer from "./store/storeReducer";
import customerReducer from "./customer/customerReducer";
import userStoreReducer from "./userstore/userStoreReducer";
import categoryReducer from "./category/categoryReducer";
import additionalFieldsReducer from "./additionalFields/additionalFieldsReducer";
import taxGroupReducer from "./taxGroup/taxGroupReducer";
import invoiceReducer from "./invoices/invoiceReducer";
import orderReducer from "./orders/orderReducer";
import storeCustomerReducer from "./storeCustomers/storeCustomerReducer";
import billSettingsReducer from "./billSettings/billSettingsReducer";
import billConfigReducer from "./billConfig/billConfigReducer";
import reloadReducer from "./reload/reloadReducer";
import couponReducer from "./coupons/couponReducer";
import appsReducer from "./apps/appReducer";
import reportsReducer from "./reports/reportReducer";
import purchaseReducer from "./purchaseOrders/purchaseReducer";
import estimateReducer from "./estimateOrders/estimateReducer";
import dashboardReducer from "./dashboard/dashboardReducer";
import cartReducer from "./carts/cartReducer";
import headerReducer from "./header/headerReducer";
import bannerReducer from "./banners/bannerReducer";
import userReducer from "./usersAndRoles/userReducer";
import collectionReducer from "./collections/collectionReducer";
import menuReducer from "./menu/menuReducer";
import referAndEarnReducer from "./referAndEarn/referAndEarnReducer";
import walletReducer from "./wallet/walletReducer";
import settingsReducer from "./settings/settingsReducer";
import contractReducer from "./subscription/contract/contractrReducer";
import superproductReducer from "./subscription/superPorduct/superProdReducer";
import planReducer from "./subscription/plans/planReducer";
import currencyReducer from "./currency/currencyReducer";

const rootReducer = combineReducers({
  products: productReducer,
  store: storeReducer,
  customer: customerReducer,
  userstore: userStoreReducer,
  tax: taxGroupReducer,
  categories: categoryReducer,
  additionalFields: additionalFieldsReducer,
  invoices: invoiceReducer,
  orders: orderReducer,
  storeCustomers: storeCustomerReducer,
  billSettings: billSettingsReducer,
  billConfig: billConfigReducer,
  reload: reloadReducer,
  coupon: couponReducer,
  apps: appsReducer,
  reports: reportsReducer,
  purchases: purchaseReducer,
  estimates: estimateReducer,
  dashboard: dashboardReducer,
  carts: cartReducer,
  header: headerReducer,
  banners: bannerReducer,
  users: userReducer,
  collections: collectionReducer,
  menu: menuReducer,
  referAndEarn: referAndEarnReducer,
  wallet: walletReducer,
  settings: settingsReducer,
  contracts: contractReducer,
  superProduct: superproductReducer,
  subPlans: planReducer,
  currency: currencyReducer,
});

export default rootReducer;
