import React from "react";
import { SvgIcon } from "@material-ui/core";

export default function Reorder({
  viewBox = "0 0 12 18",
  stroke = "#666666",
  fill = "#666666",
  style = {},
  ...props
}) {
  return (
    <SvgIcon
      style={{ width: "12px", height: "18px", ...style }}
      viewBox={viewBox}
      {...props}
    >
      <svg
        width="12"
        height="18"
        viewBox="0 0 12 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.5 4C3.03043 4 3.53914 3.78929 3.91421 3.41421C4.28929 3.03914 4.5 2.53043 4.5 2C4.5 1.46957 4.28929 0.960859 3.91421 0.585786C3.53914 0.210714 3.03043 0 2.5 0C1.96957 0 1.46086 0.210714 1.08579 0.585786C0.710714 0.960859 0.5 1.46957 0.5 2C0.5 2.53043 0.710714 3.03914 1.08579 3.41421C1.46086 3.78929 1.96957 4 2.5 4ZM2.5 11C3.03043 11 3.53914 10.7893 3.91421 10.4142C4.28929 10.0391 4.5 9.53043 4.5 9C4.5 8.46957 4.28929 7.96086 3.91421 7.58579C3.53914 7.21071 3.03043 7 2.5 7C1.96957 7 1.46086 7.21071 1.08579 7.58579C0.710714 7.96086 0.5 8.46957 0.5 9C0.5 9.53043 0.710714 10.0391 1.08579 10.4142C1.46086 10.7893 1.96957 11 2.5 11ZM4.5 16C4.5 16.5304 4.28929 17.0391 3.91421 17.4142C3.53914 17.7893 3.03043 18 2.5 18C1.96957 18 1.46086 17.7893 1.08579 17.4142C0.710714 17.0391 0.5 16.5304 0.5 16C0.5 15.4696 0.710714 14.9609 1.08579 14.5858C1.46086 14.2107 1.96957 14 2.5 14C3.03043 14 3.53914 14.2107 3.91421 14.5858C4.28929 14.9609 4.5 15.4696 4.5 16ZM9.5 4C10.0304 4 10.5391 3.78929 10.9142 3.41421C11.2893 3.03914 11.5 2.53043 11.5 2C11.5 1.46957 11.2893 0.960859 10.9142 0.585786C10.5391 0.210714 10.0304 0 9.5 0C8.96957 0 8.46086 0.210714 8.08579 0.585786C7.71071 0.960859 7.5 1.46957 7.5 2C7.5 2.53043 7.71071 3.03914 8.08579 3.41421C8.46086 3.78929 8.96957 4 9.5 4ZM11.5 9C11.5 9.53043 11.2893 10.0391 10.9142 10.4142C10.5391 10.7893 10.0304 11 9.5 11C8.96957 11 8.46086 10.7893 8.08579 10.4142C7.71071 10.0391 7.5 9.53043 7.5 9C7.5 8.46957 7.71071 7.96086 8.08579 7.58579C8.46086 7.21071 8.96957 7 9.5 7C10.0304 7 10.5391 7.21071 10.9142 7.58579C11.2893 7.96086 11.5 8.46957 11.5 9ZM9.5 18C10.0304 18 10.5391 17.7893 10.9142 17.4142C11.2893 17.0391 11.5 16.5304 11.5 16C11.5 15.4696 11.2893 14.9609 10.9142 14.5858C10.5391 14.2107 10.0304 14 9.5 14C8.96957 14 8.46086 14.2107 8.08579 14.5858C7.71071 14.9609 7.5 15.4696 7.5 16C7.5 16.5304 7.71071 17.0391 8.08579 17.4142C8.46086 17.7893 8.96957 18 9.5 18Z"
          fill="#666666"
        />
      </svg>
    </SvgIcon>
  );
}
