import React from "react";
import {
  FormControlLabel,
  Checkbox as MuiCheckbox,
  Typography,
} from "@material-ui/core";

const Checkbox = (props) => {
  const {
    disabled,
    name,
    label,
    value,
    color,
    onChange,
    style = {},
    checkboxStyles = {},
    fontWeight = 600,
    labelStyle = {},
  } = props;
  return (
    <FormControlLabel
      control={
        <MuiCheckbox
          name={name}
          color={color}
          checked={value}
          onChange={disabled ? () => {} : onChange}
          style={{ ...checkboxStyles }}
        />
      }
      label={
        <Typography
          style={{ ...labelStyle, fontSize: 14, fontWeight: fontWeight }}
        >
          {label}
        </Typography>
      }
      labelPlacement="end"
      style={{ ...style }}
    />
  );
};
export default Checkbox;
