import { CLEAR_COMPLETE } from "../commom/commonTypes";
import { FETCH_WALLET_BALANCE, PROGRESS_WALLET_BALANCE } from "./walletTypes";

const initialState = {
  progress: false,
  loaded: false,
  walletData: null,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PROGRESS_WALLET_BALANCE:
      return {
        ...state,
        progress: action.payload,
      };

    case FETCH_WALLET_BALANCE:
      return {
        ...state,
        loaded: true,
        progress: false,
        walletData: {...action.payload},
      };

    case CLEAR_COMPLETE:
      return {
        ...state,
        progress: false,
        balance: null,
        loaded: false,
        error: null,
      };
    default:
      return state;
  }
};
export default reducer;
