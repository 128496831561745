import PaymentIcon from "@material-ui/icons/Payment";
import React, { useState } from "react";
import Controls from "../../components/controls/Controls";
import { toLocaleISOString } from "../../utils/dateUtils";
import FormModal from "../common/FormModal";
import useStyles from "./styles";
import { useSelector } from "react-redux";

const RecordPaymentModal = ({ open, amount, payment, onClose, onRecord }) => {
  const classes = useStyles();
  const paymentModes = [
    { id: "CASH", title: "Cash" },
    { id: "WALLET", title: "Wallet" },
    { id: "UPI", title: "UPI" },
    { id: "CARD", title: "Card" },
    { id: "CHEQUE", title: "Cheque" },
    { id: "NETBANKING", title: "Net Banking" },
    { id: "OTHER", title: "Other" },
  ];
  const [invDateError, setInvDateError] = useState(false);
  const [error, setError] = useState("");
  const [invDateFocus, setInvDateFocus] = useState(false);
  const [btnError, setBtnError] = useState(false);
  const [loader, setLoader] = useState(false);
  const store = useSelector((state) => state.store.store);
  const countrySymbol = store.currency_symbol;
  let dueAmount = amount;
  const initValues = payment
    ? { ...payment, amount: payment.amount.toFixed(2) }
    : {
        amount: dueAmount.toFixed(2),
        created_at: new Date().toISOString(),
        payment_type: "none",
        reference_id: null,
        order_id: null,
        notes: null,
      };

  const [paymentData, setPaymentData] = useState({
    amount: initValues.amount,
    created_at: initValues.created_at,
    order_id: initValues.order_id,
    payment_type: initValues.payment_type,
    reference_id: initValues.reference_id,
    notes: initValues.notes,
    id: null,
  });

  const onComplete = () => {
    if (error || invDateError) {
      return;
    }
    if (payment == null && paymentData.amount.trim() === "") {
      setError("Enter the paid amount");
      return;
    }
    if (!paymentData.payment_type || paymentData.payment_type === "none") {
      setBtnError(true);
      return;
    }
    setLoader(true);
    onRecord({ ...paymentData, amount: parseFloat(paymentData.amount) });
  };

  const onModalClose = () => {
    onClose();
  };
  return (
    <FormModal
      open={open}
      onClose={onModalClose}
      onSave={onComplete}
      heading="Record Payment"
      btnTxt={
        <span>
          &nbsp; Record &nbsp;
          <span style={{ fontSize: 12, fontWeight: 500 }}>
            {parseFloat(dueAmount) - paymentData.amount > 0
              ? `(Due ${countrySymbol}${(
                  parseFloat(dueAmount) - paymentData.amount
                ).toFixed(2)})`
              : ""}
          </span>
        </span>
      }
      loading={loader}
      startIcon={<PaymentIcon fontSize="small" />}
    >
      <div
        className={classes.dividerGrid}
        style={{ display: "block", paddingBottom: 2 }}
      >
        <Controls.Input
          name="amount"
          label="Amount"
          value={paymentData.amount}
          onChange={(e) => {
            setPaymentData({ ...paymentData, amount: e.target.value });
            if (dueAmount < parseFloat(e.target.value)) {
              setError("Paid amount can't be more than Due amount");
              return;
            }
            if (error) setError("");
          }}
          onFocus={() => {
            setError("");
          }}
          onBlur={() => {
            if (paymentData.amount.trim() === "") {
              setError("Enter the paid amount");
            }
          }}
          error={error}
          type="number"
          placeholder={`${countrySymbol}00.00`}
          onEnter={onComplete}
        />
      </div>
      <div
        className={classes.dividerGrid}
        style={{ display: "block", paddingBottom: 2 }}
      >
        <Controls.Select
          label="Payment Type"
          value={paymentData.payment_type}
          placeholder="Select "
          options={paymentModes}
          color="secondary"
          onChange={(e) => {
            setBtnError(false);
            setPaymentData({ ...paymentData, payment_type: e.target.value });
          }}
          error={btnError ? "Select Payment Mode" : ""}
        />
      </div>
      <div
        className={classes.dividerGrid}
        style={{ display: "block", paddingBottom: 2 }}
      >
        <Controls.Input
          InputProps={{
            defaultValue: toLocaleISOString(
              paymentData.created_at
                ? new Date(paymentData.created_at)
                : new Date()
            ),
          }}
          inputProps={{
            style: {
              color: invDateFocus ? "#1A1A1A" : "#999999",
              fontSize: 12,
            },
          }}
          type="date"
          label="Payment Date"
          onFocus={() => {
            setInvDateError(false);
            setInvDateFocus(true);
          }}
          onChange={() => {
            if (invDateError) setInvDateError(false);
          }}
          onBlur={(e) => {
            if (Date.parse(e.target.value)) {
              setPaymentData({
                ...paymentData,
                created_at: new Date(e.target.value).toISOString(),
              });
            } else {
              setInvDateError(true);
            }
            setInvDateFocus(false);
          }}
          onEnter={(e) => {
            if (Date.parse(e.target.value)) {
              setPaymentData({
                ...paymentData,
                created_at: new Date(e.target.value).toISOString(),
              });
            } else {
              setInvDateError(true);
              return;
            }
            setInvDateFocus(false);
            onComplete();
          }}
          error={invDateError ? "Enter Valid Date" : ""}
        />
      </div>

      <div
        className={classes.dividerGrid}
        style={{ display: "block", paddingBottom: 2 }}
      >
        <Controls.Input
          name="reference_id"
          label="Reference ID"
          value={paymentData.reference_id}
          onChange={(e) => {
            setPaymentData({ ...paymentData, reference_id: e.target.value });
          }}
          type="text"
          onEnter={onComplete}
        />
      </div>
      <div
        className={classes.dividerGrid}
        style={{ display: "block", paddingBottom: 2 }}
      >
        <Controls.Input
          name="notes"
          label="Notes"
          value={paymentData.notes}
          onChange={(e) => {
            setPaymentData({
              ...paymentData,
              notes: e.target.value,
            });
          }}
          type="text"
          onEnter={onComplete}
        />
      </div>
    </FormModal>
  );
};

export default RecordPaymentModal;
