import React, { useState, useEffect } from "react";
import useStyles from "./styles";
import {
  FormControl,
  Grid,
  Typography,
  Select,
  MenuItem,
  Checkbox,
} from "@material-ui/core";
import InfoIcon from "../../icons/InfoIcon";
import Controls from "../../components/controls/Controls";
import SubtitleSelect from "../../components/controls/SubtitleSelect";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchReportList,
  clearReports,
  generateReport as generateReportApi,
} from "../../redux/reports/reportActions";
import NoReports from "./NoReports";
import SingleReport from "./SingleReport";
import ReportTableHead from "./ReportTableHead";
import Paginator from "../../components/Paginator/Paginator";
import {
  reportTypes,
  initialState,
  durationList,
  formatList,
} from "./Constants";
import {
  DEFAULT_REPORTS_PER_PAGE,
  getDateRange,
  REPORTS_KEY,
  REPORTS_PER_PAGE,
} from "../../utils/utils";
import {
  disableContainer,
  layoutBodyBox,
  layoutContainer,
  layoutLoaderHeader,
  rowFlexOnlyJustifyCenter,
} from "../../styles/common";
import SolidButton from "../../components/buttons/SolidButton";
import PageLoader from "../../components/Layout/PageLoader";
import { BASIC, PLANS } from "../plans/constants";
import PaidPopUp from "../../components/PaidPopUp";
import HeaderBreadcrumbs from "../../components/common/HeaderBreadcrumbs";
import { getStoreIdFromUrl } from "../../utils/storeUtil";
import HighlightWidget from "../../components/common/HighlightWidget";

function getReportOptions(options, storeId) {
  return options.filter(
    (rep) =>
      !rep.availableForStoreIds ||
      rep.availableForStoreIds.includes(parseInt(storeId, 10))
  );
}

const Reports = () => {
  const classes = useStyles();
  const [formState, setFormState] = useState(initialState);
  const [columnList, setColumnList] = useState([]);
  const [dateRangeError, setDateRangeError] = useState(false);
  const reportState = useSelector((state) => state.reports);
  const storeState = useSelector((state) => state.store);
  const wallet = useSelector((state) => state.wallet.walletData);
  const [currentPage, setCurrentPage] = useState(0);
  const [maxPageSoFar, setMaxPageSoFar] = useState(0);
  const [reportsPerPage, setReportsPerPage] = useState(REPORTS_PER_PAGE);
  const storeId = getStoreIdFromUrl();
  const reportOptions = getReportOptions(reportTypes, storeId);
  const [planNeeded, setPlanNeeded] = useState(null);
  const [planSubTxt, setPlanSubTxt] = useState("");
  let recordsPerPage = currentPage * reportsPerPage;
  const onNextPage = () => {
    if (maxPageSoFar < currentPage + 1) {
      dispatch(fetchReportList(reportsPerPage, currentPage + 1));
      setMaxPageSoFar(
        maxPageSoFar < currentPage + 1 ? currentPage + 1 : maxPageSoFar
      );
      setCurrentPage(currentPage + 1);
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const onPrevPage = () => {
    setCurrentPage(currentPage - 1);
  };
  const onChangeReportsPerPage = (updatedReportsPerPage) => {
    localStorage.setItem(REPORTS_KEY, updatedReportsPerPage);
    dispatch(clearReports());
    setCurrentPage(0);
    setMaxPageSoFar(0);
    setReportsPerPage(updatedReportsPerPage);
    dispatch(fetchReportList(updatedReportsPerPage, 0));
  };
  const onFormFieldChange = (name, value) => {
    let clonedState = {
      ...formState,
    };
    clonedState[name].value = value;
    setFormState(clonedState);
  };
  const areValidFormFields = (state) => {
    let flag = true;
    if (state.duration.value === "custom") {
      if (!state.startDate.value || !state.endDate.value) {
        setDateRangeError("Please select a start and end date");
      } else if (
        new Date(state.startDate.value) > new Date(state.endDate.value)
      ) {
        setDateRangeError("Start Date must be before end date");
        flag = false;
      } else {
        if (dateRangeError !== false) {
          setDateRangeError(false);
        }
      }
    } else {
      if (dateRangeError !== false) {
        setDateRangeError(false);
      }
    }
    return flag;
  };
  // Api calls and effects
  const generateReport = async () => {
    if (areValidFormFields(formState)) {
      let range = formState.duration.value;
      let { aoe, boe } = getDateRange(range);
      if (
        aoe == null &&
        boe == null &&
        formState.startDate.value &&
        formState.endDate.value
      ) {
        aoe = new Date(
          new Date(formState.startDate.value).setHours(0, 0, 0, 0)
        ).toISOString();
        boe = new Date(
          new Date(formState.endDate.value).setHours(23, 59, 59, 59)
        ).toISOString();
      }
      if (aoe && boe) {
        let data = {
          created_aoe: aoe,
          created_boe: boe,
          report_generator_type: formState.report_type.value,
          report_type: formState.report_type.value,
          type: formState.report_type.value,
          report_columns: formState.columns.value,
          format: formState.format.value,
        };
        dispatch(generateReportApi(data));
        setCurrentPage(0);
      }
    }
  };
  const fetchColumns = async (reportType) => {
    try {
      let response = await fetch(
        `${process.env.REACT_APP_API}/api/v3/stores/report-columns/${reportType}`
      );
      if (!response.ok) {
        throw response;
      } else {
        let data = await response.json();
        setColumnList(data.payload);
      }
    } catch (err) {
      console.log({ err, message: "Failed to fetch columns" });
    }
  };
  const dispatch = useDispatch();
  useEffect(() => {
    if (reportState.fetchReportStatus !== "completed") {
      dispatch(fetchReportList(reportsPerPage));
    }
    fetchColumns("ORDER");
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Grid container spacing={2}>
        <HighlightWidget screen="reports" />
        <Grid
          item
          xs={12}
          className={`${rowFlexOnlyJustifyCenter} ${
            wallet && wallet.balance < wallet.order_balance
              ? disableContainer
              : ""
          }`}
        >
          <div className={layoutContainer}>
            <div className={layoutLoaderHeader}>
              <HeaderBreadcrumbs list={[{ label: "Reports" }]} />
            </div>
            {reportState.fetchReportStatus === "loading" &&
            !reportState.reportList.length ? (
              <PageLoader />
            ) : (
              <div style={{ position: "relative", width: "100%" }}>
                <div className={layoutBodyBox}>
                  <div className={classes.typePicker}>
                    <SubtitleSelect
                      optionList={reportOptions}
                      value={formState.report_type.value}
                      width="40%"
                      onChange={(value, plan) => {
                        if (
                          plan &&
                          PLANS[storeState.store.plan].weight <
                            PLANS[plan].weight
                        ) {
                          setPlanSubTxt(
                            plan === BASIC
                              ? "Generate order item, payment and product inventory reports with the Basic plan"
                              : `Generate all kind of reports with the ${PLANS[plan].name} plan`
                          );
                          setPlanNeeded(plan);
                          return;
                        }
                        onFormFieldChange("report_type", value);
                        fetchColumns(value);
                      }}
                      label="Select Report"
                      labelClass={classes.typePickerHeader}
                      boldDisplayValue={true}
                    />
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginTop: "12px",
                      }}
                    >
                      <InfoIcon />
                      <Typography className={classes.noteText}>
                        {
                          reportTypes.filter(
                            (rt) => rt.value === formState.report_type.value
                          )[0].note
                        }
                      </Typography>
                    </div>
                  </div>
                  <div>
                    <Typography className={classes.secondaryLabel}>
                      Columns
                    </Typography>
                    <FormControl
                      className={classes.reportsSecondaryControl}
                      color="secondary"
                      variant="outlined"
                      style={{
                        marginLeft: "40px",
                        marginBottom: "16px",
                        marginTop: "0px",
                      }}
                    >
                      <Select
                        multiple
                        variant="outlined"
                        disableUnderline
                        color="secondary"
                        id="columns select box"
                        value={
                          formState.columns.value.length
                            ? formState.columns.value
                            : ["none"]
                        }
                        onChange={(e) => {
                          let value = e.target.value;
                          let clonedState = { ...formState };
                          if (value[0] === "none") {
                            value.shift();
                          }
                          clonedState.columns.value =
                            typeof value === "string"
                              ? value.split(",")
                              : value;
                          setFormState(clonedState);
                        }}
                        placeholder="Select Columns"
                        renderValue={(selected) => {
                          return (
                            <>
                              {selected[0] === "none" ||
                              selected.length === columnList.length ? (
                                <span className={classes.columnsDisplayVal}>
                                  All Columns
                                </span>
                              ) : (
                                <span className={classes.columnsDisplayVal}>
                                  {selected.length} columns selected
                                </span>
                              )}
                            </>
                          );
                        }}
                        classes={{
                          icon: classes.icon,
                        }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                          PaperProps: {
                            style: {
                              maxHeight: "300px",
                            },
                          },
                        }}
                      >
                        <MenuItem
                          value="none"
                          style={{ padding: "12px", margin: "0px" }}
                          disabled
                        >
                          <span style={{ fontSize: "12px" }}>All Columns</span>
                        </MenuItem>
                        {columnList.map((column) => {
                          let isChecked =
                            formState.columns.value.indexOf(
                              column.mapping_name
                            ) > -1;
                          return (
                            <MenuItem
                              key={column.id}
                              value={column.mapping_name}
                              style={{ padding: "3px" }}
                            >
                              <Checkbox checked={isChecked} />
                              <span
                                className={
                                  isChecked
                                    ? classes.checkedColumnsListText
                                    : classes.columnsListText
                                }
                              >
                                {column.display_name}
                              </span>
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className={classes.SelectDisplayBox}>
                      <SubtitleSelect
                        optionList={durationList}
                        value={formState.duration.value}
                        width="320px"
                        onChange={(value) => {
                          onFormFieldChange("duration", value);
                        }}
                        label="Duration"
                        labelClass={classes.customSelectLabel}
                        error={dateRangeError}
                      />
                    </div>
                    {formState.duration.value === "custom" && (
                      <>
                        <Controls.Input
                          name="startDate"
                          label="From"
                          type="date"
                          value={formState.startDate.value}
                          color="secondary"
                          onChange={(e) =>
                            onFormFieldChange(e.target.name, e.target.value)
                          }
                          onFocus={() => {}}
                          onBlur={() => {}}
                          controlClass={classes.controlInput}
                          error={
                            formState.startDate.error.length
                              ? formState.startDate.error
                              : ""
                          }
                          labelClass={classes.dateLabel}
                          labelPlacement="top"
                          extLabelWidth={classes.labelWidth}
                        />
                        <Controls.Input
                          name="endDate"
                          label="To"
                          type="date"
                          value={formState.endDate.value}
                          color="secondary"
                          onChange={(e) =>
                            onFormFieldChange(e.target.name, e.target.value)
                          }
                          onFocus={() => {}}
                          onBlur={() => {}}
                          controlClass={classes.controlInput}
                          error={
                            formState.endDate.error.length
                              ? formState.endDate.error
                              : ""
                          }
                          labelClass={classes.dateLabel}
                          labelPlacement="top"
                          extLabelWidth={classes.labelWidth}
                        />
                      </>
                    )}
                  </div>
                  <div className={classes.SelectDisplayBox}>
                    <SubtitleSelect
                      optionList={formatList}
                      value={formState.format.value}
                      width="320px"
                      onChange={(value) => {
                        onFormFieldChange("format", value);
                      }}
                      label="Format"
                      labelClass={classes.customSelectLabel}
                    />
                  </div>
                  <SolidButton
                    style={{
                      margin: "8px 0px 12px 40px",
                      fontSize: "0.875rem",
                      fontWeight: 500,
                      lineHeight: 1.75,
                      height: 44,
                    }}
                    onClick={() => generateReport()}
                  >
                    Generate Report
                  </SolidButton>
                  {reportState.reportList.length === 0 ? (
                    <NoReports />
                  ) : (
                    <>
                      <div className={classes.reportArea}>
                        <ReportTableHead />
                        {reportState.fetchReportStatus === "loading" && (
                          <div style={{ height: "378px", width: "100%" }}></div>
                        )}
                        {reportState.reportList
                          .slice(
                            recordsPerPage,
                            recordsPerPage + reportsPerPage <
                              reportState.reportList.length
                              ? recordsPerPage + reportsPerPage
                              : reportState.reportList.length
                          )
                          .map((report, idx) => {
                            return (
                              <SingleReport
                                key={report.id}
                                report={report}
                                noBorder={idx === reportsPerPage - 1}
                              />
                            );
                          })}
                      </div>
                    </>
                  )}
                </div>
                <Paginator
                  changeRecordsPerPage={onChangeReportsPerPage}
                  possibleRecordsPerPage={[
                    DEFAULT_REPORTS_PER_PAGE,
                    10,
                    20,
                    50,
                  ]}
                  recordsPerPage={reportsPerPage}
                  onNext={onNextPage}
                  onPrev={onPrevPage}
                  total={Math.ceil(reportState.totalElements / reportsPerPage)}
                  currentPage={currentPage}
                />
              </div>
            )}
          </div>
        </Grid>
      </Grid>
      <PaidPopUp
        open={!!planNeeded}
        close={() => setPlanNeeded(null)}
        plan={planNeeded ? planNeeded : BASIC}
        subtxt={planSubTxt}
      />
    </div>
  );
};

export default Reports;
