import {
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@material-ui/core";
import useStyles from "../styles";
import React, { Fragment, useEffect, useState } from "react";
import EditPen from "../../../icons/EditPen";
import SettingsIcon from "../../../icons/SettingsIcon";
import RoundAddIcon from "../../../icons/RoundAddIcon";
import { useDispatch, useSelector } from "react-redux";
import { updateBillSettings } from "../../../redux/billSettings/billSettingsActions";
import AddBankModal from "./AddBankModal";
import AddUpiModal from "./AddUpiModal";
import PaymentAccordion from "../../payments/components/PaymentAccordion";
import { transparentButton } from "../../../styles/common";

const InvoiceSettingsSide = ({
  invoice,
  setInvoice,
  payment,
  paymentDetails,
  dueAmount,
  onPaymentEdit,
  setOpenRecordPayment,
}) => {
  const classes = useStyles();
  const store = useSelector((state) => state.store.store);
  const countrySymbol = store.currency_symbol;
  const billSettings = useSelector((state) => state.billSettings.settings);
  const dispatch = useDispatch();
  const [openAddBank, setOpenAddBank] = useState(false);
  const [openAddUpi, setOpenAddUpi] = useState(false);

  const bankAccAvailable =
    store.ac_holder_name &&
    store.account_number &&
    store.ifsc_code &&
    store.bank_name &&
    store.ac_type;

  const [tos, setTos] = useState("");
  let { payments } = paymentDetails;
  let dueAmountWithPayment = dueAmount - (payment ? payment.amount : 0);

  useEffect(() => {
    if (billSettings.tos === null) {
      setTos(
        `All goods remain the property of ${store.name} until full payment has been received`
      );
    } else {
      setTos(billSettings.tos);
    }
    // eslint-disable-next-line
  }, [billSettings]);

  return (
    <Fragment>
      <div>
        <Typography
          style={{
            fontWeight: 600,
            color: "#1A1A1A",
            paddingBottom: 12,
            marginTop: 30,
            borderBottom: "1px solid #E5E5E5",
            fontSize: 16,
          }}
        >
          Payment Details
        </Typography>
        {paymentDetails.progress && (
          <div style={{ textAlign: "center", marginTop: 20 }}>
            <CircularProgress color="secondary" />
          </div>
        )}
        {!paymentDetails.progress && (
          <div>
            {payments.length > 0 &&
              payments.map((pmt, i) => {
                return <PaymentAccordion key={i} payment={pmt} />;
              })}
            {payment != null && (
              <PaymentAccordion
                payment={payment}
                showEdit={true}
                onEdit={onPaymentEdit}
              />
            )}
            <div
              style={{
                width: "100%",
                marginTop: -12,
              }}
              className={classes.flexBox}
            >
              <Typography
                className={classes.subHeading}
                style={{ paddingTop: 18 }}
              >
                Due
              </Typography>
              <Typography
                className={classes.subHeading}
                style={{ paddingTop: 18 }}
              >
                {countrySymbol} {parseFloat(dueAmountWithPayment).toFixed(2)}
              </Typography>
            </div>
            {dueAmountWithPayment > 0 && payment === null && (
              <Button
                className={transparentButton}
                onClick={() => setOpenRecordPayment(true)}
              >
                + RECORD PAYMENT
              </Button>
            )}
          </div>
        )}
      </div>
      <Typography
        style={{
          fontWeight: 600,
          color: "#1A1A1A",
          paddingBottom: 12,
          marginTop: 24,
          borderBottom: "1px solid #E5E5E5",
          fontSize: 16,
        }}
      >
        Invoice Settings{" "}
        <span style={{ marginLeft: 5 }}>
          <SettingsIcon stroke="#666666" />
        </span>
      </Typography>
      <div
        style={{
          paddingBottom: 24,
          marginTop: 24,
          borderBottom: "1px solid #E5E5E5",
        }}
      >
        <Typography
          style={{
            fontWeight: 600,
            color: "#666666",
            fontSize: 12,
          }}
        >
          Bank Details
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 10,
          }}
        >
          {bankAccAvailable && (
            <Typography
              style={{
                fontWeight: 400,
                color: "#999999",
                fontSize: 12,
              }}
            >
              {store.ac_holder_name} <br />
              {store.account_number} <br />
              {store.ifsc_code} <br />
              {store.bank_name} <br />
              {store.ac_type
                ? store.ac_type.charAt(0).toUpperCase() +
                  store.ac_type.substr(1).toLowerCase()
                : ""}
            </Typography>
          )}
          {!bankAccAvailable && (
            <Typography
              style={{
                fontWeight: 400,
                color: "#999999",
                fontSize: 12,
              }}
            >
              Not Added
            </Typography>
          )}
          {!bankAccAvailable && (
            <span onClick={() => setOpenAddBank(true)}>
              <RoundAddIcon />
            </span>
          )}
        </div>
      </div>
      <div
        style={{
          paddingBottom: 24,
          marginTop: 24,
          borderBottom: "1px solid #E5E5E5",
        }}
      >
        <Typography
          style={{
            fontWeight: 600,
            color: "#666666",
            fontSize: 12,
          }}
        >
          UPI
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 10,
          }}
        >
          {store.upi_address && (
            <Typography
              style={{
                fontWeight: 400,
                color: "#999999",
                fontSize: 12,
              }}
            >
              {store.upi_address}
            </Typography>
          )}
          {store.upi_address && (
            <span onClick={() => setOpenAddUpi(true)}>
              <EditPen />
            </span>
          )}
          {!store.upi_address && (
            <Typography
              style={{
                fontWeight: 400,
                color: "#999999",
                fontSize: 12,
              }}
            >
              Not Added
            </Typography>
          )}
          {!store.upi_address && (
            <span onClick={() => setOpenAddUpi(true)}>
              <RoundAddIcon />
            </span>
          )}
        </div>
      </div>
      <div className={classes.tosBox}>
        <Typography className={classes.tosHead}>Terms Of Services</Typography>
        <div style={{ width: "100%" }}>
          <TextField
            style={{ width: "100%" }}
            InputProps={{
              className: classes.tosBody,
              disableUnderline: true,
              multiline: true,
            }}
            value={tos}
            onChange={(e) => setTos(e.target.value)}
            onBlur={() => {
              dispatch(updateBillSettings({ ...billSettings, tos: tos }));
            }}
          />
        </div>
      </div>
      <AddBankModal openAddBank={openAddBank} setOpenAddBank={setOpenAddBank} />
      <AddUpiModal openAddUpi={openAddUpi} setOpenAddUpi={setOpenAddUpi} />
    </Fragment>
  );
};

export default InvoiceSettingsSide;
