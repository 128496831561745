import React, { useState, useEffect } from "react";
import { Grid, Typography, ClickAwayListener } from "@material-ui/core";
import useStyles from "../styles";
import SalesIcon from "../../../icons/SalesIcon";
import DashboardOrdIcon from "../../../icons/DashboardOrdIcon";
import {
  FILTER_KEY,
  INSIGHTS_FILTER,
  getAbandonedCarts,
  getLowStockCount,
  getOrders,
  getSales,
} from "../../../utils/dashboardUtils";
import { Skeleton } from "@material-ui/lab";
import { rowFlexJustifyBetween } from "../../../styles/common";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import TextButton from "../../../components/buttons/TextButton";
import LowStocksIcon from "../../../icons/LowStocksIcon";
import AbandonedCartsIcon from "../../../icons/AbandonedCartsIcon";
import { useDispatch, useSelector } from "react-redux";
import { setInsightsFilter } from "../../../redux/dashboard/dashboardActions";
import { useHistory } from "react-router-dom";
import { getStoreIdFromUrl } from "../../../utils/storeUtil";
const StoreInsights = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const dashboardState = useSelector((state) => state.dashboard);
  const insightsFilter = dashboardState.insightsFilter;
  const [sales, setSales] = useState(null);
  const [orders, setOrders] = useState(null);
  const [lowStockCount, setLowStockCount] = useState(null);
  const [abandonedCarts, setAbandonedCarts] = useState(null);
  const [openDropdown, setOpenDropdown] = useState(false);
  const storeId = getStoreIdFromUrl();
  const store = useSelector((state) => state.store.store);
  const countrySymbol = store.currency_symbol;
  useEffect(() => {
    if (!insightsFilter) {
      dispatch(setInsightsFilter(INSIGHTS_FILTER));
    }
    // eslint-disable-next-line
  }, [insightsFilter]);

  useEffect(() => {
    if (insightsFilter) {
      getSales(insightsFilter).then((sals) => {
        setSales(sals);
      });
      getOrders(insightsFilter).then((ords) => {
        setOrders(ords);
      });
      getLowStockCount().then((counts) => {
        setLowStockCount(counts);
      });
      getAbandonedCarts(insightsFilter).then((carts) => {
        setAbandonedCarts(carts);
      });
    }
  }, [storeId, insightsFilter]);

  const storeInsights = [
    {
      icon: <SalesIcon />,
      title: "Sales",
      value: sales !== undefined ? sales : null,
      change: "",
      isRupee: true,
    },
    {
      icon: <DashboardOrdIcon />,
      title: "Orders",
      value: orders !== undefined ? orders : null,
      change: "",
      isRupee: false,
    },
    {
      icon: <LowStocksIcon />,
      title: "Low Stocks",
      value: lowStockCount !== undefined ? lowStockCount : null,
      change: "",
      isRupee: false,
      onClick: () =>
        history.push(
          `/${storeId}/products?filter=lowstock${
            storeId == "288581" ? "&max=5" : ""
          }`
        ),
    },
    {
      icon: <AbandonedCartsIcon />,
      title: "Abandoned Carts",
      value: abandonedCarts !== undefined ? abandonedCarts : null,
      change: "",
      isRupee: false,
      onClick: () =>
        history.push(
          `/${storeId}/carts?filter=${insightsFilter
            .replace(" ", "-")
            .toLowerCase()}`
        ),
    },
  ];

  const handleFilter = (filBy) => {
    localStorage.setItem(FILTER_KEY, filBy);
    dispatch(setInsightsFilter(filBy));
    setSales(null);
    setOrders(null);
    setAbandonedCarts(null);
    getSales(filBy).then((sals) => {
      setSales(sals);
    });
    getOrders(filBy).then((ords) => {
      setOrders(ords);
    });
    getAbandonedCarts(filBy).then((carts) => {
      setAbandonedCarts(carts);
    });
  };

  return (
    <div>
      <div
        className={rowFlexJustifyBetween}
        style={{ alignItems: "flex-start" }}
      >
        <Typography className={classes.heading}>Store Insights</Typography>
        <ClickAwayListener
          onClickAway={() => {
            setOpenDropdown(false);
          }}
        >
          <div style={{ position: "relative" }}>
            <TextButton
              style={{
                textDecoration: "none",
                fontSize: 12,
                fontWeight: 400,
                color: "#1A1A1A",
                "&:hover": {
                  background: "#FFFFFF !important",
                },
              }}
              className={classes.textBtn}
              endIcon={
                openDropdown ? (
                  <ExpandLess style={{ color: "#7D7D7D" }} />
                ) : (
                  <ExpandMore style={{ color: "#7D7D7D" }} />
                )
              }
              onClick={() => {
                setOpenDropdown(!openDropdown);
              }}
            >
              {insightsFilter}
            </TextButton>
            <div
              className={classes.sheet}
              style={{
                visibility: openDropdown ? "visible" : "hidden",
                display: "block",
                width: 93,
                padding: 4,
                top: 25,
              }}
            >
              <div
                className={classes.bulkAction}
                onClick={(e) => {
                  handleFilter("Today");
                  setOpenDropdown(false);
                }}
              >
                <span>Today</span>
              </div>
              <div
                className={classes.bulkAction}
                onClick={(e) => {
                  handleFilter("Yesterday");
                  setOpenDropdown(false);
                }}
              >
                <span>Yesterday</span>
              </div>
              <div
                className={classes.bulkAction}
                onClick={(e) => {
                  handleFilter("This Week");
                  setOpenDropdown(false);
                }}
              >
                <span>This Week</span>
              </div>
              <div
                className={classes.bulkAction}
                onClick={(e) => {
                  handleFilter("This Month");
                  setOpenDropdown(false);
                }}
              >
                <span>This Month</span>
              </div>
              <div
                className={classes.bulkAction}
                onClick={(e) => {
                  handleFilter("Last Week");
                  setOpenDropdown(false);
                }}
              >
                <span>Last Week</span>
              </div>
              <div
                className={classes.bulkAction}
                onClick={(e) => {
                  handleFilter("Last Month");
                  setOpenDropdown(false);
                }}
              >
                <span>Last Month</span>
              </div>
            </div>
          </div>
        </ClickAwayListener>
      </div>
      <Grid container spacing={2}>
        {storeInsights.map((insight, index) => {
          return (
            <Grid item xs={6} md={3} key={index}>
              <div
                className={classes.card}
                style={{ cursor: insight.onClick && "pointer" }}
                onClick={insight.onClick}
              >
                {insight.icon}
                <div style={{ marginLeft: 16 }}>
                  {insight.value === null ? (
                    <>
                      <Skeleton animation="wave" width={50} height={25} />
                      <Skeleton animation="wave" width={80} height={20} />
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          style={{
                            fontWeight: 600,
                            fontSize: 16,
                            lineHeight: "28px",
                          }}
                        >
                          {insight.isRupee && (
                            <span className={classes.rupee}>
                              {countrySymbol}
                            </span>
                          )}
                          {insight.title === "Bounce Rate"
                            ? `${insight.value?.toFixed()} %`
                            : insight.title === "Avg Visit Time"
                            ? insight.value.minutes > 0
                              ? `${insight.value.minutes}m ${insight.value.remainingSeconds}s`
                              : insight.value.remainingSeconds
                              ? `${insight.value.remainingSeconds}s`
                              : `${insight.value}s`
                            : insight.value?.toFixed()}{" "}
                        </Typography>
                        <Typography
                          style={{
                            fontWeight: 400,
                            fontSize: 14,
                            color:
                              insight.change > 0 || insight.sign === "positive"
                                ? "#16AC67"
                                : insight.change < 0 ||
                                  insight.sign === "negative"
                                ? "#FF6060"
                                : "",
                            marginLeft: "25px",
                          }}
                        >
                          {insight.title === "Bounce Rate"
                            ? `${insight.change} %`
                            : insight.title === "Avg Visit Time"
                            ? insight.change.minutes > 0
                              ? `${insight.change.minutes}m ${insight.change.remainingSeconds}s`
                              : insight.change.remainingSeconds
                              ? `${insight.change.remainingSeconds}s`
                              : `${insight.change}s`
                            : insight.change}
                        </Typography>
                      </div>
                      <Typography
                        style={{
                          fontWeight: 400,
                          fontSize: 16,
                          lineHeight: "20px",
                        }}
                      >
                        {insight.title}
                      </Typography>
                    </>
                  )}
                </div>
              </div>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default StoreInsights;
