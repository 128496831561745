import { makeStyles, Typography } from "@material-ui/core";
import React, { useState } from "react";
import FormModal from "../../../components/common/FormModal";
import Controls from "../../../components/controls/Controls";
import { rowFlexJustifyBetween } from "../../../styles/common";
import {
  fetchOpenOrders,
  postStoreTransaction,
} from "../../../utils/customerUtils";
import { toLocaleISOString } from "../../../utils/dateUtils";
import { useSelector } from "react-redux";

const useStyles = makeStyles(() => ({
  subtxt: {
    fontSize: 14,
    color: "#666666",
    fontWeight: 400,
    "& span": {
      color: "#1A1A1A",
      fontWeight: 600,
    },
    position: "absolute",
    top: -16,
  },
  dividerGrid: {
    paddingTop: 20,
    paddingBottom: 2,
    borderBottom: "1px solid #E6E6E6",
  },
  radioLabel: {
    marginRight: 24,
  },
}));

const StoreTransactionModal = ({
  transactionModalType,
  name,
  balance,
  onClose,
  customerId,
  subText,
  open,
  mobile,
  saveRecordCallback,
}) => {
  const classes = useStyles();
  const store = useSelector((state) => state.store.store);
  const countrySymbol = store.currency_symbol;
  const [invDateError, setInvDateError] = useState(false);
  const [invDateFocus, setInvDateFocus] = useState(false);
  const [error, setError] = useState("");
  const [btnError, setBtnError] = useState(false);
  const [openOrders, setOpenOrders] = useState([]);
  const [paymentData, setPaymentData] = useState({
    amount: "",
    tx_date: new Date().toISOString(),
    description: "",
    type: "GIVEN",
    refNo: "",
    paymentType: "none",
  });
  const [dueAmount, setDueAmount] = useState(null);
  const [loading, setLoading] = useState(false);
  const paymentModes = [
    { id: "CASH", title: "Cash" },
    { id: "WALLET", title: "Wallet" },
    { id: "UPI", title: "UPI" },
    { id: "CARD", title: "Card" },
    { id: "CHEQUE", title: "Cheque" },
    { id: "NETBANKING", title: "Net Banking" },
    { id: "OTHER", title: "Other" },
  ];

  React.useEffect(() => {
    if (transactionModalType) {
      setPaymentData({ ...paymentData, type: transactionModalType });
    }
    // eslint-disable-next-line
  }, [transactionModalType]);

  React.useEffect(() => {
    if (!!paymentData.refNo || paymentData.refNo === "none") {
      const due =
        openOrders.find((order) => {
          return order.id === paymentData.refNo;
        })?.dueAmount ?? null;
      setDueAmount(due);
      if (!!due && due < parseFloat(paymentData.amount.trim())) {
        setError("Paid amount can't be more than Due amount");
        return;
      }
    }
    // eslint-disable-next-line
  }, [paymentData.refNo]);

  React.useEffect(() => {
    if (open) {
      fetchOpenOrders(customerId, paymentData.type).then((res) => {
        setOpenOrders(
          res.data.payload.map((obj) => {
            return {
              id: obj.display_id,
              title: (
                <div
                  className={rowFlexJustifyBetween}
                  style={{ width: "100%", padding: "3px 0" }}
                >
                  <div>
                    {obj.type === "ONLINE" ? obj.display_id : obj.invoice_id}{" "}
                  </div>
                  <div>{`(Due ${countrySymbol}${Math.abs(
                    obj.due_amount
                  )})`}</div>
                </div>
              ),
              dueAmount: Math.abs(obj.due_amount),
            };
          })
        );
      });
    }
    // eslint-disable-next-line
  }, [open, paymentData.type]);

  const close = () => {
    onClose();
    setPaymentData({
      amount: "",
      tx_date: new Date().toISOString(),
      description: "",
      type: "RECEIVED",
      refNo: "",
    });
    setLoading(false);
    setError("");
    setInvDateError("");
    setBtnError(false);
    setDueAmount(null);
  };

  const onSave = () => {
    if (!paymentData.amount.trim()) {
      setError("Enter the amount");
      return;
    }
    if (!!dueAmount && dueAmount < parseFloat(paymentData.amount.trim())) {
      setError("Paid amount can't be more than Due amount");
      return;
    }
    if (!paymentData.tx_date) {
      setInvDateError(true);
      return;
    }
    if (!paymentData.paymentType || paymentData.paymentType === "none") {
      setBtnError(true);
      return;
    }

    setLoading(true);
    postStoreTransaction(customerId, {
      amount: paymentData.amount,
      type: paymentData.type,
      description: paymentData.description,
      transaction_date: paymentData.tx_date,
      payment_type: paymentData.paymentType,
      name,
      mobile,
      ref_display_ord_id:
        paymentData.refNo === "none" || !paymentData.refNo
          ? null
          : paymentData.refNo,
    })
      .then((res) => {
        saveRecordCallback(res.data.payload);
        close();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <FormModal
      open={open}
      heading={name}
      onSave={onSave}
      btnTxt={
        <span>
          &nbsp;{" "}
          {paymentData.type === "RECEIVED" ? "Receive Money" : "Give Money"}{" "}
          &nbsp;
          <span style={{ fontSize: 12, fontWeight: 500 }}>
            {parseFloat(dueAmount) - paymentData.amount > 0
              ? `(Due ${countrySymbol}${(
                  parseFloat(dueAmount) - paymentData.amount
                ).toFixed(2)})`
              : ""}
          </span>
        </span>
      }
      btnStyle={{ minWidth: 168, padding: "16px 0px" }}
      onClose={close}
      loading={loading}
    >
      <div style={{ position: "relative", paddingBottom: 12 }}>
        <Typography className={classes.subtxt}>
          {subText} - <span>{balance}</span>
        </Typography>
      </div>

      <Controls.RadioGroup
        name="type"
        label=""
        value={paymentData.type}
        labelClass={classes.label}
        onChange={(e) => {
          setPaymentData({
            ...paymentData,
            type: e.target.value,
            refNo: "",
          });
          setError("");
        }}
        items={[
          { id: "GIVEN", title: "Given" },
          { id: "RECEIVED", title: "Received" },
        ]}
        radioLabelClass={classes.radioLabel}
      />
      <div className={classes.dividerGrid}>
        <Controls.Input
          name="amount"
          label="Amount"
          value={paymentData.amount}
          onChange={(e) => {
            if (!!dueAmount && dueAmount < parseFloat(e.target.value)) {
              setError("Paid amount can't be more than Due amount");
              return;
            }
            setPaymentData({ ...paymentData, amount: e.target.value });
            if (error) setError("");
          }}
          onFocus={() => {
            setError("");
          }}
          onBlur={() => {
            if (paymentData.amount.trim() === "") {
              setError("Enter the amount");
            }
          }}
          error={error}
          type="number"
          placeholder={`${countrySymbol}0.00`}
          onEnter={() => {
            if (paymentData.amount.trim() === "") {
              setError("Enter the amount");
              return;
            }
            onSave();
          }}
        />
      </div>
      <div className={classes.dividerGrid}>
        <Controls.Select
          label="Order/Invoice"
          value={paymentData.refNo}
          placeholder="Reference No. (Optional)"
          options={openOrders}
          color="secondary"
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
          onChange={(e) => {
            setPaymentData({ ...paymentData, refNo: e.target.value });
          }}
        />
      </div>
      <div className={classes.dividerGrid}>
        <Controls.Input
          name="description"
          label="Description"
          value={paymentData.description}
          onChange={(e) => {
            setPaymentData({
              ...paymentData,
              description: e.target.value,
            });
          }}
          type="text"
          onEnter={onSave}
          maxLength={256}
        />
      </div>
      <div className={classes.dividerGrid}>
        <Controls.Select
          label="Payment Type"
          value={paymentData.paymentType}
          placeholder="Select "
          options={paymentModes}
          color="secondary"
          onChange={(e) => {
            setBtnError(false);
            setPaymentData({ ...paymentData, paymentType: e.target.value });
          }}
          error={btnError ? "Select Payment Mode" : ""}
        />
      </div>
      <div className={classes.dividerGrid}>
        <Controls.Input
          InputProps={{
            defaultValue: toLocaleISOString(new Date(paymentData.tx_date)),
          }}
          inputProps={{
            style: {
              color: invDateFocus ? "#1A1A1A" : "#999999",
              fontSize: 12,
            },
          }}
          type="date"
          label={`${paymentData.type === "RECEIVED" ? "Received" : "Given"} On`}
          onFocus={() => {
            setInvDateError(false);
            setInvDateFocus(true);
          }}
          onChange={() => {
            if (invDateError) setInvDateError(false);
          }}
          onBlur={(e) => {
            if (Date.parse(e.target.value)) {
              setPaymentData({
                ...paymentData,
                tx_date: new Date(e.target.value).toISOString(),
              });
            } else {
              setInvDateError(true);
            }
            setInvDateFocus(false);
          }}
          onEnter={(e) => {
            if (Date.parse(e.target.value)) {
              setPaymentData({
                ...paymentData,
                tx_date: new Date(e.target.value).toISOString(),
              });
            } else {
              setInvDateError(true);
              return;
            }
            setInvDateFocus(false);
            onSave();
          }}
          error={invDateError ? "Enter Valid Date" : ""}
        />
      </div>
    </FormModal>
  );
};

export default StoreTransactionModal;
