import React from "react";
export function Header({ fill = "#666666", strokeWidth = 0 }) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_27415_34323"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="18"
        height="18"
      >
        <rect width="18" height="18" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_27415_34323)">
        <path
          d="M3.19983 7.30005C3.19983 7.02391 3.42369 6.80005 3.69983 6.80005H14.2998C14.576 6.80005 14.7998 7.02391 14.7998 7.30005V14.3C14.7998 14.5762 14.576 14.8 14.2998 14.8H3.69983C3.42369 14.8 3.19983 14.5762 3.19983 14.3V7.30005Z"
          stroke="#666666"
        />
      </g>
      <rect x="0.5" y="0.5" width="17" height="17" rx="1.5" stroke="#666666" />
      <rect
        x="3.19989"
        y="3.05005"
        width="11.6"
        height="1.7"
        rx="0.5"
        fill="#F9F9F9"
        stroke="#666666"
      />
    </svg>
  );
}

export function SelHeader({ fill = "#666666", strokeWidth = 0 }) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_29158_4494"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="18"
        height="18"
      >
        <rect width="18" height="18" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_29158_4494)">
        <path
          d="M3.19983 7.30005C3.19983 7.02391 3.42369 6.80005 3.69983 6.80005H14.2998C14.576 6.80005 14.7998 7.02391 14.7998 7.30005V14.3C14.7998 14.5762 14.576 14.8 14.2998 14.8H3.69983C3.42369 14.8 3.19983 14.5762 3.19983 14.3V7.30005Z"
          stroke="#1641DB"
        />
      </g>
      <rect x="0.5" y="0.5" width="17" height="17" rx="1.5" stroke="#1641DB" />
      <rect
        x="3.19989"
        y="3.05005"
        width="11.6"
        height="1.7"
        rx="0.5"
        fill="#F9F9F9"
        stroke="#1641DB"
      />
    </svg>
  );
}
