import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  containersection: {
    width: "100%",
    backgroundColor: "#ffffff",
    borderRadius: "10px",
    boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.04)",
    marginTop: "16px",
  },
  heading: {
    fontSize: "14px",
    fontWeight: "600",
    marginBottom: "12px",
  },
  subHeading: {
    fontSize: "12px",
    fontWeight: "400",
    marginTop: "-10px",
    marginBottom: "12px",
  },
  addProduct: {
    color: "blue",
    border: "1px solid blue",
    padding: "8px 16px",
    borderRadius: "4px",
    fontSize: "14px",
    fontWeight: "600",
    background: "transparent",
    width: "100%",
    marginTop: "12px",
  },

  selector: {
    border: "1px solid #E6E6E6",
    borderRadius: 6,
    fontSize: 12,
    padding: 12,
    height: 44,
    textTransform: "none",
    minWidth: 142,
    justifyContent: "space-between",
  },
  catSheet: {
    position: "absolute",
    top: 50,
    display: "flex",
    borderRadius: 6,
    background: "#FFFFFF",
    overflow: "hidden",
    zIndex: 1,
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.25)",
  },
  hover: {
    "&:hover": {
      background: "rgba(220, 220, 220, 0.2)",
    },
  },
  productBox: {
    paddingBottom: "15px",
    position: "sticky",
    zIndex: 2,
    background: "rgb(255, 255, 255)",
    top: 0,
    paddingTop: 15,
    justifyContent: "space-between",
  },
  prodimage: {
    width: "36px",
    height: "36px",
    borderRadius: "4px",
  },
  checkboxLabel: {
    marginLeft: 0, // Removes the default margin-left
  },
  tableCell: {
    padding: "0px !important",
    "& .MuiFormControlLabel-root": {
      // Note the space between '&' and '.MuiFormControlLabel-root'
      marginLeft: "7px",
      marginRight: "16px",
    },
  },
  headrow: {
    height: "50px",
  },

  //
  tableHeader: {
    display: "flex",
    marginLeft: theme.spacing(3.25),
    marginRight: theme.spacing(4),
    // justifyContent: "space-between",
    marginBottom: theme.spacing(2),
    alignItems: "center",
  },
  search: {
    display: "flex",
    border: "1px solid #E6E6E6",
    borderRadius: 6,
    padding: "12px",
    paddingTop: 9,
    height: "44px",
    width: 410,
    marginRight: "18px",
  },
  searchIcon: {
    height: "100%",
  },
  searchBox: {
    outlineWidth: 0,
    lineHeight: "16px",
    marginLeft: "12px",
    border: "none",
    fontSize: "12px",
    flex: 1,
    minWidth: 0,
    marginTop: "3px",
  },
  button: {
    background: "rgba(23, 57, 183, 0.1)",
    border: "1px solid rgba(23, 57, 183, 0.2)",
    borderRadius: "6px",
    color: "#1641DB",
    boxShadow: "none",
    padding: "12px",

    "&:hover": {
      boxShadow: "unset",
      background: "unset",
    },
  },
  disabledButton: {
    border: "1px solid #eeeeee",
  },
  buttonlabel: {
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "16px",
    textTransform: "none",
  },
  endIcon: {
    marginLeft: 12,
  },
  tablehead: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px -1px 0px #E5E5E5, 0px 1px 0px #E6E6E6",
    borderTop: "1px solid #E6E6E6",
  },
  tablebody: {
    "& tr": {
      height: "69px",
    },
  },
  anchor: {
    "& li": {
      color: "#1641DB",
      fontSize: 12,
      lineHeight: "16px",
    },
  },
  customerName: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: "20px",
    marginBottom: 16,
  },
  addressHeading: {
    fontSize: 16,
    color: "#1A1A1A",
    fontWeight: 600,
    lineHeight: "20px",
    marginBottom: 16,
    textAlign: "center",
    marginTop: "28px",
  },
  addressName: {
    fontSize: 14,
    color: "#1A1A1A",
    fontWeight: 600,
    lineHeight: "20px",
    marginBottom: 4,
    marginTop: -2,
  },
  addressCard: {
    border: "1px solid #E6E6E6",
    padding: "14px",
    borderRadius: "12px",
    display: "flex",
  },
  noAddress: {
    color: "#666666",
    textAlign: "center",
    margin: "30px 0",
  },

  row: {
    display: "grid",
    gap: "28px",
    gridTemplateColumns: "1fr 1fr",
    marginBottom: "10px",
  },
  label: {
    alignItems: "flex-start",
    justifyContent: "flex-end",
    margin: "0 0 8px 0",
  },
  labelWidth: {
    width: "auto",
  },
  controlInput: {
    width: "100% !important",
    margin: "12px 0px 0px 0px !important",
  },
  horizontalDivider: {
    color: "#E5E5E5",
    marginBottom: "16px",
    marginTop: "8px",
    borderWidth: "1px",
  },
  clickableHeading: {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 500,
    marginBottom: "16px",
    color: "#1641DB",
    cursor: "pointer",
  },
  cardContainer: {
    borderRadius: "4px",
    border: "1px solid #E1E1E1",
    padding: "12px",
  },
  primaryTxt: {
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "20px",
  },
  mobileTxt: {
    fontSize: "14px",
    lineHeight: "20px",
    margin: "4px 0px",
  },
  secondaryTxt: {
    color: "#666666",
    fontSize: "14px",
    lineHeight: "20px",
  },
  radioRoot: {
    padding: "0px !important",
  },

  //new css
  stateElement: {
    fontWeight: 400,
    fontSize: 12,
    color: "#666666",
    padding: 10,
    paddingBottom: "4px",
    alignItems: "center",
  },

  statusRow: {
    marginLeft: "21px",
    marginRight: "32px",
    display: "flex",
    marginBottom: "24px",
  },

  tbody: {
    height: "300px",
    display: "flex !important",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  rupee: {
    color: "#999999",
    fontSize: "12px",
    lineHeight: "16px",
  },
  addOptionButton: {
    color: "blue",
    fontWeight: "600",
    padding: "0px",
    background: "red",
    textTransform: "none",
    cursor: "default",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  noPlans: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    height: "calc(100vh - 163px)",
  },
  noPlansHeading: {
    fontWeight: 600,
    fontSize: 20,
    color: "rgb(26, 26, 26)",
    marginBottom: "32px",
  },
  stickyTableColumn: {
    position: "sticky",
    left: 0,
    zIndex: 2,
  },
}));
