import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import Controls from "../../../components/controls/Controls";
import {
  addStoreCustomer,
  editStoreCustomer,
} from "../../../redux/storeCustomers/storeCustomerActions";
import CombinedButtons from "../../../components/buttons/CombinedButtons";
import {
  layoutBodyBox,
  layoutContainer,
  rowFlexCenterAll,
} from "../../../styles/common";
import HeaderBreadcrumbs from "../../../components/common/HeaderBreadcrumbs";
import useStyles from "../styles";
import AddressCard from "./AddressCard";
import AddressFormModal from "./AddressFormModal";
import ManageAddressesModal from "./ManageAddressesModal";
import OutlinedButton from "../../../components/buttons/OutlinedButton";
import AddressListModal from "./AddressListModal";
import { useParams } from "react-router-dom";
import {
  fetchCustomerAddresses,
  fetchStoreCustomerDetails,
} from "../../../utils/customerUtils";
import ContentCopyIcon from "../../../icons/ContentCopyIcon";
import { Done } from "@material-ui/icons";
import ReusablePhoneInput from "../../../components/common/PhoneInput";

const initialValue = {
  name: false,
  mobile: false,
  email: false,
  type: false,
  gstin: false,
};

const CustomerForm = ({
  record,
  setRecord,
  onSave,
  onClose,
  setToast = () => {},
}) => {
  const params = useParams();
  const classes = useStyles();
  const state = useSelector((state) => state);
  const store = state.store.store;

  const [isBusinessCustomer, setIsBusinessCustomer] = useState(
    record?.gstin ? true : false
  );

  const [isBlockCod, setIsBlockCod] = useState(
    record?.disable_cod ? true : false
  );

  const [errors, setErrors] = useState(initialValue);
  const [tapped, setTapped] = useState(initialValue);
  const [loader, setLoader] = useState(false);
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [openAddressFormModal, setOpenAddressFormModal] = useState(false);
  const [openAddressListModal, setOpenAddressListModal] = useState(false);
  const [openManageAddressesModal, setOpenManageAddressesModal] =
    useState(false);
  const [addressState, setAddressState] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [refetchLoading, setRefetchLoading] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [isCopied, setIsCopied] = useState(false);

  const dispatch = useDispatch();

  const initialValues = {
    name: record?.name ?? "",
    email: record?.email ?? "",
    mobile: record?.mobile?.replace(/\s+/g, "") ?? "",
    type: record?.type ?? "CONSUMER",
    gstin: record?.gstin ?? "",
    disableCod: record?.disable_cod ?? "",
    company_name: record?.company_name ?? "",
    billing_address: record?.billing_address ?? null,
    shipping_address: record?.shipping_address ?? null,
    is_referrer: record?.is_referrer ?? false,
    referrer_commission: record?.referrer_commission ?? null,
    ref_code: record?.ref_code ?? null,
  };
  const [formData, setFormData] = useState(initialValues);
  const [addressFormData, setAddressFormData] = useState(null);

  useEffect(() => {
    setFormData(initialValues);

    // eslint-disable-next-line
  }, [record]);

  useEffect(() => {
    if (record?.id) {
      fetchCustomerAddresses(record.id).then((rec) => {
        setAddresses(rec);
      });
    }
  }, [record]);

  const onAddressSave = async (address) => {
    if (addressState === "BILLING") {
      setFormData({
        ...formData,
        billing_address: address,
      });
    }
    if (addressState === "SHIPPING") {
      setFormData({
        ...formData,
        shipping_address: address,
      });
    }
    return true;
  };

  const validate = (e) => {
    switch (e.target.name) {
      case "name":
        if (e.target.value.trim() === "") {
          setErrors((errors) => ({
            ...errors,
            [e.target.name]: "Name cannot be empty",
          }));
        } else {
          setErrors((errors) => ({
            ...errors,
            [e.target.name]: false,
          }));
        }
        break;
      case "mobile":
        if (!isPhoneValid) {
          setErrors((errors) => ({
            ...errors,
            [e.target.name]: "Invalid mobile number",
          }));
        } else {
          setErrors((errors) => ({
            ...errors,
            [e.target.name]: false,
          }));
        }
        break;

      case "email":
        if (
          !/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g.test(
            e.target.value.trim()
          ) &&
          e.target.value.trim().length !== 0
        ) {
          setErrors((errors) => ({
            ...errors,
            [e.target.name]: "Invalid email",
          }));
        } else {
          setErrors((errors) => ({
            ...errors,
            [e.target.name]: false,
          }));
        }
        break;
      case "gstin":
        if (
          !/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/g.test(
            e.target.value.trim()
          ) &&
          e.target.value.trim().length !== 0
        ) {
          setErrors((errors) => ({
            ...errors,
            [e.target.name]: "Invalid gst number",
          }));
        } else {
          setErrors((errors) => ({
            ...errors,
            [e.target.name]: false,
          }));
        }
        break;
      default:
        break;
    }
  };

  const onBlur = (e) => {
    validate(e);
    setTapped((tapped) => ({ ...tapped, [e.target.name]: true }));
  };

  const handleFormDataChange = (e) => {
    setFormData((formData) => {
      return { ...formData, [e.target.name]: e.target.value };
    });
    validate(e);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.name.length === 0) {
      setErrors((errors) => ({
        ...errors,
        name: "Name cannot be empty",
      }));
      setTapped((tapped) => ({
        ...tapped,
        name: "Name cannot be empty",
      }));
      return;
    }
    if (!isPhoneValid) {
      return;
    }
    setLoader(true);

    let payload = {};
    if (record !== null) {
      payload = {
        ...payload,
        ...record,
        billing_address_id: formData?.billing_address?.id ?? 0,
        shipping_address_id: formData?.shipping_address?.id ?? 0,
      };
    }

    payload = {
      ...payload,
      name: formData.name,
      mobile: formData.mobile,
      email: formData.email,
      gstin: isBusinessCustomer ? formData.gstin : "",
      disable_cod: isBlockCod ? true : false,
      company_name: isBusinessCustomer ? formData.company_name : "",
      tag: "consumer",
      type: formData.type?.toUpperCase(),
      billing_address: formData.billing_address,
      shipping_address: formData.shipping_address,
      use_same_address: false,
      org_id: store.org_id,
      store_id: store.id,
      is_referrer: formData.is_referrer,
      referrer_commission: formData.referrer_commission,
    };

    if (record) {
      dispatch(editStoreCustomer(payload)).then(() => {
        setLoader(false);
        setToast(true);
        onSave();
      });
    } else
      dispatch(addStoreCustomer(payload)).then(() => {
        setLoader(false);
        setToast(true);
        onSave();
      });
  };

  const onCloseManageAddressModal = () => {
    setOpenManageAddressesModal(false);
    setRefetchLoading(true);
    fetchStoreCustomerDetails(params.customerid).then((rec) => {
      setRecord(rec);
      setRefetchLoading(false);
    });
  };

  let copyTimeout;
  const handleCopy = () => {
    navigator.clipboard.writeText(
      `https://${store.domain}?utm_id=${formData.ref_code}&utm_campaign=_site-referrer`
    );
    setIsCopied(true);

    // Clear any existing timeout
    if (copyTimeout) clearTimeout(copyTimeout);

    // Set a new timeout
    copyTimeout = setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  return (
    <div style={{ position: "relative" }}>
      {refetchLoading && (
        <div
          style={{
            position: "fixed",
            left: "50%",
            top: "50%",
            zIndex: "900",
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      )}
      <div className={layoutContainer}>
        <HeaderBreadcrumbs
          list={
            record
              ? [
                  {
                    label: "Customers",
                    link: "/customers",
                  },
                  {
                    label: record.name ? record.name : record.mobile,
                    link: `/customers/${params.customerid}`,
                  },
                  {
                    label: "Edit",
                  },
                ]
              : [
                  {
                    label: "Customers",
                    link: "/customers",
                  },

                  {
                    label: "Add",
                  },
                ]
          }
        />
        <div className={rowFlexCenterAll}>
          <div
            className={layoutBodyBox}
            style={{
              width: 604,
              padding: 32,
            }}
          >
            <div className={classes.row}>
              <Controls.Input
                name="name"
                label="Name"
                required={true}
                labelPlacement="top"
                value={formData.name}
                onChange={handleFormDataChange}
                onBlur={onBlur}
                error={tapped.name && errors.name ? errors.name : ""}
                maxLength={64}
              />
              <ReusablePhoneInput
                value={formData.mobile}
                onChange={(value) =>
                  handleFormDataChange({ target: { name: "mobile", value } })
                }
                onValidation={(isValid) => setIsPhoneValid(isValid)}
                helperText=""
              />
            </div>
            <div className={classes.row}>
              <Controls.Input
                name="email"
                label="Email"
                labelPlacement="top"
                value={formData.email}
                onChange={handleFormDataChange}
                // onFocus={onFocus}
                onBlur={onBlur}
                error={tapped.email && errors.email ? errors.email : ""}
              />
              <Controls.Select
                name="type"
                label="Type"
                labelPlacement="top"
                value={formData.type}
                options={[
                  { id: "CONSUMER", title: "Consumer" },
                  { id: "RETAILER", title: "Retailer" },
                  { id: "DISTRIBUTOR", title: "Distributor" },
                  { id: "DOCTOR", title: "Doctor" },
                ]}
                color="secondary"
                onChange={handleFormDataChange}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                error={errors.type ? errors.type : ""}
                controlClass={classes.controlInput}
                labelClass={classes.label}
                extLabelWidth={classes.labelWidth}
              />
            </div>
            {formData.type === "AFFILIATE" && (
              <div className={classes.row}>
                <Controls.Input
                  name="referrer_commission"
                  label="Affiliate Commission"
                  type="number"
                  labelPlacement="top"
                  value={formData.referrer_commission}
                  preventDecimal
                  onChange={(e) => {
                    if (
                      parseInt(e.target.value) < 0 ||
                      parseInt(e.target.value) > 100
                    ) {
                      return;
                    }
                    handleFormDataChange(e);
                  }}
                  endAdornment={
                    <Typography
                      style={{
                        fontSize: 14,
                      }}
                    >
                      %
                    </Typography>
                  }
                  onBlur={onBlur}
                  error={tapped.email && errors.email ? errors.email : ""}
                />
              </div>
            )}
            <hr
              className={classes.horizontalDivider}
              style={{ marginBottom: 24 }}
            />
            <Controls.Checkbox
              style={{ marginBottom: 18 }}
              name="business_customer"
              label={
                <div style={{ fontWeight: 400 }}>
                  Customer has GST No.
                  <span> (Optional)</span>
                </div>
              }
              checkboxStyles={{
                padding: 0,
                marginLeft: 11,
                marginRight: 12,
                fontWeight: 400,
              }}
              value={isBusinessCustomer}
              color="secondary"
              onChange={(e) => {
                setIsBusinessCustomer(e.target.checked);
              }}
            />
            <div className={classes.row}>
              {isBusinessCustomer && (
                <>
                  <Controls.Input
                    name="gstin"
                    label="GST Number"
                    labelPlacement="top"
                    value={formData.gstin}
                    onBlur={onBlur}
                    onChange={handleFormDataChange}
                    error={tapped.gstin && errors.gstin ? errors.gstin : ""}
                  />
                  <Controls.Input
                    name="company_name"
                    label="Company Name"
                    labelPlacement="top"
                    value={formData.company_name}
                    onChange={handleFormDataChange}
                  />
                </>
              )}
            </div>
            <Controls.Checkbox
              style={{ marginBottom: 18 }}
              name="is_referrer"
              label={
                <div style={{ fontWeight: 400 }}>
                  Affiliate Customer
                  <span> (Optional)</span>
                </div>
              }
              checkboxStyles={{ padding: 0, marginLeft: 11, marginRight: 12 }}
              checked={formData.is_referrer}
              value={formData.is_referrer}
              color="secondary"
              onChange={(e) => {
                handleFormDataChange({
                  target: { name: "is_referrer", value: e.target.checked },
                });
              }}
            />
            {formData.is_referrer && (
              <div className={classes.row}>
                <div>
                  <Controls.Input
                    name="referrer_commission"
                    label="Affiliate Commission"
                    type="number"
                    labelPlacement="top"
                    value={formData.referrer_commission}
                    preventDecimal
                    onChange={(e) => {
                      if (
                        parseInt(e.target.value) < 0 ||
                        parseInt(e.target.value) > 100
                      ) {
                        return;
                      }
                      handleFormDataChange(e);
                    }}
                    endAdornment={
                      <Typography
                        style={{
                          fontSize: 14,
                        }}
                      >
                        %
                      </Typography>
                    }
                    onBlur={onBlur}
                    error={tapped.email && errors.email ? errors.email : ""}
                  />
                </div>
                <div>
                  <Typography
                    style={{
                      fontSize: "14px",
                      fontWeight: 600,
                      marginBottom: "12px",
                    }}
                  >
                    Affiliate Link
                  </Typography>
                  {formData.ref_code ? (
                    <Controls.Input
                      name="affiliate_link"
                      readOnly
                      disabled
                      value={`https://${store.domain}?utm_id=${formData.ref_code}&utm_campaign=_site-referrer`}
                      endAdornment={
                        <>
                          {isCopied ? (
                            <Done
                              style={{
                                cursor: "pointer",
                                fontSize: 16,
                                color: "#1A1A1A",
                              }}
                              color="secondary"
                            />
                          ) : (
                            <ContentCopyIcon
                              style={{
                                width: "16px",
                                height: "16px",
                                cursor: "copy",
                              }}
                              fill={"#1A1A1A"}
                              onClick={handleCopy}
                            />
                          )}
                        </>
                      }
                    />
                  ) : (
                    <Typography style={{ color: "#666666", fontSize: "14px" }}>
                      Affiliate link will be generate on save.
                    </Typography>
                  )}
                </div>
              </div>
            )}
            <Controls.Checkbox
              style={{ marginBottom: 18 }}
              name="disableCod"
              label={
                <div style={{ fontWeight: 400 }}>
                  Block Cash on Delivery for this Customer
                </div>
              }
              checkboxStyles={{
                padding: 0,
                marginLeft: 11,
                marginRight: 12,
                fontWeight: 400,
              }}
              value={isBlockCod}
              color="secondary"
              onChange={(e) => {
                setIsBlockCod(e.target.checked);
              }}
            />
            <hr className={classes.horizontalDivider} />
            <div
              style={{
                marginTop: "20px",
                marginBottom: "16px",
              }}
            >
              {!formData?.billing_address ? (
                <span
                  onClick={() => {
                    setAddressState("BILLING");
                    if (addresses.length > 0) {
                      setOpenAddressListModal(true);
                      setSelectedAddress(null);
                    } else {
                      setOpenAddressFormModal(true);
                      setAddressFormData({
                        customer_name: formData.name,
                        mobile: formData.mobile,
                      });
                    }
                  }}
                  className={classes.clickableHeading}
                >
                  Add Billing Address
                </span>
              ) : (
                <div>
                  <Typography className={classes.heading}>
                    Billing Address
                  </Typography>
                  <AddressCard
                    address={formData.billing_address}
                    showChange={true}
                    onChange={() => {
                      setAddressState("BILLING");
                      if (addresses.length === 0) {
                        setOpenAddressFormModal(true);
                        setAddressFormData(formData.billing_address);
                      } else {
                        setOpenAddressListModal(true);
                        setSelectedAddress(formData.billing_address);
                      }
                    }}
                  />
                </div>
              )}
            </div>
            <div
              style={{
                marginTop: "20px",
                marginBottom: "16px",
              }}
            >
              {!formData.shipping_address ? (
                <div>
                  <span
                    onClick={() => {
                      setAddressState("SHIPPING");
                      if (addresses.length > 0) {
                        setOpenAddressListModal(true);
                        setSelectedAddress(null);
                      } else {
                        setOpenAddressFormModal(true);
                        setAddressFormData({
                          customer_name: formData.name,
                          mobile: formData.mobile,
                        });
                      }
                    }}
                    className={classes.clickableHeading}
                  >
                    Add Shipping Address
                  </span>
                </div>
              ) : (
                <div>
                  <Typography className={classes.heading}>
                    Shipping Address
                  </Typography>
                  <AddressCard
                    showChange={true}
                    address={formData.shipping_address}
                    onChange={() => {
                      setAddressState("SHIPPING");
                      if (addresses.length === 0) {
                        setOpenAddressFormModal(true);
                        setAddressFormData(formData.shipping_address);
                      } else {
                        setOpenAddressListModal(true);
                        setSelectedAddress(formData.shipping_address);
                      }
                    }}
                  />
                </div>
              )}
            </div>
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 50,
                }}
              >
                <CombinedButtons
                  outlinedBtnAction={onClose}
                  solidBtnAction={handleSubmit}
                  disableOutline={loader}
                  loading={loader}
                />
              </div>
            </Grid>
          </div>
        </div>
      </div>
      <AddressFormModal
        open={openAddressFormModal}
        setOpen={setOpenAddressFormModal}
        title="Add Address"
        initialValue={addressFormData}
        onSave={onAddressSave}
      />
      {openManageAddressesModal && (
        <ManageAddressesModal
          open={openManageAddressesModal}
          setOpen={onCloseManageAddressModal}
        />
      )}
      {openAddressListModal && (
        <AddressListModal
          open={openAddressListModal}
          setOpen={setOpenAddressListModal}
          addresses={addresses}
          selectedAddress={selectedAddress}
          onSave={onAddressSave}
        />
      )}
      {record && (
        <OutlinedButton
          onClick={() => {
            setOpenManageAddressesModal(true);
          }}
          style={{
            background: "none",
            position: "absolute",
            right: 2,
            top: -10,
            width: 185,
          }}
        >
          Manage Addresses
        </OutlinedButton>
      )}
    </div>
  );
};
export default CustomerForm;
