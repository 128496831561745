import { Modal, Radio, Typography } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { rowFlexJustifyBetween } from "../../../../styles/common";
import CombinedButtons from "../../../../components/buttons/CombinedButtons";
import { templateConfigs } from "./AllTemplates";
import Diamond from "../../../../icons/Diamond";
import { PLANS, planCustomProps } from "../../../plans/constants";
import LockIcon from "../../../../icons/LockIcon";
import PaidPopUp from "../../../../components/PaidPopUp";

const SelectTemplateModal = ({
  openModal,
  setOpenModal,
  currStyle,
  handleStyleChange,
  storePlan,
  formState,
}) => {
  const [style, setStyle] = useState(currStyle);
  const [isPaidPopUpOpen, setIsPaidPopUpOpen] = useState(false);
  const [openPaidPopUpForPlan, setPpenPaidPopUpForPlan] = useState("FREE");
  useEffect(() => {
    setStyle(currStyle);
  }, [currStyle, openModal]);

  const checkIfThisFeatureAvailableInCurrPlan = (plan) => {
    if (storePlan) {
      const plans = Object.keys(PLANS);
      const featurePlanIndex = plans.findIndex((e) => e === plan);
      const storePlanIndex = plans.findIndex((e) => e === storePlan);
      return featurePlanIndex <= storePlanIndex;
    } else {
      return false;
    }
  };

  return (
    <Fragment>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            padding: 25,
            paddingTop: 0,
            background: "#ffffff",
            borderRadius: 12,
            minWidth: 570,
            maxWidth: 800,
            width: "100%",
            objectFit: "contain",
            position: "relative",
          }}
        >
          <div
            style={{
              position: "sticky",
              top: 0,
              background: "white",
              padding: "25px 0",
              zIndex: 10,
            }}
          >
            <div className={rowFlexJustifyBetween} style={{ marginBottom: 12 }}>
              <Typography
                style={{
                  fontWeight: 700,
                  fontSize: 20,
                  lineHeight: "24px",
                  color: "#1A1A1A",
                }}
              >
                Select template
              </Typography>
              <CombinedButtons
                style={{
                  minWidth: "unset",
                  width: 78,
                  height: 36,
                  borderRadius: 8,
                  fontSize: 14,
                  fontWeight: 600,
                }}
                outlineBtnStyle={{
                  color: "#666666",
                  borderColor: "#666666",
                }}
                outlinedBtnText="Cancel"
                solidBtnText="Done"
                outlinedBtnAction={() => setOpenModal(false)}
                solidBtnAction={() => {
                  handleStyleChange(style, formState);
                  setOpenModal(false);
                }}
              />
            </div>
            <div
              style={{
                height: 1,
                background: "#E1E1E1",
                margin: "0 -25px",
              }}
            ></div>
          </div>
          <div
            style={{
              maxHeight: "calc(90vh - 75px)",
              overflow: "auto",
              marginLeft: -25,
              marginRight: -25,
              padding: "0 25px",
            }}
          >
            {Object.entries(templateConfigs).map(
              ([styleName, template], idx, elm) => {
                const isAvailable = checkIfThisFeatureAvailableInCurrPlan(
                  template.plan
                );
                return (
                  <div
                    key={styleName}
                    style={{
                      padding: 16,
                      ...(styleName === "style7" && {
                        padding: "10px 16px",
                      }),
                      ...(styleName !== "style7" && {
                        paddingTop: 6,
                      }),
                      border:
                        style === styleName
                          ? "1px solid #1641DB"
                          : "1px solid #E1E1E1",
                      borderRadius: 12,
                      ...(isAvailable && {
                        cursor: "pointer",
                      }),
                      marginBottom: idx < elm.length - 1 ? 20 : 0,
                    }}
                    {...(isAvailable && {
                      onClick: () => setStyle(styleName),
                    })}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        ...(template.generateHtml(template.config) && {
                          marginBottom: idx < elm.length - 1 ? 4 : 0,
                        }),
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          style={{
                            fontSize: 14,
                            fontWeight: 600,
                            marginRight: 10,
                          }}
                        >
                          {template.label}
                        </Typography>
                        {!isAvailable && (
                          <div
                            style={{
                              width: 24,
                              height: 24,
                              background:
                                planCustomProps[template.plan].background,
                              padding: 6,
                              borderRadius: 100,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setPpenPaidPopUpForPlan(template.plan);
                              setIsPaidPopUpOpen(true);
                            }}
                          >
                            <LockIcon style={{ color: "#fff" }} />
                          </div>
                        )}
                      </div>
                      {isAvailable ? (
                        <Radio
                          checked={style === styleName}
                          style={{ marginRight: -12 }}
                        />
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            background:
                              planCustomProps[template.plan].background,
                            padding: "4px 10px",
                            borderRadius: 6,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setPpenPaidPopUpForPlan(template.plan);
                            setIsPaidPopUpOpen(true);
                          }}
                        >
                          <Diamond
                            {...planCustomProps[template.plan].diamondProps}
                            style={{
                              width: 24,
                              height: 24,
                              marginLeft: -6,
                              marginBottom: 0,
                              marginRight: 6,
                            }}
                          />
                          <Typography
                            style={{
                              fontSize: 14,
                              textTransform: "capitalize",
                              color: "#fff",
                            }}
                          >
                            {PLANS[template.plan].name}
                          </Typography>
                        </div>
                      )}
                    </div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: template.previewHtml(template.config),
                      }}
                    ></div>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </Modal>
      <PaidPopUp
        open={isPaidPopUpOpen}
        close={() => setIsPaidPopUpOpen(false)}
        plan={openPaidPopUpForPlan}
        subtxt="View the list of carts that have been left out by the customers as abandoned"
      />
    </Fragment>
  );
};

export default SelectTemplateModal;
