import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import useStyles from "./styles";
import Moment from "react-moment";
import { isWindows } from "react-device-detect";
import BaseFormFooter from "./BaseFormFooter";

const BaseView = ({
  record,
  store,
  dateLabel,
  enableDescount,
  enableShipping,
  enablePackaging,
  enableRoundOff,
}) => {
  const classes = useStyles();
  const [scale, setScale] = useState(1);
  const countrySymbol = store.currency_symbol;
  useEffect(() => {
    if (isWindows) {
      setScale(window.devicePixelRatio - 0.07);
    }
  }, []);
  return (
    <Grid
      item
      xs={9}
      className={classes.addInvoiceContainer}
      style={{ height: `${92 * scale}vh` }}
    >
      <Grid
        container
        style={{
          borderBottom: "4px solid #E5E5E5",
          paddingBottom: 22,
        }}
      >
        <Grid item xs={4}>
          <Typography
            style={{
              fontWeight: 600,
              color: "#1A1A1A",
              paddingBottom: 12,
            }}
          >
            Bill To
          </Typography>
          <span style={{ textTransform: "capitalize" }}>
            {record.org_customer_name}
          </span>
        </Grid>
        <Grid item xs={3}>
          <Typography
            style={{
              fontWeight: 600,
              color: "#1A1A1A",
              paddingBottom: 12,
            }}
          >
            {dateLabel}
          </Typography>
          <Moment format="DD-MMM-YYYY">{record.invoice_date}</Moment>
        </Grid>
        <Grid item xs={3} style={{ textAlign: "left" }}>
          <Typography
            style={{
              fontWeight: 600,
              color: "#1A1A1A",
              paddingBottom: 12,
            }}
          >
            Due Date
          </Typography>
          <Moment format="DD-MMM-YYYY">{record.last_payment_date}</Moment>
        </Grid>
        <Grid item xs={2} style={{ textAlign: "left" }}>
          <Typography
            style={{
              fontWeight: 600,
              color: "#1A1A1A",
              paddingBottom: 12,
            }}
          >
            Order Id
          </Typography>
          <span>{record.display_id}</span>
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: 24, position: "relative" }}>
        <div
          style={{
            width: "100%",
            height: `${69 * scale}vh`,
            overflowY: "auto",
            paddingBottom: 34,
          }}
        >
          <TableContainer>
            <Table>
              <TableHead className={classes.tablehead}>
                <TableRow
                  style={{
                    borderLeft: "1px solid #F5F7FC",
                    borderRight: "1px solid #F5F7FC",
                    boxSizing: "border-box",
                  }}
                >
                  <TableCell align="left" style={{ width: "28%" }}>
                    Item Name
                  </TableCell>
                  <TableCell align="center" style={{ width: "13%" }}>
                    MRP
                  </TableCell>
                  <TableCell align="center" style={{ width: "13%" }}>
                    Price
                  </TableCell>
                  <TableCell align="center" style={{ width: "12%" }}>
                    Discount
                  </TableCell>
                  <TableCell align="center" style={{ width: "8%" }}>
                    Qty
                  </TableCell>
                  <TableCell align="center" style={{ width: "12%" }}>
                    Tax
                  </TableCell>
                  <TableCell align="right" style={{ width: "14%" }}>
                    Amount
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {record.order_items.map((item, index) => (
                  <TableRow
                    key={index}
                    style={{
                      border: "1px solid rgba(224, 224, 224, .5)",
                      verticalAlign: "top",
                    }}
                  >
                    <TableCell
                      align="left"
                      style={{
                        fontSize: 12,
                      }}
                    >
                      {item.display_name}
                      {item.product_id && (
                        <span>&nbsp; ({item.product_id})</span>
                      )}
                      <span>&nbsp; ({item.sku})</span>
                    </TableCell>
                    <TableCell align="center">
                      {countrySymbol}
                      {item.mrp.toFixed(2)}
                    </TableCell>
                    <TableCell align="center">
                      {countrySymbol}
                      {item.sale_price.toFixed(2)}
                    </TableCell>
                    <TableCell align="center">
                      <div>
                        {countrySymbol}
                        {item.disc.toFixed(2)}
                      </div>
                    </TableCell>
                    <TableCell align="center">{item.qty}</TableCell>
                    <TableCell align="center">
                      <div>
                        {countrySymbol}
                        {(item.tax * item.qty).toFixed(2)}
                        <Typography
                          style={{
                            fontSize: 12,
                            marginRight: -11,
                            marginTop: 4,
                            color: "#888888",
                          }}
                        >
                          ({item.total_tax_in_per}%
                          {item.sp_tax_included ? " Incl." : " Excl."})
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{
                        color: "#1A1A1A",
                        fontSize: 12,
                      }}
                    >
                      {countrySymbol}
                      {(
                        (item.sp_tax_included
                          ? item.sale_price.toFixed(2)
                          : (item.sale_price + item.tax).toFixed(2)) * item.qty
                      ).toFixed(2)}
                    </TableCell>
                  </TableRow>
                ))}

                {enableDescount && (
                  <TableRow
                    style={{
                      verticalAlign: "top",
                      height: 20,
                    }}
                  >
                    <TableCell colSpan={4} style={{ borderBottom: "none" }} />
                    <TableCell
                      colSpan={2}
                      align="left"
                      style={{
                        border: "1px solid rgba(224, 224, 224, .5)",
                        paddingLeft: 0,
                      }}
                    >
                      <Typography
                        className={classes.subHeading}
                        style={{
                          padding: "0px 10px",
                          color: "#666666",
                          fontSize: 12,
                        }}
                      >
                        Discount
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{
                        border: "1px solid rgba(224, 224, 224, .5)",
                        borderLeft: "none",
                      }}
                    >
                      {countrySymbol}
                      {record.order_discount.toFixed(2)}
                    </TableCell>
                  </TableRow>
                )}
                {enableShipping && (
                  <TableRow
                    style={{
                      verticalAlign: "top",
                      height: 20,
                    }}
                  >
                    <TableCell colSpan={4} style={{ borderBottom: "none" }} />
                    <TableCell
                      colSpan={2}
                      align="left"
                      style={{
                        border: "1px solid rgba(224, 224, 224, .5)",
                        paddingLeft: 0,
                      }}
                    >
                      <Typography
                        className={classes.subHeading}
                        style={{
                          padding: "0px 10px",
                          paddingRight: 0,
                          color: "#666666",
                          fontSize: 12,
                        }}
                      >
                        Shipping Charge
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{
                        border: "1px solid rgba(224, 224, 224, .5)",
                        borderLeft: "none",
                      }}
                    >
                      {countrySymbol}
                      {record.shipping_charge.toFixed(2)}
                    </TableCell>
                  </TableRow>
                )}
                {enablePackaging && (
                  <TableRow
                    style={{
                      verticalAlign: "top",
                      height: 20,
                    }}
                  >
                    <TableCell colSpan={4} style={{ borderBottom: "none" }} />
                    <TableCell
                      colSpan={2}
                      align="left"
                      style={{
                        border: "1px solid rgba(224, 224, 224, .5)",
                        paddingLeft: 0,
                        paddingRight: 0,
                      }}
                    >
                      <Typography
                        className={classes.subHeading}
                        style={{
                          padding: "0px 10px",
                          color: "#666666",
                          paddingRight: 0,
                          fontSize: 12,
                        }}
                      >
                        Packaging Charges
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{
                        border: "1px solid rgba(224, 224, 224, .5)",
                        borderLeft: "none",
                      }}
                    >
                      {countrySymbol}
                      {record.packaging_charge.toFixed(2)}
                    </TableCell>
                  </TableRow>
                )}
                {enableRoundOff && (
                  <TableRow
                    style={{
                      verticalAlign: "top",
                      height: 20,
                    }}
                  >
                    <TableCell colSpan={4} style={{ borderBottom: "none" }} />
                    <TableCell
                      colSpan={2}
                      align="left"
                      style={{
                        border: "1px solid rgba(224, 224, 224, .5)",
                        paddingLeft: 0,
                      }}
                    >
                      <Typography
                        className={classes.subHeading}
                        style={{
                          padding: "0px 10px",
                          color: "#666666",
                          fontSize: 12,
                        }}
                      >
                        Round
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{
                        border: "1px solid rgba(224, 224, 224, .5)",
                        borderLeft: "none",
                      }}
                    >
                      {countrySymbol}
                      {record.round_value.toFixed(2)}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            {record.order_items.length === 0 && (
              <div className={classes.noItem}> No Item Added </div>
            )}
          </TableContainer>
          {record.order_items.length !== 0 && (
            <div
              style={{
                paddingTop: 32,
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginTop: 24,
                borderTop: "3px solid #E5E5E5",
              }}
            >
              <div style={{ width: "50%" }}></div>
              {store && store.signature_url && (
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    className={classes.subHeading}
                    style={{
                      padding: "0px 18px",
                      color: "#666666",
                      fontSize: 12,
                    }}
                  >
                    Signature
                  </Typography>
                  <div
                    style={{
                      height: 80,
                      width: 180,
                      border: "1px solid #F6F6F6",
                      borderRadius: 6,
                    }}
                  >
                    <img
                      src={store.signature_url}
                      alt=""
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </Grid>
      <Grid
        className={classes.bottomSheet}
        style={{ justifyContent: "flex-end" }}
      >
        <BaseFormFooter record={record} />
      </Grid>
    </Grid>
  );
};

export default BaseView;
