import { makeStyles, Typography } from "@material-ui/core";
import React, { useState } from "react";
import Editor from "../../pages/products/components/Editor";
import FormModal from "./FormModal";
import { rowFlexCenterAll } from "../../styles/common";
import gifImage from "../../images/genrateMessage.gif";
const useStyles = makeStyles((theme) => ({
  genreateContainer: {
    borderTop: "1px solid #e1e1e1",
    paddingTop: "20px",
    marginBottom: "-17px",
  },
}));
const GenrateMessage = ({
  genrateModalOpen,
  setGenrateModalOpen,
  setProduct,
  textMessage,
  setTextMessage,
  textGenrateLoading,
  heading,
  loadingText,
}) => {
  // Store the editor instance
  const GenRateButton = [
    {
      key: "REPHRASE",
      label: "Rephrase",
    },
    {
      key: "SHORTEN",
      label: "Shorten",
    },
    {
      key: "EXPAND",
      label: "Expand",
    },
    {
      key: "MORE_PROFESSIONAL",
      label: "More Professional",
    },
    {
      key: "MORE_FORMAL",
      label: "More Formal",
    },
  ];
  const classes = useStyles();
  const [selectedKey, setSelectedKey] = useState("");

  const handleButtonClick = (key) => {
    setSelectedKey(key);
  };
  const onFieldChange = (value) => {
    setTextMessage(value);
  };
  const onCloseGenrateModal = async () => {
    setGenrateModalOpen(false);
    setSelectedKey("");
    setTextMessage("");
  };

  const GenrateText = () => {
    setProduct((prev) => ({
      ...prev,
      description: textMessage,
    }));
    setGenrateModalOpen(false);
    setSelectedKey("");
  };

  return (
    <FormModal
      open={genrateModalOpen}
      heading={heading}
      btnTxt="Use Generated Text"
      onClose={onCloseGenrateModal}
      disableSave={textGenrateLoading}
      maxHeight="90vh"
      onSave={GenrateText}
      hideCancel={true}
      solidBtnStyle={{ height: 36, width: 180, fontSize: "14px" }}
      width="750px"
    >
      <div className={classes.genreateContainer}>
        {textGenrateLoading ? (
          <div
            style={{
              padding: "8px",
              marginBottom: "20px",
              border: "1px solid #e1e1e1",
              minHeight: "40vh",
              flexDirection: "column",
            }}
            className={rowFlexCenterAll}
          >
            <img
              src={gifImage}
              alt=""
              style={{ width: "150px", height: "150px", marginTop: "-56px" }}
            />
            <Typography
              style={{
                fontSize: "12px",
                marginTop: "-40px",
                color: "#999999",
                width: "100%",
                maxWidth: "360px",
                textAlign: "center",
              }}
            >
              {loadingText}
            </Typography>
          </div>
        ) : (
          <div
            style={{
              marginBottom: "-10px",
            }}
          >
            <Editor
              editorText={textMessage}
              setEditorText={(message) => onFieldChange(message)}
              height="45vh"
              toolbar={false}
            />
          </div>
        )}

        {/* <div>
        {GenRateButton.map((button, index) => (
          <Button
            key={index}
            onClick={() => handleButtonClick(button.key)}
            style={{
              padding: "3px 12px",
              borderRadius: "4px",
              border: "1px solid #e1e1e1",
              marginRight: "12px",
              backgroundColor: button.key === selectedKey ? "#E9EEFF" : "white", // Highlight selected button
              fontWeight: button.key === selectedKey ? "600" : "400", // Highlight selected button
            }}
            disabled={textMessage === ""}
          >
            {button.label}
          </Button>
        ))}
      </div> */}
      </div>
    </FormModal>
  );
};

export default GenrateMessage;
