export const generateJsForTimer = ({
  config,
  generateCountDownTimerHtml,
  generateHtmlForJs,
}) => {
  const js = `
try {if(helloBarCountDownTimerId) clearInterval(helloBarCountDownTimerId);} catch {};
var helloBarCountDownTimerId = null;
var headerSpacingReloadHitted = false;
if(document.getElementById('notice-timer')) {
  const generateCountDownTimerHtml = ${generateCountDownTimerHtml.toString()};

  helloBarCountDownTimerId = setInterval(()=>{
    const timerHtml = \`${generateHtmlForJs(config)}\`;
    const countDownTimerHtml = generateCountDownTimerHtml('${
      config.show_timer_till.value
    }');
    if(timerHtml && countDownTimerHtml) {
      const timer = document.getElementById("timer");
      if (timer) {
        timer.innerHTML = countDownTimerHtml;
      } else {
        const notice = document.getElementById('notice-timer');
        const timerHtmlElement = document.createElement('div');
        timerHtmlElement.innerHTML = timerHtml;
        const timer = timerHtmlElement.querySelector('#timer');
        timer.innerHTML = countDownTimerHtml;
        notice.innerHTML = timerHtmlElement.innerHTML;
      }
      window.helloBar?.onHeaderSpacingReload();
    } else {
      window.helloBar.closeHelloBar();
      clearInterval(helloBarCountDownTimerId);
    }
  }, 1000);
}`;

  return js;
};
