// Core react imports
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// Component imports
import HeaderBreadcrumbs from "../../components/common/HeaderBreadcrumbs";
import PageLoader from "../../components/Layout/PageLoader";
import Form from "./Form";
import {
  BANNER_PRODUCT,
  BANNER_VIDEO,
  GENERIC_COLLECTION,
  getCollectionsById,
  HTML_COLLECTION,
} from "./utils";
// Exporting default component
export default function Edit() {
  const params = useParams();
  const collectionId = params.collectionId;
  const [record, setRecord] = useState(null);
  const [collectionType, setCollectionType] = useState(BANNER_PRODUCT);

  useEffect(() => {
    getCollectionsById(collectionId).then((res) => {
      setRecord(res);
      setCollectionType(res.type);
    });
  }, [collectionId]);

  if (!record) {
    return <PageLoader />;
  }

  return (
    <div style={{ maxWidth: 1130, margin: "0 auto" }}>
      <div
        {...([GENERIC_COLLECTION, BANNER_VIDEO, HTML_COLLECTION].includes(
          collectionType
        ) && {
          style: { width: "60%", margin: "auto" },
        })}
      >
        <HeaderBreadcrumbs
          list={[
            { label: "Collections", link: "/collections" },
            {
              label: `${record.name}`,
            },
          ]}
        />
      </div>
      <Form mode="edit" record={record} />
    </div>
  );
}
