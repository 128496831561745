import { Button } from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormModal from "../../components/common/FormModal";
import Controls from "../../components/controls/Controls";
import { addProduct } from "../../redux/product/productActions";
import { getGSTTaxGroupById, getTaxCodes } from "../../utils/utils";
import useStyles from "./styles";

const AddProductModal = ({
  openAddProduct,
  searchKeyword,
  setOpenAddProduct,
  handleSelect,
}) => {
  const classes = useStyles();
  const state = useSelector((state) => state);
  const store = useSelector((state) => state.store.store);
  const countrySymbol = store.currency_symbol;
  const taxGroups = state.tax.taxGroups;
  const [product, setProduct] = useState({
    name: searchKeyword,
    sale_price: "",
    tax: "none",
    qty: 1,
    sp_tax_included: true,
    name_error: false,
    sp_error: false,
  });

  const dispatch = useDispatch();

  const changeHandler = (e) => {
    if (e.target.name === "qty" && e.target.value < 0) {
      return;
    }
    setProduct({ ...product, [e.target.name]: e.target.value });
  };

  const onAdd = () => {
    if (product.name.trim() === "") {
      setProduct({ ...product, name_error: true });
    }
    if (product.sale_price === "") {
      setProduct({ ...product, sp_error: true });
    }
    if (product.name.trim() === "" || product.sale_price === "") return;
    let taxGroup = getGSTTaxGroupById(taxGroups, product.tax);
    dispatch(
      addProduct({
        name: product.name,
        display_name: product.name,
        sale_price: parseFloat(product.sale_price),
        sp_tax_included: product.sp_tax_included,
        tax_group: taxGroup != null ? taxGroup : {},
      })
    ).then((res) => {
      handleSelect(res, product.qty);
      setOpenAddProduct(false);
    });
  };
  return (
    <FormModal
      open={openAddProduct}
      onClose={() => {
        setOpenAddProduct(false);
        setProduct({
          name: "",
          sale_price: "",
          tax: "none",
          qty: 1,
          sp_tax_included: true,
          name_error: false,
          sp_error: false,
        });
      }}
      onSave={onAdd}
      heading="Add Product"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        className={classes.dividerGrid}
        style={{ display: "block", paddingBottom: 2 }}
      >
        <Controls.Input
          name="name"
          label="Name"
          value={product.name}
          onChange={changeHandler}
          onFocus={() => {
            setProduct({ ...product, name_error: false });
          }}
          onBlur={(e) => {
            if (e.target.value.trim() === "")
              setProduct({ ...product, name_error: true });
          }}
          error={product.name_error ? "Please enter Name" : ""}
          onEnter={onAdd}
          autoFocus={true}
        />
      </div>
      <div
        className={classes.dividerGrid}
        style={{ display: "block", paddingBottom: 2 }}
      >
        <Controls.Input
          name="sale_price"
          label="Sale Price"
          type="number"
          startAdornment={
            <span className={classes.rupee}>{countrySymbol}</span>
          }
          endAdornment={
            <>
              <Button
                className={classes.taxbtn}
                variant="outlined"
                style={{
                  color: product.sp_tax_included ? "white" : "#1641DB",
                  backgroundColor: product.sp_tax_included
                    ? "#1641DB"
                    : "white",
                  outline: "none",
                }}
                onClick={() =>
                  setProduct({ ...product, sp_tax_included: true })
                }
                disableElevation
              >
                Tax Incl.
              </Button>
              <Button
                className={classes.taxbtn}
                variant="outlined"
                style={{
                  marginLeft: 8,
                  color: !product.sp_tax_included ? "white" : "#1641DB",
                  backgroundColor: !product.sp_tax_included
                    ? "#1641DB"
                    : "white",
                  outline: "none",
                }}
                onClick={() =>
                  setProduct({ ...product, sp_tax_included: false })
                }
                disableElevation
              >
                Tax Excl.
              </Button>
            </>
          }
          value={product.sale_price}
          onChange={changeHandler}
          onFocus={() => {
            setProduct({ ...product, sp_error: false });
          }}
          onBlur={(e) => {
            if (e.target.value.trim() === "")
              setProduct({ ...product, sp_error: true });
          }}
          error={product.sp_error ? "Please enter Sale Price" : ""}
          onEnter={onAdd}
        />
      </div>
      <div className={classes.dividerGrid} style={{ paddingBottom: 2 }}>
        <div style={{ width: "53%" }}>
          <Controls.Select
            name="tax"
            label="Tax"
            value={product.tax}
            onChange={changeHandler}
            options={getTaxCodes(taxGroups)}
            placeholder="Optional"
          />
        </div>
        <div style={{ width: "44%" }}>
          <Controls.Input
            name="qty"
            label="Qty"
            value={product.qty}
            onChange={changeHandler}
            onEnter={onAdd}
            type="number"
          />
        </div>
      </div>
    </FormModal>
  );
};

export default AddProductModal;
