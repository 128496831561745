export const colorProfile = {
  "engage-surge": {
    text_font_color: "#1A1A1A",
    timer_font_color: "#FFFFFF",
    timer_background_color: "#73388F",
    timer_dots_color: "#3A0BBF",
    background_color: "#F6F1FF",
    button_text_color: "#73388F",
    button_background_color: "#F6F1FF",
    button_border_color: "#73388F",
    texture_color: "#E4C2F4",
    close_icon_color: "#73388F",
  },
  "action-ignite": {
    text_font_color: "#1A1A1A",
    timer_font_color: "#FFFFFF",
    timer_background_color: "#FF9736",
    timer_dots_color: "#3A0BBF",
    background_color: "#FFEEDE",
    button_text_color: "#FF9736",
    button_background_color: "#FFEEDE",
    button_border_color: "#FF9736",
    texture_color: "#DFA9A9",
    close_icon_color: "#FF9736",
  },
  "engage-elevate": {
    text_font_color: "#196F82",
    timer_font_color: "#FFFFFF",
    timer_background_color: "#0B9FBF",
    timer_dots_color: "#3A0BBF",
    background_color: "#F1FDFF",
    button_text_color: "#0B9FBF",
    button_background_color: "#F1FDFF",
    button_border_color: "#0B9FBF",
    texture_color: "#84D9EB",
    close_icon_color: "#0B9FBF",
  },
  "engage-pulse": {
    text_font_color: "#FFFFFF",
    timer_font_color: "#FFFFFF",
    timer_background_color: "#7F70DE",
    timer_dots_color: "#3A0BBF",
    background_color: "#140763",
    button_text_color: "#FFFFFF",
    button_background_color: "#140763",
    button_border_color: "#FFFFFF",
    texture_color: "#817C99",
    close_icon_color: "#FFFFFF",
  },
};
