import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Typography,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import useStyles from "../styles";
import {
  rowFlexCenterAll,
  rowFlexJustifyBetween,
} from "../../../../../styles/common";
import { useParams } from "react-router-dom";
import { getUpcomingActivities, skipOrResume } from "../../contractUtils";
import Toast from "../../../../../components/Layout/Toast";
import Paginator from "../../../../../components/Paginator/Paginator";
import { ACTIVIE_KEY } from "../../../../../utils/utils";
import Moment from "react-moment";

const colorMap = {
  COMPLETED: {
    color: "#16ac67",
    backgroundColor: "#eef8f3",
  },
  SKIPPED: {
    color: "#f97231",
    backgroundColor: "#fff8ef",
  },
  FAILED: {
    color: "#f44336",
    backgroundColor: "#ffefef",
  },
  ORDER_PLACED: {
    color: "#d4a600",
    backgroundColor: "#fff9db",
  },
  PROCESSING: {
    color: "#d4a600",
    backgroundColor: "#fff9db",
  },
};

const statusMap = {
  COMPLETED: "Delivered",
  SKIPPED: "Skipped",
  ORDER_PLACED: "Order in Progress",
  FAILED: "Failed",
  PROCESSING: "Added to Cart",
};

const UpcomingOrders = ({ contractDetails }) => {
  const classes = useStyles();
  const params = useParams();
  const UPCOMING_DELIVERIES_PER_PAGE = 6;
  const [activities, setActivities] = useState([]);
  const [message, setMessage] = useState("");
  const [loadingActivityId, setLoadingActivityId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [perPageContracts, setPerPageContracts] = useState(
    parseInt(localStorage.getItem(ACTIVIE_KEY)) || UPCOMING_DELIVERIES_PER_PAGE
  );
  const [currentPage, setCurrentPage] = useState(0);
  useEffect(() => {
    if (params.contractId && contractDetails.status !== "CANCELED") {
      fetchActivities();
    }
  }, [params.contractId, contractDetails]);

  const fetchActivities = async () => {
    try {
      setLoading(true);
      const res = await getUpcomingActivities(
        params.contractId,
        currentPage,
        perPageContracts
      );
      setActivities(res);
    } catch (error) {
      console.error("Error fetching activities:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleSkipResume = async (activity) => {
    const updatedPayload = activity.status === "SKIPPED" ? "OPEN" : "SKIPPED";
    const message = activity.status === "SKIPPED" ? "resume" : "skipped";
    try {
      setLoadingActivityId(activity.activity_on);
      let response;
      const payload = {
        ...activity,
        status: updatedPayload,
      };
      response = await skipOrResume(payload, params.contractId);
      if (response) {
        setLoadingActivityId(null);
        const updatedAct = response;
        const updatedList = activities.content.map((act) =>
          act.activity_on === updatedAct.activity_on ? updatedAct : act
        );
        setActivities((prev) => ({
          ...prev,
          content: updatedList,
        }));
        const formattedDate = activity.activity_on.split("T")[0];
        setMessage(`${formattedDate} ${message} successfully`);
      }
    } catch (error) {
      setErrorMessage("An unexpected error occurred");
    } finally {
      setLoadingActivityId(null);
    }
  };
  const onChangeContractsPerPage = async (updatedActivitiesPerPage) => {
    localStorage.setItem(ACTIVIE_KEY, updatedActivitiesPerPage);
    setLoading(true);
    setPerPageContracts(updatedActivitiesPerPage);
    const res = await getUpcomingActivities(
      params.contractId,
      0,
      updatedActivitiesPerPage
    );
    setActivities(res);
    setLoading(false);
  };
  const prePage = async () => {
    setCurrentPage(currentPage - 1);
    setLoading(true);
    const res = await getUpcomingActivities(
      params.contractId,
      currentPage - 1,
      perPageContracts
    );
    setActivities(res);
    setLoading(false);
  };
  const nextPage = async () => {
    setCurrentPage(currentPage + 1);
    setLoading(true);
    const res = await getUpcomingActivities(
      params.contractId,
      currentPage + 1,
      perPageContracts
    );
    setActivities(res);
    setLoading(false);
  };
  return (
    <Box>
      <Typography className={classes.heading} style={{ marginBottom: 0 }}>
        Upcoming Deliveries
      </Typography>
      <Typography className={classes.subHeading} style={{ marginTop: 8 }}>
        Manage customers upcoming orders
      </Typography>
      {loading ? (
        <div style={{ minHeight: "336px" }} className={rowFlexCenterAll}>
          <CircularProgress style={{ color: "#164BDB" }} />
        </div>
      ) : (
        <>
          {activities?.content?.map((activity, idx) => (
            <div key={activity.activity_on}>
              <div className={rowFlexJustifyBetween} style={{ marginTop: 14 }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    className={classes.heading}
                    style={{ fontWeight: 400 }}
                  >
                    <Moment format="DD MMMM YYYY">
                      {activity.activity_on}
                    </Moment>
                  </Typography>

                  {activity.status !== "OPEN" && (
                    <Typography
                      className={classes.skippedHeading}
                      style={colorMap[activity.status] ?? {}}
                    >
                      {statusMap[activity.status]}
                    </Typography>
                  )}
                </div>
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    alignItems: "flex-end",
                  }}
                >
                  {activity.status !== "COMPLETED" &&
                    activity.status !== "FAILED" &&
                    activity.status !== "ORDER_PLACED" && (
                      <Button
                        className={classes.skippedButton}
                        onClick={() => handleSkipResume(activity)}
                        disabled={loadingActivityId === activity.id}
                        variant="text"
                      >
                        {loadingActivityId === activity.activity_on ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : activity.status === "SKIPPED" ? (
                          "Resume"
                        ) : (
                          "Skip"
                        )}
                      </Button>
                    )}
                </div>
              </div>
              {idx !== activities.length - 1 && (
                <Divider style={{ margin: "14px 0px" }} />
              )}
            </div>
          ))}
        </>
      )}
      <Paginator
        changeRecordsPerPage={onChangeContractsPerPage}
        possibleRecordsPerPage={[UPCOMING_DELIVERIES_PER_PAGE, 20, 50]}
        recordsPerPage={perPageContracts}
        onNext={nextPage}
        onPrev={prePage}
        total={activities?.totalPages}
        currentPage={currentPage}
        showPagination={false}
      />
      <Toast
        message={message}
        severity="success"
        open={Boolean(message)}
        close={() => setMessage(null)}
      />
      <Toast
        message={errorMessage}
        severity="error"
        open={Boolean(errorMessage)}
        close={() => setErrorMessage(null)}
      />
    </Box>
  );
};

export default UpcomingOrders;
