import { useEffect, useState } from "react";
import { colorValueConverter } from "../helpers/colorValueConverter";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import {
  createCollections,
  updateCollection,
} from "../../../../../../redux/collections/collectionActions";
import {
  BANNER,
  BANNER_VIDEO,
  GENERIC_COLLECTION,
  HTML_COLLECTION,
} from "../../../../utils";
import { v4 as uuidv4 } from "uuid";

const initialBannerFormValue = (record) => ({
  id: { value: record?.id ?? null, error: null },
  desktop_image: { value: record?.desktop_image ?? null, error: null },
  desktop_image_name: { value: record?.desktop_image_name ?? "", error: null },
  mobile_image: { value: record?.mobile_image ?? null, error: null },
  mobile_image_name: { value: record?.mobile_image_name ?? "", error: null },
  link: { value: record?.link ?? "", error: null },
  background_color: {
    value: record?.background_color
      ? colorValueConverter(record.background_color)
      : colorValueConverter("#FFFFFF"),
    error: null,
  },
  text_color: {
    value: record?.color
      ? colorValueConverter(record.color)
      : colorValueConverter("#000000"),
    error: null,
  },
  type: { value: BANNER, error: null },
});

const initialVideoFormValue = (record) => ({
  id: { value: record?.id ?? null, error: null },
  desktop_image: { value: record?.desktop_image ?? null, error: null },
  desktop_image_name: { value: record?.desktop_image_name ?? "", error: null },
  mobile_image: { value: record?.mobile_image ?? null, error: null },
  mobile_image_name: { value: record?.mobile_image_name ?? "", error: null },
  video_url: { value: record?.video_url ?? "", error: null },
  background_color: {
    value: record?.background_color
      ? colorValueConverter(record.background_color)
      : colorValueConverter("#FFFFFF"),
    error: null,
  },
  text_color: {
    value: record?.color
      ? colorValueConverter(record.color)
      : colorValueConverter("#000000"),
    error: null,
  },
  type: { value: BANNER_VIDEO, error: null },
});

const initialHtmlFormValue = (record) => ({
  id: { value: record?.id ?? null, error: null },
  html_content: {
    value: record?.html_content ?? "<div>Hello world!</div>",
    error: null,
  },
  slug: { value: record?.slug ?? uuidv4() },
  type: { value: HTML_COLLECTION, error: null },
  active: { value: true, error: null },
});

const initialForm = (record) => () => {
  return {
    id: {
      value: record?.id ?? "",
      error: null,
    },
    max_products_mobile: {
      value: record?.max_products_mobile ?? 1,
      error: null,
    },
    mobile_item_spacing: {
      value: record?.mobile_item_spacing ?? 4,
      error: null,
    },
    mobile_row_padding: {
      value: record?.mobile_row_padding ?? "0px 0px 0px 0px",
      error: null,
    },
    max_products_desktop: {
      value: record?.max_products_desktop ?? 2,
      error: null,
    },
    desktop_item_spacing: {
      value: record?.desktop_item_spacing ?? 8,
      error: null,
    },
    desktop_row_padding: {
      value: record?.desktop_row_padding ?? "0px 0px 0px 0px",
      error: null,
    },
    background_color: {
      value: record?.background_color
        ? colorValueConverter(record.background_color)
        : colorValueConverter("#FFFFFF"),
      error: null,
    },
    text_color: {
      value: record?.color
        ? colorValueConverter(record.color)
        : colorValueConverter("#000000"),
      error: null,
    },
    title: { value: record?.title ?? "", error: null },
    type: { value: GENERIC_COLLECTION, error: null },
    card_type: { value: "", error: null },
    show_title: { value: record?.show_title ?? true, error: null },
    desktop_item_ratios: {
      value: record?.desktop_item_ratios ?? [],
      error: null,
    },
    mobile_item_ratios: {
      value: record?.mobile_item_ratios ?? [],
      error: null,
    },
  };
};

const initialTitleForm = (record) => ({
  title: { value: record?.title ?? "" },
});

const useGenericCollection = ({ formState, record, setErrorToast }) => {
  const [showAddCard, setShowAddCard] = useState(false);
  const [error, setError] = useState("");
  const [form, setForm] = useState(initialForm(record));
  const [bannerForm, setBannerForm] = useState(initialBannerFormValue(record));
  const [videoForm, setVideoForm] = useState(initialVideoFormValue(record));
  const [htmlForm, setHtmlForm] = useState(initialHtmlFormValue(record));
  const [addedCards, setAddedCards] = useState(record?.collections ?? []);
  const [titleForm, setTitleForm] = useState(initialTitleForm(record));
  const [editingIndex, setEditingIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [bannerError, setBannerError] = useState("");
  const [videoError, setVideoError] = useState("");
  const [htmlError, setHtmlError] = useState("");
  const [drawerOpenTab, setDrawerOpenTab] = useState(null); // null, mobile, desktop
  const [cardsCollapsed, setCardsCollapsed] = useState(true);

  const dispatch = useDispatch();
  const history = useHistory();

  const handleError = (key, value) => {
    setError((prev) => ({ ...prev, [key]: value }));
  };

  const handleFormChange = (key, value) => {
    setForm((prev) => ({ ...prev, [key]: { value } }));
    setBannerError("");
    setVideoError("");
    setHtmlError("");
  };

  const handleFormMultiChange = (keyValue2DArr) => {
    setForm((prev) => {
      const updatedForm = { ...prev };
      keyValue2DArr.forEach(([key, value]) => {
        updatedForm[key] = { value };
      });
      return updatedForm;
    });
  };

  const handleBannerFormChange = (key, value) => {
    setBannerForm((prev) => ({ ...prev, [key]: { value } }));
  };

  const handleVideoFormChange = (key, value) => {
    setVideoForm((prev) => ({ ...prev, [key]: { value } }));
  };

  const handleHtmlFormChange = (key, value) => {
    setHtmlForm((prev) => ({ ...prev, [key]: { value } }));
  };

  const handleTitleFormChange = (key, value) => {
    setTitleForm((prev) => ({ ...prev, [key]: { value } }));
  };

  const resetAddCardValues = (resetTitle = false) => {
    setBannerForm(initialBannerFormValue());
    setVideoForm(initialVideoFormValue());
    setHtmlForm(initialHtmlFormValue());
    if (resetTitle) setTitleForm(initialTitleForm());
    handleFormChange("card_type", "");
  };

  const handleEditCard = (index) => {
    setEditingIndex(index);
    const card = addedCards[index];
    handleTitleFormChange("title", card.title);
    handleFormChange("card_type", card.type);
    if (card.type === BANNER) {
      const reformValues = initialBannerFormValue(card);
      setBannerForm(reformValues);
    } else if (card.type === BANNER_VIDEO) {
      const reformValues = initialVideoFormValue(card);
      setVideoForm(reformValues);
    } else if (card.type === HTML_COLLECTION) {
      const reformValues = initialHtmlFormValue(card);
      setHtmlForm(reformValues);
    }
    setShowAddCard(true);
  };

  useEffect(() => {
    if (record?.collections?.length > 0) {
      setAddedCards(record.collections);
    }
  }, [record]);

  const updateCardsCapacity = (cardsPerSectionByDefault, prevCapacities) => {
    if (addedCards.length > prevCapacities.length) {
      // Adding cards
      const newCapacities = [...prevCapacities];
      for (let i = prevCapacities.length; i < addedCards.length; i++) {
        newCapacities.push(cardsPerSectionByDefault);
      }
      return newCapacities;
    } else {
      // Removing cards
      const newCapacities = prevCapacities.slice(0, addedCards.length);
      return newCapacities;
    }
  };

  useEffect(() => {
    handleFormChange(
      "desktop_item_ratios",
      updateCardsCapacity(6, form.desktop_item_ratios.value)
    );
    handleFormChange(
      "mobile_item_ratios",
      updateCardsCapacity(12, form.mobile_item_ratios.value)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedCards.length]);

  const onSave = async () => {
    setLoading(true);
    let errors = {};
    if (!formState.name.value) {
      errors.title = "This field is required";
    }
    if (addedCards.length === 0) {
      errors.card = "Add a card first";
    }
    if (Object.keys(errors).length > 0) {
      setError(errors);
      setLoading(false);
      return;
    }
    let defaultvalue = "10";
    const data = {
      type: GENERIC_COLLECTION,
      title: formState.title.value,
      name: formState.name.value,
      description: formState.description.value,
      color: form.text_color.value.css.backgroundColor,
      background_color: form.background_color.value.css.backgroundColor,
      active: true,
      overlay: true,
      show_title: form.show_title.value,
      link: formState.link.value,
      video_url: formState.video_url.value,
      display_only: false,
      slug: uuidv4(),
      ...(record
        ? {
            collections: addedCards,
            id: form.id.value,
            position: record.position,
          }
        : {
            collections: addedCards.map((e) => {
              const { id, ...rest } = e;
              return rest;
            }),
          }),
      max_products_mobile: form.max_products_mobile.value,
      max_products_desktop: form.max_products_desktop.value,
      desktop_item_spacing: form.desktop_item_spacing.value || defaultvalue,
      desktop_item_ratios: form.desktop_item_ratios.value,
      desktop_row_padding: form.desktop_row_padding.value,
      mobile_item_spacing: form.mobile_item_spacing.value || defaultvalue,
      mobile_item_ratios: form.mobile_item_ratios.value,
      mobile_row_padding: form.mobile_row_padding.value,
    };

    if (record) {
      await dispatch(updateCollection(record.id, data)).then((res) => {
        if (res.error) {
          setErrorToast({ value: true, msg: res.errorMsg });
          setLoading(false);
        } else {
          setLoading(false);
          history.goBack();
        }
      });
    } else {
      await dispatch(createCollections(data)).then((res) => {
        if (res.error) {
          setErrorToast({ value: true, msg: res.errorMsg });
          setLoading(false);
        } else {
          setLoading(false);
          history.goBack();
        }
      });
    }

    setLoading(false);
  };

  return {
    showAddCard,
    setShowAddCard,
    error,
    bannerError,
    setBannerError,
    videoError,
    setVideoError,
    htmlError,
    setHtmlError,
    handleError,
    form,
    handleFormChange,
    handleFormMultiChange,
    bannerForm,
    handleBannerFormChange,
    videoForm,
    handleVideoFormChange,
    htmlForm,
    handleHtmlFormChange,
    resetAddCardValues,
    addedCards,
    setAddedCards,
    titleForm,
    setTitleForm,
    handleTitleFormChange,
    handleEditCard,
    editingIndex,
    setEditingIndex,
    loading,
    onSave,
    drawerOpenTab,
    setDrawerOpenTab,
    cardsCollapsed,
    setCardsCollapsed,
  };
};

export default useGenericCollection;
