import { uploadImage } from "../../../../../../../../utils/imageUploader";

const uploadImageToCloud = async (image, filename) => {
  if (image) {
    if (image.startsWith("https:")) {
      return image;
    } else {
      let cloudFile = await uploadImage(filename, image);
      return cloudFile.payload.location;
    }
  }
};

export { uploadImageToCloud as uploadImage };
