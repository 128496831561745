import axios from "axios";

export const fetchUserCountry = async () => {
  let accessToken = localStorage.getItem("access_token");
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API}/api/v1/web/currency-meta`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "text/plain",
        },
      }
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
};
