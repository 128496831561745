import { Drawer, Grid, Modal, IconButton } from "@material-ui/core";
import {
  Done,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Fragment } from "react";
import CloseIcon from "../../../icons/CloseIcon";
import { updateBillSettings } from "../../../redux/billSettings/billSettingsActions";
import { COLORS, getColorByCode } from "../../../utils/invoiceUtil";
import useStyles from "../styles";
import { useParams, useHistory } from "react-router-dom";
import {
  startInvoiceLoader,
  stopInvoiceLoader,
} from "../../../redux/invoices/invoiceActions";
import CombinedButtons from "../../../components/buttons/CombinedButtons";
import { InvoiceTemplates } from "./InvoiceTemplates";

const FirstInvoice = ({ openFirstInvoice, setOpenFirstInvoice }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const [scrollIndex, setScrollIndex] = useState(0);
  const [preview, setPreview] = useState(false);
  const billSettings = useSelector((state) => state.billSettings.settings);

  const [selectedColor, setSelectedColor] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState("");

  useEffect(() => {
    setSelectedColor(getColorByCode(billSettings.color));
    setSelectedTemplate(
      billSettings.bill_template ? billSettings.bill_template : "A4"
    );
  }, [billSettings]);

  function hexToRGBA(h) {
    let r = 0,
      g = 0,
      b = 0;

    r = "0x" + h[1] + h[2];
    g = "0x" + h[3] + h[4];
    b = "0x" + h[5] + h[6];

    return "rgba(" + +r + "," + +g + "," + +b + ",0.24)";
  }

  const onSave = async () => {
    dispatch(startInvoiceLoader());
    let upSettings = {
      ...billSettings,
      color: selectedColor.code,
      bill_template: selectedTemplate,
      page_size: selectedTemplate,
    };
    dispatch(updateBillSettings(upSettings)).then(() => {
      setOpenFirstInvoice(false);
      dispatch(stopInvoiceLoader());
      history.push(`/${params.storeId}/invoices/create`);
    });
  };

  const saveTemplate = (template) => {
    setSelectedTemplate(template.bill_template);
  };

  const onTemplatePreview = (templateName) => {
    setPreview(templateName);
  };

  return (
    <>
      <Drawer
        open={openFirstInvoice}
        onClose={() => setOpenFirstInvoice(false)}
        anchor="right"
        PaperProps={{
          style: {
            borderTopLeftRadius: 16,
            borderBottomLeftRadius: 16,
            padding: 52,
            width: 570,
          },
        }}
      >
        <Grid container style={{ position: "relative" }}>
          <Grid item xs={12} className={classes.flexBox}>
            <p style={{ fontWeight: 700, fontSize: 24, marginTop: -10 }}>
              Start Creating Invoice
            </p>
            <IconButton
              aria-label="Close"
              aria-haspopup="true"
              color="inherit"
              onClick={() => {
                setOpenFirstInvoice(false);
              }}
              style={{ marginTop: -26 }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>

        <Grid
          className={classes.dividerGrid}
          style={{ display: "block", width: "100%", paddingBottom: 6 }}
        >
          <p className={classes.subHeading}>Choose a Template</p>
          <InvoiceTemplates
            selectedTemplate={selectedTemplate}
            onSave={saveTemplate}
            onPreview={onTemplatePreview}
          />
        </Grid>
        {(selectedTemplate === "A4" ||  selectedTemplate === "NOMRP.V1" || selectedTemplate === "A5") && (
          <Grid className={classes.dividerGrid}>
            <div style={{ width: "110%", marginTop: -6, marginLeft: "-5%" }}>
              <p
                className={classes.subHeading}
                style={{ marginBottom: 20, marginLeft: "5%" }}
              >
                Set Invoice Colour
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    marginTop: -42,
                    cursor: scrollIndex > 0 ? "pointer" : "default",
                  }}
                  onClick={() => {
                    if (scrollIndex > 0) setScrollIndex(scrollIndex - 1);
                  }}
                >
                  {scrollIndex > 0 && <KeyboardArrowLeft color="secondary" />}
                  {scrollIndex <= 0 && (
                    <div style={{ height: 24, width: 24 }} />
                  )}
                </span>
                {COLORS.map((color, i) => {
                  if (i < scrollIndex || i > 7 + scrollIndex) {
                    return <Fragment key={i} />;
                  }

                  return (
                    <div
                      style={{
                        width: 66,
                        textAlign: "-webkit-center",
                        cursor: "pointer",
                      }}
                      key={i}
                      onClick={() => setSelectedColor(color)}
                    >
                      <div
                        style={{
                          borderRadius: "50%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: 28,
                          width: 28,
                          background: color.code,
                          boxShadow: `0px 10px 20px ${hexToRGBA(color.code)}`,
                          border:
                            selectedColor.code === color.code
                              ? "2px solid white"
                              : "",
                        }}
                      >
                        {selectedColor.code === color.code && (
                          <Done fontSize="small" color="primary" />
                        )}
                      </div>
                      <p
                        style={{
                          marginTop: 12,
                          fontSize: 12,
                          color: "#666666",
                          fontWeight:
                            selectedColor.code === color.code ? 600 : 400,
                        }}
                      >
                        {color.name}
                      </p>
                    </div>
                  );
                })}
                <span
                  style={{
                    marginTop: -42,
                    cursor:
                      scrollIndex < COLORS.length - 8 ? "pointer" : "default",
                  }}
                  onClick={() => {
                    if (scrollIndex < COLORS.length - 8)
                      setScrollIndex(scrollIndex + 1);
                  }}
                >
                  {scrollIndex < COLORS.length - 8 && (
                    <KeyboardArrowRight color="secondary" />
                  )}
                  {scrollIndex >= COLORS.length - 8 && (
                    <div style={{ height: 24, width: 24 }} />
                  )}
                </span>
              </div>
            </div>
          </Grid>
        )}

        <div style={{ height: "100%", position: "relative" }}>
          <div style={{ position: "absolute", bottom: 0, right: 0 }}>
            <CombinedButtons
              outlinedBtnAction={() => {
                setOpenFirstInvoice(false);
              }}
              solidBtnAction={onSave}
              solidBtnText="Proceed"
            />
          </div>
        </div>
      </Drawer>
      {preview && (
        <Modal
          open={Boolean(preview)}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          disableAutoFocus
          disableEnforceFocus
          onClose={() => setPreview(false)}
        >
          <img
            src={require(`../../../images/${preview}.png`)}
            style={{ height: "85%" }}
            alt=""
          />
        </Modal>
      )}
    </>
  );
};

export default FirstInvoice;
