import { Typography } from "@material-ui/core";
import React, { useEffect, useRef } from "react";
import PhoneInput from "react-phone-input-2";
import Controls from "../../../components/controls/Controls";
import useStyles from "./styles";
import "react-phone-input-2/lib/material.css";
const DynamicInput = ({
  handleInputChange,
  inputType,
  inputs,
  errors,
  country,
}) => {
  const classes = useStyles();
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputType === "phone") {
      setTimeout(() => {
        const phoneInputField = document.querySelector(".form-control");
        if (phoneInputField) {
          phoneInputField.focus();
        }
      }, 100);
    }
  }, [inputType]);
  return (
    <>
      <Typography style={{ fontSize: 14, fontWeight: 400, marginBottom: 8 }}>
        {inputType === "phone"
          ? "Enter Phone Number"
          : inputType === "email"
          ? "Enter Email"
          : "Enter Email or Phone"}
      </Typography>
      {inputType === "phone" ? (
        <PhoneInput
          name="userNumber"
          country={country}
          specialLabel=""
          placeholder="Phone Number"
          value={inputs.mobile}
          onChange={(value, country) =>
            handleInputChange(value, "mobile", country)
          }
          isValid={!errors.email || !errors.mobile}
          inputStyle={{
            height: "44px",
            fontSize: "16px",
            borderRadius: 8,
          }}
          dropdownStyle={{
            borderRadius: "8px",
          }}
        />
      ) : (
        <Controls.Input
          label=""
          name="email"
          variant="outlined"
          labelPlacement="top"
          hideHelperText={true}
          fullWidth
          textClass={classes.input}
          value={inputs.email}
          onChange={(event) => handleInputChange(event.target.value, "email")}
          inputRef={inputRef}
          autoFocus
        />
      )}

      <Typography
        style={{
          color: "red",
          height: 18,
          fontSize: 12,
          marginTop: 4,
          marginBottom: errors.email || errors.mobile ? 18 : 4,
        }}
      >
        {errors.email || errors.mobile}
      </Typography>
    </>
  );
};

export default DynamicInput;
