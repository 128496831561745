import { Typography, Input } from "@material-ui/core";
import React from "react";
import { rowFlexOnlyJustifyBetweenCenter } from "../../styles/common";
import { useSelector } from "react-redux";

const ChargeComponent = ({ label, field, value, onUpdate }) => {
  const store = useSelector((state) => state.store.store);
  const countrySymbol = store.currency_symbol;
  return (
    <div
      className={rowFlexOnlyJustifyBetweenCenter}
      style={{
        width: 225,
        marginTop: 8,
      }}
    >
      <Typography
        style={{
          padding: "0px 18px",
          color: "#666666",
          fontSize: 12,
          fontWeight: 600,
        }}
      >
        {label}
      </Typography>
      <Input
        style={{
          border: "1px dashed #1641DB",
          width: 70,
          borderRadius: 6,
        }}
        inputProps={{
          style: {
            textAlign: "center",
            fontSize: 12,
          },
        }}
        type="number"
        placeholder={`${countrySymbol}00.00`}
        disableUnderline
        onFocus={(event) => event.target.select()}
        value={value}
        onChange={(e) => onUpdate(field, e.target.value)}
      />
    </div>
  );
};

export default ChargeComponent;
