import { makeStyles } from "@material-ui/core";
export default makeStyles((theme) => ({
  disabledBtn: {
    background: "#F6F6F6 !important",
    border: "none !important",
    color: "#999999 !important",
  },
  imgContainer: {
    border: "1.69422px solid #E1E1E1",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    cursor: "pointer",
  },
  addImgTxt: {
    marginTop: "20px",
    fontSize: "16px",
    fontWeight: 400,
    color: "#666666",
  },
  addImgTxtSmall: {
    marginTop: "8px",
    fontSize: "12px",
    lineHeight: "16px",
    color: "#666666",
    fontWeight: "400",
  },
  sizeNote: {
    marginTop: "16px",
    fontSize: "12px",
    fontWeight: 400,
    color: "#666666",
    width: "540px",
  },
  horizontalDivider: {
    color: "#E5E5E5",
    marginTop: "16px",
    marginBottom: "20px",
    borderWidth: "1px",
  },
  switchSubTitle: {
    fontSize: "12px",
    fontWeight: 400,
    color: "#666666",
  },
  bannerImg: {
    height: "100%",
    width: "100%",
    borderRadius: "8px",
  },
  bannerCard: {
    border: "1px solid #E5E5E5",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
  },
  cardDivider: {
    color: "#E5E5E5",
    borderWidth: "1px",
  },
  imgRoot: {
    background: "#FFFFFF",
    borderRadius: "8px 8px 0px 0px",
  },
  img: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  indicator: {
    background: "#C4C4C4",
    width: 8,
    height: 8,
    display: "inline-block",
    margin: "0 5px",
    borderRadius: "50%",
  },
  indexInd: {
    height: "21px",
    width: "21px",
    borderRadius: "50%",
    background: "#F9F9F9",
    position: "absolute",
    zIndex: 2,
    top: 12,
    left: 12,
    color: "#1A1A1A",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "12px",
  },
  iconInd: {
    height: "24px",
    width: "24px",
    borderRadius: "50%",
    background: "#F9F9F9",
    position: "absolute",
    zIndex: 2,
    top: 12,
    right: 12,
  },

  bannerAction: {
    fontWeight: 400,
    fontSize: 12,
    borderRadius: 6,
    padding: 9,
    cursor: "pointer",
    "&:hover": {
      background: "#F5F7FC",
    },
    display: "flex",
    alignItems: "center",
    color: "#1641DB",
  },
  sheet: {
    position: "absolute",
    top: 50,
    display: "flex",
    borderRadius: 6,
    background: "#FFFFFF",
    overflow: "hidden",
    zIndex: 1,
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.25)",
  },
}));
