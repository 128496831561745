import {
  CircularProgress,
  Divider,
  Fade,
  Grid,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { LocalPhone, WhatsApp } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import ConfirmModal from "../../components/common/ConfirmModal";
import HeaderBreadcrumbs from "../../components/common/HeaderBreadcrumbs";
import PageLoader from "../../components/Layout/PageLoader";
import { DeleteIcon, EditIcon, LocationIcon } from "../../icons";
import { deleteStoreCustomer } from "../../redux/storeCustomers/storeCustomerActions";
import {
  iconStyle,
  layoutBodyBox,
  layoutContainer,
  listPoints,
  loadingCircular,
  rowFlexAlignCenter,
  rowFlexCenterAll,
  rowFlexOnlyJustifyBetweenCenter,
} from "../../styles/common";
import {
  fetchStoreCustomerDetails,
  fetchStoreCustomerMetric,
} from "../../utils/customerUtils";
import OverviewCard from "./components/OverviewCard";
import useStyles from "./styles";
import { getDefaultStore, getStoreUrl } from "../../utils/storeUtil";

const ViewCustomer = ({ customersPerPage }) => {
  const params = useParams();
  const classes = useStyles();
  const history = useHistory();
  const [onlineMetric, setOnlineMetric] = useState(0);
  const [invoiceMetric, setInvoiceMetric] = useState(0);
  const [purchaseOrderMetric, setPurchaseOrderMetric] = useState(0);
  const [showConfirm, setShowConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [estimateMetric, setEstimateMetric] = useState(0);
  const storeUser = getDefaultStore();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const storeCustomerState = state.storeCustomers;
  const store = state.store.store;
  const countrySymbol = store.symbol;
  const storeCustomersPerPage = storeCustomerState.storeCustomersPerPage
    ? storeCustomerState.storeCustomersPerPage
    : 8;

  const [customer, setCustomer] = useState(null);
  const storeUrl = getStoreUrl(store);
  useEffect(() => {
    fetchStoreCustomerMetric(params.customerid, "ONLINE").then((res) =>
      setOnlineMetric(res)
    );
    fetchStoreCustomerMetric(params.customerid, "INVOICE").then((res) =>
      setInvoiceMetric(res)
    );
    fetchStoreCustomerMetric(params.customerid, "PURCHASE_ORDER").then((res) =>
      setPurchaseOrderMetric(res)
    );
    fetchStoreCustomerMetric(params.customerid, "ESTIMATE").then((res) =>
      setEstimateMetric(res)
    );
    fetchStoreCustomerDetails(params.customerid).then((data) =>
      setCustomer(data)
    );
  }, [params.customerid]);

  if (!customer) {
    return <PageLoader />;
  }

  const onCloseModal = () => {
    setShowConfirm(false);
  };

  const onConfirmModal = async () => {
    setShowConfirm(false);
    setLoading(true);
    dispatch(deleteStoreCustomer(customer.id, storeCustomersPerPage)).then(
      () => {
        setLoading(false);
        history.goBack();
      }
    );
  };

  const overviewCards = [
    {
      icon: customer.balance_amount > 0 ? "pay-money" : "receive-money",
      backgroundColor: customer.balance_amount > 0 ? "#E8F7F0" : "#FDE6EA",
      value: countrySymbol + Math.abs(customer.balance_amount),
      label: customer.balance_amount > 0 ? "You Give" : "You Recieve",
      link: `/customers/${customer.id}/transactions`,
      color:
        customer.balance_amount === 0
          ? "#1A1A1A"
          : customer.balance_amount > 0
          ? "#16AC67"
          : "#F0042E",
      iconWidth: 36,
    },
    {
      icon: "wallet",
      backgroundColor: "rgba(255, 172, 65, 0.15)",
      value: customer.wallet_points,
      label: "Wallet Balance",
      link: `/customers/${customer.id}/wallet`,
      iconWidth: 32,
    },
    {
      icon: "order",
      backgroundColor: "#FEF1EA",
      value: onlineMetric,
      label: "Orders",
      link: `/orders?refetch=${Date.now()}&customer-id=${customer.id}`,
      iconWidth: 32,
    },
    {
      icon: "invoice",
      backgroundColor: "#ECF0FC",
      value: invoiceMetric,
      label: "Invoices",
      link: `/invoices?refetch=${Date.now()}&customer-id=${customer.id}`,
      iconWidth: 28,
    },
    {
      icon: "purchase",
      backgroundColor: "#FDE6EA",
      value: purchaseOrderMetric,
      label: "Purchases",
      link: `/purchases?refetch=${Date.now()}&customer-id=${customer.id}`,
      iconWidth: 28,
    },
    {
      icon: "estimate",
      backgroundColor: "rgba(255, 172, 65, 0.15)",
      value: estimateMetric,
      label: "Estimates",
      link: `/estimates?refetch=${Date.now()}&customer-id=${customer.id}`,
      iconWidth: 32,
    },
  ];

  return (
    <>
      <div style={{ position: "relative" }}>
        <div className={layoutContainer}>
          <HeaderBreadcrumbs
            list={[
              {
                label: "Customers",
                link: "/customers",
              },
              {
                label: customer.name ? customer.name : customer.mobile,
              },
            ]}
          />
        </div>
        {loading && (
          <div className={loadingCircular}>
            <CircularProgress color="secondary" />
          </div>
        )}
        <div className={rowFlexCenterAll}>
          <div
            className={layoutBodyBox}
            style={{
              maxWidth: 680,
              padding: "30px 36px 36px",
              marginTop: 34,
            }}
          >
            <div className={rowFlexOnlyJustifyBetweenCenter}>
              <div className={classes.customerName}>
                {customer.name ? customer.name : customer.mobile}
              </div>
              <div className={rowFlexAlignCenter}>
                <Tooltip title="Edit" TransitionComponent={Fade}>
                  <span
                    className={iconStyle}
                    style={{ width: 24, height: 24 }}
                    onClick={() => {
                      history.push(`./${customer.id}/edit`);
                    }}
                  >
                    <EditIcon
                      width="14"
                      height="14"
                      size={14}
                      stroke="#1641DB"
                    />
                  </span>
                </Tooltip>
                {storeUser && storeUser.role !== "BILLING_MANAGER" ? (
                  <Tooltip title="Delete" TransitionComponent={Fade}>
                    <span
                      className={iconStyle}
                      style={{
                        width: 24,
                        height: 24,
                        border: "1.5px solid #FF0000",
                      }}
                      onClick={() => {
                        setShowConfirm(true);
                      }}
                    >
                      <DeleteIcon width="14px" height="14px" color="#FF0000" />
                    </span>
                  </Tooltip>
                ) : (
                  <></>
                )}
              </div>
            </div>
            {customer.mobile && (
              <div
                className={rowFlexCenterAll}
                style={{ justifyContent: "flex-start", marginBottom: 32 }}
              >
                <div className={rowFlexAlignCenter}>
                  <LocalPhone fontSize="small" color="secondary" />
                  <a
                    href={`tel:${customer.mobile}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Typography
                      style={{
                        margin: "0 20px 0 5px",
                        fontSize: 14,
                        color: "#1A1A1A",
                      }}
                    >
                      {customer.mobile}
                    </Typography>
                  </a>
                </div>
                <div className={rowFlexAlignCenter}>
                  <WhatsApp htmlColor="#16AC67" fontSize="small" />
                  <a
                    href={"https://wa.me/91" + customer.mobile}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Typography
                      color="secondary"
                      style={{ margin: "0 20px 0 5px", fontSize: 14 }}
                    >
                      WhatsApp
                    </Typography>
                  </a>
                </div>
                {customer.is_referrer && customer.ref_code && (
                  <div className={rowFlexAlignCenter}>
                    <img
                      style={{ width: "18px", height: "18px" }}
                      src="https://i.imgur.com/DRVOFLb.png"
                      alt=""
                    />
                    <a
                      href={`${storeUrl}?utm_id=${customer.ref_code}&utm_campaign=_site-referrer`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Typography
                        color="secondary"
                        style={{ margin: "0 0 0 5px", fontSize: 14 }}
                      >
                        Affiliate Link
                      </Typography>
                    </a>
                  </div>
                )}
              </div>
            )}
            <Grid container spacing={3}>
              {overviewCards.map((card, i) => (
                <OverviewCard {...card} key={i} />
              ))}
            </Grid>
            <Divider style={{ marginTop: "28px" }} />
            <div className={classes.addressHeading}>Billing Address</div>
            {customer.billing_address ? (
              <div className={classes.addressCard}>
                <LocationIcon style={{ marginRight: 12 }} fill="#1641DB" />
                <div>
                  <div className={classes.addressName}>
                    {customer.billing_address.customer_name}
                  </div>
                  <div>{customer.billing_address.mobile}</div>
                  <div style={{ color: "#666666", marginTop: 4 }}>
                    {customer.billing_address.street1}
                    {", "}
                    {customer.billing_address.street2
                      ? `${customer.billing_address.street2}, `
                      : ""}
                    {customer.billing_address.city},{" "}
                    {customer.billing_address.state},{" "}
                    {customer.billing_address.pincode}
                  </div>
                </div>
              </div>
            ) : (
              <div className={classes.noAddress}>Address not added</div>
            )}
            <div className={classes.addressHeading}>Shipping Address</div>
            {customer.shipping_address ? (
              <div className={classes.addressCard}>
                <LocationIcon style={{ marginRight: 12 }} fill="#1641DB" />
                <div>
                  <div className={classes.addressName}>
                    {customer.shipping_address.customer_name}
                  </div>
                  <div>{customer.shipping_address.mobile}</div>
                  <div style={{ color: "#666666", marginTop: 4 }}>
                    {customer.shipping_address.street1}
                    {", "}
                    {customer.shipping_address.street2
                      ? `${customer.shipping_address.street2}, `
                      : ""}
                    {customer.shipping_address.city},{" "}
                    {customer.shipping_address.state},{" "}
                    {customer.shipping_address.pincode}
                  </div>
                </div>
              </div>
            ) : (
              <div className={classes.noAddress}>Address not added</div>
            )}
          </div>
        </div>
      </div>
      <ConfirmModal
        open={showConfirm}
        closeBtnText="Close"
        confirmBtnText="Yes, Delete"
        intent="negative"
        onClose={onCloseModal}
        onConfirm={onConfirmModal}
        title="Delete Customer"
        solidFirst={true}
      >
        <p style={{ fontSize: 16 }}>
          Do you want to delete Customer{" "}
          <b>{customer.name ? customer.name : customer.mobile}</b> ? It will
          also delete
        </p>
        <List dense={true} className={listPoints}>
          <ListItem>
            <ListItemText primary="Customer Orders" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Customer Transactions and Wallet Points" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Customer other details" />
          </ListItem>
        </List>
        <p style={{ fontSize: 14, marginTop: 16 }}>
          This action can not be undone.
        </p>
      </ConfirmModal>
    </>
  );
};

export default ViewCustomer;
