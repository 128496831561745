import React from "react";
import { TEMPLATES } from "../../../utils/invoiceUtil";
import Slider from "react-slick";
import useStyles from "../styles";
import { rowFlexAlignCenter } from "../../../styles/common";
import { CheckCircle } from "@material-ui/icons";
import CarouselNextBtn from "../../../icons/CarouselNextBtn";
import CarouselPrevBtn from "../../../icons/CarouselPrevBtn";
import { Grid } from "@material-ui/core";

export const InvoiceTemplates = ({ selectedTemplate, onSave, onPreview }) => {
  const classes = useStyles();
  const NextArrow = (props) => {
    const { onClick } = props;
    return (
      <div
        className="slider-arrow"
        style={{
          right: -40,
        }}
        onClick={onClick}
      >
        <CarouselNextBtn />
      </div>
    );
  };

  const PrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div
        className="slider-arrow"
        style={{
          left: -40,
        }}
        onClick={onClick}
      >
        <CarouselPrevBtn />
      </div>
    );
  };

  const sliderSettings = {
    infinite: false,
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    className: "center",
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <Grid container>
      <Slider {...sliderSettings}>
        {TEMPLATES.map((temp, i) => {
          return (
            <Grid
              item
              xs={3}
              key={i}
              className={rowFlexAlignCenter}
              style={{
                flexDirection: "column",
              }}
            >
              <div
                className={classes.tempelateHolder}
                style={{
                  border:
                  selectedTemplate === temp.bill_template
                      ? "1px solid #1641DB"
                      : "1px solid #CCCCCC",
                }}
                onClick={() =>
                  onSave({
                    bill_template: temp.bill_template,
                    page_size: temp.page_size,
                  })
                }
              >
                <div className={classes.innerTempHolder}>
                  <img
                    src={require(`../../../images/${temp.image}.png`)}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                    alt=""
                  />
                </div>
                <div style={{ position: "absolute", right: 1, top: 1 }}>
                  {selectedTemplate === temp.bill_template && (
                    <CheckCircle color="secondary" />
                  )}
                </div>
              </div>
              <h3
                className={`${classes.subHeading} template-title`}
              >
                {temp.name}
              </h3>
              <h4
                className={`${classes.subHeading} template-preview`}                
                onClick={() => onPreview(temp.image)}
              >
                Preview
              </h4>
            </Grid>
          );
        })}
      </Slider>
      <style jsx="true">{`
        .slick-slide {
          margin: 0 20px;
        }        
        .template-title {
          font-size: 12px;
          text-align: center;
          margin-top: 8px;
          margin-bottom: 0px;
          padding-bottom: 0px;
          width: 96px;
        }
        .template-preview {
          font-size: 12px;
          color: #1641DB;
          text-align: center;
          width: 96px;
          margin-bottom: 8px;
          cursor: pointer;      
        }
        .slider-arrow {
          position: absolute;
          cursor: pointer;
          top: calc(50% - 50px);
          z-index: 1;
        }
        * {
          min-width: 0;
        }
      `}</style>
    </Grid>
  );
};
