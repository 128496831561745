import axios from "axios";
import {
  API,
  getDefaultStore,
  getStoreIdFromUrl,
} from "../../../utils/storeUtil";
import { SUB_PLANS_PER_PAGE } from "../../../utils/utils";
import {
  ADD_SUB_PLAN,
  CLEAR_COMPLETE_SUB_PLANS,
  CLEAR_SUB_PLANS,
  SUB_PLANS_PROGRESS,
  DELETE_SUB_PLAN,
  EDIT_SUB_PLAN,
  FETCH_SUB_PLANS,
  SET_SUB_PLANS_CURRENT_PAGE,
  SET_SUB_PLANS_ERROR,
  SET_SUB_PLANS_INPUT_KEYWORD,
  SET_SUB_PLANS_LOADER,
  SET_SUB_PLANS_MAX_PAGE_SO_FAR,
  SET_SUB_PLANS_PER_PAGE,
  SET_SUB_PLANS_SEARCH_KEYWORD,
  SET_SUCCESS_TOAST,
  DELETE_SUB_PLANS_ERROR,
  ADD_PRODUCTS_TO_PLAN,
} from "./planTypes";
import { CLEAR_SUB_PLAN } from "./planTypes";

export const fetchSubPlans =
  (page = 0, keyword = null, size = SUB_PLANS_PER_PAGE) =>
  (dispatch) => {
    dispatch({
      type: SUB_PLANS_PROGRESS,
      payload: true,
    });
    let accessToken = localStorage.getItem("access_token");
    let store = getDefaultStore();
    axios
      .get(
        `${API()}/api/v1/business/store/${
          store.store_id
        }/subscription-plan/all?page=${page}&size=${size}&sort=createdAt,desc${
          keyword ? `&q=${keyword}` : ""
        }`,
        {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((response) => {
        dispatch({
          type: FETCH_SUB_PLANS,
          payload: response.data,
          contractsPerPage: size,
        });
      })
      .catch((error) => {
        dispatch({
          type: SET_SUB_PLANS_ERROR,
          payload: { error: error.message },
        });
      });
  };

export const clearSubPlan = () => (dispatch) => {
  dispatch({ type: CLEAR_SUB_PLAN, payload: null });
};

export const clearSubPlans = () => (dispatch) => {
  dispatch({ type: CLEAR_SUB_PLANS, payload: null });
};

export const addSubPlan = (planData) => async (dispatch) => {
  let accessToken = localStorage.getItem("access_token");
  let storeId = getStoreIdFromUrl();
  let data = planData;
  dispatch({
    type: SET_SUB_PLANS_LOADER,
    payload: true,
  });
  return await axios
    .post(`${API()}/api/v1/business/store/${storeId}/subscription-plan`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    })

    .then((response) => {
      return dispatch({
        type: ADD_SUB_PLAN,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: SET_SUB_PLANS_ERROR,
        payload: { error: error.message },
      });
      throw error;
    });
};
export const onlyAddProducts = (products, id) => async (dispatch) => {
  let accessToken = localStorage.getItem("access_token");
  let storeId = getStoreIdFromUrl();
  let data = products;

  return await axios
    .post(
      `${API()}/api/v1/business/store/${storeId}/subscription-plan/${id}/products`,
      data,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      dispatch({
        type: ADD_PRODUCTS_TO_PLAN,
        payload: { subPlanId: id, products: response.data },
      });
      return response.data;
    })
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const editSubPlan = (planData) => async (dispatch) => {
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  let data = planData;
  dispatch({
    type: SET_SUB_PLANS_LOADER,
    payload: true,
  });

  return await axios
    .put(
      `${API()}/api/v1/business/store/${store.store_id}/subscription-plan/${
        data.id
      }`,
      data,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return dispatch({
        type: EDIT_SUB_PLAN,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: SET_SUB_PLANS_ERROR,
        payload: { error: error.message },
      });
      throw error;
    });
};

export const deleteSubPlan = (planId) => async (dispatch) => {
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();

  return await axios
    .delete(
      `${API()}/api/v1/business/store/${
        store.store_id
      }/subscription-plan/${planId}`,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
    .then((response) => {
      return dispatch({
        type: DELETE_SUB_PLAN,
        payload: planId,
      });
    })
    .catch((error) => {
      dispatch({
        type: DELETE_SUB_PLANS_ERROR,
        payload: { error: error.message },
      });
      throw error;
    });
};

export const clearsubPlans = () => (dispatch) => {
  dispatch({
    type: CLEAR_COMPLETE_SUB_PLANS,
  });
};

export const startSubPlansLoader = () => (dispatch) => {
  dispatch({
    type: SET_SUB_PLANS_LOADER,
    payload: true,
  });
};

export const stopSubPlansLoader = () => (dispatch) => {
  dispatch({
    type: SET_SUB_PLANS_LOADER,
    payload: false,
  });
};

export const setSubPlansPerPage = (data) => (dispatch) => {
  dispatch({
    type: SET_SUB_PLANS_PER_PAGE,
    payload: data,
  });
};

export const setSubPlansCurrentPage = (data) => (dispatch) => {
  dispatch({
    type: SET_SUB_PLANS_CURRENT_PAGE,
    payload: data,
  });
};

export const setSubPlansMaxPageSoFar = (data) => (dispatch) => {
  dispatch({
    type: SET_SUB_PLANS_MAX_PAGE_SO_FAR,
    payload: data,
  });
};

export const setSubPlansInputKeyword = (data) => (dispatch) => {
  dispatch({
    type: SET_SUB_PLANS_INPUT_KEYWORD,
    payload: data,
  });
};

export const setSubPlansSearchKeyword = (data) => (dispatch) => {
  dispatch({
    type: SET_SUB_PLANS_SEARCH_KEYWORD,
    payload: data,
  });
};

export const setSuccessToast = (data) => (dispatch) => {
  dispatch({
    type: SET_SUCCESS_TOAST,
    payload: data,
  });
};
