import React from "react";
import UpArrow from "./assets/UpArrow";
import DownArrow from "./assets/DownArrow";
import { HTML_COLLECTION } from "../../../../../utils";

const CardsToDisplayInRow = ({
  spaceBetweenCards,
  paddingAround,
  cardCapacityMap: capacities,
  onChange,
  addedCards,
  isMobile,
}) => {
  const handleCapacityChange = (index, value) => {
    if (value > 12) {
      alert("Capacity for one card cannot exceed 12!");
      return;
    } else if (value < 1) {
      alert("Capacity for one card cannot be less than 1!");
      return;
    }

    const newCapacities = [...capacities];
    newCapacities[index] = value;

    onChange(newCapacities);
  };

  return (
    <div>
      <div
        style={{
          marginBottom: "10px",
          border: "2px dashed #999999",
          borderRadius: "4px",
          padding: paddingAround,
          ...(isMobile && {
            width: "320px",
            margin: "0 auto",
          }),
        }}
      >
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            margin: `-${spaceBetweenCards / 2}px`,
          }}
        >
          {capacities.map((capacity, index) => (
            <div
              style={{
                width: `calc(${(capacity / 12) * 100}%`,
                flex: "none",
                padding: `${spaceBetweenCards / 2}px`,
              }}
            >
              <div
                key={index}
                style={{
                  boxSizing: "border-box",
                  background: "#f0f0f0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "4px",
                  position: "relative",
                  overflow: "hidden",
                  minHeight: 34,
                }}
              >
                {(() => {
                  const card = addedCards[index];
                  const imageUrl = isMobile
                    ? card.mobile_image
                    : card.desktop_image || card.mobile_image;
                  if (card.type === HTML_COLLECTION) {
                    return (
                      <iframe
                        style={{
                          width: "100%",
                          minHeight: "400px",
                          height: "auto",
                          objectFit: "cover",
                          border: "1px solid #c4c4c4",
                          borderRadius: "4px",
                          zoom: 0.6,
                        }}
                        srcDoc={card.html_content}
                        title="html"
                      ></iframe>
                    );
                  } else {
                    return (
                      <img
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          borderRadius: "4px",
                        }}
                        src={imageUrl}
                        alt=""
                      />
                    );
                  }
                })()}
                <div
                  style={{
                    position: "absolute",
                    height: "0px",
                    width: "calc(100% - 20px)",
                    borderTop: "1px dashed rgb(255, 255, 255)",
                    borderBottom: "1px dashed rgb(255, 255, 255)",
                    margin: "auto",
                    top: "0",
                    bottom: "0",
                    opacity: 0.8,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <UpArrow
                      style={{
                        transform: "rotate(90deg)",
                        position: "absolute",
                        right: "-6px",
                        top: "0",
                        bottom: "0",
                        margin: "auto",
                      }}
                    />
                    <DownArrow
                      style={{
                        transform: "rotate(90deg)",
                        position: "absolute",
                        left: "-6px",
                        top: "0",
                        bottom: "0",
                        margin: "auto",
                      }}
                    />
                  </div>
                </div>
                <div style={{ position: "absolute" }}>
                  <div style={{ position: "relative" }}>
                    <input
                      type="number"
                      min="1"
                      max="12"
                      value={capacity}
                      onChange={(e) =>
                        handleCapacityChange(index, parseInt(e.target.value))
                      }
                      style={{
                        boxSizing: "border-box",
                        textAlign: "center",
                        borderRadius: "6px",
                        outline: "none",
                        border: "1px solid #fff",
                        background: "#0000005c",
                        color: "white",
                        fontWeight: 600,
                        padding: "6px",
                        paddingRight: "20px",
                        backdropFilter: "blur(4px)",
                        WebkitBackdropFilter: "blur(4px)",
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        alignItems: "center",
                        position: "absolute",
                        right: "0",
                        top: "0",
                        bottom: "0",
                        margin: "auto",
                      }}
                    >
                      <UpArrow
                        {...(() => {
                          const disabled = capacities[index] >= 12;
                          return {
                            ...(!disabled && {
                              onClick: () => {
                                handleCapacityChange(
                                  index,
                                  capacities[index] + 1
                                );
                              },
                            }),
                            style: {
                              padding: 6,
                              boxSizing: "content-box",
                              width: "12px",
                              height: "12px",
                              ...(disabled
                                ? { opacity: 0.5, cursor: "auto" }
                                : {
                                    cursor: "pointer",
                                  }),
                            },
                          };
                        })()}
                      />
                      <DownArrow
                        {...(() => {
                          const disabled = capacities[index] <= 1;
                          return {
                            ...(!disabled && {
                              onClick: () => {
                                handleCapacityChange(
                                  index,
                                  capacities[index] - 1
                                );
                              },
                            }),
                            style: {
                              padding: 6,
                              boxSizing: "content-box",
                              width: "12px",
                              height: "12px",
                              ...(disabled
                                ? { opacity: 0.5, cursor: "auto" }
                                : {
                                    cursor: "pointer",
                                  }),
                            },
                          };
                        })()}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CardsToDisplayInRow;
