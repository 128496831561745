import React from "react";

const AddImageIcon = ({ showSmall = false, style = {}, ...props }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        ...(showSmall
          ? { width: "18px", height: "18px" }
          : { width: "22px", height: "22px" }),
        ...style,
      }}
      {...props}
    >
      <path
        d="M2 18C1.45 18 0.979167 17.8042 0.5875 17.4125C0.195833 17.0208 0 16.55 0 16V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H11V2H2V16H16V7H18V16C18 16.55 17.8042 17.0208 17.4125 17.4125C17.0208 17.8042 16.55 18 16 18H2ZM14 6V4H12V2H14V0H16V2H18V4H16V6H14ZM3 14H15L11.25 9L8.25 13L6 10L3 14Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default AddImageIcon;
