import axios from "axios";
import { getDefaultStore } from "./storeUtil";

export const getBillSequence = async (type = "INVOICE") => {
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  let orderType = type === "ONLINE" ? "INVOICE" : type;
  let response = await axios.get(
    `${process.env.REACT_APP_API}/api/v1/store/${store.store_id}/bill-sequences/${orderType}`,
    {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  let payload = response.data.payload;
  let sequence = "";
  if (payload) {
    sequence =
      payload.prefix_val + String(payload.max_sequence + 1).padStart(4, "0");
  }
  return sequence;
};

export const getPdfLink = async (order_id, tiny = false) => {
  let accessToken = localStorage.getItem("access_token");
  let response = await axios.get(
    `${process.env.REACT_APP_API}/api/v1/org/order/${order_id}/invoice/signed-url?tiny-url=${tiny}`,
    {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return response.data.payload;
};

export const COLORS = [
  {
    code: "#FF2222",
    name: "Red",
  },
  {
    code: "#FF7222",
    name: "Orange",
  },
  {
    code: "#7622FF",
    name: "Purple",
  },
  {
    code: "#24C678",
    name: "Green",
  },
  {
    code: "#2253FF",
    name: "Blue",
  },
  {
    code: "#FCE72C",
    name: "Yellow",
  },
  {
    code: "#975A3F",
    name: "Brown",
  },
  {
    code: "#C8CB1F",
    name: "Lime",
  },
  {
    code: "#15DCDC",
    name: "Cayn",
  },
  {
    code: "#3F00FF",
    name: "Indigo",
  },
  {
    code: "#d81b60",
    name: "Pink",
  },
  {
    code: "#666666",
    name: "Grey",
  },
];

export const getColorByCode = (color) => {
  let ret = COLORS[0];
  if (color) {
    for (let index = 0; index < COLORS.length; index++) {
      if (COLORS[index].code === color) {
        ret = COLORS[index];
        break;
      }
    }
  }
  return ret;
};

export const TEMPLATES = [
  {
    name: "A4",
    page_size: "A4",
    bill_template: "A4",
    image: "A4"
  },
  {
    name: "A4 without MRP",
    page_size: "A4",
    bill_template: "NOMRP.V1",
    image: "A4-WITHOUT-MRP"
  },
  {
    name: "A5",
    page_size: "A5",
    bill_template: "A5",
    image: "A5"
  },
  {
    name: "Thermal 57MM",
    page_size: "57MM",
    bill_template: "57MM",
    image: "57MM"
  },
  {
    name: "Thermal 80MM",
    page_size: "80MM",
    bill_template: "80MM",
    image: "80MM"
  }
];

export const getWhatsAppShareLink = async (invoice) => {
  let baseUrl = "https://wa.me/";
  let toNumber = invoice.org_customer_mobile;
  let countryCode = 91;
  if (toNumber) {
    if (toNumber.length === 10) {
      toNumber = countryCode + toNumber;
    }
    baseUrl = baseUrl + toNumber;
  }
  let link = await getPdfLink(invoice.id, true);
  let text = `Thank you for shopping with ${invoice.store_name}. Your invoice is available to download at ${link}`;
  if (text) {
    let encodedText = encodeURIComponent(text);
    baseUrl = `${baseUrl}?text=${encodedText}`;
  }
  return baseUrl;
};

export const fetchInvoiceById = async (invoiceid) => {
  let accessToken = localStorage.getItem("access_token");
  let store = getDefaultStore();
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_API}/api/v1/org/store/${store.store_id}/order/find-invoice`,
      { invoice_id: decodeURIComponent(invoiceid) },
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data.payload;
  } catch (err) {
    console.log("Failure while fetching invoice", err);
  }
};
