import { Box, Typography, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import RechargeSuccessIcon from "../../../icons/RechargeSuccessIcon";
import CheckIcon from "../../../icons/CheckIcon";
import RechargeHeader from "./RechargeHeader";
import { useParams } from "react-router-dom";
import PageLoader from "../../../components/Layout/PageLoader";
import {
  fetchStoreDetails,
  formatIsoString,
  getRechargeInfo,
} from "./WalletUtils";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  loader: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  nonHeader: {
    minHeight: `calc(100vh - 64px)`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 20px", // Add padding for mobile devices
  },
  card: {
    width: "100%",
    maxWidth: 480,
    margin: "auto",
    border: "1px solid #EEEEEE",
    borderRadius: 10,
    padding: 20,
    textAlign: "center",
    backgroundColor: "#fff",
    [theme.breakpoints.up("md")]: {
      width: 480, // Set width to 480px on medium screens (desktop)
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%", // Make the card full width on mobile
      padding: 10,
    },
  },
  icon: {
    margin: "24px 0px",
  },
  details: {
    margin: "20px 0",
    color: "#666",
  },
  credits: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 10,
    backgroundColor: "#e0f7fa",
    padding: 10,
    borderRadius: 5,
    color: "#333",
    marginBottom: 10,
    marginTop: 20,
  },
}));

const RechargeSuccess = () => {
  const classes = useStyles();
  const { slug, requestId } = useParams();
  const store = useSelector((state) => state.store.store);
  const countrySymbol = store.currency_symbol;
  const [loading, setLoading] = useState(true);
  const [rechargeDetails, setRechargeDetails] = useState(null);

  useEffect(() => {
    fetchStoreDetails(slug)
      .then((storeResponse) => {
        const { id: storeId } = storeResponse;
        return getRechargeInfo(storeId, requestId);
      })
      .then((rechargeResponse) => {
        setRechargeDetails(rechargeResponse);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  }, [requestId, slug]);

  if (loading) {
    return (
      <div className={classes.loader}>
        <PageLoader />
      </div>
    );
  }

  return (
    <div>
      <RechargeHeader />
      <div className={classes.nonHeader}>
        <Box className={classes.card}>
          <Box className={classes.icon}>
            <RechargeSuccessIcon />
          </Box>
          <Typography variant="h4">Recharge Successfull!</Typography>
          <Typography variant="body2" style={{ fontSize: 14, marginTop: 10 }}>
            Wallet Recharged for Transactional SMS
          </Typography>
          <Box className={classes.details}>
            <Typography variant="body2">
              Amount:{" "}
              <strong
                style={{ fontWeight: 700, fontSize: 18, color: "#000000" }}
              >
                {countrySymbol}
                {rechargeDetails.amount}
              </strong>
            </Typography>
            <Typography variant="body2" style={{ marginTop: 10 }}>
              Transaction #: {rechargeDetails.order_id}
            </Typography>
            <Typography variant="body2" style={{ marginTop: 10 }}>
              {formatIsoString(rechargeDetails.updated_at).date} |{" "}
              {formatIsoString(rechargeDetails.updated_at).time}
            </Typography>
          </Box>
          <Box className={classes.credits}>
            <span>
              <CheckIcon />
            </span>
            <Typography variant="body2" style={{ color: "#1A1A1A" }}>
              {rechargeDetails.amount * 3} Credits added to your Shoopy Wallet
            </Typography>
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default RechargeSuccess;
