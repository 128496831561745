import React, { Fragment, useEffect, useState } from "react";
import useStyles from "./styles";
import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  LinearProgress,
  Typography,
  Box,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import { SettingsIcon } from "../../icons";
import { useDispatch, useSelector } from "react-redux";
import {
  REFER_EARN_TRANSACTIONS_PER_PAGE,
  DEFAULT_REFER_EARN_TRANSACTIONS_PER_PAGE,
  REFER_EARN_TRANSACTIONS_KEY,
  getDateRange,
} from "../../utils/utils";
import Paginator from "../../components/Paginator/Paginator";
import { getStoreIdFromUrl } from "../../utils/storeUtil";
import {
  layoutBodyBox,
  layoutContainer,
  layoutLoaderHeader,
  layoutSubHeader,
} from "../../styles/common";
import PageLoader from "../../components/Layout/PageLoader";
import HeaderBreadcrumbs from "../../components/common/HeaderBreadcrumbs";
import OutlinedButton from "../../components/buttons/OutlinedButton";
import { Link, useHistory } from "react-router-dom";
import SubtitleSelect from "../../components/controls/SubtitleSelect";
import DateRangePicker from "react-bootstrap-daterangepicker";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import ReferralDashboard from "./components/ReferralDashboard";
import {
  clearTransactions,
  fetchTransactions,
  setTransactionDateRange,
  setTransactionDateSelector,
  setTransactionsCurrentPage,
  setTransactionsInputKeyword,
  setTransactionsMaxPageSoFar,
  setTransactionsPerPage,
  setTransactionsSearchKeyword,
} from "../../redux/referAndEarn/referAndEarnActions";
import Moment from "react-moment";
import { getReferralConfig } from "./components/referralUtils";
import ViewOrder from "../orders/components/ViewOrder";
import RecordOrderPaymentModal from "../orders/components/RecordOrderPaymentModal";
import Toast from "../../components/Layout/Toast";
import { fetchOrderById } from "../../utils/orderUtil";
import NoticeBar from "../../components/common/NoticeBar";
import { PLANS } from "../plans/constants";
import { BUSINESS } from "../plans/constants";
import PaidPopUp from "../../components/PaidPopUp";
import NoRecord from "../../components/common/NoRecord";

const ReferralMarketing = () => {
  const classes = useStyles();
  const history = useHistory();
  const referAndEarnState = useSelector((state) => state.referAndEarn);
  const store = useSelector((state) => state.store.store);
  const countrySymbol = store.currency_symbol;
  const transactions = referAndEarnState.transactions;
  const numberOfPages = referAndEarnState.numberOfPages;
  const dateSelector = referAndEarnState.dateSelector;
  const dateRange = referAndEarnState.dateRange;
  const transactionsPerPage = referAndEarnState.transactionsPerPage;
  const currentPage = referAndEarnState.currentPage;
  const maxPageSoFar = referAndEarnState.maxPageSoFar;
  const searchKeyword = referAndEarnState.searchKeyword;
  const inputKeyword = referAndEarnState.inputKeyword;

  const [config, setConfig] = useState(null);
  const [configLoading, setConfigLoading] = useState(true);
  const [whiteSpaceDiv, setWhiteSpaceDiv] = useState(false);
  const [viewOrderId, toggleViewOrder] = useState(null);
  const [loadingOrder, setLoadingOrder] = useState(true);
  const [orderRecord, setOrderRecord] = useState(null);
  const [openRecordPayment, setOpenRecordPayment] = useState(false);
  const [reloadPayment, setReloadPayment] = useState(false);
  const [toastRec, setToastRec] = useState(false);
  const [isPaidPopUpOpen, setIsPaidPopUpOpen] = useState(false);

  useEffect(() => {
    if (viewOrderId) {
      setLoadingOrder(true);
      fetchOrderById(viewOrderId).then((order) => {
        setOrderRecord(order);
        setLoadingOrder(false);
      });
    }
  }, [viewOrderId]);

  const onChangeTransactionsPerPage = (updatedTransactionsPerPage) => {
    localStorage.setItem(
      REFER_EARN_TRANSACTIONS_KEY,
      updatedTransactionsPerPage
    );
    dispatch(clearTransactions());
    dispatch(setTransactionsCurrentPage(0));
    dispatch(setTransactionsMaxPageSoFar(0));
    dispatch(setTransactionsPerPage(updatedTransactionsPerPage));
    dispatch(
      fetchTransactions(
        0,
        dateRange.aoe,
        dateRange.boe,
        searchKeyword,
        updatedTransactionsPerPage
      )
    );
  };

  let startIndex = currentPage * transactionsPerPage;
  let endIndex =
    startIndex + transactionsPerPage < transactions.length
      ? startIndex + transactionsPerPage
      : transactions.length;

  const dispatch = useDispatch();
  const storeId = getStoreIdFromUrl();
  const planInfo = useSelector((state) => state.store.storePlanRenewInfo);
  const referAllowed = planInfo && planInfo.refer_and_earn;

  useEffect(() => {
    getReferralConfig().then((res) => {
      if (res.id > 0) {
        setConfig(res);
      }
      setConfigLoading(false);
    });
    dispatch(setTransactionDateSelector(dateSelector ? dateSelector : "All"));
    dispatch(
      fetchTransactions(
        currentPage,
        dateRange.aoe,
        dateRange.boe,
        searchKeyword,
        transactionsPerPage
      )
    );
    // eslint-disable-next-line
  }, [storeId]);

  useEffect(() => {
    if (store) {
      if (
        !referAllowed &&
        PLANS[store.plan]?.weight < PLANS[BUSINESS]?.weight
      ) {
        setIsPaidPopUpOpen(true);
      }
    }
    // eslint-disable-next-line
  }, [store, planInfo]);

  useEffect(() => {
    if (!transactionsPerPage) {
      dispatch(setTransactionsPerPage(REFER_EARN_TRANSACTIONS_PER_PAGE));
    }
    // eslint-disable-next-line
  }, [transactionsPerPage]);

  const onNextPage = () => {
    setWhiteSpaceDiv(false);
    if (maxPageSoFar < currentPage + 1) {
      dispatch(
        fetchTransactions(
          currentPage + 1,
          dateRange.aoe,
          dateRange.boe,
          searchKeyword,
          transactionsPerPage
        )
      );
      dispatch(
        setTransactionsMaxPageSoFar(
          maxPageSoFar < currentPage + 1 ? currentPage + 1 : maxPageSoFar
        )
      );
      dispatch(setTransactionsCurrentPage(currentPage + 1));
    } else {
      dispatch(setTransactionsCurrentPage(currentPage + 1));
    }
  };

  const onPrevPage = () => {
    dispatch(setTransactionsCurrentPage(currentPage - 1));
  };

  const handleChangeDateSelector = (range) => {
    setWhiteSpaceDiv(true);
    if (range === dateSelector) return;
    dispatch(setTransactionsMaxPageSoFar(0));
    dispatch(setTransactionsCurrentPage(0));
    let res = getDateRange(range);
    let aoe = res.aoe;
    let boe = res.boe;
    dispatch(
      fetchTransactions(0, aoe, boe, searchKeyword, transactionsPerPage)
    );
    dispatch(setTransactionDateSelector(range));
    dispatch(setTransactionDateRange({ aoe: aoe, boe: boe }));
  };

  const handleCustomDateSelector = (rangePicker) => {
    setWhiteSpaceDiv(true);
    dispatch(setTransactionsMaxPageSoFar(0));
    dispatch(setTransactionsCurrentPage(0));
    dispatch(
      fetchTransactions(
        0,
        rangePicker.startDate._d.toISOString(),
        rangePicker.endDate._d.toISOString(),
        searchKeyword,
        transactionsPerPage
      )
    );
    dispatch(setTransactionDateSelector("Custom"));
    dispatch(
      setTransactionDateRange({
        aoe: rangePicker.startDate._d.toISOString(),
        boe: rangePicker.endDate._d.toISOString(),
      })
    );
  };

  const onSearch = (keyword) => {
    setWhiteSpaceDiv(true);
    if (keyword) {
      dispatch(setTransactionsMaxPageSoFar(0));
      dispatch(setTransactionsCurrentPage(0));
      dispatch(setTransactionDateSelector("All"));
      dispatch(fetchTransactions(0, null, null, keyword, transactionsPerPage));
    }
  };

  if (!referAllowed && PLANS[store?.plan]?.weight < PLANS[BUSINESS]?.weight) {
    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} className={layoutLoaderHeader}>
            <div className={layoutContainer}>
              <HeaderBreadcrumbs list={[{ label: "Refer & Earn" }]} />
              <NoRecord
                text={
                  <div>
                    Please{" "}
                    <span
                      className={classes.upgradeLink}
                      onClick={() => setIsPaidPopUpOpen(true)}
                    >
                      Upgrade Plan
                    </span>{" "}
                    to Activate Refer & Earn
                  </div>
                }
                image={
                  <img
                    src="https://storage.googleapis.com/shy-pub/_static/app-img/no-referral-history.png"
                    alt="No Referrals"
                  />
                }
                arrow={false}
              />
            </div>
          </Grid>
        </Grid>
        <PaidPopUp
          open={isPaidPopUpOpen}
          close={() => setIsPaidPopUpOpen(false)}
          plan={BUSINESS}
          subtxt="Unlock unlimited growth potential with our powerful Refer & Earn feature for your business."
        />
      </div>
    );
  }

  if (configLoading) {
    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} className={layoutLoaderHeader}>
            <div className={layoutContainer}>
              <HeaderBreadcrumbs list={[{ label: "Refer & Earn" }]} />
              <PageLoader />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className={layoutContainer}>
            <div className={layoutSubHeader}>
              <HeaderBreadcrumbs list={[{ label: "Refer & Earn" }]} />
              <OutlinedButton
                startIcon={<SettingsIcon stroke="#1641DB" />}
                style={{ width: 185, marginRight: 20 }}
                onClick={() => {
                  history.push("./refer-and-earn/settings");
                }}
              >
                Settings
              </OutlinedButton>
            </div>
            {!config ? (
              <>
                <NoRecord
                  text="Go to Settings to activate Refer And Earn"
                  image={
                    <img
                      src="https://storage.googleapis.com/shy-pub/_static/app-img/no-referral-history.png"
                      alt="No Referrals"
                    />
                  }
                  arrow={true}
                />
              </>
            ) : (
              <div style={{ position: "relative", width: "100%" }}>
                {!config?.activated && (
                  <div style={{ marginTop: 30 }}>
                    <NoticeBar
                      imgSrc="https://storage.googleapis.com/shy-pub/_static/app-img/note-svgrepo-com.png"
                      text="Refer & Earn is disabled, please go to settings to
                enable it"
                    />
                  </div>
                )}
                <div className={layoutBodyBox}>
                  <ReferralDashboard />
                </div>
                <div className={layoutBodyBox}>
                  <Typography
                    className={classes.heading}
                    style={{ paddingLeft: 24 }}
                  >
                    Transactions
                  </Typography>
                  <div className={classes.tableHeader}>
                    <div className={classes.search}>
                      <SearchIcon />
                      <input
                        type="text"
                        placeholder="Search by Referee and Referred User"
                        className={classes.searchBox}
                        value={inputKeyword}
                        onChange={(e) =>
                          dispatch(setTransactionsInputKeyword(e.target.value))
                        }
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            if (inputKeyword.trim() !== "") {
                              dispatch(
                                setTransactionsSearchKeyword(
                                  inputKeyword.trim()
                                )
                              );
                              onSearch(inputKeyword.trim());
                            }
                          }
                          if (e.key === "Escape") {
                            setWhiteSpaceDiv(true);
                            dispatch(setTransactionsSearchKeyword(""));
                            dispatch(setTransactionsInputKeyword(""));
                            dispatch(setTransactionsMaxPageSoFar(0));
                            dispatch(setTransactionsCurrentPage(0));
                            dispatch(fetchTransactions());
                            dispatch(setTransactionDateSelector("All"));
                            dispatch(
                              setTransactionDateRange({ aoe: null, boe: null })
                            );
                            e.currentTarget.blur();
                          }
                        }}
                      />
                      {searchKeyword !== "" && (
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setWhiteSpaceDiv(true);
                            dispatch(setTransactionsSearchKeyword(""));
                            dispatch(setTransactionsInputKeyword(""));
                            dispatch(setTransactionsMaxPageSoFar(0));
                            dispatch(setTransactionsCurrentPage(0));
                            dispatch(fetchTransactions());
                            dispatch(setTransactionDateSelector("All"));
                            dispatch(
                              setTransactionDateRange({ aoe: null, boe: null })
                            );
                          }}
                        >
                          <CloseIcon fontSize="small" />
                        </span>
                      )}
                    </div>
                    <div className={classes.tableHeaderRight}>
                      <div>
                        <SubtitleSelect
                          optionList={[
                            { value: "All", title: "All", displayValue: "All" },
                            {
                              value: "Today",
                              title: "Today",
                              displayValue: "Today",
                            },
                            {
                              value: "Yesterday",
                              title: "Yesterday",
                              displayValue: "Yesterday",
                            },
                            {
                              value: "Last Week",
                              title: "Last Week",
                              displayValue: "Last Week",
                            },
                            {
                              value: "Last Month",
                              title: "Last Month",
                              displayValue: "Last Month",
                            },
                            {
                              value: "Custom",
                              title: "Custom",
                              displayValue: "Custom",
                            },
                          ]}
                          value={dateSelector}
                          onChange={(value) => {
                            handleChangeDateSelector(value);
                          }}
                          width="137px"
                        />
                      </div>
                      <div>
                        <DateRangePicker
                          initialSettings={{
                            startDate: new Date(
                              new Date().setMonth(new Date().getMonth() - 1)
                            ),
                            endDate: new Date(),
                            maxDate: new Date(),
                            applyButtonClasses: classes.applyBtn,
                          }}
                          onApply={(fn, rangePicker) => {
                            handleCustomDateSelector(rangePicker);
                          }}
                        >
                          <Box
                            margin="0px 8px"
                            border="1px solid #e6e6e6"
                            display="flex"
                            borderRadius="4px"
                          >
                            <Box
                              padding="9px"
                              borderRight="1px solid #e6e6e6"
                              style={{ cursor: "pointer" }}
                            >
                              <DateRangeOutlinedIcon
                                fontSize="small"
                                style={{ height: "14px", width: "14px" }}
                              />
                            </Box>
                            <Box padding="9px">
                              <Typography className={classes.dateText}>
                                {dateRange.aoe && dateRange.boe ? (
                                  <>
                                    <Moment format="DD-MM-YYYY">
                                      {dateRange.aoe}
                                    </Moment>
                                    <span> - </span>
                                    <Moment format="DD-MM-YYYY">
                                      {dateRange.boe}
                                    </Moment>
                                  </>
                                ) : (
                                  "dd/mm/yyyy - dd/mm/yyyy"
                                )}
                              </Typography>
                            </Box>
                          </Box>
                        </DateRangePicker>
                      </div>
                    </div>
                  </div>
                  <TableContainer>
                    <Table>
                      <TableHead className={classes.tablehead}>
                        <TableRow className={classes.headrow}>
                          <TableCell style={{ width: "20%" }}>
                            Customer
                          </TableCell>

                          <TableCell style={{ width: "14%" }}>Coupon</TableCell>
                          <TableCell style={{ width: "14%" }}>
                            Order ID
                          </TableCell>
                          <TableCell style={{ width: "12%" }}>Type</TableCell>
                          <TableCell style={{ width: "13%" }}>Date</TableCell>
                          <TableCell style={{ width: "14%" }}>
                            Order Value
                          </TableCell>
                          <TableCell style={{ width: "13%" }}>
                            Discount
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className={classes.tablebody}>
                        {transactions
                          .slice(startIndex, endIndex)
                          .map((tableitem, index) => (
                            <TableRow
                              key={tableitem.id}
                              style={{
                                borderBottom:
                                  (index + 1) % transactionsPerPage === 0
                                    ? "none"
                                    : "auto",
                              }}
                            >
                              <TableCell>
                                <Link
                                  to={`./customers/${
                                    tableitem.type === "SYS_REFERRAL"
                                      ? tableitem.referred_customer_id
                                      : tableitem.referee_customer_id
                                  }`}
                                  style={{
                                    color: "#1641DB",
                                    textDecoration: "none",
                                  }}
                                >
                                  {tableitem.type === "SYS_REFERRAL"
                                    ? tableitem.referred_customer_name
                                    : tableitem.referee_customer_name}
                                </Link>
                              </TableCell>
                              <TableCell>
                                <Link
                                  to={`./coupons/edit?id=${tableitem.coupon_id}`}
                                  style={{
                                    color: "#1641DB",
                                    textDecoration: "none",
                                  }}
                                >
                                  {tableitem.coupon_code}
                                </Link>
                              </TableCell>
                              <TableCell
                                onClick={() => {
                                  tableitem.order_id &&
                                    toggleViewOrder(tableitem.order_display_id);
                                }}
                                style={{
                                  ...(tableitem.order_id && {
                                    color: "#1641DB",
                                    cursor: "pointer",
                                  }),
                                }}
                              >
                                {tableitem.order_display_id
                                  ? tableitem.order_display_id
                                  : "Order pending"}
                              </TableCell>
                              <TableCell>
                                {tableitem.type === "SYS_REFERRAL"
                                  ? "Referral"
                                  : "Referee"}
                              </TableCell>
                              <TableCell>
                                <Moment format="DD-MM-YYYY">
                                  {tableitem.updated_at}
                                </Moment>
                              </TableCell>
                              <TableCell>
                                {tableitem.order_value > 0
                                  ? `${countrySymbol}${tableitem.order_value.toFixed(
                                      2
                                    )}`
                                  : "--"}
                              </TableCell>
                              <TableCell>
                                {tableitem.coupon_discount > 0
                                  ? `${countrySymbol}${tableitem.coupon_discount.toFixed(
                                      2
                                    )}`
                                  : "--"}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>

                    {referAndEarnState.progress && (
                      <Fragment>
                        {!whiteSpaceDiv && (
                          <div
                            style={{
                              height: 69 * transactionsPerPage - 4,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          ></div>
                        )}
                        <LinearProgress color="secondary" />
                      </Fragment>
                    )}
                    {transactions.length === 0 && (
                      <div
                        style={{
                          height: 69 * transactionsPerPage,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: 18,
                            fontWeight: 400,
                            marginTop: -34,
                          }}
                        >
                          No Transactions Found
                        </Typography>
                      </div>
                    )}
                  </TableContainer>
                </div>
                <Paginator
                  changeRecordsPerPage={onChangeTransactionsPerPage}
                  possibleRecordsPerPage={[
                    DEFAULT_REFER_EARN_TRANSACTIONS_PER_PAGE,
                    20,
                    50,
                    100,
                  ]}
                  recordsPerPage={transactionsPerPage}
                  onNext={onNextPage}
                  onPrev={onPrevPage}
                  total={numberOfPages === 0 ? 1 : numberOfPages}
                  currentPage={currentPage}
                />
              </div>
            )}
          </div>
        </Grid>
      </Grid>
      <ViewOrder
        openViewOrder={!!viewOrderId}
        setOpenViewOrder={toggleViewOrder}
        order={orderRecord}
        setOpenRecordPayment={setOpenRecordPayment}
        setAnchoredOrder={() => {}}
        reloadPayment={reloadPayment}
        loading={loadingOrder}
      />
      {orderRecord && (
        <RecordOrderPaymentModal
          order={orderRecord}
          setOpenRecordPayment={setOpenRecordPayment}
          openRecordPayment={openRecordPayment}
          setReloadPayment={setReloadPayment}
          reloadPayment={reloadPayment}
          setToastRec={setToastRec}
        />
      )}
      <Toast
        open={toastRec}
        close={setToastRec}
        message="Payment Recorded Successfully"
      />
    </Fragment>
  );
};
export default ReferralMarketing;
