import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import {
  layoutBodyBox,
  layoutContainer,
  layoutSubHeader,
} from "../../styles/common";
import StoreInsights from "./components/StoreInsights";
import Activities from "./components/Activities";
import DashboardStoreLink from "./components/DashboardStoreLink";
import BannerCarousel from "./components/BannerCarousel";
import OnlineStores from "./components/OnlineStores";
import HeaderBreadcrumbs from "../../components/common/HeaderBreadcrumbs";
import { getDefaultStore, getStoreIdFromUrl } from "../../utils/storeUtil";
import { isCustomDomain } from "../../utils/utils";
import HighlightWidget from "../../components/common/HighlightWidget";
import PromotionModal from "./components/PromotionModal";

const Dashboard = () => {
  const storeId = getStoreIdFromUrl();
  const [customDomain, setCustomDomain] = useState(false);
  const [showInsightsTodos, setShowInsightsTodos] = useState(true);

  useEffect(() => {
    let storeUser = getDefaultStore();
    if (storeUser && storeUser.role) {
      const showInsights =
        storeUser.role === "SUPER_ADMIN" ||
        storeUser.role === "ADMIN" ||
        storeUser.role === "MARKETING_MANAGER";
      setShowInsightsTodos(showInsights);
    }
    const isCustom = isCustomDomain();
    setCustomDomain(isCustom);
  }, [storeId]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <HighlightWidget screen="dashboard" />
        </Grid>
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className={layoutContainer}>
            <div className={layoutSubHeader} style={{ paddingTop: 7 }}>
              <HeaderBreadcrumbs list={[{ label: "Dashboard" }]} />
            </div>
            <div style={{ position: "relative", width: "100%" }}>
              <div className={layoutBodyBox}>
                <div style={{ padding: "8px 32px 32px " }}>
                  {showInsightsTodos && (
                    <div style={{ marginBottom: 32 }}>
                      <StoreInsights />
                    </div>
                  )}
                  <Grid container>
                    <Grid item xs={12}>
                      <DashboardStoreLink />
                    </Grid>
                  </Grid>
                  {!customDomain && (
                    <Grid container spacing={3} style={{ marginTop: 32 }}>
                      <Grid item xs={12} sm={12} md={12} lg={6}>
                        <BannerCarousel />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={6}>
                        <Activities />
                      </Grid>
                    </Grid>
                  )}
                </div>
              </div>
              {!customDomain && (
                <div style={{ marginTop: 20 }}>
                  <OnlineStores />
                </div>
              )}
            </div>
          </div>
        </Grid>
      </Grid>
      <PromotionModal />
    </>
  );
};

export default Dashboard;
