import React from "react";

const EngageSurgeIcon = (props) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 6C0 2.68629 2.68629 0 6 0H12C15.3137 0 18 2.68629 18 6V12C18 15.3137 15.3137 18 12 18H6C2.68629 18 0 15.3137 0 12V6Z"
        fill="#F6F1FF"
      />
      <path
        d="M0 6C0 2.68629 2.68629 0 6 0H10V18H6C2.68629 18 0 15.3137 0 12V6Z"
        fill="#73388F"
      />
    </svg>
  );
};

export default EngageSurgeIcon;
