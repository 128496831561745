import React, { useEffect, useState } from "react";
import useStyles from "../components/ConfigStyles";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  fetchApps,
  getPluginConfig,
  postPluginConfig,
} from "../../../redux/apps/appActions";
import Toast from "../../../components/Layout/Toast";
import HeaderBreadcrumbs from "../../../components/common/HeaderBreadcrumbs";
import { Button, Paper } from "@material-ui/core";
import CombinedButtons from "../../../components/buttons/CombinedButtons";
import { layoutCenterContainer } from "../../../styles/common";
import PageLoader from "../../../components/Layout/PageLoader";
import Switch from "../../../components/controls/Switch";
import ChangeIcon from "./assets/ChangeIcon";
import { templateConfigs } from "./components/AllTemplates";
import SelectTemplateModal from "./components/SelectTemplateModal";
import PcIcon from "./assets/PcIcon";
import MobIcon from "./assets/MobIcon";
import ResetIcon from "./assets/ResetIcon";
import FormModal from "../../../components/common/FormModal";
import BasicStyle1 from "./components/AllTemplates/BasicStyle1";
import ButtonStyle2 from "./components/AllTemplates/ButtonStyle2";
import { getStoreUrl } from "../../../utils/storeUtil";
import PreviewHelloBar from "./components/PreviewHelloBar";
import TimerStyle1 from "./components/AllTemplates/TimerStyle1";
import TimerStyle2 from "./components/AllTemplates/TimerStyle2";
import ButtonStyle1 from "./components/AllTemplates/ButtonStyle1";
import HtmlStyle from "./components/AllTemplates/HtmlStyle";
import TimerStyle3 from "./components/AllTemplates/TimerStyle3";
import PreviewHelloBarDesktop from "./components/PreviewHelloBarDesktop";

const list = [
  {
    label: "App Store",
    link: "/apps",
  },
  {
    label: "Hello Bar",
    link: "/apps/hello-bar",
  },
  {
    label: "Configure",
  },
];

const initialStates = {
  popup_enabled: {
    value: true,
    touched: false,
    error: "",
  },
  style: {
    value: "style1",
    touched: false,
    error: "",
  },
};

const HelloBar = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const storeData = useSelector((state) => state.store);
  const appState = useSelector((state) => state.apps);
  const apps = appState.apps;

  const [loading, setLoading] = useState(true);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [savePending, setSavePending] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openRestoreModal, setOpenRestoreModal] = useState(false);
  const [formState, setFormState] = useState({
    ...initialStates,
    ...templateConfigs.style1.config,
  });
  const [currTab, setCurrTab] = useState("desktop");

  const storeUrl = storeData?.store ? getStoreUrl(storeData.store) : "";

  const handleFormChnage = (field, payload) => {
    setFormState({
      ...formState,
      [field]: {
        ...formState[field],
        ...payload,
      },
    });
  };

  const handleFormMultipleValueChange = (fieldValueArr) => {
    setFormState((prevState) => {
      const updatedState = { ...prevState };

      fieldValueArr.forEach(({ field, payload }) => {
        updatedState[field] = {
          ...updatedState[field],
          ...payload,
        };
      });

      return updatedState;
    });
  };

  const handleStyleChange = (styleName, previousFormState) => {
    const newForm = {
      ...initialStates,
      style: {
        ...initialStates.style,
        value: styleName,
      },
      ...templateConfigs[styleName].config,
    };
    if (previousFormState) {
      Object.entries(previousFormState)
        .filter(([_, value]) => value.willPersist === true)
        .forEach(([key, value]) => {
          newForm[key] = {
            ...newForm[key],
            value: value.value,
          };
        });
    }
    setFormState(newForm);
  };

  useEffect(() => {
    if (apps.length === 0) {
      dispatch(fetchApps());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apps.length]);

  useEffect(() => {
    if (storeData.fetched) {
      dispatch(getPluginConfig("hello-bar")).then((responses) => {
        if (responses.length > 0) {
          const style = responses.find((e) => e.name === "style");
          if (style) {
            handleStyleChange(style.value);
          }
          const newFormState = {
            ...initialStates,
            ...templateConfigs[style.value].config,
          };
          responses.forEach((response) => {
            if (
              Object.keys(newFormState)
                .filter((e) => e.name !== "style")
                .includes(response.name)
            ) {
              let value;
              if (response.value === "true") {
                value = true;
              } else if (response.value === "false") {
                value = false;
              } else {
                value = response.value;
              }
              newFormState[response.name] = {
                ...newFormState[response.name],
                value,
              };
            }
          });
          setFormState(newFormState);
        }
        setLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeData.fetched]);

  const navigateToUrl = () => {
    history.goBack();
  };

  if (loading) return <PageLoader />;

  const outputConfigs = {
    ...Object.fromEntries(
      Object.entries(formState).map(([key, value]) => [key, value.value])
    ),
    html: templateConfigs[formState.style.value].generateHtml(formState),
    js: templateConfigs[formState.style.value].generateJs(formState),
  };

  const previewConfig = {
    ...outputConfigs,
    html: templateConfigs[formState.style.value].previewHtml(formState),
  };

  const handleSave = async () => {
    if (storeData.fetched) {
      setSavePending(true);
      dispatch(postPluginConfig("hello-bar", outputConfigs)).then((cobj) => {
        if (cobj.error) {
          setShowError(true);
        } else {
          setShowSuccess(true);
        }
        setSavePending(false);
      });
    }
  };

  return (
    <div className={layoutCenterContainer}>
      <div style={{ width: "100%" }}>
        <div className={classes.breadcrumbsContainer}>
          <div className={classes.backDiv}>
            <HeaderBreadcrumbs list={list} />
          </div>
        </div>
        <div style={{ display: "flex", margin: "-10px" }}>
          <div style={{ width: "50%" }}>
            <div style={{ padding: "10px" }}>
              <Paper
                elevation={0}
                style={{
                  padding: "20px",
                  boxShadow: "0px 1px 3px 0px #0000001A",
                  borderRadius: 8,
                }}
              >
                <Switch
                  name="annBar"
                  label="Enable Hello bar"
                  labelFontWeight={400}
                  helperText="Switching on will enable Hello bar on your website"
                  color="secondary"
                  value={formState.popup_enabled.value}
                  onChange={() =>
                    handleFormChnage("popup_enabled", {
                      value: !formState.popup_enabled.value,
                    })
                  }
                  width="100%"
                  style={{ marginBottom: 0 }}
                />
              </Paper>
            </div>
            <div style={{ padding: "10px" }}>
              <Paper
                elevation={0}
                style={{
                  boxShadow: "0px 1px 3px 0px #0000001A",
                  borderRadius: 8,
                }}
              >
                <div
                  style={{
                    padding: "20px",
                    borderBottom: "1px solid #EBEBEB",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: 700,
                        marginRight: 6,
                      }}
                    >
                      Templates
                    </div>
                    <span style={{ fontSize: 12 }}>
                      ({templateConfigs[formState.style.value].label})
                    </span>
                  </div>
                  <div>
                    <Button
                      size="small"
                      color="secondary"
                      startIcon={<ChangeIcon />}
                      style={{
                        textTransform: "none",
                        fontSize: "14px",
                        fontWeight: 600,
                        padding: "6px 8px",
                        lineHeight: "12px",
                      }}
                      onClick={() => setOpenModal(true)}
                    >
                      Change
                    </Button>
                    <SelectTemplateModal
                      openModal={openModal}
                      setOpenModal={setOpenModal}
                      currStyle={formState.style.value}
                      handleStyleChange={handleStyleChange}
                      storePlan={storeData?.store.plan}
                      formState={formState}
                    />
                  </div>
                </div>
                <div style={{ padding: "20px" }}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: templateConfigs[
                        formState.style.value
                      ].previewHtml(
                        templateConfigs[formState.style.value].config
                      ),
                    }}
                  ></div>
                </div>
              </Paper>
            </div>
            {formState.style.value === "style1" && (
              <BasicStyle1
                formState={formState}
                handleFormChnage={handleFormChnage}
                handleFormMultipleValueChange={handleFormMultipleValueChange}
              />
            )}
            {formState.style.value === "style2" && (
              <ButtonStyle1
                formState={formState}
                handleFormChnage={handleFormChnage}
                handleFormMultipleValueChange={handleFormMultipleValueChange}
              />
            )}
            {formState.style.value === "style3" && (
              <ButtonStyle2
                formState={formState}
                handleFormChnage={handleFormChnage}
                handleFormMultipleValueChange={handleFormMultipleValueChange}
              />
            )}
            {formState.style.value === "style4" && (
              <TimerStyle1
                formState={formState}
                handleFormChnage={handleFormChnage}
                handleFormMultipleValueChange={handleFormMultipleValueChange}
              />
            )}
            {formState.style.value === "style5" && (
              <TimerStyle2
                formState={formState}
                handleFormChnage={handleFormChnage}
                handleFormMultipleValueChange={handleFormMultipleValueChange}
              />
            )}
            {formState.style.value === "style6" && (
              <TimerStyle3
                formState={formState}
                handleFormChnage={handleFormChnage}
                handleFormMultipleValueChange={handleFormMultipleValueChange}
              />
            )}
            {formState.style.value === "style7" && (
              <HtmlStyle
                formState={formState}
                handleFormChnage={handleFormChnage}
                handleFormMultipleValueChange={handleFormMultipleValueChange}
              />
            )}
            <div style={{ padding: "10px" }}>
              <Paper
                elevation={0}
                style={{
                  padding: "20px",
                  boxShadow: "0px 1px 3px 0px #0000001A",
                  borderRadius: 8,
                }}
              >
                <Button
                  size="small"
                  color="secondary"
                  startIcon={<ResetIcon />}
                  style={{
                    textTransform: "none",
                    fontSize: "14px",
                    fontWeight: 600,
                    padding: "6px 8px",
                    lineHeight: "12px",
                  }}
                  onClick={() => setOpenRestoreModal(true)}
                >
                  Reset to default
                </Button>
                <FormModal
                  open={openRestoreModal}
                  onClose={() => setOpenRestoreModal(false)}
                  btnTxt="Restore"
                  isDelete={false}
                  onSave={() => {
                    handleStyleChange(formState.style.value);
                    setOpenRestoreModal(false);
                  }}
                  heading="Reset to default"
                >
                  <p style={{ marginBottom: 4 }}>
                    Revert Changes and Restore Settings to its Original Default
                    State
                  </p>
                </FormModal>
              </Paper>
            </div>
            <div style={{ padding: "10px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "12px 0",
                }}
              >
                <CombinedButtons
                  outlinedBtnText="Back"
                  outlinedBtnAction={navigateToUrl}
                  solidBtnAction={handleSave}
                  loading={savePending}
                />
              </div>
            </div>
          </div>
          <div style={{ width: "50%" }}>
            <div style={{ padding: "10px", position: "sticky", top: 84 }}>
              <Paper
                elevation={0}
                style={{
                  boxShadow: "0px 1px 3px 0px #0000001A",
                  borderRadius: 8,
                }}
              >
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: 700,
                    padding: "12px 20px",
                    borderBottom: "1px solid #EBEBEB",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span style={{ fontSize: 14, fontWeight: 400 }}>
                    <strong>Preview:</strong>{" "}
                    {templateConfigs[formState.style.value].label}
                  </span>
                  <div
                    style={{
                      background: "#E9EEFF",
                      borderRadius: "100px",
                      width: "fit-content",
                      display: "flex",
                      alignItems: "center",
                      padding: "4px",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        width: "50px",
                        borderRadius: "100px",
                        background: "white",
                        position: "absolute",
                        top: "4px",
                        bottom: "4px",
                        left: currTab === "desktop" ? "54px" : "4px",
                        transition: "200ms ease-in-out",
                      }}
                    ></div>
                    <div
                      style={{
                        padding: "6px 16px",
                        position: "relative",
                        cursor: "pointer",
                      }}
                      onClick={() => setCurrTab("mobile")}
                    >
                      <MobIcon
                        style={{
                          width: "18px",
                          ...(currTab === "mobile" && { color: "#1641DB" }),
                        }}
                      />
                    </div>
                    <div
                      style={{
                        padding: "6px 16px",
                        position: "relative",
                        cursor: "pointer",
                      }}
                      onClick={() => setCurrTab("desktop")}
                    >
                      <PcIcon
                        style={{
                          width: "18px",
                          ...(currTab === "desktop" && { color: "#1641DB" }),
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div style={{ padding: "20px", minHeight: "300px" }}>
                  {currTab === "mobile" && (
                    <div
                      style={{
                        width: 360,
                        height: 600,
                        margin: "auto",
                        border: "1px solid #E1E1E1",
                      }}
                    >
                      <PreviewHelloBar
                        storeUrl={storeUrl}
                        outputConfigs={previewConfig}
                      />
                    </div>
                  )}
                  {currTab === "desktop" && (
                    <div
                      style={{
                        width: "100%",
                        height: 400,
                        margin: "auto",
                        border: "1px solid #E1E1E1",
                        overflow: "hidden",
                      }}
                      id="hello-bar-desktop-preview"
                    >
                      <PreviewHelloBarDesktop
                        storeUrl={storeUrl}
                        outputConfigs={previewConfig}
                      />
                    </div>
                  )}
                </div>
              </Paper>
            </div>
          </div>
        </div>
      </div>

      <Toast
        autoClose
        message={"Configrations saved successfully!"}
        open={showSuccess}
        close={() => setShowSuccess(false)}
      />
      <Toast
        autoClose
        message={"Failed to save. Please try again later."}
        open={showError}
        close={() => setShowError(false)}
        severity="error"
      />
    </div>
  );
};

export default HelloBar;
