import React, { useEffect, useState } from "react";
import PlanTitle from "./PlanTitle";
import useStyles from "../../../styles";
import DeliveryFrequency from "./DeliveryFrequecy";
import SelectProduct from "./SelectProduct/SelectProduct";
import CombinedButtons from "../../../../../../components/buttons/CombinedButtons";
import useValidator from "./useFormValidator";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  addSubPlan,
  editSubPlan,
  fetchSubPlans,
  onlyAddPorducts,
  onlyAddProducts,
} from "../../../../../../redux/subscription/plans/planActions";
import Toast from "../../../../../../components/Layout/Toast";
import { getStoreIdFromUrl } from "../../../../../../utils/storeUtil";

const Form = ({ record }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const storeId = getStoreIdFromUrl();
  const initialFormData = {
    ...(record?.id && {
      id: record?.id,
    }),
    display_title: record?.display_title ?? "",
    display_name: record?.display_name ?? "",
    frequencies: record?.frequencies
      ? record.frequencies.map((item) => ({
          interval:
            item.interval === "DAILY" && item.repeat_every === 2
              ? "ALTERNATE_DAYS"
              : item.interval === "DAILY" && item.repeat_every === 3
              ? "EVERY_3_DAYS"
              : item.interval ?? "",
          repeat_every: item.repeat_every ?? null,
          discount_type: item.discount_type ?? "",
          discount_value:
            item.discount_value !== null &&
            item.discount_value !== undefined &&
            item.discount_value !== ""
              ? parseInt(item.discount_value)
              : null,

          deletable: item.deletable ?? true,
          id: item.id ? item.id : null,
        }))
      : [
          {
            interval: "",
            repeat_every: null,
            discount_type: "AMOUNT_OFF",
            discount_value: null,
          },
        ],
    products: [],
  };
  const intialProductsData = {
    products: [],
  };
  const [formData, setFormData] = useState(initialFormData);
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState(intialProductsData);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastSeverity, setToastSeverity] = useState("success");
  const { errors, validateFields, clearErrors, onBlur } =
    useValidator(formData);
  const onChange = (e, index = null, section = null) => {
    const { name, value } = e.target;

    const parsedValue =
      name === "repeat_every" || name === "discount_value"
        ? parseInt(value, 10) || 0 // parse as integer, fallback to 0 if NaN
        : value;
    setFormData((prevData) => {
      if (section && index !== null) {
        return {
          ...prevData,
          [section]: prevData[section].map((item, i) =>
            i === index ? { ...item, [name]: parsedValue } : item
          ),
        };
      }
      return {
        ...prevData,
        [name]: parsedValue,
      };
    });
  };

  useEffect(() => {
    if (record) {
      setFormData(initialFormData);
    }
    // eslint-disable-next-line
  }, [record]);

  const checkForDuplicates = (frequencies) => {
    const seen = new Set();
    for (const frequency of frequencies) {
      const key = `${frequency.interval}-${frequency.frequency}-${frequency.discount_type}-${frequency.discount_value}`;
      if (seen.has(key)) {
        return true;
      }
      seen.add(key);
    }
    return false;
  };

  const onSave = async () => {
    clearErrors();
    if (checkForDuplicates(formData.frequencies)) {
      setToastMessage("Duplicate frequency entries found.");
      setToastSeverity("warning");
      setToastOpen(true);
      return;
    }

    const isValid = validateFields(formData);
    if (!isValid) {
      setToastMessage("Please correct the highlighted errors before saving.");
      setToastSeverity("error");
      setToastOpen(true);
      return;
    }
    const updatedFrequencies = formData.frequencies.map((item) => ({
      ...item,
      interval:
        item.interval === "EVERY_3_DAYS" || item.interval === "ALTERNATE_DAYS"
          ? "DAILY"
          : item.interval,
    }));
    const updatedFormData = {
      ...formData,
      frequencies: updatedFrequencies,
    };

    try {
      let response;
      setLoading(true);
      if (record?.id) {
        response = await dispatch(editSubPlan(updatedFormData));
      } else {
        response = await dispatch(addSubPlan(updatedFormData));
      }
      const id = response?.payload?.id;
      if (id) {
        await dispatch(onlyAddProducts(products.products, id));
      }
      setLoading(false);
      dispatch(fetchSubPlans());
      history.push(`/${storeId}/subscription/plans`);
    } catch (error) {
      console.error("Error saving the plan:", error);
      setToastMessage("An error occurred while saving the plan.");
      setToastSeverity("error");
      setToastOpen(true);
      setLoading(false);
    }
  };
  const handleToastClose = () => {
    setToastOpen(false);
  };
  return (
    <>
      <div
        className={classes.containersection}
        style={{ padding: "24px 24px 10px 24px" }}
      >
        <PlanTitle
          formData={formData}
          onChange={onChange}
          errors={errors}
          onBlur={onBlur}
        />
      </div>
      <div className={classes.containersection} style={{ padding: "24px" }}>
        <DeliveryFrequency
          formData={formData}
          setFormData={setFormData}
          onChange={onChange}
          errors={errors}
          onBlur={onBlur}
        />
      </div>
      <div className={classes.containersection} style={{ padding: "24px" }}>
        <SelectProduct setProducts={setProducts} products={products} />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "25px",
        }}
      >
        <CombinedButtons
          solidBtnAction={onSave}
          loading={loading}
          outlinedBtnAction={() => {
            history.push(`/${storeId}/subscription/plans`);
          }}
        />
      </div>

      <Toast
        open={toastOpen}
        close={handleToastClose}
        message={toastMessage}
        severity={toastSeverity}
      />
    </>
  );
};

export default Form;
