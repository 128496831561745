import React from "react";

export default function UrlRedirectsIcon({
  fill = "#666666",
  strokeWidth = 0,
}) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_27463_34164"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="28"
        height="28"
      >
        <rect width="28" height="28" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_27463_34164)">
        <path
          d="M13.4167 24.5V20.1699C13.4167 19.111 13.2664 18.2741 12.9657 17.6593C12.6651 17.0446 12.2127 16.477 11.6083 15.9565L12.4407 15.1241C12.704 15.3679 12.9762 15.6712 13.2574 16.0338C13.5386 16.3964 13.7861 16.7812 14 17.1882C14.2273 16.714 14.5268 16.2611 14.8986 15.8296C15.2702 15.3982 15.6617 15.0014 16.0732 14.6393C17.0512 13.7793 17.8268 12.7151 18.3998 11.4468C18.9726 10.1784 19.2485 8.28411 19.2275 5.76392L16.8067 8.18475L15.9743 7.35904L19.8333 3.5L23.6924 7.35904L22.8667 8.18475L20.3942 5.719C20.4152 8.33506 20.1108 10.3658 19.481 11.8113C18.8514 13.257 17.9861 14.4839 16.8852 15.4919C16.2182 16.1007 15.6678 16.7061 15.234 17.3081C14.8002 17.9101 14.5833 18.864 14.5833 20.1699V24.5H13.4167ZM7.88403 9.47012C7.79128 9.06626 7.72284 8.51142 7.6787 7.80558C7.63456 7.09956 7.61026 6.40403 7.60578 5.719L5.13333 8.18475L4.30762 7.35904L8.16666 3.5L12.0257 7.35904L11.1933 8.18475L8.77245 5.77062C8.75748 6.41812 8.77313 7.05269 8.81941 7.67433C8.86588 8.29578 8.93102 8.80687 9.01482 9.20762L7.88403 9.47012ZM10.0558 14.4016C9.74176 14.0232 9.43473 13.5805 9.1347 13.0734C8.83487 12.5663 8.60114 12.0712 8.43353 11.5882L9.55762 11.3032C9.69237 11.6787 9.87485 12.0698 10.1051 12.4766C10.3355 12.8834 10.5965 13.2476 10.8882 13.5692L10.0558 14.4016Z"
          fill={fill}
          stroke={fill}
          strokeWidth={strokeWidth}
        />
      </g>
    </svg>
  );
}
