import React, { useRef, useState } from "react";
import FormModal from "../../components/common/FormModal";
import { collectionTypes, HTML_COLLECTION, MINI_BANNER } from "./utils";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { collectionUrlMap } from "./Create";
import PaidTab from "../../components/common/PaidTab";
import { FREE, PLANS } from "../plans/constants";
import { useSelector } from "react-redux";
import PaidPopUp from "../../components/PaidPopUp";

const useStyles = makeStyles(() => ({
  collectionType: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  icon: {
    flex: "none",
    width: 64,

    "& svg": {
      width: "100%",
    },
  },
  heading: {
    margin: 0,
    fontSize: 14,
    fontWeight: 400,
    marginBottom: 4,
  },
  subTitle: {
    margin: 0,
    color: "#666666",
    fontSize: 12,
    fontWeight: 400,
  },
  plan: {
    marginLeft: "auto",
  },
}));

const CollectionTypeModal = ({ open, onClose }) => {
  const classes = useStyles();
  const history = useHistory();
  const reversedCollectionUrlMap = Object.fromEntries(
    Object.entries(collectionUrlMap).map(([key, value]) => [value, key])
  );
  const [openPremiumModal, setOpenPremiumModal] = useState(false);
  const store = useSelector((state) => state.store.store);
  const askForPlan = useRef(FREE);
  const askForCategory = useRef(HTML_COLLECTION);

  return (
    <>
      <FormModal
        heading="Select Collection Type"
        open={open}
        width={600}
        maxHeight={90}
        onClose={onClose}
        overlay={false}
        headingCtnStyle={{
          borderBottom: "1px solid #EBEBEB",
          paddingBottom: 16,
          marginBottom: 12,
        }}
        combinedButtonCtnStyle={{
          marginTop: 8,
        }}
        hideBottomStickyButton
      >
        <div
          style={{
            maxHeight: "calc(90vh - 119px)",
            overflowY: "auto",
            marginBottom: 25,
          }}
        >
          {collectionTypes
            .filter((e) => e.value !== MINI_BANNER)
            .map((type, index, arr) => (
              <div key={type.value}>
                <div
                  className={classes.collectionType}
                  onClick={() => {
                    if (
                      store?.plan &&
                      PLANS[store.plan].weight < PLANS[type.plan].weight
                    ) {
                      askForPlan.current = type.plan;
                      askForCategory.current = type.title;
                      setOpenPremiumModal(true);
                      return;
                    }
                    history.push(
                      `./collections/create/${
                        reversedCollectionUrlMap[type.value]
                      }`
                    );
                  }}
                >
                  <div className={classes.icon}>{type.icon()}</div>
                  <div style={{ marginLeft: 16 }}>
                    <h4 className={classes.heading}>{type.title}</h4>
                    <p className={classes.subTitle}>{type.subtitle}</p>
                  </div>
                  {store?.plan &&
                    PLANS[store.plan].weight < PLANS[type.plan].weight && (
                      <div className={classes.plan}>
                        <PaidTab plan={type.plan} style={{ marginLeft: 8 }} />
                      </div>
                    )}
                </div>
                {index < arr.length - 1 && <hr style={{ margin: "8px 0" }} />}
              </div>
            ))}
        </div>
      </FormModal>
      <PaidPopUp
        open={openPremiumModal}
        close={() => setOpenPremiumModal(false)}
        plan={askForPlan.current}
        subtxt={`To create ${askForCategory.current}, you need to upgrade your plan.`}
      />
    </>
  );
};

export default CollectionTypeModal;
