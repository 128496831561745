import React from "react";
import { Typography, Box } from "@material-ui/core";
import { getDomainPrice } from "../utils";
import { useStyles } from "../styles";
import { useSelector } from "react-redux";

export const RenderDomainInfo = ({
  domain,

  showDetails = true,
}) => {
  const classes = useStyles();
  const store = useSelector((state) => state.store.store);
  const countrySymbol = store.currency_symbol;
  const DOLLR_TO_INR = 90;
  const domainAmount = Math.floor(domain.purchasePrice * DOLLR_TO_INR);
  const domainRenewal = Math.floor(domain.renewalPrice * DOLLR_TO_INR);
  const amountSave = domainRenewal - domainAmount;
  const percentageDiscount = Math.floor((amountSave / domainRenewal) * 100);

  return (
    <Box>
      <Typography
        className={classes.domainName}
        style={{ width: "65%", display: "inline", wordBreak: "break-all" }}
      >
        {domain.domainName}
      </Typography>

      {showDetails && (
        <Box>
          <Typography
            className={classes.domainName}
            style={{ marginTop: "3px", fontSize: "16px", fontWeight: "bold" }}
          >
            <span>{countrySymbol}</span>
            {getDomainPrice(domain.purchasePrice)}
          </Typography>
          {domainRenewal > domainAmount && (
            <Typography style={{ marginTop: "8px", color: "#999999" }}>
              <span
                style={{
                  textDecoration: "line-through",
                  marginRight: "3px",
                  fontWeight: 400,
                  fontSize: "14px",
                }}
              >
                {countrySymbol} {Math.floor(domainRenewal)}/year
              </span>
              <span
                style={{ color: "blue", fontSize: "14px", fontWeight: "600" }}
              >
                ({percentageDiscount}% off)
              </span>
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};
