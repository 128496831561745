import React, { useEffect, useRef, useState } from "react";

const PreviewHelloBarDesktop = ({ storeUrl, outputConfigs }) => {
  const ref = useRef();
  const [width, setWidth] = useState(600);

  const onIfrrameLoad = () => {
    if (ref?.current) {
      ref.current.contentWindow.postMessage(
        JSON.stringify({
          helloBar: outputConfigs,
          type: "hello-bar-externally",
          action: "setHelloBar",
        }),
        storeUrl
        // "http://little-dragon.localhost:3000"
      );
    }
  };

  useEffect(() => {
    onIfrrameLoad();

    const ctnWidth = document
      .getElementById("hello-bar-desktop-preview")
      .getBoundingClientRect().width;
    setWidth(ctnWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outputConfigs]);

  const renderWidth = 1200;

  return (
    <iframe
      ref={ref}
      onLoad={(e) => {
        setTimeout(() => {
          onIfrrameLoad(e);
        }, 1000);
      }}
      title="Theme Preview"
      id="my-this-ifrma"
      src={storeUrl}
      // src={"http://little-dragon.localhost:3000"}
      frameBorder="0"
      width={`${renderWidth}px`}
      style={{
        transform: `scale(${width / renderWidth})`,
        transformOrigin: "0 0",
      }}
      height={`${(1 / (width / renderWidth)) * 100}%`}
      scrolling="yes"
    />
  );
};

export default PreviewHelloBarDesktop;
