import React from "react";
import { useState } from "react";
import { makeStyles, Tooltip } from "@material-ui/core";
import LockIcon from "../../../../../../../icons/LockIcon";
import LockOpenIcon from "../../../../../../../icons/LockOpenIcon";
import Input from "../../../../../../../components/controls/Input";
import LeftIcon from "./assets/LeftIcon";
import TopIcon from "./assets/TopIcon";
import RightIcon from "./assets/RightIcon";
import BottomIcon from "./assets/BottomIcon";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    marginTop: 16,
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  heading: {
    fontSize: "14px",
    fontWeight: 400,
    marginRight: "8px",
    marginTop: 0,
    marginBottom: 0,
    whiteSpace: "nowrap",
  },
  inputsContainer: {
    display: "flex",
    gap: "16px",
  },
  inputWrapper: {
    position: "relative",
    flex: 1,
  },
  icon: {
    width: 24,
    height: 24,
    minWidth: 24,
    marginLeft: -8,
  },
});

export default function PaddingControl({ padding, setPadding }) {
  const classes = useStyles();
  const [isLocked, setIsLocked] = useState(false);

  // Parse initial padding values
  const initialValues = padding.split(" ").map((val) => Number.parseInt(val));
  const [values, setValues] = useState({
    top: initialValues[0] || 0,
    right: initialValues[1] || 0,
    bottom: initialValues[2] || 0,
    left: initialValues[3] || 0,
  });

  const handleChange = (side, value) => {
    const numValue = Number.parseInt(value) || 0;
    if (numValue < 0) return;

    if (isLocked) {
      // Update all sides when locked
      const newValues = {
        top: numValue,
        right: numValue,
        bottom: numValue,
        left: numValue,
      };
      setValues(newValues);
      setPadding(`${numValue}px ${numValue}px ${numValue}px ${numValue}px`);
    } else {
      // Update only the changed side
      const newValues = { ...values, [side]: numValue };
      setValues(newValues);
      setPadding(
        `${newValues.top}px ${newValues.right}px ${newValues.bottom}px ${newValues.left}px`
      );
    }
  };

  const icons = {
    left: <LeftIcon className={classes.icon} />,
    top: <TopIcon className={classes.icon} />,
    right: <RightIcon className={classes.icon} />,
    bottom: <BottomIcon className={classes.icon} />,
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <h3 className={classes.heading}>Space around Cards</h3>
        {/* <Tooltip title={isLocked ? "Locked" : "Unlocked"} placement="left">
          <span
            style={{ cursor: "pointer" }}
            onClick={() => setIsLocked(!isLocked)}
          >
            {isLocked ? <LockIcon /> : <LockOpenIcon />}
          </span>
        </Tooltip> */}
      </div>
      <div className={classes.inputsContainer}>
        {["top", "right", "bottom", "left"].map((side) => (
          <div key={side} className={classes.inputWrapper}>
            <Input
              placeholder="10"
              color="secondary"
              variant="outlined"
              value={values[side]}
              onChange={(e) => handleChange(side, e.target.value)}
              onFocus={() => {}}
              onBlur={() => {}}
              labelPlacement="start"
              labelFontWeight={400}
              endAdornment={<span style={{ color: "#b9b9b9" }}>px</span>}
              startAdornment={icons[side]}
              type="number"
              labelStyle={{ marginTop: 0, whiteSpace: "nowrap" }}
              hideHelperText
              inputCtnCss={{
                flex: "unset",
              }}
              labelCtnStyle={{ width: "100%" }}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
