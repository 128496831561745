import { Typography } from "@material-ui/core";
import React from "react";
import {
  rowFlexAlignCenter,
  rowFlexOnlyJustifyBetweenStart,
} from "../../../styles/common";
import useStyles from "./styles";
import { useSelector } from "react-redux";

const ChargeDetails = ({
  diamond,
  plans,
  title,
  shortDescription,
  amount,
  term,
}) => {
  const classes = useStyles();
  const store = useSelector((state) => state.store.store);
  const countrySymbol = store.currency_symbol;
  return (
    <div className={rowFlexOnlyJustifyBetweenStart} style={{ marginTop: 20 }}>
      <div className={rowFlexAlignCenter}>
        <div>{diamond}</div>
        <div
          style={{
            marginLeft: 12,
          }}
        >
          <Typography className={classes.charge}>{title}</Typography>
          <Typography className={classes.secondary} style={{ marginTop: 4 }}>
            {shortDescription}
          </Typography>
        </div>
      </div>
      <Typography className={classes.charge}>
        <span className={classes.rupee}>{countrySymbol}</span>
        &nbsp;
        <span>{amount}</span>
        &nbsp;
        <span
          className={classes.secondary}
          style={{ color: "#1a1a1a", fontWeight: "normal" }}
        >
          {term}
        </span>
      </Typography>
    </div>
  );
};
export default ChargeDetails;
