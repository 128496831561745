import React, { useRef, useState } from "react";
import FormModal from "../../../../../../../components/common/FormModal";
import { makeStyles } from "@material-ui/core";
import Input from "../../../../../../../components/controls/Input";
import {
  controlInput,
  formLabel,
  labelWidth,
  textFieldLabel,
} from "../../../../../style";
import SubtitleSelect from "../../../../../../../components/controls/SubtitleSelect";
import {
  BANNER,
  BANNER_VIDEO,
  collectionPlansMap,
  collectionTypes,
  genericCollectionCardTypes,
  HTML_COLLECTION,
} from "../../../../../utils";
import Banner from "./Banner";
import Video from "./Video";
import { uploadImage } from "./helpers/uploadImage";
import Html from "./Html";
import { useSelector } from "react-redux";
import { FREE, PLANS } from "../../../../../../plans/constants";
import PaidPopUp from "../../../../../../../components/PaidPopUp";

export const cardHeadingObj = {
  [BANNER]: "Banner Card",
  [BANNER_VIDEO]: "Video Card",
  [HTML_COLLECTION]: "HTML Card",
};

const useStyles = makeStyles((theme) => ({
  hr: {
    color: "#EBEBEB",
    marginTop: 8,
    borderWidth: 1,
    margin: "0 -25px",
  },
  addFormLabel: {
    marginBottom: "8px !important",
  },
}));

const AddCard = ({
  open,
  onClose,
  bannerForm,
  handleBannerFormChange,
  videoForm,
  handleVideoFormChange,
  htmlForm,
  handleHtmlFormChange,
  resetAddCardValues,
  form,
  handleFormChange,
  setAddedCards,
  titleForm,
  handleTitleFormChange,
  editingIndex,
  setEditingIndex,
  bannerError,
  setBannerError,
  videoError,
  setVideoError,
  htmlError,
  setHtmlError,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [openPremiumModal, setOpenPremiumModal] = useState(false);
  const store = useSelector((state) => state.store.store);
  const askForPlan = useRef(FREE);
  const askForCategory = useRef(HTML_COLLECTION);

  const handleSave = async () => {
    setLoading(true);
    if (form.card_type.value === BANNER && !bannerForm.mobile_image.value) {
      setBannerError("Mobile image is required.");
      setLoading(false);
      return;
    }
    if (form.card_type.value === BANNER_VIDEO) {
      let errors = {};
      if (!videoForm.video_url.value) {
        errors.video_url = "YouTube video url is required.";
      }
      if (!videoForm.mobile_image.value) {
        errors.thumbnail = "Mobile thumbnail is required.";
      }
      if (Object.keys(errors).length > 0) {
        setVideoError(errors);
        setLoading(false);
        return;
      }
    }
    if (form.card_type.value === HTML_COLLECTION) {
      let errors = {};
      if (!htmlForm.html_content.value) {
        errors.html_content = "HTML is required.";
      }
      if (Object.keys(errors).length > 0) {
        setHtmlError(errors);
        setLoading(false);
        return;
      }
    }
    setBannerError("");
    setVideoError("");
    setHtmlError("");
    const newCard = {
      ...(form.card_type.value === BANNER && {
        title: titleForm.title.value,
        ...Object.fromEntries(
          Object.entries(bannerForm)
            .filter(
              ([key]) =>
                key !== "desktop_image_name" || key !== "mobile_image_name"
            )
            .map(([key, value]) => [
              key,
              value.value?.css ? value.value.raw : value.value,
            ])
        ),
        ...(bannerForm.desktop_image && {
          desktop_image: await uploadImage(
            bannerForm.desktop_image.value,
            bannerForm.desktop_image_name.value
          ),
        }),
        ...(bannerForm.mobile_image && {
          mobile_image: await uploadImage(
            bannerForm.mobile_image.value,
            bannerForm.mobile_image_name.value
          ),
        }),
      }),
      ...(form.card_type.value === BANNER_VIDEO && {
        title: titleForm.title.value,
        ...Object.fromEntries(
          Object.entries(videoForm)
            .filter(
              ([key]) =>
                key !== "desktop_image_name" || key !== "mobile_image_name"
            )
            .map(([key, value]) => [
              key,
              value.value?.css ? value.value.raw : value.value,
            ])
        ),
        ...(videoForm.desktop_image && {
          desktop_image: await uploadImage(
            videoForm.desktop_image.value,
            videoForm.desktop_image_name.value
          ),
        }),
        ...(videoForm.mobile_image && {
          mobile_image: await uploadImage(
            videoForm.mobile_image.value,
            videoForm.mobile_image_name.value
          ),
        }),
      }),
      ...(form.card_type.value === HTML_COLLECTION && {
        title: titleForm.title.value,
        ...Object.fromEntries(
          Object.entries(htmlForm).map(([key, value]) => [
            key,
            value.value?.css ? value.value.raw : value.value,
          ])
        ),
      }),
    };

    if (typeof editingIndex === "number") {
      setAddedCards((prev) => [
        ...prev.slice(0, editingIndex),
        newCard,
        ...prev.slice(editingIndex + 1),
      ]);
      setEditingIndex(null);
    } else {
      setAddedCards((prev) => [...prev, newCard]);
    }
    setLoading(false);
    onClose();
  };

  return (
    <div>
      <FormModal
        heading={cardHeadingObj[form.card_type.value] ?? "Add"}
        open={open}
        width={form.card_type.value === HTML_COLLECTION ? 800 : 600}
        maxHeight={90}
        onClose={onClose}
        onSave={handleSave}
        btnTxt={editingIndex !== null ? "Update" : "Add"}
        hideCancel={editingIndex === null}
        loading={loading}
        overlay={false}
        headingCtnStyle={{
          borderBottom: "1px solid #EBEBEB",
          paddingBottom: 16,
          marginBottom: 20,
        }}
        combinedButtonCtnStyle={{
          marginTop: 8,
        }}
      >
        <div
          style={{
            ...(form.card_type.value && {
              maxHeight: "calc(90vh - 200px)",
              overflowY: "auto",
              overflowX: "hidden",
            }),
            padding: "0 25px",
            margin: "0 -25px",
          }}
        >
          <Input
            placeholder="Title"
            name="title"
            label="Title"
            value={titleForm.title.value}
            color="secondary"
            onChange={(e) => {
              handleTitleFormChange("title", e.target.value);
            }}
            onFocus={() => {}}
            onBlur={() => {}}
            controlClass={controlInput}
            error={""}
            labelClass={textFieldLabel}
            labelPlacement="top"
            extLabelWidth={labelWidth}
            fullWidth
            autoFocus
          />
          {!form.card_type.value && (
            <SubtitleSelect
              optionList={genericCollectionCardTypes}
              value={form.card_type.value}
              placeholder="Select"
              onChange={(val) => {
                if (
                  store?.plan &&
                  PLANS[store.plan].weight <
                    PLANS[collectionPlansMap[val]].weight
                ) {
                  askForPlan.current = collectionPlansMap[val];
                  askForCategory.current = collectionTypes.find(
                    (e) => e.value === val
                  ).title;
                  setOpenPremiumModal(true);
                  return;
                }
                resetAddCardValues();
                handleFormChange("card_type", val);
              }}
              label="Card Type"
              labelClass={`${formLabel} ${classes.addFormLabel}`}
              wrapperStyle={{
                marginBottom: "16px",
                position: "relative",
                zIndex: 3,
              }}
            />
          )}
          {form.card_type.value === BANNER && (
            <Banner
              bannerForm={bannerForm}
              bannerError={bannerError}
              handleBannerFormChange={handleBannerFormChange}
            />
          )}
          {form.card_type.value === BANNER_VIDEO && (
            <Video
              videoForm={videoForm}
              handleVideoFormChange={handleVideoFormChange}
              videoError={videoError}
            />
          )}
          {form.card_type.value === HTML_COLLECTION && (
            <Html
              htmlForm={htmlForm}
              handleHtmlFormChange={handleHtmlFormChange}
              htmlError={htmlError}
            />
          )}
        </div>
      </FormModal>
      <PaidPopUp
        open={openPremiumModal}
        close={() => setOpenPremiumModal(false)}
        plan={askForPlan.current}
        subtxt={`To create ${askForCategory.current}, you need to upgrade your plan.`}
      />
    </div>
  );
};

export default AddCard;
