import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useStyles } from "./WalletStyle";
import {
  capitalizeString,
  formatDate,
  formatToFourDigits,
} from "./WalletUtils";
import CoinIcon from "../../../icons/CoinIcon";
import { Skeleton } from "@material-ui/lab";
import { useSelector } from "react-redux";

const WalletTable = ({ tableLoading, displayedRows }) => {
  const classes = useStyles();
  const store = useSelector((state) => state.store.store);
  const countrySymbol = store.currency_symbol;
  const renderSkeleton = () => {
    return Array.from(new Array(5)).map((_, index) => (
      <TableRow key={index}>
        <TableCell>
          <Skeleton variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" />
        </TableCell>
      </TableRow>
    ));
  };

  return (
    <TableContainer style={{ padding: "0px 8px" }}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tabelHead} style={{ width: "10%" }}>
              ID
            </TableCell>
            <TableCell
              className={classes.tabelHead}
              align="left"
              style={{ width: "20%" }}
            >
              Date & Time
            </TableCell>
            <TableCell
              className={classes.tabelHead}
              align="left"
              style={{ width: "25%" }}
            >
              Description
            </TableCell>
            <TableCell
              className={classes.tabelHead}
              align="center"
              style={{ width: "12%" }}
            >
              Type
            </TableCell>
            <TableCell
              className={classes.tabelHead}
              align="right"
              style={{ width: "15%" }}
            >
              Amount ({countrySymbol})
            </TableCell>
            <TableCell
              className={classes.tabelHead}
              align="right"
              style={{ width: "15%" }}
            >
              Credits
            </TableCell>
            <TableCell
              className={classes.tabelHead}
              align="right"
              style={{ width: "13%" }}
            >
              Balance
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableLoading ? (
            renderSkeleton()
          ) : displayedRows.length ? (
            displayedRows.map((row) => (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row">
                  #{formatToFourDigits(row.id)}
                </TableCell>
                <TableCell align="left" style={{ color: "#1A1A1A" }}>
                  {formatDate(row.created_at)}
                </TableCell>
                <TableCell align="left" style={{ color: "#1A1A1A" }}>
                  {row.detail}
                </TableCell>
                <TableCell align="center" style={{ color: "#1A1A1A" }}>
                  {capitalizeString(row.tx_type)}
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    color: row.tx_type === "CREDIT" ? "#208C5A" : "#F0042E",
                    fontWeight: 600,
                  }}
                >
                  {row.tx_type === "CREDIT" ? "+" : "-"}{" "}
                  {(row.credit / 3).toFixed(1)}
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    color: row.tx_type === "CREDIT" ? "#208C5A" : "#F0042E",
                    fontWeight: 600,
                  }}
                >
                  {row.tx_type === "CREDIT" ? "+" : "-"} {row.credit}
                </TableCell>

                <TableCell align="right">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: 4,
                    }}
                  >
                    <CoinIcon color="secondary" height={18} width={18} />
                    <span style={{ fontWeight: 600, fontSize: 12 }}>
                      {row.balance}
                    </span>
                  </div>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={6} align="center">
                No Transaction Records available
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default WalletTable;
