import React from "react";

export default function GokwikWorksIcon() {
  return (
    <svg
      width="27"
      height="28"
      viewBox="0 0 27 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="13.0155" cy="14" rx="13.0155" ry="14" fill="#FFE7E7" />
      <mask
        id="mask0_28373_70070"
        style={{ maskType: "alpha", WebkitMaskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="1"
        y="2"
        width="24"
        height="24"
      >
        <rect x="1.8595" y="2" width="22.3123" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_28373_70070)">
        <path
          d="M13.0157 24C12.6128 24 12.2487 23.8958 11.9233 23.6875C11.5979 23.4792 11.3423 23.2 11.1563 22.85C10.645 22.85 10.2073 22.6542 9.84316 22.2625C9.47904 21.8708 9.29697 21.4 9.29697 20.85V17.3C8.38279 16.65 7.65066 15.7917 7.1006 14.725C6.55054 13.6583 6.27551 12.5 6.27551 11.25C6.27551 9.23333 6.93016 7.52083 8.23946 6.1125C9.54876 4.70417 11.1408 4 13.0157 4C14.8905 4 16.4826 4.70417 17.7919 6.1125C19.1012 7.52083 19.7559 9.23333 19.7559 11.25C19.7559 12.5333 19.4808 13.7 18.9308 14.75C18.3807 15.8 17.6486 16.65 16.7344 17.3V20.85C16.7344 21.4 16.5524 21.8708 16.1882 22.2625C15.8241 22.6542 15.3864 22.85 14.8751 22.85C14.6891 23.2 14.4335 23.4792 14.1081 23.6875C13.7827 23.8958 13.4186 24 13.0157 24ZM11.1563 20.85H14.8751V19.95H11.1563V20.85ZM11.1563 18.95H14.8751V18H11.1563V18.95ZM10.9704 16H12.3184V13.3L10.2731 11.1L11.2493 10.05L13.0157 11.95L14.7821 10.05L15.7583 11.1L13.713 13.3V16H15.061C15.8977 15.5667 16.5795 14.9292 17.1063 14.0875C17.6331 13.2458 17.8965 12.3 17.8965 11.25C17.8965 9.78333 17.4239 8.54167 16.4788 7.525C15.5336 6.50833 14.3792 6 13.0157 6C11.6522 6 10.4978 6.50833 9.55264 7.525C8.60746 8.54167 8.13487 9.78333 8.13487 11.25C8.13487 12.3 8.39828 13.2458 8.9251 14.0875C9.45192 14.9292 10.1337 15.5667 10.9704 16Z"
          fill="#FF6060"
        />
      </g>
    </svg>
  );
}
