import { Collapse, Paper, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { formWrapperLeft } from "../../style";
import DropdownIcon from "../../../../icons/DropdownIcon";
import transitions from "@material-ui/core/styles/transitions";

const CartDisplaySettingsWrapper = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  if (!children.some((child) => !!child)) return null;
  return (
    <Paper className={formWrapperLeft} style={{ width: "100%", padding: 0 }}>
      <div
        style={{
          padding: "24px 32px",
          cursor: "pointer",
        }}
        onClick={() => setCollapsed(!collapsed)}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography style={{ fontSize: 14, fontWeight: 600 }}>
            Layout Settings
          </Typography>
          <DropdownIcon
            style={{
              ...(collapsed && {
                transform: "rotate(180deg)",
              }),
              transitions: transitions.create("transform"),
            }}
          />
        </div>
        <Typography
          style={{
            fontSize: 12,
            fontWeight: 400,
            color: "#666666",
            marginTop: 6,
          }}
        >
          Customize the display and arrangement of your cards.
        </Typography>
      </div>
      <Collapse in={collapsed} timeout="auto">
        <div style={{ padding: "0 32px 32px" }}>{children}</div>
      </Collapse>
    </Paper>
  );
};

export default CartDisplaySettingsWrapper;
