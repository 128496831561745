import { Divider, Paper, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import FilledEditButton from "../../../components/buttons/FilledEditButton";
import CombinedButtons from "../../../components/buttons/CombinedButtons";
import useStyles from "../components/ConfigStyles";
import {
  fetchApps,
  getPluginConfig,
  postPluginConfig,
} from "../../../redux/apps/appActions";
import { useDispatch, useSelector } from "react-redux";
import { copyToClipboard } from "../../../utils/utils";
import {
  RemoveRedEyeOutlined,
  VisibilityOffOutlined,
} from "@material-ui/icons";
import Controls from "../../../components/controls/Controls";
import Toast from "../../../components/Layout/Toast";
import { useHistory } from "react-router-dom";
import { centerContainer, layoutCenterContainer } from "../../../styles/common";
import HeaderBreadcrumbs from "../../../components/common/HeaderBreadcrumbs";
import WebhookDetails from "./components/WebhookDetails";
import PageLoader from "../../../components/Layout/PageLoader";

const list = [
  {
    label: "App Store",
    link: "/apps",
  },
  { label: "PhonePe", link: "/apps/phonepe" },
  {
    label: "Configure",
  },
];

const PhonePeConfig = () => {
  const classes = useStyles();
  const history = useHistory();
  const storeData = useSelector((state) => state.store);
  const dispatch = useDispatch();
  const appState = useSelector((state) => state.apps);
  const apps = appState.apps;
  const [showPass, setShowPass] = useState(false);
  const initialState = {
    merchant_id: {
      value: "",
      touched: false,
      error: false,
    },
    secret_key: { value: "", touched: false, error: false },
    salt_index: { value: "", touched: false, error: false },
  };
  const [formState, setFormState] = useState(initialState);
  const [isEditing, setIsEditing] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccesMessage] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (apps.length === 0) {
      dispatch(fetchApps());
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (storeData.fetched) {
      dispatch(getPluginConfig("phonepe"));
    }
    // eslint-disable-next-line
  }, [storeData]);

  useEffect(() => {
    if (Object.keys(appState?.phonepe).length > 0) {
      let form = {};
      Object.keys(appState?.phonepe).forEach((key) => {
        form[key] = {
          value: appState.phonepe[key],
          touched: false,
          error: "",
        };
      });
      setFormState(form);
    }

    // eslint-disable-next-line
  }, [appState.phonepe]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let error = "";
    setFormState((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        value: value,
        touched: true,
        error: error,
      },
    }));
  };

  const getMessage = (name) => {
    const messages = {
      merchant_id: "please enter a merchant id",
      secret_key: "please enter a valid password",
      salt_index: "plese enter a salt index",
    };
    return messages[name] || "";
  };

  const onBlur = (event) => {
    const { name } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        error: !prevState[name].value
          ? getMessage(name)
          : prevState[name].error,
      },
    }));
  };

  const onFocus = (event) => {
    const { name } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        touched: true,
        error: "",
      },
    }));
  };

  const handleCopyToClipboard = (text) => () => {
    const result = copyToClipboard(text);
    if (!result) {
      setShowError(true);
    } else {
      setShowSuccess(true);
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
    let clonedForm = { ...initialState };
    clonedForm.merchant_id.value = formState.merchant_id.value;
    setFormState(clonedForm);
  };
  const validattion = () => {
    let errorFlag = false;
    let updatedState = { ...formState };
    Object.keys(updatedState).forEach((key) => {
      if (!updatedState[key].value) {
        errorFlag = true;
        updatedState[key].error = getMessage(key);
      }
    });
    setFormState(updatedState);
    return errorFlag;
  };

  const navigateToUrl = () => {
    if (isEditing) {
      setIsEditing(false);
      let form = {};
      Object.keys(appState.phonepe).forEach((key) => {
        form[key] = {
          value: appState.phonepe[key],
          touched: false,
          error: "",
        };
      });
      setFormState(form);
    } else {
      history.goBack();
    }
  };

  const handleSave = async () => {
    let isError = validattion();
    if (isError) return;
    setLoading(true);
    dispatch(
      postPluginConfig("phonepe", {
        merchant_id: formState.merchant_id.value,
        secret_key: formState.secret_key.value,
        salt_index: formState.salt_index.value,
      })
    ).then((cobj) => {
      if (cobj.error) {
        setErrorMessage(cobj.message);
        setShowError(true);
        setLoading(false);
      } else {
        setShowSuccess(true);
        setSuccesMessage("phonepe configuration settings saved successfully.");
        setLoading(false);
      }
    });
  };
  if (!appState.phonepe) {
    return <PageLoader />;
  }
  return (
    <div className={layoutCenterContainer}>
      <div className={centerContainer}>
        <div className={classes.breadcrumbsContainer}>
          <div className={classes.backDiv}>
            <HeaderBreadcrumbs list={list} />
          </div>
        </div>
        <Paper elevation={0} className={classes.paperCard}>
          <div>
            <div className={classes.headingButtonSection}>
              <Typography className={classes.formHead}>
                PhonePe Configuration Settings
              </Typography>
              {formState["webhook_url"]?.value && (
                <FilledEditButton onClick={handleEdit} />
              )}
            </div>

            <Controls.Input
              name="merchant_id"
              label="Merchant Id"
              value={formState.merchant_id ? formState.merchant_id.value : ""}
              onChange={handleInputChange}
              error={
                formState.merchant_id && formState.merchant_id.error.length > 0
                  ? formState.merchant_id.error
                  : ""
              }
              onFocus={onFocus}
              onBlur={onBlur}
              labelPlacement="top"
              disabled={formState["webhook_url"]?.value ? true : false}
            />
            <Controls.Input
              name="secret_key"
              label="Salt Secret"
              value={
                formState.token
                  ? formState.token.value
                  : formState.secret_key
                  ? formState.secret_key.value
                  : ""
              }
              onChange={handleInputChange}
              error={
                formState.secret_key && formState.secret_key.error.length > 0
                  ? formState.secret_key.error
                  : ""
              }
              onFocus={onFocus}
              onBlur={onBlur}
              labelPlacement="top"
              disabled={formState["webhook_url"]?.value ? true : false}
              endAdornment={
                formState["webhook_url"]?.value ? (
                  <></>
                ) : !showPass ? (
                  <RemoveRedEyeOutlined
                    style={{ cursor: "pointer", color: "#2238A8" }}
                    onClick={() => {
                      setShowPass(true);
                    }}
                  />
                ) : (
                  <VisibilityOffOutlined
                    style={{ cursor: "pointer", color: "#2238A8" }}
                    onClick={() => {
                      setShowPass(false);
                    }}
                  />
                )
              }
              type={showPass ? "text" : "password"}
            />
            <Controls.Input
              name="salt_index"
              label="Salt Index"
              value={formState.salt_index ? formState.salt_index.value : ""}
              onChange={handleInputChange}
              error={
                formState.salt_index && formState.salt_index.error.length > 0
                  ? formState.salt_index.error
                  : ""
              }
              onFocus={onFocus}
              onBlur={onBlur}
              labelPlacement="top"
              disabled={formState["webhook_url"]?.value ? true : false}
            />
            {formState["webhook_url"]?.value && (
              <WebhookDetails
                formData={formState}
                handleCopyToClipboard={handleCopyToClipboard}
              />
            )}
            {
              formState[
                "webhook_url" && <Divider style={{ margin: "32px 0px" }} />
              ]
            }
          </div>
          {!formState["webhook_url"]?.value && (
            <>
              <div className={classes.button}>
                <CombinedButtons
                  outlinedBtnText="Back"
                  outlinedBtnAction={navigateToUrl}
                  solidBtnAction={handleSave}
                  loading={loading}
                />
              </div>
            </>
          )}
        </Paper>
        <Toast
          message={errorMessage ? errorMessage : "Could not copy to clipboard"}
          open={showError}
          close={() => setShowError(false)}
          severity="error"
          autoClose
        />
        <Toast
          message={successMessage ? successMessage : "Copied to clipboard"}
          open={showSuccess}
          close={() => setShowSuccess(false)}
          autoClose
        />
      </div>
    </div>
  );
};
export default PhonePeConfig;
