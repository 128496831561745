// Importing types
import {
  SETAPPS,
  TOGGLEAPPSLOADING,
  INSTALLFAILED,
  INSTALLSUCCESS,
  INSTALLLOADING,
  CLEARAPPS,
  LOADFAILED,
  SETCHECKOUTFIELDS,
  CHECKOUTFIELDACTIONLOADING,
  DELETECHECKOUTFIELD,
  SETPIXELVALUES,
  CHECKOUTFIELDACTIONSUCCESS,
  CHECKOUTFIELDACTIONFAILED,
  RESETCHECKOUTACTION,
  SETPLUGINCONFIG,
  CONFIGACTIONSTATUS,
  AMENDCHECKOUTFIELD,
  CLEARAPPSTATE,
} from "./appTypes";
// Util imports
import { getDefaultStore } from "../../utils/storeUtil";
// Actions
const noApps = ["seo", "advance-shipping", "review-rating", "wishlist"];

const slugToPlugin = {
  "facebook-conversion-api": "conversion",
};

const getPluginNameOrSlug = (slug) => slugToPlugin[slug] || slug;

const setApps = (apps) => {
  apps = apps.filter((app) => !noApps.includes(app.slug));
  return {
    type: SETAPPS,
    apps: apps,
  };
};

const loadFailed = () => {
  return {
    type: LOADFAILED,
  };
};

const toggleLoading = () => {
  return { type: TOGGLEAPPSLOADING };
};

const toggleInstallLoading = () => {
  return { type: INSTALLLOADING };
};

/*
Note: installStatus->true denotes that the app was installed
installStatus->false denotes that the app was uninstalled
*/
const installSuccess = (appSlug, installStatus) => {
  return {
    type: INSTALLSUCCESS,
    slug: appSlug,
    status: installStatus,
  };
};

const installfailed = () => {
  return { type: INSTALLFAILED };
};

export const fetchApps = () => async (dispatch) => {
  try {
    dispatch(toggleLoading());
    const store = getDefaultStore();
    let accessToken = localStorage.getItem("access_token");
    let response = await fetch(
      `${process.env.REACT_APP_BETA_API}/api/v1/store/${store.store_id}/plugins`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    let data = await response.json();
    if (!response.ok) {
      throw data;
    }
    dispatch(setApps(data.payload));
  } catch (err) {
    console.log(err);
    dispatch(loadFailed());
  }
};

export const fetchApp = async (appSlug) => {
  try {
    const store = getDefaultStore();
    let accessToken = localStorage.getItem("access_token");
    let response = await fetch(
      `${process.env.REACT_APP_BETA_API}/api/v1/store/${store.store_id}/plugins/${appSlug}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    let data = await response.json();
    return data.payload;
  } catch (err) {
    console.log(err);
    return {};
  }
};

export const installApp = (appSlug) => async (dispatch) => {
  try {
    dispatch(toggleInstallLoading());
    const store = getDefaultStore();
    let accessToken = localStorage.getItem("access_token");
    let response = await fetch(
      `${process.env.REACT_APP_BETA_API}/api/v1/store/${store.store_id}/plugins/slug/${appSlug}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ installed: true }),
      }
    );
    let data = await response.json();
    if (!response.ok) {
      throw data;
    }
    dispatch(installSuccess(appSlug, true));
  } catch (err) {
    console.log(err);
    dispatch(installfailed());
  }
};

export const unInstallApp = (appSlug) => async (dispatch) => {
  try {
    dispatch(toggleInstallLoading());
    const store = getDefaultStore();
    let accessToken = localStorage.getItem("access_token");
    let response = await fetch(
      `${process.env.REACT_APP_BETA_API}/api/v1/store/${store.store_id}/plugins/slug/${appSlug}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ installed: false }),
      }
    );
    let data = await response.json();
    if (!response.ok) {
      throw data;
    }
    dispatch(installSuccess(appSlug, false));
    dispatch(clearAppState(appSlug));
  } catch (err) {
    console.log(err);
    dispatch(installfailed());
  }
};

export const clearAppState = (appSlug) => {
  return {
    type: CLEARAPPSTATE,
    payload: getPluginNameOrSlug(appSlug),
  };
};

export const clearApps = () => {
  return {
    type: CLEARAPPS,
  };
};

export const toggleCheckoutActionLoading = () => {
  return { type: CHECKOUTFIELDACTIONLOADING };
};

export const setCheckoutFields = (fields) => {
  fields = fields.sort((a, b) => -(a.position - b.position));
  return { type: SETCHECKOUTFIELDS, payload: fields };
};

export const checkoutActionSuccess = (message) => {
  return { type: CHECKOUTFIELDACTIONSUCCESS, message };
};

export const checkoutActionFailed = (message) => {
  return { type: CHECKOUTFIELDACTIONFAILED, message };
};

export const resetCheckoutActions = () => {
  return { type: RESETCHECKOUTACTION };
};

export const removeField = (fieldSlug) => {
  return { type: DELETECHECKOUTFIELD, payload: fieldSlug };
};

export const amendField = (fieldSlug, fieldData) => {
  return {
    type: AMENDCHECKOUTFIELD,
    payload: { slug: fieldSlug, data: fieldData },
  };
};

export const getCheckoutFields = () => async (dispatch, state) => {
  try {
    const store = state().store.store;
    dispatch(toggleCheckoutActionLoading());
    let accessToken = localStorage.getItem("access_token");
    let response = await fetch(
      `${process.env.REACT_APP_API}/api/v1/org/store/${store.slug}/order-fields`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    let data = await response.json();
    dispatch(setCheckoutFields(data.payload));
  } catch (err) {
    console.log(err);
    dispatch(checkoutActionFailed("Failed to fetch fields"));
  }
};

export const rearrangeFields = (d) => async (dispatch, state) => {
  try {
    const store = state().store.store;
    dispatch(toggleCheckoutActionLoading());
    let accessToken = localStorage.getItem("access_token");
    let response = await fetch(
      `${process.env.REACT_APP_API}/api/v1/org/store/${store.slug}/order-fields/position`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(d),
      }
    );
    let data = await response.json();
    dispatch(setCheckoutFields(data.payload));
  } catch (err) {
    console.log(err);
    dispatch(checkoutActionFailed("Failed to rearrange fields"));
  }
};

export const deleteField = (fieldSlug) => async (dispatch, state) => {
  try {
    dispatch(toggleCheckoutActionLoading());
    const store = state().store.store;
    let accessToken = localStorage.getItem("access_token");
    let response = await fetch(
      `${process.env.REACT_APP_API}/api/v1/org/store/${store.slug}/order-fields/${fieldSlug}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (!response.ok) {
      throw response;
    } else {
      dispatch(removeField(fieldSlug));
      dispatch(checkoutActionSuccess("Successfully deleted fields"));
    }
  } catch (err) {
    console.log(err);
    dispatch(checkoutActionFailed("Failed to delete field"));
  }
  dispatch(resetCheckoutActions());
};

export const editField = (fieldSlug, fieldData) => async (dispatch, state) => {
  try {
    dispatch(toggleCheckoutActionLoading());
    const store = state().store.store;
    let accessToken = localStorage.getItem("access_token");
    let response = await fetch(
      `${process.env.REACT_APP_API}/api/v1/org/store/${store.slug}/order-fields/${fieldSlug}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(fieldData),
      }
    );
    if (!response.ok) {
      throw response;
    } else {
      dispatch(amendField(fieldSlug, fieldData));
      dispatch(checkoutActionSuccess("Successfully edited field"));
    }
  } catch (err) {
    console.log(err);
    dispatch(checkoutActionFailed("Failed to edit checkout field"));
  }
  dispatch(resetCheckoutActions());
};

export const createField = (data) => async (dispatch, state) => {
  try {
    dispatch(toggleCheckoutActionLoading());
    const stateData = state();
    const store = stateData.store.store;
    let accessToken = localStorage.getItem("access_token");
    data.context = "CHECKOUT_FIELDS";
    let response = await fetch(
      `${process.env.REACT_APP_API}/api/v1/org/store/${store.slug}/order-fields/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data),
      }
    );
    let responseData = await response.json();
    if (!response.ok) {
      throw responseData;
    }
    stateData.apps.checkoutFields.push(responseData.payload);
    dispatch(setCheckoutFields(stateData.apps.checkoutFields));
    dispatch(checkoutActionSuccess("Successfully created field"));
  } catch (err) {
    console.log(err);
    dispatch(checkoutActionFailed("Failed to create checkout field"));
  }
  dispatch(resetCheckoutActions());
};

export const setPixelValues = (name, data) => {
  let value = "";
  if (data) {
    value = data.value;
  }
  return { type: SETPIXELVALUES, payload: { name, data: value } };
};

export const postPixelValue = (data) => async (dispatch, state) => {
  try {
    const stateData = state();
    let accessToken = localStorage.getItem("access_token");
    const store = stateData.store.store;
    let response = await fetch(
      `${process.env.REACT_APP_API}/api/v1/org/store/${store.slug}/group-names/pixels/attributes`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data),
      }
    );
    if (!response.ok) {
      let error = await response.json();
      return { error: true, message: error.message };
    }
    dispatch(setPixelValues(data[0].name, data[0]));
    return { data: data[0] };
  } catch (err) {
    return { error: true, message: "Failed to save pixel value" };
  }
};

export const getPixelValue = (name) => async (dispatch, state) => {
  try {
    const stateData = state();
    let accessToken = localStorage.getItem("access_token");
    const store = stateData.store.store;
    let response = await fetch(
      `${process.env.REACT_APP_API}/api/v1/org/store/${store.slug}/group-names/pixels/attributes/${name}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (!response.ok) {
      throw response;
    }
    const data = await response.json();
    dispatch(setPixelValues(name, data.payload));
  } catch (err) {
    console.log(err);
  }
};

const setPluginConfig = (plugin, config) => {
  return { type: SETPLUGINCONFIG, payload: config, plugin };
};

export const setConfigActionStatus = (status) => {
  return { type: CONFIGACTIONSTATUS, payload: status };
};

export const getPluginConfig = (plugin) => async (dispatch, state) => {
  try {
    const stateData = state();
    let accessToken = localStorage.getItem("access_token");
    const store = stateData.store.store;
    let response = await fetch(
      `${process.env.REACT_APP_BETA_API}/api/v1/store/${store.id}/plugins/${plugin}/configs`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (!response.ok) {
      throw response;
    }
    const data = await response.json();
    dispatch(setPluginConfig(getPluginNameOrSlug(plugin), data.payload.config));
    return data.payload.config;
  } catch (err) {
    console.log(err);
    return {};
  }
};

export const postPluginConfig = (plugin, config) => async (dispatch, state) => {
  try {
    const stateData = state();
    let accessToken = localStorage.getItem("access_token");
    const store = stateData.store.store;
    config.type = plugin;
    let configuration = Object.keys(config).map((key) => ({
      name: key,
      value: config[key],
    }));
    let response = await fetch(
      `${process.env.REACT_APP_BETA_API}/api/v1/store/${store.id}/plugins/${plugin}/configs`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          store_id: store.id,
          plugin_slug: plugin,
          config: configuration,
        }),
      }
    );
    if (!response.ok) {
      let error = await response.json();
      return { error: true, message: error.message };
    } else {
      let data = await response.json();
      dispatch(
        setPluginConfig(getPluginNameOrSlug(plugin), data.payload.config)
      );
      return { data: data.payload.config };
    }
  } catch (err) {
    return { error: true, message: "Failed to save config" };
  }
};
