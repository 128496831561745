import React from "react";
import { Button, Typography, Box } from "@material-ui/core";
import { useStyles } from "../styles";
import DiamondIcon from "./DiamondIcon";
import BusinessDiamondIcon from "./BusinessDiamondIcon";
import { Done } from "@material-ui/icons";
import { getDay } from "../../../utils/dateUtils";
import { useSelector } from "react-redux";
const planDescriptions = {
  Basic: "Perfect for quickly setting up a Store with your own Domain",
  Professional:
    "Ideal for connecting your Domain, Android app, and Managing staff",
  Business:
    "Best for expert Store Setup, Android app, Premium support and Blog",
};
export default function Plan(props) {
  const percentoff = ((props.mrp - props.amount) / props.mrp) * 100;
  const store = useSelector((state) => state.store.store);
  const countrySymbol = store.currency_symbol;
  const percentageOffInt = percentoff.toFixed(0);
  const classes = useStyles();
  const planDesc = planDescriptions[props.planTitle]
    ? planDescriptions[props.planTitle]
    : "";
  const calculateRemainingDays = (renewDate) => {
    if (!renewDate) return null;
    const renewTime = getDay(new Date(renewDate)).getTime();
    const nowTime = getDay(new Date()).getTime();
    return Math.floor((renewTime - nowTime) / (1000 * 3600 * 24));
  };
  const dueIn = props.renewObj
    ? calculateRemainingDays(props.renewObj.renew_date)
    : null;

  const getFeatureIcon = (slug) => {
    switch (slug) {
      case "everything_on_basic":
        return (
          <DiamondIcon
            style={{
              width: "20px",
              height: "20px",
            }}
          />
        );
      case "everything_on_app_pro":
        return (
          <BusinessDiamondIcon
            style={{
              width: "20px",
              height: "20px",
            }}
          />
        );
      default:
        return <Done className={classes.bluetickField} />;
    }
  };
  const renewObj = props.renewObj && props.renewObj;
  return (
    <div
      className={`${classes.planCard} ${
        props.planTitle === "Business" &&
        (renewObj?.plan === "FREE" ||
          renewObj?.plan === "BUSINESS_APP_PRO" ||
          renewObj?.status === "EXPIRED" ||
          renewObj?.plan === "BASIC") &&
        !props.isCurrent
          ? classes.planCardProfessional
          : ""
      }`}
    >
      <div>
        <Typography
          className={classes.planTitle}
          style={props.isFree ? { marginTop: "65px" } : {}}
        >
          {props.planTitle}
          {props.planTitle === "Business" &&
            (renewObj?.plan === "FREE" ||
              renewObj?.plan === "BUSINESS_APP_PRO" ||
              renewObj?.status === "EXPIRED" ||
              renewObj?.plan === "BASIC") &&
            !props.isCurrent && (
              <span
                style={{
                  backgroundColor: "#9C75FF",
                  padding: "2px 5px",
                  fontSize: "14px",
                  borderRadius: "5px",
                  color: "white",
                  marginLeft: "10px",
                }}
              >
                Recommended
              </span>
            )}
        </Typography>

        <Typography className={classes.pricing}>
          <span style={{ paddingRight: "3px" }} className={classes.ruppesiocn}>
            {countrySymbol}
          </span>{" "}
          <span className={classes.pricingAmount}>{props.amount}</span>{" "}
          <span className={classes.priceoblic}>/</span>
          <span className={classes.pricingValidity}>{props.validityText}</span>
        </Typography>

        <Typography className={classes.real_pricing}>
          <div className={classes.pricelightarrow}>
            <span
              style={{ paddingRight: "3px" }}
              className={classes.real_ruppesiocn}
            >
              {countrySymbol}
            </span>
            <span className={classes.real_pricingAmount}>{props.mrp}</span>
            <span className={classes.real_priceoblic}>/</span>
            <span className={classes.real_pricingValidity}>
              {props.validityText}
            </span>
          </div>

          <span className={classes.real_discoutpercent}>
            <span>({percentageOffInt}% off)</span>
          </span>
        </Typography>
        <Typography className={classes.planSubTitle}>{planDesc}</Typography>
      </div>
      <div className={classes.planBody}>
        <Button
          variant="contained"
          disableElevation
          disabled={
            props.renewObj && props.renewObj.status === "EXPIRED"
              ? false
              : props.isCurrent || !props.isButtonActive
          }
          className={[
            renewObj.plan === "BASIC" || renewObj.plan === "FREE" || dueIn < -7
              ? classes.professionalbtn
              : classes.domainBtn,
            props.isCurrent &&
            props.renewObj &&
            props.renewObj?.status !== "EXPIRED"
              ? classes.inactiveDomainBtn
              : "",
            props.planTitle === "Business" &&
            (renewObj?.plan === "FREE" ||
              renewObj?.plan === "BUSINESS_APP_PRO" ||
              renewObj?.status === "EXPIRED" ||
              renewObj?.plan === "BASIC") &&
            !props.isCurrent
              ? classes.blueBackground
              : "",
            props.planTitle === "Professional" && !props.isCurrent
              ? classes.isButtonActive
              : "",
          ].join(" ")}
          onClick={props.onPlanSelect}
        >
          {props.renewObj && props.renewObj.status === "EXPIRED"
            ? "Upgrade"
            : props.isCurrent
            ? "Current Plan"
            : "Upgrade"}
        </Button>

        <Typography className={classes.keyhighlights}>
          Key Highlights:
        </Typography>

        <Box
          display="flex"
          height={`${props.calculatedHeight}px`}
          flexDirection="column"
          alignItems="stretch"
        >
          {props.features.slice(0, -1).map((feature, index) => {
            const featureIcon = getFeatureIcon(feature.slug);
            return (
              <Box
                key={feature.id}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                style={{ margin: "8px 0px" }}
              >
                {index === 0 ? (
                  featureIcon
                ) : (
                  <Done className={classes.bluetickField} />
                )}
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  style={{ margin: "auto", marginLeft: "12px" }}
                >
                  <Typography className={classes.featureTitle}>
                    {feature.title}
                  </Typography>
                </Box>
              </Box>
            );
          })}
        </Box>
        <Typography key={props.id} className={classes.renewal_typo}>
          <span className={classes.renewalat}>Renewal at: </span>
          <span className={classes.renewalatruppesicon}> {countrySymbol} </span>
          <span className={classes.renewalmaount}>{props.renewal_amount} </span>
          <span className={classes.renewal_domain}>
            <span>yearly </span>
            <span>+ </span>
            <span>Domain charges </span>
          </span>
        </Typography>
        <Typography
          style={{
            fontWeight: 600,
            fontSize: "12px",
            background: "#F9F9F9",
            padding: "12px",
            borderRadius: "5px",
          }}
        >
          {props.features.slice(-1).map((feature, id) => (
            <span key={id}>
              <img
                style={{ width: "20px", height: "20px", marginRight: "6px" }}
                src={`${process.env.PUBLIC_URL}${feature.icon_url}`}
                alt={feature.slug}
              />
              <span>{feature.title}</span>
            </span>
          ))}
        </Typography>
      </div>
    </div>
  );
}
