import React, { useState } from "react";
import { BASIC, BUSINESS_APP_PRO } from "../plans/constants";
import { makeStyles, useMediaQuery } from "@material-ui/core";
import HeaderBreadcrumbs from "../../components/common/HeaderBreadcrumbs";
import { NavLink, useRouteMatch, useLocation } from "react-router-dom";
import StoreDetailsIcon from "../../icons/StoreDetailsIcon";
import PaymentsSettingsIcon from "../../icons/PaymentsSettingsIcon";
import DelhverySettingsIcon from "../../icons/DelhverySetiingsIcon";
import ProductsSettingsIcon from "../../icons/ProductsSettingsIcon";
import SeoSettingIcon from "../../icons/SeoSettingIcon";
import NotificationSettingsIcon from "../../icons/NotificationSettingsIcon";
import LoginSettingIcon from "../../icons/LoginSettingIcon";
import LabelIcon from "../../icons/LabelIcon";
import ReturnOrderIcon from "../../icons/ReturnOrderIcon";
import DocumentIcon from "../../icons/DocumentIcon";
import StoreDomainIcon from "../../icons/StoreDomainIcon";
import UrlRedirectsIcon from "../../icons/UrlRedirectsIcon";
import CheckoutIcon from "../../icons/CheckoutIcon";
import CurrencyIcon from "../../icons/CurrencyIcon";
const navigationItems = [
  {
    title: "Store Details",
    slug: "store-details",
    icon: <StoreDetailsIcon />,
  },
  {
    title: "Store Domain",
    slug: "store-domain",
    icon: <StoreDomainIcon />,
  },
  {
    title: "Products Settings",
    slug: "product-and-order",
    icon: <ProductsSettingsIcon />,
  },
  {
    title: "Checkout Settings",
    slug: "checkout",
    icon: <CheckoutIcon />,
  },
  {
    title: "Currency",
    slug: "currency",
    icon: <CurrencyIcon />,
  },
  {
    title: "Delivery Settings",
    slug: "delivery",
    icon: <DelhverySettingsIcon />,
  },
  {
    title: "Payment Settings",
    slug: "payments",
    icon: <PaymentsSettingsIcon />,
  },
  {
    title: "Return Order Settings",
    slug: "return-order",
    icon: <ReturnOrderIcon />,
  },

  {
    title: "Label Settings",
    slug: "label",
    icon: <LabelIcon />,
    plan: BASIC,
  },
  {
    title: "SEO Settings",
    slug: "seo",
    icon: <SeoSettingIcon />,
  },
  {
    title: "URL Redirects",
    slug: "url-redirects",
    icon: <UrlRedirectsIcon />,
  },
  {
    title: "Notifications Settings",
    slug: "notifications",
    icon: <NotificationSettingsIcon />,
    plan: BUSINESS_APP_PRO,
  },
  {
    title: "Policies",
    slug: "policies",
    icon: <DocumentIcon />,
  },
  {
    title: "Login Settings",
    slug: "login",
    icon: <LoginSettingIcon />,
    plan: BUSINESS_APP_PRO,
  },
];
const useStyles = makeStyles((theme) => ({
  sidebar: {
    backgroundColor: "#FFFFFF",
    borderRadius: 8,
    position: "fixed",
    top: 126,
    padding: 12,
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    overflowY: "auto",
    maxHeight: "calc(100vh - 136px)",
    scrollbarWidth: "none",
    "-ms-overflow-style": "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  breadcrumbContainer: {
    position: "fixed",
    zIndex: 1000,
  },

  link: {
    cursor: "pointer",
    width: "100%",
    display: "flex",
    alignItems: "center",
    gap: 12,
    textDecoration: "none",
    color: "#666666",
    fontSize: 14,
    padding: "4px 8px",
    borderRadius: 6,
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#f4f6f9",
      textDecoration: "none",
      color: "#666666",
      fontWeight: 500,
    },
  },
  activeLink: {
    fontWeight: 600,
    color: "#1A1A1A",
    backgroundColor: "#F1F4FF",
    "&:hover": {
      fontWeight: 600,
      backgroundColor: "#F1F4FF",
      color: "#1A1A1A",
    },
  },
  listItem: {
    marginBottom: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  title: {
    fontSize: 14,
    lineHeight: "20px",
    display: "flex",
    alignItems: "center",
    height: 20,
  },
  icon: {
    fill: "#666666",
    transition: "fill 0.3s ease",
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

function getLastSegment(pathname) {
  let segments = pathname.replace(/\/$/, "").split("/");
  return segments[segments.length - 1];
}

export default function NavigationSidebar() {
  const { url } = useRouteMatch();
  const location = useLocation();
  const isSmallScreen = useMediaQuery("(max-width:1400px)");
  const classes = useStyles();

  const [activeItem, setActiveItem] = useState(
    getLastSegment(location.pathname)
  );

  const handleNavLinkClick = (slug) => setActiveItem(slug);

  return (
    <div>
      <div className={classes.breadcrumbContainer}>
        <HeaderBreadcrumbs list={[{ label: "Store Settings" }]} />
      </div>
      <div
        className={classes.sidebar}
        style={{ width: isSmallScreen ? "240px" : "300px" }}
      >
        {navigationItems.map((item) => (
          <div key={item.slug} className={classes.listItem}>
            <NavLink
              to={`${url}/${item.slug}`}
              className={`${classes.link} ${
                activeItem === item.slug ? classes.activeLink : ""
              }`}
              onClick={() => handleNavLinkClick(item.slug)}
            >
              <div className={classes.icon} style={{ marginTop: 0 }}>
                {activeItem === item.slug
                  ? React.cloneElement(item.icon, {
                      fill: "#1641DB",
                      strokeWidth: 1,
                    })
                  : item.icon}
              </div>
              <div className={classes.title}>{item.title}</div>
            </NavLink>
          </div>
        ))}
      </div>
    </div>
  );
}
