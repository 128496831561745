import parsePhoneNumberFromString from "libphonenumber-js";

export const validatePhoneNumber = (phoneNumber, countryCode) => {
  console.log(phoneNumber, countryCode);
  try {
    const phoneNumberObj = parsePhoneNumberFromString(phoneNumber, countryCode);
    if (phoneNumberObj && phoneNumberObj.isValid()) {
      return true;
    }
  } catch (error) {
    console.error("Validation error:", error);
  }
  return false;
};
