import React from "react";

export const nativeHelperText = {
  purchase_price: "Goods purchase price (visible to you only)",
  summary: "2-3 Highlight points e.g., 4 Star Frost Free Refrigerator.",
  description: "Product detailed information",
  measuring_unit: "Measurng units of the product e.g Kilogram, Litre",
  min_qty: "Minimum quantity to be sold per order",
  max_qty: "Maximum quantity to be sold per order",
  brand: "Product brand information",
  barcode: "ISBN, UPC or other unique code (visible to you only)",
  product_id: "Helpful in identifying the product (visible to you only)",
  hsn_code: "6 digit code, helpful in GST bills",
  weight: "Helpful for weight based shipping",
  dimension: "Helpful for dimension based shipping",
  condition: "Condition of the product e.g New, Used",
  gender: "Gender for whom your product is designed",
  age_group: "Demographic that your product is designed for",
  mpn: "Manufacturer Part Number",
  gtin: "Unique Global Trade Item Number",
  material: "Main fabric or material of the product",
  pattern: "Describes the pattern or graphic print on your product",
  no_gmc:
    "Control whether to Sync / Don't Sync a product to Google Merchant Center",
};

export const nonGmcNativeFieldsConfig = [
  {
    name: "purchase_price",
    label: "Purchase Price",
  },
  { name: "summary", label: "Summary" },
  { name: "description", label: "Description" },
  { name: "brand", label: "Brand" },
  { name: "barcode", label: "Barcode" },
  { name: "hsn_code", label: "HSN" },
  { name: "min_qty", label: "Min Quantity" },
  { name: "max_qty", label: "Max Quantity" },
  { name: "product_id", label: "Product ID" },
  { name: "weight", label: "Shipping Weight" },
  { name: "dimension", label: "Dimension" },
];

export const gmcNativeFieldsConfig = [
  { name: "condition", label: "Condition" },
  { name: "gender", label: "Gender" },
  { name: "age_group", label: "Age Group" },
  { name: "mpn", label: "MPN" },
  { name: "gtin", label: "GTIN" },
  { name: "material", label: "Material" },
  { name: "pattern", label: "Pattern" },
  { name: "no_gmc", label: "Sync to Google" },
];

export const productFiledTypes = [
  { id: "VARIANT", title: "Variant Field" },
  { id: "ATTRIBUTE", title: "Attribute Field" },
  { id: "CHECKOUT", title: "Checkout Field", plan: "BUSINESS" },
];

export const fieldDataType = [
  { id: "TEXT", title: "Text" },
  { id: "FILE", title: "File" },
  { id: "DATE_TIME", title: "Date Time" },
  { id: "DATE", title: "Date" },
  { id: "LIST", title: "List" },
];

export const nativeFieldsConfig = [
  {
    name: "brand",
    label: "Brand",
    type: "text",
    fieldType: "input",
    placeholder: "",
    startAdornment: null,
    endAdornment: null,
  },
  {
    name: "barcode",
    label: "Barcode",
    type: "text",
    fieldType: "input",
    placeholder: "",
    startAdornment: null,
    endAdornment: null,
  },
  {
    name: "product_id",
    label: "Product ID",
    type: "text",
    fieldType: "input",
    placeholder: "",
    startAdornment: null,
    endAdornment: null,
  },
  {
    name: "hsn_code",
    label: "HSN",
    type: "text",
    fieldType: "input",
    placeholder: "",
    startAdornment: null,
    endAdornment: null,
  },

  {
    name: "weight",
    label: "Shipping Weight",
    type: "number",
    fieldType: "input",
    placeholder: "",
    startAdornment: null,
    endAdornment: (
      <span style={{ fontSize: 16, lineHeight: "16px", color: "#999999" }}>
        Kgs
      </span>
    ),
  },
];

export const getFieldTitle = (fieldId) => {
  const field = fieldDataType.find((field) => field.id === fieldId);
  return field.title;
};

const parseTime = (timeStr) => {
  const [hours, minutes] = timeStr.split(":");
  const time = new Date();
  time.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0, 0);
  return time;
};

const calculateDuration = (min_buffer, max_buffer, min_time, max_time) => {
  const startTime = parseTime(min_time);
  const endTime = parseTime(max_time);

  const durationMs = endTime - startTime;

  const days = parseInt(max_buffer, 10) - parseInt(min_buffer, 10);
  const hours = Math.floor(
    (durationMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );

  return { days, hours };
};

export const getFieldValue = (field) => {
  switch (field.field_type) {
    case "TEXT":
      return `${field.settings?.text_length}`;
    case "FILE":
      return [
        field.settings?.types.includes("DOCUMENT") ? "Document" : "",
        field.settings?.types.includes("IMAGE") ? "Image" : "",
      ]
        .filter(Boolean)
        .join(", ");
    case "DATE_TIME":
      const { min_buffer, max_buffer, min_time, max_time } = field.settings;

      const durationResult = calculateDuration(
        min_buffer,
        max_buffer,
        min_time,
        max_time
      );

      const { days, hours } = durationResult;

      return `${days} days, ${hours} hours`;
    case "DATE":
      const { min_buffer: minDate, max_buffer: maxDate } = field.settings;

      return `${parseInt(maxDate, 10) - parseInt(minDate, 10)} days`;

    default:
      return "";
  }
};

export const getAdditionalFields = (formData) => {
  if (formData.productFieldType.value !== "CHECKOUT") {
    return [];
  }

  const dataTypeToFields = {
    TEXT: ["textLimit"],
    FILE: ["documentType", "imageType"],
    DATE_TIME: [
      "minBufferPeriod",
      "maxBufferPeriod",
      "minTimeRange",
      "maxTimeRange",
    ],
    DATE: ["minBufferPeriod", "maxBufferPeriod"],
    LIST: ["listOptions"],
  };

  if (formData.fieldDataType.value === "none") {
    return ["fieldDataType"];
  }

  const fields = dataTypeToFields[formData.fieldDataType.value];

  return fields || [];
};

export const makeSettingsField = (formData) => {
  const {
    fieldDataType,
    textLimit,
    documentType,
    imageType,
    minBufferPeriod,
    maxBufferPeriod,
    minTimeRange,
    maxTimeRange,
    productFieldType,
    listOptions,
  } = formData;

  switch (fieldDataType.value) {
    case "TEXT":
      if (productFieldType !== "VARIANT") {
        return {
          text_length: textLimit.value,
        };
      }
      break;
    case "FILE":
      const types = [
        documentType.value && "DOCUMENT",
        imageType.value && "IMAGE",
      ].filter(Boolean);

      return {
        types: types,
      };

    case "DATE_TIME":
      return {
        min_buffer: minBufferPeriod.value,
        max_buffer: maxBufferPeriod.value,
        min_time: minTimeRange.value,
        max_time: maxTimeRange.value,
      };

    case "DATE":
      return {
        min_buffer: minBufferPeriod.value,
        max_buffer: maxBufferPeriod.value,
      };

    case "LIST":
      return {
        list_options: listOptions.value,
      };

    default:
      return {};
  }
};

export const generateCategoryName = (title) => {
  const trimmedTitle = title.trim();
  const lowercased = trimmedTitle.toLowerCase();
  const newName = lowercased
    .replace(/[^\w\s-]/g, "")
    .replace(/\s+/g, "-")
    .replace(/-{2,}/g, "-")
    .substring(0, 50)
    .replace(/-+$/, "");

  return newName;
};

export const renderField = (field, selectedCategories) => {
  if (
    !field.categories ||
    !field.categories.length ||
    !selectedCategories.length
  ) {
    return false;
  }

  return field.categories.some((fieldCategory) =>
    selectedCategories.some(
      (selectedCat) =>
        selectedCat.id === fieldCategory.cat_slug ||
        selectedCat.cat_slug === fieldCategory.cat_slug
    )
  );
};

export const shouldRenderField = (field, selectedCategories) => {
  return !field.cat_slug || renderField(field, selectedCategories);
};

export function createProductFields(fields, fieldsData) {
  let fieldsMap = Object.fromEntries(
    fields.map((field) => [field.id.toString(), field])
  );
  return Object.keys(fieldsData)
    .map((fieldKey) => {
      const field = fieldsMap[fieldKey];
      if (!field) return null;
      const fieldNameLower = field.name?.toLowerCase();
      const { value, data } = fieldsData[fieldKey];
      if (fieldNameLower === "color" || fieldNameLower === "colour") {
        const [val, extraData] = value.split(":");
        if (!val) return null;
        return {
          ef_id: field.id,
          name: field.name,
          slug: field.slug,
          type: field.field_type,
          val: val,
          data: extraData || data,
        };
      } else {
        return {
          ef_id: field.id,
          name: field.name,
          slug: field.slug,
          type: field.field_type,
          val: value,
        };
      }
    })
    .filter((extraField) => extraField !== null);
}
