import React, { useEffect, useState } from "react";
import ShoopyIcon from "../../../icons/ShoopyIconLatest";
import { Typography, Modal, Link } from "@material-ui/core";
import useStyles from "./styles";
import MobileForm from "./MobileForm";
import OTPVerifyForm from "./OTPVerifyForm";
import HeartIcon from "../../../icons/HeartIcon";
import IndiaFlagIcon from "../../../icons/IndiaFlagIcon";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import { isCustomDomain } from "../../../utils/utils";
import { useDispatch } from "react-redux";
import { fetchCurrency } from "../../../redux/currency/currenyActions";

const SignIn = ({
  onSuccess,
  newAcc,
  setNewAcc,
  otpSent,
  setOtpSent,
  onSubmit,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [record, setRecord] = useState({ email: "", mobile: "" });
  const [formType, setFormType] = useState("SIGNUP");
  const [inputType, setInputType] = useState("text"); // Lift the state here
  const customDomain = isCustomDomain();
  const [countryInfo, setCountryInfo] = useState({
    code: "",
    dialCode: "",
  });
  const onOtpSuccess = (contact, isNew) => {
    setOtpSent(true);
    setNewAcc(isNew);
    setRecord((prev) => ({
      ...prev,
      email: inputType === "email" ? contact : "",
      mobile: inputType === "mobile" ? "" : contact,
    }));
    setFormType(isNew ? "SIGNUP" : "SIGNIN");
  };

  useEffect(() => {
    let isSignupShown = localStorage.getItem("is_signup_shown");
    setFormType(isSignupShown ? "SIGNIN" : "SIGNUP");
  }, []);

  useEffect(() => {
    dispatch(fetchCurrency());
    // eslint-disable-next-line
  }, []);

  const onChangeNum = () => {
    setOtpSent(false);
    if (inputType === "phone") {
      setRecord((prev) => ({
        ...prev,
        mobile: `${prev.mobile}`,
      }));
    }
    if (inputType === "email") {
      setRecord((prev) => ({
        ...prev,
        email: `${prev.email}`,
      }));
    }
  };

  return (
    <>
      <div className={classes.loginBanner} style={{ padding: 24 }}>
        <Modal
          open={true}
          BackdropProps={{
            style: { backgroundColor: "fff" },
          }}
          className={classes.modal}
        >
          <div
            style={{
              borderRadius: 12,
              position: "absolute",
              background: "#ffffff",
              width: 432,
              padding: "24px 24px 12px 24px",
              outline: 0,
              boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.3)",
            }}
          >
            <div className={classes.signin}>
              <div style={{ maxWidth: 400, width: "100%" }}>
                {!customDomain && (
                  <div className={classes.icon}>
                    <ShoopyIcon width={40} height={40} viewBox="0 0 32 32" />
                    <span
                      style={{ fontSize: 20, fontWeight: 700, marginLeft: 16 }}
                    >
                      {" "}
                      Shoopy
                    </span>
                  </div>
                )}
                {otpSent ? (
                  <>
                    <Typography
                      style={{ fontSize: 24, fontWeight: 700, marginTop: 32 }}
                    >
                      Verify OTP
                    </Typography>
                    <div className={classes.subContainer}>
                      <Typography
                        className={classes.subheading}
                        style={{ marginTop: 0 }}
                      >
                        OTP sent to{" "}
                        {inputType === "email" ? (
                          <span>{record?.email}</span>
                        ) : (
                          <span>{record?.mobile}</span>
                        )}{" "}
                        <div
                          onClick={onChangeNum}
                          style={{
                            cursor: "pointer",
                            display: "inline-flex",
                            alignItems: "center",
                            position: "absolute",
                            top: "32%",
                            right: 24,
                          }}
                        >
                          <BorderColorIcon style={{ color: "#1641DB" }} />
                        </div>
                      </Typography>
                    </div>
                  </>
                ) : (
                  <>
                    <Typography variant="h2" className={classes.heading}>
                      {formType === "SIGNUP" ? "Sign Up" : "Sign In"}
                    </Typography>
                    <Typography
                      className={classes.subheading}
                      style={{ fontWeight: 400, color: "#757575" }}
                    >
                      Start managing your store by signing in
                    </Typography>
                  </>
                )}

                {otpSent ? (
                  <OTPVerifyForm
                    onSubmit={onSubmit}
                    mobile={record.mobile}
                    onSuccess={onSuccess}
                    newAcc={newAcc}
                  />
                ) : (
                  <>
                    <MobileForm
                      onOtpSuccess={onOtpSuccess}
                      formType={formType}
                      record={record}
                      otpSent={otpSent}
                      inputType={inputType} // Pass inputType as prop
                      setCountryInfo={setCountryInfo}
                      countryInfo={countryInfo}
                      setInputType={setInputType} // Pass setInputType as prop
                    />

                    <Typography
                      className={classes.subheading}
                      style={{ textAlign: "center", marginTop: 12 }}
                    >
                      {formType === "SIGNUP"
                        ? "Already have an account?"
                        : "Don't have an account?"}{" "}
                      &nbsp;
                      <span
                        style={{
                          color: "#1641DB",
                          cursor: "pointer",
                          fontWeight: 600,
                        }}
                        onClick={() =>
                          setFormType(
                            formType === "SIGNUP" ? "SIGNIN" : "SIGNUP"
                          )
                        }
                      >
                        {formType === "SIGNUP" ? "Sign In" : "Sign Up"}
                      </span>
                    </Typography>
                  </>
                )}

                <div className={classes.madeinindiactn}>
                  <div className={classes.madeinindiamsg}>
                    <Typography style={{ fontSize: 10, lineHeight: "12px" }}>
                      Made with &nbsp;
                    </Typography>
                    <HeartIcon />
                    <Typography style={{ fontSize: 10, lineHeight: "12px" }}>
                      &nbsp; in INDIA &nbsp;
                    </Typography>
                    <IndiaFlagIcon />
                  </div>
                </div>
              </div>
            </div>
            <Typography
              variant="body2"
              style={{
                textAlign: "center",
                fontSize: 10,
                position: "relative",
                bottom: "-52px",
              }}
            >
              By continuing, you agree to our{" "}
              <Link
                href="/terms-of-use"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#1A1A1A", textDecoration: "underline" }}
              >
                Terms of Use
              </Link>{" "}
              and{" "}
              <Link
                href="/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#1A1A1A", textDecoration: "underline" }}
              >
                Privacy Policy
              </Link>
              .
            </Typography>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default SignIn;
