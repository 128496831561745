import { useState } from "react";
import { HTML_COLLECTION } from "../../../../utils";
import {
  createCollections,
  updateCollection,
} from "../../../../../../redux/collections/collectionActions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

const initialForm = (record) => ({
  id: { value: record?.id ?? null, error: null },
  html_content: {
    value: record?.html_content ?? "<div>Hello world!</div>",
    error: null,
  },
  slug: { value: record?.slug ?? uuidv4() },
});

export const useHtmlCollection = ({ formState, record, setErrorToast }) => {
  const [form, setForm] = useState(initialForm(record));
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  const disabled = !form.html_content.value || !formState.name.value;

  const handleFormChange = (key, value) => {
    setForm((prev) => ({ ...prev, [key]: { value } }));
  };

  const handleFormMultiChange = (keyValue2DArr) => {
    setForm((prev) => {
      const updatedForm = { ...prev };
      keyValue2DArr.forEach(([key, value]) => {
        updatedForm[key] = { value };
      });
      return updatedForm;
    });
  };

  const onSave = async () => {
    console.log(record);
    const data = {
      ...(record && {
        id: form.id.value,
        position: record.position,
      }),
      slug: form.slug.value,
      type: HTML_COLLECTION,
      active: true,
      html_content: form.html_content.value,
      name: formState.name.value,
      show_title: false,
    };

    if (record) {
      await dispatch(updateCollection(record.id, data)).then((res) => {
        if (res.error) {
          setErrorToast({ value: true, msg: res.errorMsg });
          setLoading(false);
        } else {
          setLoading(false);
          history.goBack();
        }
      });
    } else {
      await dispatch(createCollections(data)).then((res) => {
        if (res.error) {
          setErrorToast({ value: true, msg: res.errorMsg });
          setLoading(false);
        } else {
          setLoading(false);
          history.goBack();
        }
      });
    }

    setLoading(false);
  };

  return {
    form,
    handleFormChange,
    handleFormMultiChange,
    disabled,
    onSave,
    loading,
  };
};
