import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const GenrateTextIcon = ({
  viewBox = "0 0 20 20",
  stroke = "#999999",
  fill = "#999999",
}) => {
  return (
    <SvgIcon viewBox={viewBox} style={{ width: "20px", height: "20px" }}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="10" cy="10" r="10" fill="#8019FF" />
        <path
          d="M6 10.7727C9.32386 10.7727 10.7727 9.37432 10.7727 6C10.7727 9.37432 12.2114 10.7727 15.5455 10.7727C12.2114 10.7727 10.7727 12.2114 10.7727 15.5455C10.7727 12.2114 9.32386 10.7727 6 10.7727Z"
          stroke="white"
          stroke-linejoin="round"
        />
        <path
          d="M5.95455 5C5.95455 6.38208 5.36123 6.95455 4 6.95455C5.36123 6.95455 5.95455 7.54395 5.95455 8.90909C5.95455 7.54395 6.54395 6.95455 7.90909 6.95455C6.54395 6.95455 5.95455 6.38208 5.95455 5Z"
          fill="white"
          stroke="white"
          stroke-width="0.781818"
          stroke-linejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default GenrateTextIcon;
